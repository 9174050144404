import React, { useState, useEffect } from 'react'
import {
    Button,
    Grid,
    Box,
    TextField,
    Backdrop,
    CircularProgress,
    Stack,
    Skeleton,  
    Paper
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux'
import { changeLocalData, changeStep, getActionLegalTypeCompany, getActionTypeCompany } from './stepFormSlice';
import { v4 as uuid } from 'uuid';
import { styled } from '@mui/material/styles';
import { RootState } from '../../../../app/store';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Select from 'react-select'
import required from '../../../../assets/img/icon/required.png'
import swal from 'sweetalert';


const Input = styled('input')({
    display: 'none',
  });

const FormLegalDocument : React.FC<any> = () => {

    const dispatch = useDispatch()
    const state_stepregister = useSelector((state : RootState) => state.step_register)
    const { type, loading_type, legal, loading_legal, local_data } = useSelector((state : RootState) => state.step_state)

    const [localData, setLocalData] = useState<any>({});
    const [legalDocState, setLegalDocState] = useState<any>([]);

    const [selectedTypeCompany, setSelectedTypeCompany] = useState<any>([]);
    const [errorTypeCompany, setErrorTypeCompany] = useState<boolean>(false);

    const handleChangeTypeCompany = (value: any) : void => {
        setErrorTypeCompany(false)
        dispatch(getActionLegalTypeCompany(value.label))
        setSelectedTypeCompany(value)
    }

    const [loading, setLoading] = useState(false);
    const [index, setIndex] = useState("");

    const onChangeValue = (index : any, value : any) => {
        let copyState = [...legalDocState]
        const newData = copyState.map((obj : any, i : any) => {
            if(i === index)
            return {
                ...obj,
                [value.target.name]: value.target.value,
            }
            return obj
        });
        setLegalDocState(newData)
    }

    const onChangeValueFile = (index : any, e : any) => {
        setLoading(true)
        setIndex(index)
        const copyState = [...legalDocState]
        const random = uuid();
        setTimeout(() => {
            var S3 = require("aws-sdk/clients/s3");
            const s3bucket = new S3({
                endpoint: process.env.REACT_APP_S3_BUCKET_ENDPOINT,
                accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
                secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET
            });
        
            if (e.target.files && e.target.files[0]) {
            const blob = e.target.files[0]
            const file_name = blob.name.replace(/\s/g, "")
            const params = { Body: blob, 
                            Bucket: process.env.REACT_APP_S3_BUCKET_NAME, 
                            Key: process.env.REACT_APP_S3_FOLDER_NAME + random + file_name
                            };
            s3bucket.putObject(params)
            .on('build', (request : any) => {
                request.httpRequest.headers.Host = process.env.REACT_APP_S3_API_URL
                request.httpRequest.headers['Content-Length'] = blob.size;
                request.httpRequest.headers['Content-Type'] = blob.type;
                request.httpRequest.headers['Access-Control-Allow-Origin']= '*';
                request.httpRequest.headers['x-amz-acl'] = 'public-read';
            })
            .send((err : any, data : any) => {
                if (err){  
                    swal("Error",  `${err.stack}`, "error")
                } 
                else {      
                    const imageUrl = `${process.env.REACT_APP_S3_CDN_URL}${process.env.REACT_APP_S3_FOLDER_NAME}${random}${file_name}`
                    const newData = copyState.map((obj : any, i : any) => {
                        if(i === index)
                        return {
                            ...obj,
                            file : {
                                url : imageUrl,
                                fileName : file_name,
                            }
                        }
                        return obj
                    });
                    setLegalDocState(newData)
                    setLoading(false)
                }}
            )} 
        }, 1000);
    }

    const onClickNext = (e : any) => {
        e.preventDefault()
        if(selectedTypeCompany.length === 0){
            setErrorTypeCompany(true)
        } else {
            const body_send = {
                legal_docs: legalDocState,
                type_company: selectedTypeCompany,
                company : localData.company ? localData.company : {},
                contactUs : localData.contactUs ? localData.contactUs : {},
                address : localData.address ? localData.address : {},
                bank : localData.bank ? localData.bank : {}
            }
            localStorage.setItem('detail_company', JSON.stringify(body_send))
            dispatch(changeStep(2))
        }
    }

    const proceedState = (data:any) => {
        let state = []
        for(const element of data) {
            state.push({
                company : "null",
                legal : element.value,
                title: element.label,
                file : {
                    url : "https",
                    fileName : "null",
                },
                number : "",
                error : false
            })
        }
        setLegalDocState(state)
    }

    useEffect(() => { 
        if(!local_data && legal.length !== 0 && selectedTypeCompany.length !== 0) {
            proceedState(legal)
        }
        // eslint-disable-next-line
    }, [legal, selectedTypeCompany]);


    useEffect(() => {
        const local_data = localStorage.getItem('detail_company')
        const checkLocalData = () => {
            const data : any = local_data === null ? null : JSON.parse(local_data)
            if(data !== null) {
                if(data.legal_docs.length !== 0) {
                    dispatch(changeLocalData(true))
                }
                setLegalDocState(data.legal_docs)
                setSelectedTypeCompany(data.type_company.value ? data.type_company : [])
                setLocalData(data)
            }
        }
        checkLocalData()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(getActionTypeCompany())
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={state_stepregister.loading_legal_document}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            
            <Box mt={2} pl={2} >
                <h3>Legal Document Company </h3>
            </Box> 
            <div className="section-form-company-detail">
               <form onSubmit={(e) => onClickNext(e)}>
               <Grid container >
                    <Grid item xl={6} lg={6} xs={12}>
                    <Box py="1rem" mx="0.8rem">
                        <Box pb={0.6} fontWeight="bold" fontSize={15}> 
                        Company Type <span><img src={required} width="10px" alt='icon'/></span>
                        </Box>
                        <Box pt={1}>
                            { loading_type ? 
                            <Skeleton height={68} />
                            :
                            <Select
                                placeholder="Select Type Company"
                                value={selectedTypeCompany}
                                isSearchable={true}
                                options={type && type}
                                onChange={handleChangeTypeCompany}
                                id="select-style-type"
                            /> }
                        </Box>
                        { errorTypeCompany ? <div className="error-p"><p>Type is required</p></div> : null }
                    </Box>
                    </Grid>
                </Grid>
                
                { loading_legal ? 
                <Grid container spacing={2} sx={{ mx: '2px', mt: '2px' }}>
                    <Grid item xl={6} lg={6} xs={12} >
                        <Paper sx={{p:2, borderRadius: 3}} elevation={4}>
                            <Skeleton height={50} />
                            <Skeleton height={100} />
                        </Paper>
                    </Grid>
                    <Grid item xl={6} lg={6} xs={12} >
                        <Paper sx={{p:2, borderRadius: 3}} elevation={4}>
                            <Skeleton height={50} />
                            <Skeleton height={100} />
                        </Paper>
                    </Grid>
                    <Grid item xl={6} lg={6} xs={12} >
                        <Paper sx={{p:2, borderRadius: 3}} elevation={4}>
                            <Skeleton height={50} />
                            <Skeleton height={100} />
                        </Paper>
                    </Grid>
                    <Grid item xl={6} lg={6} xs={12}>
                        <Paper sx={{p:2, borderRadius: 3}} elevation={4}>
                            <Skeleton height={50} />
                            <Skeleton height={100} />
                        </Paper>
                    </Grid>
                </Grid>
                :
                <Box pl={2}>
                { legalDocState.length === 0  ? null :
                    <Box mt={2}>
                        <Grid container spacing={3} >
                            {legalDocState?.map((val : any, i :any) => (
                            <Grid item xl={6} lg={6} xs={12} key={i}>
                                <Paper sx={{p:2, borderRadius: 3, minHeight: 210}} elevation={4}>
                                    <Box pt={2}><h4>{val.title}</h4></Box>
                                    <TextField
                                        margin="dense"
                                        fullWidth
                                        placeholder={`Number ${val.title}`}
                                        value={val.number}
                                        name="number"
                                        size="small"
                                        onChange={(e) => onChangeValue(i, e)}
                                    />
                                    <Box>
                                        { loading && i === index ? 
                                        <Skeleton 
                                            variant="rectangular" 
                                            height={50} 
                                            sx={{ borderRadius: '4px', mt: 2 }}
                                        /> :
                                        <Box>
                                            { val.file.url === "https" ?
                                            <Grid my="1rem" flexDirection="row" justifyContent="center">
                                                <Box>
                                                <label htmlFor={`icon-button-files-${i}`} >
                                                    <Input 
                                                        accept="application/pdf,image/*" 
                                                        id={`icon-button-files-${i}`} 
                                                        type="file"  
                                                        onChange={(e) => onChangeValueFile(i,e)} 
                                                    />
                                                    <Box className='btn-icon-upload'>
                                                        <FileUploadIcon sx={{ color : "#0091d6" }}/> Upload Document
                                                    </Box>
                                                </label>
                                                </Box>
                                            </Grid>
                                            :
                                            <Stack flexDirection="row" alignItems="center" >
                                                <Box pt={2} pr={2} >
                                                    <a  
                                                        className="link-file-name"
                                                        href={val.file.url} target="_blank" rel="noreferrer"
                                                    >{val.file.fileName}</a>
                                                </Box>
                                                <Box>
                                                    <label htmlFor={`icon-button-files-${i}`} >
                                                        <Input 
                                                            accept="application/pdf,image/*" 
                                                            id={`icon-button-files-${i}`} 
                                                            type="file"  
                                                            onChange={(e) => onChangeValueFile(i,e)} 
                                                        />
                                                        <Box 
                                                            sx={{
                                                                color: 'red',
                                                                cursor: 'pointer',
                                                                fontSize: 12,
                                                                pl: 1,
                                                                pt : 2
                                                            }}
                                                        >
                                                            Change
                                                        </Box>
                                                    </label>
                                                </Box>
                                            </Stack> }
                                        </Box> }
                                    </Box>
                                </Paper>
                            </Grid>
                            ))}
                        </Grid>
                    </Box>
                    }
                   
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 4, pb: 2 }}>
                        <Button
                            variant="contained"
                            color="inherit"
                            onClick={() => { 
                                dispatch(changeStep(0))
                            }} 
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button 
                            variant="contained"
                            type="submit"
                        >
                            Next
                        </Button>
                    </Box> 
                </Box> }
                </form>
           </div>
        </div>
    )
}

export default FormLegalDocument
