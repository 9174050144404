import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import swal from 'sweetalert'
import { downloadReports, getChartsReports, getSumChartsReports } from './reducers/chartsReducers';

const initialState: any = {
  data: [], 
  charts : [],
  charts_sales : [],
  charts_orders : [],
  loaded : false,
  loading_charts: false,
  loading : false,
  download_report :"",
  loading_download_report : false
};

export const chartsReportSlice = createSlice({
  name: 'charts-report',
  initialState, 
  reducers: {
    closeDownloadReport: (state) => {
      state.download_report = "";
    }
  },
  extraReducers: (builder) => {
    builder
      // get data
      .addCase(getChartsReports.pending, (state) => {
        state.loading = true;
        state.loaded = true;
      })
      .addCase(getChartsReports.fulfilled, (state, action:any) => {
        const sales_data = action.payload.salesList.map((item:any) => item.total)
        const sales_label = action.payload.salesList.map((item:any) => moment(item.createdAt).format('DD-MMMM'))

        const orders_data = action.payload.totalOrders.map((item:any) => item.count)
        const orders_label = action.payload.totalOrders.map((item:any) => moment(item.createdAt).format('DD-MMMM'))
        const sales = {
          labels : sales_label,
          data : sales_data
        }
        const orders = {
          labels : orders_label,
          data : orders_data
        }

        state.data = action.payload;
        state.charts_sales = sales;
        state.charts_orders = orders;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getChartsReports.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload.message}`, 'error')
      })

      // get sum charts
      .addCase(getSumChartsReports.pending, (state) => {
        state.loading_charts = true;
      })
      .addCase(getSumChartsReports.fulfilled, (state, action:any) => {
        state.loading_charts = false;
        
        state.charts = action.payload.data;
      })
      .addCase(getSumChartsReports.rejected, (state, action : any) => {
        state.loading_charts = false;
        swal("Error", `${action.payload.message}`, 'error')
      })
      
      // download report
      .addCase(downloadReports.pending, (state) => {
        state.loading_download_report = true;
      })
      .addCase(downloadReports.fulfilled, (state, action:any) => {
        state.loading_download_report = false;
        state.download_report = action.payload;
        swal("Success", `Report Successfuly Downloaded.`, 'success')
      })
      .addCase(downloadReports.rejected, (state, action : any) => {
        state.loading_download_report = false;
        swal("Error", `${action.payload.message}`, 'error')
      })
  },
});


export const { closeDownloadReport } = chartsReportSlice.actions;

export default chartsReportSlice.reducer;
