import { Box } from '@mui/system'
import { Button, Grid, Skeleton, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumbs from '../../../../components/BreadCrumbs';
import HeadlineTitle from '../../../../components/HeadlineTitle';
import receivableimg from '../../../../assets/img/dashboard/receiver.png'
import unpaid from '../../../../assets/img/icon/unpaid.png'
import grn from '../../../../assets/img/icon/grn.png'
import invoice from '../../../../assets/img/icon/invoice.png'
import { useState, useEffect } from 'react';
import StatisticInvoice from './components/StatisticInvoice';
import SearchInvoice from './components/SearchInvoice';
import ButtonCreate from '../../../../components/ButtonCreate';
import CardInvoice from './components/CardInvoice';
import { RootState } from '../../../../app/store';
import { getAccountReceivable, getInvoicesData, getInvoicesDataLoadMore, getStatistivInvoice } from './redux/invoicesReducers';
import EmptyData from '../../../../components/EmptyData';
import { closeOpenInvoice } from './redux/invoicesSlice';
import swal from 'sweetalert';
import LoaderProgress from '../../../../components/LoaderProgress';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getPreferredAll } from '../../buyer_lists/redux/buyerListsReducer';
import { checkValidation } from '../../../../utilities/userFeaturesValidation';

const PageInvoices = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { 
        invoices, loading_invoices, total_invoices, 
        receivable, loading_receivable, statistic_inv, loading_statistic_inv,
        approve_payment,
    } = useSelector((state : RootState) => state.invoice)
    const { create  } = useSelector((state : RootState) => state.preferred)


    const [dataInfinite, setDataInfinite] = useState<any>([]);
    const [limit] = useState(10);
    const [skip, setSkip] = useState(0);

    const [filterType, setFilterType] = useState([]);
    const [filterStatus, setFilterStatus] = useState([]);
    
    const [valueSearch, setValueSearch] = useState("");
    const [rangeTopic, setRangeTopic] = useState([]);
    const [rangeFrom, setRangeFrom] = useState([]);
    const [rangeTo, setRangeTo] = useState([]);
    const [hasMore,setHasMore] = useState(true);
    const [activeStatus, setActiveStatus] = useState("All");
    
    const isNotEmpty = (obj : any) => {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return true;
        }
        return false;
    }

    const fetchMoreData = async () => {
        const params = {
            limit,
            skip : skip + 1,
            search : valueSearch, 
            'rangeTopic' : rangeTopic,
            'rangeFrom' : rangeFrom,
            'rangeTo' : rangeTo,
            'type[]' : filterType,
            'lastStatus[]' : filterStatus,
        }
         
        const newdata : any = await dispatch(getInvoicesDataLoadMore(params))
        if(newdata.type === "invoices-vendor-load-more/get/fulfilled"){
            setSkip(skip + 1)
            let array = [...dataInfinite, ...newdata.payload.data]
            if(newdata.payload.data.length === 0){
                setHasMore(false)
            } else {
                setHasMore(true)
                setDataInfinite(array)
            }
        } else {
            swal("Error", newdata.payload.message, "error")
        }
    };

    useEffect(() => {
        setDataInfinite(invoices)
        if(invoices.length >= total_invoices) {
            setHasMore(false)
        }
        // eslint-disable-next-line
    }, [invoices, total_invoices]);

    const scrollToTop = () => {
        // @ts-ignore
        document.getElementById("scrollableDivInv").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const fetchData = async () => {
        const params = {
            limit,
            skip : 0,
            search : valueSearch,
            'rangeTopic' : rangeTopic,
            'rangeFrom' : rangeFrom,
            'rangeTo' : rangeTo,
            'type[]' : filterType,
            'lastStatus[]' : filterStatus,
        }
        dispatch(getInvoicesData(params))
    }

    useEffect(() => {
        fetchData()
        dispatch(getAccountReceivable())
        dispatch(getStatistivInvoice())
        // eslint-disable-next-line
    }, [valueSearch, rangeTopic, rangeFrom, rangeTo, filterStatus, filterType]);

    useEffect(() => {
        if(approve_payment || create) {
            fetchData()
            dispatch(getAccountReceivable())
            dispatch(getStatistivInvoice())
        }
        // eslint-disable-next-line
    }, [approve_payment, create]);
    
    useEffect(() => {
        dispatch(getPreferredAll())
        // eslint-disable-next-line
    }, []);

    
    useEffect(() => {
        if(create) {
            dispatch(getPreferredAll())
        }
        // eslint-disable-next-line
    }, [create]);

    return (
        <Stack m={2} >
            <BreadCrumbs 
                isPage={false}
                current="Invoices"
            />

            <Box my={2}>
                <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <StatisticInvoice 
                            label="Account Receivable"
                            value={receivable.length && receivable[0].totalAmount}
                            loading={loading_receivable}
                            currency={true}
                            background="#fff"
                            src={receivableimg}
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <StatisticInvoice 
                            label="Total Invoices"
                            value={total_invoices}
                            loading={loading_invoices}
                            currency={false}
                            background="#fff"
                            src={invoice}
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <StatisticInvoice 
                            label="Unpaid Invoices"
                            value={isNotEmpty(statistic_inv) && statistic_inv.Unpaid.count}
                            currency={false}
                            loading={loading_statistic_inv}
                            background="#fff"
                            src={unpaid}
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <StatisticInvoice 
                            label="Paid Invoices"
                            value={isNotEmpty(statistic_inv) &&  statistic_inv.Paid.count}
                            currency={false}
                            loading={loading_statistic_inv}
                            background="#fff"
                            src={grn}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Stack direction="row" justifyContent="space-between" alignItems="center" >
                <Box>
                    <HeadlineTitle 
                        title='Invoices'
                        variant="h6"
                        bold="bold"
                    />
                </Box>
                { checkValidation('CREINV438542') &&
                <Box>
                    <ButtonCreate 
                        name="Create New Invoice"
                        onClick={() => {
                            dispatch(closeOpenInvoice())
                            history.push({
                                pathname: "/dashboard/finance/invoice",
                                search: `?page=delivered`, 
                            })
                        }}
                    />
                </Box> }
            </Stack>
            <SearchInvoice
                valueSearch={valueSearch}
                setValueSearch={setValueSearch}
                activeStatus={activeStatus}
                setActiveStatus={setActiveStatus}
                setRangeTopic={setRangeTopic}
                setRangeFrom={setRangeFrom}
                setRangeTo={setRangeTo}
                filterType={filterType}
                setFilterType={setFilterType}
                filterStatus={filterStatus}
                setFilterStatus={setFilterStatus}
            />

            { loading_invoices ?
            <Stack mt={1}>
                {[1,2,3,4].map((idx) => (
                    <Box key={idx} my={1}>
                        <Skeleton sx={{borderRadius: 2}} height={175} variant='rectangular' />
                    </Box>
                ))}
            </Stack>
            :
            <div id="scrollableDivInv"  className="overhiddenflow">
                { dataInfinite.length > 0 ? 
                <div>
                    <InfiniteScroll
                        dataLength={dataInfinite.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<LoaderProgress/>}
                        scrollableTarget="scrollableDivInv"
                        endMessage={
                            <Stack flexDirection="column" justifyContent="center" alignItems="center" mt={3} mb={5}>
                                <Box>
                                    <HeadlineTitle title="No more data found." variant="body2" bold='400' />
                                </Box>
                                { dataInfinite.length > 5 && 
                                <Box>
                                    <Button onClick={() => scrollToTop()} size="small" variant='contained'>
                                        <p>Back to Top</p>
                                    </Button>
                                </Box>  }
                            </Stack>
                        }
                    >
                        <Box>
                            { dataInfinite.map((item:any, idx:number) => (
                                <Box key={idx}>
                                    <CardInvoice item={item} />
                                </Box>
                            ))}
                        </Box>
                    </InfiniteScroll>
                </div> :
                <Box mt={2} mx={0.2}>
                    <EmptyData 
                        message="Invoices is not found." 
                        caption="Try to change your search or filter."
                    />
                </Box> }
            </div> }
        </Stack>
    )
}


export default PageInvoices;
