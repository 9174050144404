import { Box, Grid, TextField } from '@mui/material'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useEffect } from 'react';


const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

const StoreSchedule : React.FC<any> = ({ operationalTime, setOperationalTime, defaultValue }) => {

    const onChangeTime = (itm : string, value: any ) => {
        let copy_array = [...operationalTime]
        const newData = copy_array.map((obj : any, i : any) => {
            if(obj.day === itm)
            return {
                ...obj,
                [value.target.name]: value.target.value,
            }
            return obj
        });
        setOperationalTime(newData) 
      
    }

    const onClickActive = (itm : string, value: any ) => {
        if(value.target.checked) {
            let copy_array = [...operationalTime]
            const newData = copy_array.map((obj : any, i : any) => {
                if(obj.day === itm)
                return {
                    ...obj,
                    active : value.target.checked,
                    open : "07:30",
                    close : "17:00"
                }
                return obj
            });
            setOperationalTime(newData)
        } else {
            let copy_array = [...operationalTime]
            const newData = copy_array.map((obj : any, i : any) => {
                if(obj.day === itm)
                return {
                    ...obj,
                    active : value.target.checked,
                }
                return obj
            });
            setOperationalTime(newData)
        }
    }

    useEffect(() => {
        if(defaultValue.length === 0) {
            const result = days.map(day => {
                return { day, open : "07:30", close : "17:00", active: false };
            });
            setOperationalTime(result)
        } else {
            const result = days.map(day => {
                const operation = defaultValue.find((op:any) => op.day === day);
                return operation ? { ...operation, active: true } : { day, open : "07:30", close : "17:00", active: false  };
            });
            setOperationalTime(result)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);



    return (
    <div>
        <Box><h4>Store Schedule</h4></Box>
        
        <Grid flexDirection="column">
            {operationalTime.map((day:any, index:any) => (
            <Grid display="flex" flexDirection="row" alignItems="center" my={1} key={index}>
                <Box width={200}>
                    <FormGroup>
                        <FormControlLabel 
                            control={<Switch checked={day.active} />} 
                            label={day.day} 
                            onChange={(value) => onClickActive(day.day, value)}
                        />
                    </FormGroup>
                </Box>
                <Box px={1}>
                    <TextField
                        id="time"
                        label="Open"
                        type="time"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                        size='small'
                        name='open'
                        disabled={!day.active}
                        value={day.open}
                        onChange={(value) => onChangeTime(day.day, value)}
                    />
                </Box>
                <Box px={1}>
                    <TextField
                        id="time"
                        label="Close"
                        type="time"
                        defaultValue="17:00"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                        size='small'
                        name='close'
                        disabled={!day.active}
                        value={day.close}
                        onChange={(value) => onChangeTime(day.day, value)}
                    />
                </Box>
            </Grid> 
            ))}
        </Grid>
    </div>
  )
}

export default StoreSchedule