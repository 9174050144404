import crypto from 'crypto-js'; 
import { createSlice } from '@reduxjs/toolkit';
import { resetPasswordAction, signInAction, signInSuperAdminAction, signUpAction  } from './authReducers';
import { IAuthState } from '../types';
import { userCredentials } from '../../../utilities/config';
import swal from 'sweetalert';

export const checkAuthorization = (credential : any) => {
  let login = false
  if(credential !== null) {
    login = credential.login
  } 
  return login;
}

export const checkIsRegistered = (credential : any) => {
  let isRegistered = false
  if(credential !== null) {
    isRegistered = credential.isRegistered
  } 
  return isRegistered;
}

const initialState : IAuthState = {
  signup : false, 
  loading_signup : false,
  error_signup : null,
  success_signup: null,
  signin : checkAuthorization(userCredentials),
  isRegistered : checkIsRegistered(userCredentials),
  loading_signin : false,
  error_signin : null,
  forgot : false, 
  loading_forgot : false
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    // signin
    [signInAction.pending.type] : (state) => {
        state.loading_signin = true;
        state.error_signin = null;
    },
    [signInAction.fulfilled.type] : (state, action) => {
        state.loading_signin = false;
        state.signin = true;
        state.isRegistered = action.payload.isRegistered;
        const saveToLocalStorage = crypto.AES.encrypt(JSON.stringify(action.payload), `${process.env.REACT_APP_CRYPTO_SECRET}`).toString();
        localStorage.setItem('secret_credentials', saveToLocalStorage)
    },
    [signInAction.rejected.type] : (state, action) => {
        state.loading_signin = false;
        state.error_signin = action.payload;
    },

    // signin root aceses
    [signInSuperAdminAction.pending.type] : (state) => {
      state.loading_signin = true;
      state.error_signin = null;
    },
    [signInSuperAdminAction.fulfilled.type] : (state, action) => {
        state.loading_signin = false;
        state.signin = true;
        const saveToLocalStorage = crypto.AES.encrypt(JSON.stringify(action.payload), `${process.env.REACT_APP_CRYPTO_SECRET}`).toString();
        localStorage.setItem('secret_credentials', saveToLocalStorage)
    },
    [signInSuperAdminAction.rejected.type] : (state, action) => {
        state.loading_signin = false;
        state.error_signin = action.payload;
    },

    // signup
    [signUpAction.pending.type] : (state) => {
        state.loading_signup = true;
        state.error_signup = null;
        state.success_signup = null;
    },
    [signUpAction.fulfilled.type] : (state, action) => {
        state.loading_signup = false;
        state.signup = action.payload.data;
        state.success_signup = "Registration Successful! We've sent link to your email. Please verify your email to login..";
    },
    [signUpAction.rejected.type] : (state, action) => {
        state.loading_signup = false;
        state.error_signup = action.payload;
    },

    // forgot
    [resetPasswordAction.pending.type] : (state) => {
      state.loading_forgot = true;
    },
    [resetPasswordAction.fulfilled.type] : (state, action) => {
      state.forgot = action.payload
      state.loading_forgot = false;
      swal("Success", "We've just sent you an email to reset your password.", "success")
    },
    [resetPasswordAction.rejected.type] : (state, action) => {
      state.loading_forgot = false;
      swal('error', `${action.payload}`, 'error')
    },
  },
});


export default authSlice.reducer;
