import { Stack, Grid, Box } from '@mui/material'
import React, { useState } from 'react'
import searchimg from '../../../../../assets/img/icon/search1.png'
import removeimg from '../../../../../assets/img/icon/close.png'
import SelectDate from '../../../../../components/SelectDate'
import FilterInvoice from './FilterInvoice'

const SearchInvoice : React.FC<any> = ({
    setValueSearch, valueSearch,
    setRangeTopic,
    setRangeFrom, setRangeTo,
    filterType, setFilterType,
    filterStatus, setFilterStatus,
}) => {

    const [inputSearch, setInputSearch] = useState("");

    function onSearch(e:any) {
        e.preventDefault()
        setValueSearch(inputSearch)
    } 


    return (
    <Stack className="container-search-invoice">
        <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={8}>
                <form onSubmit={onSearch}>
                <Box className='search-box'>
                        <input 
                            placeholder={`Search buyer or invoice number ...`}
                            onChange={(e) => setInputSearch(e.target.value)}
                            value={inputSearch}
                        />
                        <Box>
                            { valueSearch === "" ? 
                            <Box className='notyping'>
                                <img alt='searchimg' className='searchimg' src={searchimg} onClick={onSearch} />
                            </Box>
                            : 
                            <Box className='typing'>
                                <img 
                                    alt='removeimg' className='removeimg' src={removeimg} 
                                    onClick={() => {
                                        setInputSearch("")
                                        setValueSearch("")
                                    }}
                                />
                                <Box className='divider-line'/>
                                <img alt='searchimg' className='searchimg' src={searchimg} onClick={onSearch} />
                            </Box> }
                    </Box>
                </Box>
                </form>
            </Grid>
            <Grid item xs={5} md={3} lg={1}  >
                <FilterInvoice 
                    filterType={filterType}
                    setFilterType={setFilterType}
                    filterStatus={filterStatus}
                    setFilterStatus={setFilterStatus}
                />
            </Grid>
            <Grid item xs={7} md={3} lg={3}  sx={{ display: {xs: 'none', sm: 'none', lg: 'block' }}}>
                <SelectDate 
                    setRangeTopic={setRangeTopic}
                    setRangeFrom={setRangeFrom}
                    setRangeTo={setRangeTo}
                    type="createdAt"
                />
            </Grid>
        </Grid>
    </Stack>
  )
}


export default SearchInvoice