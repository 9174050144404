import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';
import Fade from '@mui/material/Fade';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import ButtonCreate from './ButtonCreate';
import moment from 'moment';
import DateRangeIcon from '@mui/icons-material/DateRange';

const SelectDate : React.FC<any> = ({ 
  setRangeTopic,
  setRangeFrom, 
  setRangeTo,
  name,
  type
}) => {
  const [open, setOpen] = useState(false);

  const [change, setChange] = useState(false);
  const [state, setState] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const onClickFilter = () => {
    setRangeTopic(type)
    setRangeFrom(`${moment(state[0].startDate).format('YYYY-MM-DD')}T00:00:00`)
    setRangeTo(`${moment(state[0].endDate).format('YYYY-MM-DD')}T23:59:59`)
    setOpen(false)
  }

  const onClickResetState = () => {
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }
    ])
  }

  const onClickReset = () => {
    setRangeTopic([])
    setRangeFrom([])
    setRangeTo([])
    setOpen(false)
    onClickResetState()
    setChange(false)
  }

  const Container = (
    <Stack className='date-select'>
      <Box>
        <DateRangePicker
          onChange={(item:any) => {
            setState([item.selection])
            setChange(true)
          }}
          ranges={state}
          rangeColors={['#43a047', '#43a047', '#43a047']}
        />
      </Box>
      <Box className='divider-btn'>
        <ButtonCreate 
          name='Reset Filter Date'
          onClick={() => onClickReset()}
          outlined={true}
        />
        <Box mx={0.5} />
        <ButtonCreate 
          name='Apply Filter'
          onClick={() => onClickFilter()}
        />
      </Box>
    </Stack>
  );

  return (
    <Box
      onMouseLeave={event => {
        setOpen(false)
      }}
    >
        <Box 
          className='recently-view'
          onMouseEnter={event => {
            setOpen(true);
          }}
        >
          { change ? 
          <div id='flex-date'>
            <DateRangeIcon sx={{ mr:0.5, fontSize: 20 }} />
            <p>{moment(state[0].startDate).format('DD MMM YYYY')} - {moment(state[0].endDate).format('DD MMM YYYY')}</p> :
          </div> 
          :
          <div id='flex-date'>
            <DateRangeIcon sx={{ mr:0.5, fontSize: 20 }} />
            <p>{name ? name : "Select Date"}</p>
          </div>
           }
        </Box>
        <Box sx={{ display: !open ? 'none' : 'flex', position: 'absolute', zIndex: 2000, right: 40 }}>
            <Fade in={open}>{Container}</Fade>
        </Box>
    </Box>
  );
}

export default SelectDate