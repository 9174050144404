import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from '../features_app/auth/redux/authSlice';
import stepFormReducer from '../features_app/dashboard/step_register/components/stepFormSlice';
import stepRegisterReducer from '../features_app/dashboard/step_register/stepRegisterSlice'
import teamsReducers from '../features_app/dashboard/teams/redux/teamsSlice'
import getProductsReducer from '../features_app/dashboard/products/productsSlice'
import getProfileReducer from '../features_app/dashboard/profile/profileSlice'
import getBuyerListsReducer from '../features_app/dashboard/buyer_lists/redux/buyerListsSlice'
import getWarehouseReducer from '../features_app/dashboard/warehouse/warehouseSlice'
import homeDashboardReducer from '../features_app/dashboard/home/homeDashboardSlice'
import getAllVendorrReducers from '../features_app/dashboard/select_company/selectCompanySlice'
import chatReducers from '../features_app/dashboard/chat/chatSlice'
import notificationsReducers from '../features_app/dashboard/home/notification/notifiicationSlice'
import chatReportsReducers from '../features_app/dashboard/reports/charts/chartsSlice'
import invitationReducers from '../features_app/dashboard/invitation/invitationSlice'
import mastersReducers from '../features_app/dashboard/masters/mastersSlice'
import fulfillmentReducers from '../features_app/dashboard/fulfillment/redux/fulfillmentsSlice'
import getInvoiceReducers from '../features_app/dashboard/finance/invoices/redux/invoicesSlice'
import getRolesReducers from '../features_app/dashboard/roles/redux/rolesSlice'
import getChatsReducers from '../features_app/dashboard/chat/redux/chatSlices'
import getTokensReducers from '../features_app/dashboard/token_credentials/redux/tokenCredentialSlice'
import getMediaCenterReducers from '../features_app/dashboard/media_center/redux/mediaCenterSlice'
import extensionsReducers from '../features_app/dashboard/extensions/redux/extensionsSlice'

export const store = configureStore({
  reducer: {
    auth : authReducer,
    step_state : stepFormReducer,
    step_register : stepRegisterReducer,
    users_team : teamsReducers,
    products : getProductsReducer,
    profile : getProfileReducer,
    preferred : getBuyerListsReducer,
    warehouse: getWarehouseReducer,
    dashboard : homeDashboardReducer,
    all_vendor : getAllVendorrReducers,
    chat : chatReducers,
    notification : notificationsReducers,
    chart_report : chatReportsReducers,
    invitation : invitationReducers,
    masters : mastersReducers,
    fulfillment : fulfillmentReducers,
    invoice: getInvoiceReducers,
    roles: getRolesReducers,
    chats : getChatsReducers,
    tokens : getTokensReducers,
    media_center : getMediaCenterReducers,
    extensions : extensionsReducers
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
