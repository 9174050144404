import { Box, Skeleton } from '@mui/material'
import React from 'react'


const StatisticInvoice : React.FC<any> = ({
    label, value, currency, loading, src
}) => {
    return (
        <Box className='card-paper'>
            <Box className="card-info">
                <div>
                    <h4>{label}</h4>
                    { loading ?
                        <Skeleton height={42} />
                    :
                    <div>
                    {!currency ? 
                        <h3>{value}</h3> :
                        <h3>Rp. {parseInt(value).toLocaleString()}</h3>
                    }
                    </div> }
                </div>
                <div>
                    <img src={src} alt="icon" />
                </div>
            </Box>
        </Box>
    )
}

export default StatisticInvoice
