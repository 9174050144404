import { Stack, Box, TextField, Grid } from '@mui/material'
import {useEffect, useState } from 'react'
import { userCredentials } from '../../../../utilities/config';
import { useDispatch } from 'react-redux';
import { resetPasswordAction } from '../../../auth/redux/authReducers';

const Account : React.FC<any> = () => {

    const dispatch = useDispatch()

    const [companyForm, setCompanyForm] = useState<any>({
        fullname : "",
    });

    useEffect(() => {
        setCompanyForm({...companyForm, fullname : userCredentials.fullname})
        // eslint-disable-next-line
    }, []);
    const onChangeFormValue = (e : any) => {
        const { name, value } = e.target 
        setCompanyForm({...companyForm, [name] : value })
    }

    return (
        <Stack mt={1} sx={{ width: '100%' }}>
            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} xs={12}>
                    <Box><h4>Full Name</h4></Box>
                    <TextField
                        margin="dense"
                        fullWidth
                        id="fullname"
                        name="fullname"
                        size="small"
                        value={companyForm.fullname}
                        onChange={onChangeFormValue}
                        required
                        disabled
                    />
                </Grid>
                <Grid item xl={6} lg={6} md={6} xs={12}>
                    <Box><h4>Email</h4></Box>
                    <TextField
                        margin="dense"
                        fullWidth
                        id="email"
                        name="email"
                        size="small"
                        value={userCredentials.email}
                        required
                        disabled
                    />
                </Grid>
                <Grid item xl={12} lg={12} md={6} xs={12}>
                    <Box 
                        pt={1} color="#ee4054"
                        sx={{ cursor: 'pointer', width: 'fit-content' }}
                    ><h5 onClick={() => dispatch(resetPasswordAction(userCredentials.email))}>Reset Password</h5></Box>
                </Grid>

            </Grid>


            {/* <Stack sx={{my:3}} flexDirection="row" justifyContent="flex-end">
                <Button variant='contained'>Save</Button>
            </Stack> */}
        </Stack>
    )
}

export default Account
