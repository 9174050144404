import { createSlice } from '@reduxjs/toolkit';
import { 
  createBankAccount, getCompanyDetail, getSettingCustomNumberDN, getSettingCustomNumberInvoice, getSettingVisibility, removeBankAccount, updateAddressCompany, 
  updateAutomationOrder, 
  updateBankAccount, updateContactCompany, 
  updateDefaultBankAccount, 
  updateImageCompany, updateLegalDocumentCompany, updateProfileCompany, updateSettingCustomNumberDN, updateSettingCustomNumberInvoice, updateSettingInvoice, updateSettingPlatform 
} from './reducers/profilesReducers';
import { errorSweetAlert } from '../../../utilities/errorhandling';

const initialState: any = {
  profile : {},
  loading_profile : false,
  update_profile : false,
  loading_update_profile : false,
  update_contact : false,
  loading_update_contact : false,
  update_address : false,
  loading_update_address : false,
  update_legal : false,
  loading_update_legal : false,
  create_account_number : false, 
  loading_create_account_number : false,
  update_account_number : false, 
  loading_update_account_number : false,
  remove_account_number : false, 
  loading_remove_account_number : false,
  default_account_number : false, 
  loading_default_account_number : false,
  setting_platform : false,
  loading_setting_platform : false,
  visibility_product : {},
  loading_visibility_product : false,
  loading_invoice_setting : true,
  invoice_setting : false,
  delivery_number : {},
  loading_delivery_number : false,
  update_delivery_number : false,
  loading_update_delivery_number : false, 
  invoice_number : {},
  loading_invoice_number : false,
  update_invoice_number : false,
  loading_update_invoice_number : false, 
};

export const getCompanyProfile = createSlice({
  name: 'profile/get',
  initialState, 
  reducers: {
    updateImageState (state) {
      state.update_image = false
    },
    updateProfileSnackBar (state) {
      state.update_profile = false
    },
    updateContactSnackBar (state) {
      state.update_contact = false
    },
    updateAddressSnackBar (state) {
      state.update_address = false
      state.delivery_number = false
      state.invoice_number = false
    },
    updateLegalSnackBar (state) {
      state.update_legal = false
    },
    updateAccountNumberSnackBar (state) {
      state.create_account_number = false
      state.update_account_number = false
      state.default_account_number = false
      state.remove_account_number = false
    },
    updatePlatformSnackBar (state) {
      state.setting_platform = false
      state.invoice_setting = false
    },
  },
  extraReducers: (builder) => {
    builder
      // get profile company
      .addCase(getCompanyDetail.pending, (state) => {
        state.loading_profile = true;
        state.update_image = false;
        state.setting_platform = false
      })
      .addCase(getCompanyDetail.fulfilled, (state, action:any) => {
        state.loading_profile = false;
        state.profile = action.payload.data;
      })
      .addCase(getCompanyDetail.rejected, (state, action : any) => {
        state.loading_profile = false;
        errorSweetAlert(action.payload)
      })

      // update image company
      .addCase(updateImageCompany.pending, (state) => {
        state.loading_update_image = true;
        state.update_image = false;
      })
      .addCase(updateImageCompany.fulfilled, (state, action:any) => {
        state.loading_update_image = false;
        state.update_image = action.payload.data;
      })
      .addCase(updateImageCompany.rejected, (state, action : any) => {
        state.loading_update_image = false;
        errorSweetAlert(action.payload)
      })

      // update profile company
      .addCase(updateProfileCompany.pending, (state) => {
        state.loading_update_profile = true;
      })
      .addCase(updateProfileCompany.fulfilled, (state, action:any) => {
        state.loading_update_profile = false;
        state.update_profile = action.payload.data;
      })
      .addCase(updateProfileCompany.rejected, (state, action : any) => {
        state.loading_update_profile = false;
        errorSweetAlert(action.payload)
      })

      // update contact company
      .addCase(updateContactCompany.pending, (state) => {
        state.loading_update_contact = true;
      })
      .addCase(updateContactCompany.fulfilled, (state, action:any) => {
        state.loading_update_contact = false;
        state.update_contact = action.payload.data;
      })
      .addCase(updateContactCompany.rejected, (state, action : any) => {
        state.loading_update_contact = false;
        errorSweetAlert(action.payload)
      })

      // update address company
      .addCase(updateAddressCompany.pending, (state) => {
        state.loading_update_address = true;
      })
      .addCase(updateAddressCompany.fulfilled, (state, action:any) => {
        state.loading_update_address = false;
        state.update_address = action.payload.data;
      })
      .addCase(updateAddressCompany.rejected, (state, action : any) => {
        state.loading_update_address = false;
        errorSweetAlert(action.payload)
      })

      // update legal company
      .addCase(updateLegalDocumentCompany.pending, (state) => {
        state.loading_update_legal = true;
      })
      .addCase(updateLegalDocumentCompany.fulfilled, (state, action:any) => {
        state.loading_update_legal = false;
        state.update_legal = action.payload.data;
      })
      .addCase(updateLegalDocumentCompany.rejected, (state, action : any) => {
        state.loading_update_legal = false;
        errorSweetAlert(action.payload)
      })

      // update account number
      .addCase(updateBankAccount.pending, (state) => {
        state.loading_update_account_number = true;
      })
      .addCase(updateBankAccount.fulfilled, (state, action:any) => {
        state.loading_update_account_number = false;
        state.update_account_number = action.payload.data;
      })
      .addCase(updateBankAccount.rejected, (state, action : any) => {
        state.loading_update_account_number = false;
        errorSweetAlert(action.payload)
      })

      // create account number
      .addCase(createBankAccount.pending, (state) => {
        state.loading_create_account_number = true;
      })
      .addCase(createBankAccount.fulfilled, (state, action:any) => {
        state.loading_create_account_number = false;
        state.create_account_number = action.payload.data;
      })
      .addCase(createBankAccount.rejected, (state, action : any) => {
        state.loading_create_account_number = false;
        errorSweetAlert(action.payload)
      })

      // remove account number
      .addCase(removeBankAccount.pending, (state) => {
        state.loading_remove_account_number = true;
      })
      .addCase(removeBankAccount.fulfilled, (state, action:any) => {
        state.loading_remove_account_number = false;
        state.remove_account_number = action.payload
      })
      .addCase(removeBankAccount.rejected, (state, action : any) => {
        state.loading_remove_account_number = false;
        errorSweetAlert(action.payload)
      })

      // default account number
      .addCase(updateDefaultBankAccount.pending, (state) => {
        state.loading_default_account_number = true;
      })
      .addCase(updateDefaultBankAccount.fulfilled, (state, action:any) => {
        state.loading_default_account_number = false;
        state.default_account_number = action.payload.data;
      })
      .addCase(updateDefaultBankAccount.rejected, (state, action : any) => {
        state.loading_default_account_number = false;
        errorSweetAlert(action.payload)
      })

      // update setting platform
      .addCase(updateSettingPlatform.pending, (state) => {
        state.loading_setting_platform = true;
      })
      .addCase(updateSettingPlatform.fulfilled, (state, action:any) => {
        state.loading_setting_platform = false;
        state.setting_platform = action.payload.data;
      })
      .addCase(updateSettingPlatform.rejected, (state, action : any) => {
        state.loading_setting_platform = false;
        errorSweetAlert(action.payload)
      })

      // / update auto fulfillment
      .addCase(updateAutomationOrder.pending, (state) => {
        state.loading_auto = true;
      })
      .addCase(updateAutomationOrder.fulfilled, (state, action:any) => {
        state.loading_auto = false;
        state.auto = action.payload.data;
      })
      .addCase(updateAutomationOrder.rejected, (state, action : any) => {
        state.loading_auto = false;
        errorSweetAlert(action.payload)
      })


      // / update auto invoice generation
      .addCase(updateSettingInvoice.pending, (state) => {
        state.loading_invoice_setting = true;
      })
      .addCase(updateSettingInvoice.fulfilled, (state, action:any) => {
        state.loading_invoice_setting = false;
        state.invoice_setting = action.payload.data;
      })
      .addCase(updateSettingInvoice.rejected, (state, action : any) => {
        state.loading_invoice_setting = false;
        errorSweetAlert(action.payload)
      })

      // get settting visibility product
      .addCase(getSettingVisibility.pending, (state) => {
        state.loading_visibility_product = true;
      })
      .addCase(getSettingVisibility.fulfilled, (state, action:any) => {
        if(action.payload.length === 0) {
          let data = {
            value : "CLOSED",
            label : "CLOSED"
          }
          state.visibility_product = data;
        } else {
          let data = {
            value : action.payload.isVisibility,
            label : action.payload.isVisibility
          }
          state.visibility_product = data
        }
        state.loading_visibility_product = false;
      })
      .addCase(getSettingVisibility.rejected, (state, action : any) => {
        state.loading_visibility_product = false;
        errorSweetAlert(action.payload)
      })

      // get dn numbering
      .addCase(getSettingCustomNumberDN.pending, (state) => {
        state.loading_delivery_number = true;
      })
      .addCase(getSettingCustomNumberDN.fulfilled, (state, action:any) => {
        state.loading_delivery_number = false;
        state.delivery_number = action.payload;
      })
      .addCase(getSettingCustomNumberDN.rejected, (state, action : any) => {
        state.loading_delivery_number = false;
        errorSweetAlert(action.payload)
      })

      // get dn numbering
      .addCase(updateSettingCustomNumberDN.pending, (state) => {
        state.loading_update_delivery_number = true;
      })
      .addCase(updateSettingCustomNumberDN.fulfilled, (state, action:any) => {
        state.loading_update_delivery_number = false;
        state.update_delivery_number = action.payload;
      })
      .addCase(updateSettingCustomNumberDN.rejected, (state, action : any) => {
        state.loading_update_delivery_number = false;
        errorSweetAlert(action.payload)
      })

      // get dn invoice
      .addCase(getSettingCustomNumberInvoice.pending, (state) => {
        state.loading_invoice_number = true;
      })
      .addCase(getSettingCustomNumberInvoice.fulfilled, (state, action:any) => {
        state.loading_invoice_number = false;
        state.invoice_number = action.payload;
      })
      .addCase(getSettingCustomNumberInvoice.rejected, (state, action : any) => {
        state.loading_invoice_number = false;
        errorSweetAlert(action.payload)
      })

      // get dn invoice
      .addCase(updateSettingCustomNumberInvoice.pending, (state) => {
        state.loading_update_invoice_number = true;
      })
      .addCase(updateSettingCustomNumberInvoice.fulfilled, (state, action:any) => {
        state.loading_update_invoice_number = false;
        state.update_invoice_number = action.payload;
      })
      .addCase(updateSettingCustomNumberInvoice.rejected, (state, action : any) => {
        state.loading_update_invoice_number = false;
        errorSweetAlert(action.payload)
      })
  },
});

export const { 
  updateImageState, updateProfileSnackBar, 
  updateContactSnackBar, updateAddressSnackBar, 
  updateLegalSnackBar, updateAccountNumberSnackBar,
  updatePlatformSnackBar 
} = getCompanyProfile.actions;
export default getCompanyProfile.reducer;
