import { Stack, Box } from '@mui/material'
import { useState } from 'react';
import SettingPlatform from './SettingPlatform';
import SettingInvoice from './SettingInvoice';
import InvoiceNumbering from './InvoiceNumbering';
import DeliveryNoteNumbering from './DeliveryNoteNumbering';

const SettingCompany : React.FC<any> = ({ profile, refetch, setRefetch }) => {

    const [activeTabIndex, setActiveTabIndex] = useState<any>(0);

    const tabs = [
        {
            label : "Setting Platform",
            component : <SettingPlatform profile={profile} refetch={refetch} setRefetch={setRefetch}/>
        },
        {
            label : "Invoice Auto Generation",
            component : <SettingInvoice profile={profile} refetch={refetch} setRefetch={setRefetch}/>
        },
        {
            label : "Custom Invoice Numbering",
            component : <InvoiceNumbering profile={profile} />
        },
        {
            label : "Custom Delivery Note Numbering",
            component : <DeliveryNoteNumbering profile={profile} />
        },
    ]

    return (
        <Stack mt={1} sx={{ width: '100%' }}>
            <Box mb={4} className='tabs-extensions'>
                {tabs.map((item:any, index:number) => (
                    <Box 
                        key={index}
                        className={index === activeTabIndex ? 'tabs-active' : 'tabs'}
                        onClick={() => setActiveTabIndex(index)}
                    >
                        <h2>{item.label}</h2>
                    </Box>
                ))}
            </Box>

            <Box>
                {tabs[activeTabIndex].component}
            </Box>
        </Stack>
    )
}

export default SettingCompany
