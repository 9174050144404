import { Box, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DataTableBase from '../../../../../components/TableData'
import ButtonCreate from '../../../../../components/ButtonCreate';
import { RootState } from '../../../../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { TableColumn } from 'react-data-table-component';
import { getInvoicesData } from '../../../finance/invoices/redux/invoicesReducers';
import HeadlineTitle from '../../../../../components/HeadlineTitle';
import SearchComponent from '../../../../../components/SearchComponent';
import moment from 'moment';

const EfakturList : React.FC<any> = () => {

    const dispatch = useDispatch()

    const { 
        invoices, loading_invoices, total_invoices
    } = useSelector((state : RootState) => state.invoice)

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [valueSearch, setValueSearch] = useState("");


    const onChangePerPage = (page:any) => {
        setPage(page-1)
    }

    useEffect(() => {
        const params = {
            limit,
            skip : 0,
            search : valueSearch
        }
        dispatch(getInvoicesData(params))
        // eslint-disable-next-line
    }, []);

    const columns: TableColumn<any>[] = [
        {
            name: 'No',
            width: '65px',
            cell: (row, index) => (
                <p>
                { page > 0 ? 
                    <>
                    {index + 1 + (limit * (page))}.
                    </>
                    :
                    <> 
                    {index + 1}.
                    </>
                }
                </p>
            )
        },
        {
            name: 'Created',
            selector: row => moment(row.createdAt).format('DD MMMM YYYY'),
        },
        {
            name: 'Invoice Number',
            selector: row => row.invoiceNumber,
        },
        {
            name: 'Buyer',
            selector: row => row.buyer.legalName,
        },
        {
            name: 'Grand Total',
            selector: row => `Rp. ${row.grandTotal.toLocaleString()}`,
        },
        {
            name: 'Status',
            cell: (row) => (
                <p>{ row.lastStatus}</p>
            )
        },
        {
            name: 'Action',
            width: '200px',
            cell: (row) => (
                <Stack direction="row" spacing={2}>
                    <ButtonCreate
                        name="Generate E-Faktur"
                        disabled={true}
                    />
                </Stack>
            ),
        },
    ];

    return (
    <div>
        <HeadlineTitle 
            title="E-Faktur"
            variant="h6"
            bold="bold"
        />
         <Box>
            <SearchComponent 
                valueSearch={valueSearch}
                setValueSearch={setValueSearch}
                placeholder="Search by number"
            />
            <Box pb={2} />
            <DataTableBase 
                columns={columns}
                data={invoices}
                progressPending={loading_invoices} 
                pagination
                paginationServer
                onChangePage={(value) => onChangePerPage(value)}
                onChangeRowsPerPage={(value) => setLimit(value)}
                paginationPerPage={limit}
                noDataComponent="You dont have any product yet."
                paginationTotalRows={total_invoices}
                persistTableHead
            /> 
            </Box>
    </div>
  )
}

export default EfakturList