import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }

export const getAllUsersTeams = createAsyncThunk(
    'get/user-teams',
    async (params:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/user/team`, {
                params,
                headers
            })
            if(response.data) {
                return response.data
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);  


export const updateTeamMember = createAsyncThunk(
    'update/team-members',
    async (params: any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/user/team/update/${params.id}`, params.body, {headers})
            if(response.data) {
                return true
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const createNewTeamMember = createAsyncThunk(
    'member-team/post',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/user/team`, body, {headers})
            if(response.data) {
                return true
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const blockUserTeams = createAsyncThunk(
    'user-block/post',
    async (params : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/user/block/${params.id}`, params.body, {headers})
            if(response.data) {
                return true
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);