import React, { useState, useEffect } from 'react';
import { Box,Stack } from '@mui/material';
import Fade from '@mui/material/Fade';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge } from '@mui/material';
import notfound from '../assets/img/icon/notfound.png'
import shopping from '../assets/img/icon/shopping-bag.png'
import bill from '../assets/img/icon/bill.png'
import general from '../assets/img/icon/bell-ring.png'
import { userCredentials } from '../utilities/config';
import {socket} from '../utilities/socket'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateMessage } from '../features_app/dashboard/chat/chatSlice';
import moment from 'moment';
import { initConvesationLists, initNewMessage, receiveNewMessage } from '../features_app/dashboard/chat/redux/chatSlices';
import { RootState } from '../app/store';
import LoaderBackdrop from './LoaderBackdrop';
import { getStatusUpdateFullfilment } from '../features_app/dashboard/home/notification/reducers/notificationReducers';
import { checkValidation } from '../utilities/userFeaturesValidation';

const Notifications : React.FC<any> = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { loading_status } = useSelector((state : RootState) => state.notification)

  const [checked, setChecked] = useState(false);
  const [message, setMessage] = useState<any>([]);
  const [historyNotif, setHistoryNotf] = useState<any>([]);
  const [totalNewMessage, setTotalNewMessage] = useState([]);

  function onCloseHover () {
    setChecked(false);
  }

  const notifUnRead = (row:any) => {
    return row.filter((data:any) => !data.read_by || !data.read_by[userCredentials.auth_id])
  }         

  useEffect(() => {
    socket.on('connect', async () => {
      const data = {
        user_id : userCredentials.auth_id || "",
        user_name : userCredentials.fullname || "",
        company_id : userCredentials.company_id || "",
        company_name : userCredentials.company.legalName || "",
        company_logo : userCredentials.company.logo || "",
        channel : 'VENDOR',
        created_at : userCredentials.createdAt || "",
      }
      socket.emit('login', data)
      socket.on('history', ({notif}:any) => {
        if(notif){ 
          setTotalNewMessage(notifUnRead(notif.data))
          setHistoryNotf(notif.data) 
        }
      })
      socket.on('chat_history', ({conversation, message} : any) => {
        if(conversation) {
          dispatch(initConvesationLists(conversation))
        }
        if(message) {
          dispatch(initNewMessage(message))
        }
      })
      socket.on('notif', (notif : any) => {
        setMessage(notif)
      })
      socket.on('msg_from', (chat: any) => {
        let payload = {
          chat : chat,
        }
        dispatch(receiveNewMessage(payload))
      })
      socket.on('update_msg', (upd: any[]) => {
        dispatch(updateMessage(upd))
      })
    })
    return () => { socket.disconnect(); };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(message.message !== undefined) {
      let array_message = [...historyNotif]
      array_message.unshift(message)
      setHistoryNotf(array_message)
      setTotalNewMessage(notifUnRead(array_message))
    }
    // eslint-disable-next-line
  }, [message]);

  
  const replaceNotifRead = () => {
    socket.on('notif-update', async (notif : any) => {
      const notif_array = [...historyNotif]
      const id = await notif._id
      const target = await notif_array.find((obj:any) => obj._id === id);
      const source = {...notif};
      if(target !== undefined) {
        Object.assign(target, source);
        const filter : any = notif_array.filter((data:any) => !data.read_by)
        setTotalNewMessage(filter)
        setHistoryNotf(notif_array)
      }
    })
  }


  function renderImageNotif(params:string) {
    switch (params) {
      case "Orders":
        return shopping;
      case "BORADCAST":
        return general;
      case "Invoice":
        return bill;
      default:
        return general;;
    }
  }

  
  function readNoficationsMessage(row:any) {
    if(row.read_by && row.read_by[userCredentials.auth_id]) {
      return 'read'
    } else {
      socket.emit('read_notif', [`${row._id}`])
    }
  }


  const getLatestStatusOrder = async (id:string) => {
    const response : any = await dispatch(getStatusUpdateFullfilment(id))
    if(response.meta.requestStatus === 'fulfilled'){
        return response.payload
    }
    return null
  }

  const onClickReadMessage = async (row: any) => {
    readNoficationsMessage(row)
    replaceNotifRead()
    onCloseHover()
    switch (row.category) {
      case "Orders":
        let status = await getLatestStatusOrder(row.payload.idPo)
        history.push({
          pathname: "/dashboard/orders",
          state : {
            payload : row.payload,
            status : status[0].lastStatus,
          }
        })
        break
      case "BORADCAST":
        return general;
      case "Invoice":
        history.push({
          pathname: '/dashboard/finance/invoice',
          search: `?page=detail`, 
          state : {
              id : row.payload.invoiceId,
              type : "id",
              open : true
          }
        })
        break
      case "Products":
        if(row.link_url) {
          return window.open(row.link_url, '_blank')
        } else {
          history.push({
            pathname: "/dashboard/products",
            state : {}
          })
        }
        break
      default:
      return general;
    }
  }

  const Container = (
    <Stack className='container-notifications'>
      <Box className='header'>
        <h2>Notifications</h2>
      </Box>
      { historyNotif.length === 0 ? 
      <Box className='empty'>
       <img alt="empty" src={notfound}/>
        <p>We dont have any notification for you yet!</p>
      </Box> :
      <Box className='content'>
        { historyNotif.map((row:any, index:number) => (
          <Box 
            className={`list-item ${!row.read && 'unread'}`} 
            key={index}
            onClick={() => onClickReadMessage(row)}
          >
            <Box>
              <img alt="shopping" src={renderImageNotif(row.category)}/>
            </Box>
            <Box className='right-notif'>
              <h5 className='title'>{row.message}</h5>
              <h6 className='time'> {moment(row.createdAt).fromNow()}</h6>
            </Box>
          </Box>
        ))}
      </Box> }
      <Box className='footer'>
        <Box
          onClick={() => {
            onCloseHover()
            history.push({ 
              pathname: "/dashboard",
              search: `?page=notification`
            })
          }}
        > 
          <p>View All Notification</p>
        </Box>
      </Box>
    </Stack>
  );

  return (
    <Box 
      sx={{ display: checkValidation('REASTADAS418389') && userCredentials.company.isVerified ? 'block' : 'none'  }}
      onMouseLeave={event => {
        onCloseHover()
      }}
    >
      <Stack 
        flexDirection="row"
        onMouseEnter={event => {
          setChecked(true);
        }} 
      >
          <Box mr={2} sx={{ cursor: 'pointer' }} >
            <Badge 
              badgeContent={totalNewMessage.length === 0 ? "0" : totalNewMessage.length} 
              color={totalNewMessage.length === 0 ? "primary" : "error"}
            >
              <NotificationsIcon />
            </Badge>
          </Box>
        </Stack>
        <Box sx={{ display: !checked ? 'none' : 'flex', position: 'absolute', zIndex: 2000, right: 50, marginLeft: 50 }}>
            <Fade in={checked}>{Container}</Fade>
        </Box>
        <LoaderBackdrop loading={loading_status} />
    </Box>
  );
}

export default Notifications