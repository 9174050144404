import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/system';
/* istanbul ignore file */

interface IBreadCrumbs {
    current : string
    isPage : boolean
    page ?: string
    link ?: string
}

const BreadCrumbs = ({ current, page, link, isPage } : IBreadCrumbs) => {
  const history :any = useHistory()
  return (
    <div role="presentation"  >
      <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: 14 }}>
        <Link underline="hover" color="inherit" href="/dashboard">
          Dashboard
        </Link>
        { 
        /* istanbul ignore next */
        isPage ? 
        <Box
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => history.push(link)}
        >
          {page}
        </Box> : null }
        <Typography color="text.primary" variant='body2'>{current}</Typography>
      </Breadcrumbs>
    </div>
  );
}

export default BreadCrumbs;