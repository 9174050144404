import { createSlice } from '@reduxjs/toolkit';
import { getAllVendorCompany, getRoleVendorCompany } from './reducers/selectCompanyReducers';
import { errorSweetAlert } from '../../../utilities/errorhandling';

const initialState: any = {
  data : [],
  loading: false,
  roles : [],
  loading_roles : false,
};

export const getAllVendorLists = createSlice({
  name: 'selec-company',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get vendor list
      .addCase(getAllVendorCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllVendorCompany.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data.length > 0 ? action.payload.data : [];
        state.total_data = action.payload.data.length > 0 ? action.payload.total.row : 0;

      })
      .addCase(getAllVendorCompany.rejected, (state, action : any) => {
        state.loading = false;
        errorSweetAlert(action.payload)
      })

      // get vendor ROLE
      .addCase(getRoleVendorCompany.pending, (state) => {
        state.loading_roles = true;
      })
      .addCase(getRoleVendorCompany.fulfilled, (state, action:any) => {
        state.loading_roles = false;
        state.roles = action.payload;

      })
      .addCase(getRoleVendorCompany.rejected, (state, action : any) => {
        state.loading_roles = false;
        errorSweetAlert(action.payload)
      })
      
  },
});


export default getAllVendorLists.reducer;
