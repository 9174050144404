import { Stack, Box, Button, Paper, TextField, Grid, Backdrop, CircularProgress } from '@mui/material'
import {useState, useEffect} from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import Select from 'react-select'
import { changeStep } from './stepFormSlice';
import { postActionCompanyDetail } from '../reducers/stepRegisterReducers';
import { getMasterBank } from '../../masters/mastersReducers';

const FormBankAccount = () => {

    const dispatch = useDispatch()
    const store_profile = useSelector((state : RootState) => state.profile)
    const { bank } = useSelector((state : RootState) => state.masters)
    const { create_company, loading_create_company } = useSelector((state : RootState) => state.step_register)
    
    const [bankLists, setBankLists] = useState<any>([]);
    const [localData, setLocalData] = useState<any>({});

    const [open, setOpen] = useState({
        open : false,
        name : ""
    });

    const [valueBank, setValueBank] = useState<any>({
        bank_account_name: "",
        bank_number : ""
    });

    const [selectedBank, setSelectedBank] = useState<any>([]);

    const handleChangeBank = (value : any) => {
        setSelectedBank(value)    
    }

    const onChangeValue = (e:any) => {
        setValueBank({...valueBank, [e.target.name]: e.target.value })
    }

    const handleClose = () => {
        setOpen({...open, open: false, name : "" })
        setValueBank({...valueBank, bank_account_name: "", bank_number : ""})
        setSelectedBank([])
    }

    const handleOpen = (name : any) => {
        setOpen({...open, open: true, name : name })
    }

    const onClickNextSubmit = (e:any) => {
        e.preventDefault()
        const data_send = {
            company : {
                name : localData.company.name,
                legalName : localData.company.legalName,
                isPKP : localData.company.isPKP.value,
                category :localData.company.category.value,
                type :localData.type_company.value,
                owner : "null",
                platform : "VENDOR",
                logo : localData.company.logo,
                banner : localData.company.banner
            },
            address : localData.address,
            contactUs : localData.contactUs,
            legalDocument : localData.legal_docs,
            accountNumber : localData.bank,
            settingPlatform : {
                "company": "null",
                "discount": 0,
                "down_payment": 0,
                "credit_limit": 0,
                "direct_payment": true,
                "payment_term": {
                    "value": 0,
                    "label": "0 days"
                }
            },
        }
        dispatch(postActionCompanyDetail(data_send))
    }

    const onClickSubmit = () => {
        let form_bank = {
            "company": "null",
            "account_name": valueBank.bank_account_name,
            "account_number": valueBank.bank_number,
            "bank": {
                "_id": selectedBank.id,
                "label": selectedBank.label,
                "iconUrl": selectedBank.iconUrl,
                "number": selectedBank.value
            }
        }
        const body_send = {
            legal_docs: localData.legal_docs ? localData.legal_docs : [],
            type_company: localData.type_company ? localData.type_company : {},
            company : localData.company ? localData.company : {},
            contactUs : localData.contactUs ? localData.contactUs : {},
            address : localData.address ? localData.address : {},
            bank : form_bank
        }
        localStorage.setItem('detail_company', JSON.stringify(body_send))
        setLocalData(body_send)
        setBankLists(form_bank)
        handleClose()

    }

    useEffect(() => {
        const local_data = localStorage.getItem('detail_company')
        const checkLocalData = () => {
            const data : any = local_data === null ? null : JSON.parse(local_data)
            if(data !== null) {
                setBankLists(data.bank.bank ? data.bank : [])
                setLocalData(data)
            }
        }
        checkLocalData()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(getMasterBank())
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(create_company) {
            window.location.reload()
        }
    }, [create_company]);

    return (
        <Stack mt={1}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading_create_company}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Stack flexDirection="row" justifyContent="space-between">
                <Box>
                    <Box fontWeight="700" fontSize={22}>Bank Account </Box>
                    <Box fontSize={14}>Bank account is required.</Box>
                </Box>
                { bankLists.length !== 0 ? null :
                <Box>
                    <Button
                        variant='contained'
                        size="small"
                        color="primary"
                        onClick={() => handleOpen('Create New')}
                    > 
                        Create Bank Account
                    </Button>
                </Box> 
                }
            </Stack>

            <Stack>
                { bankLists.length === 0 ? 
                <Stack mt={2}>
                    <Paper elevation={3} sx={{ border: '#023047 2px solid' }}>
                        <Box p={3}>
                            <Box pt={1} fontWeight="700">
                                Please add your bank account.
                            </Box>
                        </Box>
                    </Paper>
                </Stack> 
                :  
                <Stack mt={2} >
                    <Paper elevation={3} sx={{ border: '#0091d6 2px solid' }}>
                        <Box p={3}>
                            <Box fontWeight="400">{bankLists.bank.label}</Box>
                            <Box pt={1} fontWeight="700">
                                {bankLists.account_number}
                            </Box>
                            <Box pt={1} fontWeight="500">
                                an {bankLists.account_name}
                            </Box>
                            <Stack pt={2} flexDirection="row">
                                <Box>
                                    <Button
                                        size="small"
                                        color="error"
                                        variant='contained'
                                        onClick={() => {
                                            setBankLists([])
                                        }}
                                    >
                                        Remove
                                    </Button>
                                </Box>
                            </Stack>
                        </Box>
                    </Paper>
                    <Box fontSize={14} pt={2}>
                        You can add more bank later from company setting page.
                    </Box>
                </Stack> }
            </Stack> 
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 4, pb: 2 }}>
                <Button
                    variant="contained"
                    color="inherit"
                    sx={{ mr: 1 }}
                    onClick={() => { 
                        dispatch(changeStep(1))
                    }}
                    disabled={loading_create_company}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button 
                    variant="contained"
                    type="submit"
                    onClick={onClickNextSubmit}
                    disabled={bankLists.length === 0 ? true : false}
                >
                   Save & Submit
                </Button>
            </Box>

            <Dialog open={open.open} maxWidth="sm" fullWidth={true}  >
                    <DialogTitle>{open.name}</DialogTitle>
                    <DialogContent sx={{minHeight: 400}}>
                        <Stack>
                            <Box pb={1}>
                                <Select
                                    placeholder="Choose Bank"
                                    value={selectedBank}
                                    isSearchable={true}
                                    options={bank && bank}
                                    onChange={handleChangeBank}
                                    id={`select-style-cat`}

                                />
                            </Box> 
                           <Box pt={1} pb={1}>
                           <Grid container spacing={2}>
                                <Grid item lg={12}>
                                    <TextField 
                                        label="Account Number"
                                        onChange={(e:any) => {
                                            if(e.target.value >= 0) {
                                                onChangeValue(e)
                                            }
                                        }}
                                        value={valueBank.bank_number}
                                        name="bank_number"
                                        type="number"
                                        size="small"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        fullWidth
                                    />
                               </Grid>
                           </Grid>
                           </Box>
                           <Box pt={1} pb={1}>
                           <Grid container spacing={2}>
                                <Grid item lg={12}>
                                    <TextField 
                                        label="Account Name"
                                        onChange={onChangeValue}
                                        value={valueBank.bank_account_name}
                                        name="bank_account_name"
                                        type="text"
                                        size="small"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        fullWidth
                                    />
                               </Grid>
                           </Grid>
                           </Box>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="error">Cancel</Button>
                        <Button 
                            onClick={onClickSubmit} 
                            variant="contained"
                            disabled={valueBank.bank_account_name === "" ? true : false}
                        >
                            { store_profile.loading_bank_post ? "Loading" : "Submit" } 
                        </Button>
                    </DialogActions>
            </Dialog>

        </Stack>
    )
}

export default FormBankAccount
