import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';
import { v4 as uuid } from 'uuid';

export const getMediaImages = createAsyncThunk(
    'media-center/get', 
    async (params:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/images`, { 
                params,
                headers : {
                    'Authorization' : `Bearer ${userCredentials.id_token}`
                }
            })
            if(response.data) { 
                return response.data
            } 
        } catch (error) {
            return rejectWithValue(error)
        }
})


export const postMediaImages = createAsyncThunk(
    'media-center/post', 
    async (files:any, { rejectWithValue }) => { 
        try {
            const random = uuid();
            const S3 = require("aws-sdk/clients/s3");
            const s3bucket = new S3({
            endpoint: process.env.REACT_APP_S3_BUCKET_ENDPOINT,
            accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
            secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET
            });
            const uploadedImages: any[] = [];
        
            files.forEach((file:any) => {
                const file_name = file.fileName.replace(/\s/g, "");
                const params = {
                    Body: file.file,
                    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
                    Key: `${process.env.REACT_APP_S3_FOLDER_NAME}${random}${file_name}`
                };
                s3bucket.putObject(params)
                .on("build", (request: any) => {
                    request.httpRequest.headers.Host = process.env.REACT_APP_S3_API_URL;
                    request.httpRequest.headers["Content-Length"] = file.size;
                    request.httpRequest.headers["Content-Type"] = file.type;
                    request.httpRequest.headers["Access-Control-Allow-Origin"] = "*";
                    request.httpRequest.headers["x-amz-acl"] = "public-read";
                })
                .send(async(err: any, data: any) => {
                    if (err) {
                        return rejectWithValue(err.stack)
                    } else {
                        const imageUrl = `${process.env.REACT_APP_S3_CDN_URL}${process.env.REACT_APP_S3_FOLDER_NAME}${random}${file_name}`;
                        uploadedImages.push({
                            origin_url : imageUrl, 
                            image_url : imageUrl, 
                            fileName: file_name,
                            company : userCredentials.company.code
                        });
                        if (uploadedImages.length === files.length) {
                            try {
                                const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/images`, uploadedImages, { 
                                    headers : {
                                        'Authorization' : `Bearer ${userCredentials.id_token}`
                                    }
                                })
                                if(response.data) { 
                                    return response.data.data
                                } else {
                                    return rejectWithValue(response.data.message)
                                }
                            } catch (error) {
                                return rejectWithValue(error)
                            }
                        }
                    }
                });
            });
        } catch (error) {
            return rejectWithValue(error)
        }
})