import { createSlice } from '@reduxjs/toolkit';
import { getMediaImages, postMediaImages } from './mediaCenterReducers';
import { errorSweetAlert } from '../../../../utilities/errorhandling';


const initialState: any = {
  media : [],
  loading : true,
  upload : false, 
  loading_upload : false,
  total : 0
};

export const mediaCenterSlice = createSlice({
  name: 'media-center',
  initialState,
  reducers: {
    resetMediaCenter: (state) => {
      state.upload = false; 
    }
  },
  extraReducers: (builder) => {
    builder
      // get data
      .addCase(getMediaImages.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMediaImages.fulfilled, (state, action:any) => {
        state.loading = false;
        state.media = action.payload.data.length > 0 ? action.payload.data : [];
        state.total = action.payload.data.length > 0 ? action.payload.total.row : 0;
      })
      .addCase(getMediaImages.rejected, (state, action : any) => {
        state.loading = false;
        errorSweetAlert(action.payload)
      })
      // post data
      .addCase(postMediaImages.pending, (state) => {
        state.loading_upload = true;
      })
      .addCase(postMediaImages.fulfilled, (state, action:any) => {
        state.loading_upload = false;
        state.upload = true
      })
      .addCase(postMediaImages.rejected, (state, action : any) => {
        state.loading_upload = false;
        errorSweetAlert(action.payload)
      })
  },
});

export const { resetMediaCenter } = mediaCenterSlice.actions;

export default mediaCenterSlice.reducer;
