import { Stack, Box, Button, TextField, Grid, Switch, FormGroup , FormControlLabel, Skeleton  } from '@mui/material'
import {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import {  getSettingVisibility, updateAutomationOrder, updateSettingPlatform } from '../reducers/profilesReducers';
import Select from 'react-select' 
import SnackBarAlert from '../../../../components/SnackBarAlert';
import { updatePlatformSnackBar } from '../profileSlice';
import swal from 'sweetalert';
import HintsInfo from '../../../../components/HintsInfo';
import { checkValidation } from '../../../../utilities/userFeaturesValidation';

const SettingPlatform : React.FC<any> = ({ profile, refetch, setRefetch }) => {

    const dispatch = useDispatch()
    const { setting_platform, loading_setting_platform, visibility_product, loading_visibility_product } = useSelector((state : RootState) => state.profile)

    const [companySetting, setCompanySetting] = useState<any>({
        discount : "",
        down_payment : "",
        credit_limit_amount : 0,
        credit_limit_isActive : "",
        minimum_total_order_amount : 0,
        minimum_total_order_isActive : null,
        payment_term : "",
        automation_fulfillment : false,
        id : "",
    });
    const [automation, setAutomation] = useState(false);

    const handleChangeMinOrder = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCompanySetting({...companySetting, minimum_total_order_isActive : event.target.checked })
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCompanySetting({...companySetting, credit_limit_isActive : event.target.checked })
    };

    const onChangeFormValuePercentage = (e : any) => {
        const { name, value } = e.target 
        if(value === isNaN || value === "") {
            setCompanySetting({...companySetting, [name] : 0 })
        } else {
            if(value > 100) {
                swal('Error', "Percentage can't be more than 100!", 'error')
            } else {
                setCompanySetting({...companySetting, [name] : parseFloat(value) })
            }
        }
    }

    const onChangeFormValueTerm = (e : any) => {
        const { name, value } = e.target 
        if(value === isNaN || value === "") {
            setCompanySetting({...companySetting, [name] : 0 })
        } else {
            setCompanySetting({...companySetting, [name] : parseFloat(value) })
        }
    }

    const onChangeFormValue = (e : any) => {
        const { name, value } = e.target 
        if(value === isNaN || value === "") {
            setCompanySetting({...companySetting, [name] : 0 })
        } else {
            setCompanySetting({...companySetting, [name] : value.replace(/,/g, "") })
        }
    }

        const optionsDirectPayment  = [
            { value : true, label : "Direct Payment"},
            { value : false, label : "Indirect Payment"}
        ]

        const optionsVisibility  = [
            { value : "CLOSED", label : "CLOSED"},
            { value : "OPEN", label : "OPEN"},
            { value : "EXCLUSIVE", label : "EXCLUSIVE"}
        ]

        const [directPayment, setDirectPayment] = useState<any>([]);
        const [productVisibility, setProductVisibility] = useState<any>([]);

        const handleChangeDirectPayment = (value: any) : void => {
            setDirectPayment(value)
        }
        const handleChangeVisibility = (value: any) : void => {
            setProductVisibility(value)
        }

    const onClickSave = () => {
        const body = {
            body : {
                "company": companySetting.id,
                "discount": companySetting.discount,
                "down_payment": companySetting.down_payment,
                "credit_limit": {
                    "isActive": companySetting.credit_limit_isActive,
                    "amount": companySetting.credit_limit_amount
                },
                "minimum_total_order": {
                    "isActive": companySetting.minimum_total_order_isActive,
                    "amount": companySetting.minimum_total_order_amount
                },
                "direct_payment": directPayment.value,
                "payment_term": {
                    "value": companySetting.payment_term,
                    "label": `${companySetting.payment_term} Days`
                }
            },
            visibiltySetting : productVisibility,
        }
        dispatch(updateSettingPlatform(body))
    }

    const handleChangeAutomation = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const params = {
                id_company : profile._id,
                body : {
                    automation_fulfillment : event.target.checked
                }
            }
            setCompanySetting({...companySetting, automation_fulfillment : event.target.checked })
            const response : any = await dispatch(updateAutomationOrder(params))
            if(response.meta.requestStatus === 'fulfilled') {
                setRefetch(!refetch)
                setTimeout(() => {
                    setAutomation(true)
                }, 1000);
                setTimeout(() => {
                    setAutomation(false)
                }, 4000);
            }

        } catch (error:any) {
            swal('Error', `${error.message}`, 'error')
        }
    };

    useEffect(() => {   
        // dispatch(getActionCategoryCompany())
        dispatch(getSettingVisibility(profile._id))
        // eslint-disable-next-line
    }, [profile._id]);

    useEffect(() => {
        if(visibility_product) {
            setProductVisibility(visibility_product)
        }
    }, [visibility_product]);

    useEffect(() => {
        if(setting_platform) {
            setTimeout(() => {
                dispatch(updatePlatformSnackBar())
            }, 3000);
        }
        // eslint-disable-next-line
    }, [setting_platform]);

    useEffect(() => {
        if(profile.setting.length) {
            setCompanySetting({...companySetting,
                id : profile.setting[0]._id,
                discount : profile.setting[0].discount,
                down_payment :profile.setting[0].down_payment,
                credit_limit_amount : `${profile.setting[0].credit_limit.amount}`,
                credit_limit_isActive : profile.setting[0].credit_limit.isActive,
                minimum_total_order_amount : `${profile.setting[0].minimum_total_order.amount}`,
                minimum_total_order_isActive : profile.setting[0].minimum_total_order.isActive,
                payment_term :profile.setting[0].payment_term.value,
                automation_fulfillment : profile.setting[0].automation_fulfillment || false,
            })
            setDirectPayment({
                value : profile.setting[0].direct_payment , 
                label : profile.setting[0].direct_payment  ? "Direct Payment" : "Indirect Payment"
            })
        }
        // eslint-disable-next-line
    }, [profile]);

    

    return (
        <Stack sx={{ width: '100%' }}>
            <Grid container spacing={2}>
                <Grid item xl={4} lg={4} md={4} xs={12}>
                    <Box className="title-hints-info">
                        <h4>Discount</h4>
                        <HintsInfo message="Percentage"/>
                    </Box>
                    <TextField
                        margin="dense"
                        fullWidth
                        id="discount" 
                        name="discount"
                        size="small"
                        value={companySetting.discount}
                        onChange={onChangeFormValuePercentage}
                        type="text"
                        required
                    />
                </Grid>
                <Grid item xl={4} lg={4} md={4} xs={12}>
                    <Box className="title-hints-info">
                        <h4>Down Payment</h4>
                        <HintsInfo message="Percentage"/>
                    </Box>
                    <TextField
                        margin="dense"
                        fullWidth
                        id="down_payment"
                        name="down_payment"
                        size="small"
                        value={companySetting.down_payment}
                        onChange={onChangeFormValuePercentage}
                        required
                    />
                </Grid>
                <Grid item xl={4} lg={4} md={4} xs={12}>
                    <Box className="title-hints-info">
                        <h4>Payment Term</h4>
                        <HintsInfo message="Days"/>
                    </Box>
                    <TextField
                        margin="dense" 
                        fullWidth
                        id="payment_term"
                        value={companySetting.payment_term}
                        name="payment_term"
                        size="small"
                        onChange={onChangeFormValueTerm}
                        required
                    />
                </Grid>
                <Grid item xl={4} lg={4} md={6} xs={12}>
                    <Box className="title-hints-info">
                        <h4>Credit Limit</h4>
                        <HintsInfo message="Rupiah"/>
                    </Box>
                    <Stack flexDirection="row" alignItems="center">
                        <FormGroup>
                            <FormControlLabel 
                                control={
                                    <Switch
                                        checked={companySetting.credit_limit_isActive}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label={companySetting.credit_limit_isActive ? "Active" : "Inactive"} 
                            />
                        </FormGroup>
                        <Box mx={1} />
                        <TextField
                            disabled={!companySetting.credit_limit_isActive}
                            margin="dense"
                            fullWidth
                            id="credit_limit_amount"
                            placeholder={companySetting.credit_limit_amount}
                            value={parseFloat(companySetting.credit_limit_amount).toLocaleString()}
                            name="credit_limit_amount"
                            size="small"
                            onChange={onChangeFormValue}
                            required
                        />
                    </Stack>
                </Grid>
                <Grid item xl={4} lg={4} md={6} xs={12}>
                    <Box className="title-hints-info">
                        <h4>Minimum Total Order</h4>
                        <HintsInfo message="Rupiah"/>
                    </Box>
                    <Stack flexDirection="row" alignItems="center">
                        <FormGroup>
                            <FormControlLabel 
                                control={
                                    <Switch
                                        checked={companySetting.minimum_total_order_isActive}
                                        onChange={handleChangeMinOrder}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label={companySetting.minimum_total_order_isActive ? "Active" : "Inactive"} 
                            />
                        </FormGroup>
                        <Box mx={1} />
                        <TextField
                            disabled={!companySetting.minimum_total_order_isActive}
                            margin="dense"
                            fullWidth
                            id="minimum_total_order_amount"
                            placeholder={companySetting.minimum_total_order_amount}
                            value={parseFloat(companySetting.minimum_total_order_amount).toLocaleString()}
                            name="minimum_total_order_amount"
                            size="small"
                            onChange={onChangeFormValue}
                            required
                        />
                    </Stack>
                </Grid>
                <Grid item xl={4} lg={4} md={6} xs={12}>
                    <Box ><h4>Direct Payment</h4></Box>
                    <Box pt={1}>
                        <Select
                            placeholder="Select"
                            value={directPayment}
                            isSearchable={true}
                            options={optionsDirectPayment}
                            onChange={handleChangeDirectPayment}
                            id="select-style-cat"
                        /> 
                    </Box>
                </Grid>
                <Grid item xl={4} lg={4} md={6} xs={12}>
                    <Box className="title-hints-info">
                        <h4>Automation Order</h4>
                        <HintsInfo message="Automation Orders setting"/>
                    </Box>
                    <Stack flexDirection="row" alignItems="center">
                        <FormGroup>
                            <FormControlLabel 
                                control={
                                    <Switch
                                        checked={companySetting.automation_fulfillment}
                                        onChange={handleChangeAutomation}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        disabled={!checkValidation('UPAUTORD34512341')}
                                    />
                                } 
                                label={companySetting.automation_fulfillment ? "Active" : "Inactive"} 
                            />
                        </FormGroup>
                    </Stack>    
                </Grid>
                <Grid item xl={4} lg={4} md={6} xs={12}>
                    <Box ><h4>Product Visibility</h4></Box>
                    { loading_visibility_product ? 
                    <Box mt={0} pt={0}><Skeleton height={60} /></Box>
                    :
                    <Box pt={1}>
                        <Select
                            placeholder="Select Product Visibility"
                            value={productVisibility}
                            isSearchable={true} 
                            options={optionsVisibility}
                            onChange={handleChangeVisibility}
                            id="select-style-cat"
                        /> 
                    </Box> }
                </Grid>
            </Grid>

            { checkValidation('UPSETPLCOM6424352') && 
            <Stack 
                sx={{my:3}} 
                flexDirection="row" 
                justifyContent="flex-end"
            >
                <Button 
                    onClick={onClickSave} 
                    disabled={loading_setting_platform} 
                    variant='contained'
                >{loading_setting_platform ? "Loading" : "Save"}
                </Button>
            </Stack> }

            <SnackBarAlert message={`Success update Setting Platform Company.`} initialState={setting_platform} />
            <SnackBarAlert message={`Success update Setting Automation Order.`} initialState={automation} />

        </Stack>
    )
}

export default SettingPlatform
