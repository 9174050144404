import { 
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Box,
} from '@mui/material';


const ModuleDetail : React.FC<any> = ({
    detail,
}) => {

    const stylesPaper = { 
        minHeight: 450, 
        backgroundColor: '#fff', 
        borderRadius: 10, 
        boxShadow: '#ddd 0 0 10px',
        marginBottom: '2rem' 
    }

    return (
    <Box>
        <TableContainer style={stylesPaper}>
            <Table aria-label="simple table" > 
              <TableHead>
                <TableRow >
                    <TableCell style={{fontWeight: 700,}}>Navigation</TableCell>
                    <TableCell style={{fontWeight: 700,}}>Child Navigation</TableCell>
                    <TableCell style={{fontWeight: 700,}}>Features</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
              { detail.map((row :any, i : any) => (
                <TableRow key={i} >
                    <TableCell component="th" scope="row">
                        <Box>
                            {row.navigation.name}
                        </Box> 
                    </TableCell>
                    <TableCell component="th" scope="row">
                        { row.child.length !== 0 ?
                        <Box>
                            {row.child.map((child:any, ind:any) => (
                                <Box key={ind}>
                                    {child.name},
                                </Box> 
                            ))}
                        </Box>  
                        : "-" }
                    </TableCell>
                    <TableCell component="th" scope="row">
                        { row.features.length !== 0 ?
                        <Box>
                            {row.features.map((ele:any, idx:any) => (
                            <Box key={idx}>
                                {ele.name},
                            </Box> 
                            ))}
                        </Box> 
                        : "-" }
                    </TableCell>
                </TableRow>
              ))}
              </TableBody>
          </Table>
        </TableContainer>
    </Box>
  );
}

export default ModuleDetail