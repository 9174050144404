import { Stack } from '@mui/material'
import { useLocation } from 'react-router-dom';
import ProductForm from './ProductForm';
import ProductDetail from './ProductsDetail';
import ProductLists from './ProductLists';

function MainProductPage() {

    const location : any = useLocation()
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    
    let query = useQuery();
    let active_page = query.get('page')
    
    const switchPage = (page:any) => {
        switch (page) {
        case "create":
            return <ProductForm/>
        case "detail":
            return <ProductDetail/>
        case "edit":
            return <ProductForm/>
        default:
            return <ProductLists/>
        }
    }
 
    return (
        <Stack>
            {switchPage(active_page)}
        </Stack>
    )
}

export default MainProductPage
