import { Box, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import DialogCreation from '../features_app/dashboard/buyer_lists/components/DialogCreation';
import { getSearchCompanyByCode } from '../features_app/dashboard/buyer_lists/redux/buyerListsReducer';
import { resetSnackBarPreferred } from '../features_app/dashboard/buyer_lists/redux/buyerListsSlice';
import { checkValidation } from '../utilities/userFeaturesValidation';
import SnackBarAlert from './SnackBarAlert';

const PreferredButton : React.FC<any> = ({ company }) => {
    const dispatch = useDispatch()

    const { preferred_all, loading_preferred_all, loading_search, search_buyer, create  } = useSelector((state : RootState) => state.preferred)
    const [dialogCreation, setDialogCreation] = useState(false);
    const [valueSearchCompany, setValueSearchCompany] = useState("");

    const checkPreferred = (buyer:any, lists:any) => {
        const check = lists.filter((item:any) => item._id === buyer._id)
        if(check.length > 0) {
            return true
        } else {
            return false
        }
    }

    const onClickPreferred = (buyer:any) => {
        const search = {
            limit : 10,
            skip : 0,
            'filterTopic[]' : ['code'],
            'filterValue[]' : [buyer.code]
        }
        dispatch(getSearchCompanyByCode(search))
        setDialogCreation(true)
    }

    useEffect(() => {
        if(create) {
          setTimeout(() => {
            dispatch(resetSnackBarPreferred())
          }, 2500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create]);

    return (
        <Stack>
            { checkValidation('CREPREBUY883630') &&
            <Box 
                className="btn-chat" 
                onClick={() => onClickPreferred(company)}
                sx={{ display: !loading_preferred_all && checkPreferred(company, preferred_all) ? 'none' : 'block' }}
            >
                <p>Add to Preferred</p>
            </Box> }
            <DialogCreation  
                open={dialogCreation} 
                setOpen={setDialogCreation} 
                name="Add Preferred Buyer"
                search_buyer={search_buyer}
                loading_search={loading_search}
                valueSearch={valueSearchCompany}
                setValueSearch={setValueSearchCompany}
            />
            <SnackBarAlert message={`Preferred Buyer is successfuly added`} initialState={create} />
        </Stack>

  )
}

export default PreferredButton