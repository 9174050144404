import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';
import Fade from '@mui/material/Fade';
import ButtonCreate from '../../../../../components/ButtonCreate';

const FilterInvoice : React.FC<any> = ({ 
    filterType, setFilterType,
    filterStatus, setFilterStatus,
}) => {
    const [open, setOpen] = useState(false);

    function handleCheckBoxType(item:any) {
        if(!filterType.includes(item)) {
            setFilterType([...filterType, item])
        } else {
            let filter = filterType.filter((i:any) => i !== item)
            setFilterType(filter)
        }
    }

    function handleCheckBoxStatus(item:any) {
        if(!filterStatus.includes(item)) {
            setFilterStatus([...filterStatus, item])
        } else {
            let filter = filterStatus.filter((i:any) => i !== item)
            setFilterStatus(filter)
        }
    }

    function checkSelected(array: any, item:any) {
        if (array.includes(item)) {
            return true
        } else {
            return false
        }
    }
  
    const onClickResetState = () => {
        setFilterStatus([])
        setFilterType([])
    }

  const onClickReset = () => {
    setOpen(false)
    onClickResetState()
  }

  const Container = (
    <Stack className='filter-content'>
        <Box>
            <h4>Filter by Type : </h4>
            <Box pt={1} pb={2} className='filter-checkbox'>
                {type.map((item:any) => (
                    <div key={item}>
                        <input 
                            type='checkbox' 
                            id='type' 
                            name={item}
                            onChange={() => handleCheckBoxType(item)} 
                            checked={checkSelected(filterType,item)}
                        /> 
                        <label htmlFor={item}>
                            <span>{item}</span>
                        </label>
                    </div>
                ))}
            </Box>
            <h4>Filter by Status :</h4>
            <Box pt={1} pb={2} className='filter-checkbox'> 
                {status.map((item:any) => (
                    <div key={item}>
                        <input 
                            type='checkbox' 
                            id='status' 
                            name={item}
                            onChange={() => handleCheckBoxStatus(item)} 
                            checked={checkSelected(filterStatus, item)}
                        /> 
                        <label htmlFor={item}>
                            <span>{item}</span>
                        </label>
                    </div>
                ))}
            </Box>
        </Box>
        <Box className='divider-btn'>
        <ButtonCreate 
          name='Reset Filter'
          onClick={() => onClickReset()}
          outlined={true}
        />
      </Box>
    </Stack>
  );

  return (
    <Box
      onMouseLeave={event => {
        setOpen(false)
      }}
    >
        <Box 
          className='filter-component'
          onMouseEnter={event => {
            setOpen(true);
          }}
        >
            Filter
        </Box>
        <Box className='filter-container' sx={{ display: !open ? 'none' : 'flex' }}>
            <Fade in={open}>{Container}</Fade>
        </Box>
    </Box>
  );
}

const type = ["Final Payment", "Down Payment", "Draft"]
const status = ["Waiting to Confirm", "Ready for Payment", "PAID",]

export default FilterInvoice