import React from 'react'
import ButtonCreate from '../../../../components/ButtonCreate';
import { Stack, Box, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import HintsInfo from '../../../../components/HintsInfo';
import StarRequired from '../../../../components/StarRequired';
import TextField from '@mui/material/TextField';
import ProductsField from './ProductsField';
import swal from 'sweetalert';
import AutoGenerationSetting from './AutoGenerationSetting';

const FormPreferred : React.FC<any> = ({
  search_buyer,
  resetData,
  resetSearchBuyer,
  productItems,
  setProductItems,
  bulkUpload,
  setBulkUpload,
  fileExcel,
  setFileExcel,
  companySetting,
  setCompanySetting,
  setSelectedPeriod,
  setSelectedTrigger,
  selectedPeriod,
  selectedTrigger,
  errorTrigger,
  errorPeriod,
  setErrorPeriod,
  setErrorTrigger,
  type
}) => {

  const dispatch = useDispatch()
  const regex = /^\d*$/; // only allow digits (0-9)

  const onChangeFormValuePercentage = (e : any) => {
    const { name, value } = e.target 
    if(regex.test(value) === false) {
        setCompanySetting({...companySetting, [name] : 0 })
    } else {
        if(value > 100) {
            swal('Error', "Percentage can't be more than 100!", 'error')
        } else {
            setCompanySetting({...companySetting, [name] : parseFloat(value) })
        }
    }
  }

  const onChangeFormValueTerm = (e : any) => {
      const { name, value } = e.target 
      if(regex.test(value) === false) {
          setCompanySetting({...companySetting, [name] : 0 })
      } else {
          setCompanySetting({...companySetting, [name] : parseFloat(value) })
      }
  }

  const handleChange = (e:any) => {
    const { name, checked } = e.target 
    setCompanySetting({...companySetting, [name] : checked })
  };

  const onChangeFormValue = (e : any) => {
    const { name, value } = e.target 
    if(value === isNaN || value === "") {
        setCompanySetting({...companySetting, [name] : 0 })
    } else {
        setCompanySetting({...companySetting, [name] : value.replace(/,/g, "") })
    }
  }



  return (
      <Box>
          <Box className='card-company'> 
            <Box className='top-header'>
              { type === 'create' ?
              <h2>{search_buyer.length && search_buyer[0].legalName}</h2> : 
              <h2>{search_buyer.preferred.legalName || "N/A"}</h2> }
              { type === 'create' ?
              <ButtonCreate 
                name="Cancel" 
                type="button"
                color="error" 
                onClick={() => {
                  resetData()
                  dispatch(resetSearchBuyer())
                }}
              /> : null }
            </Box>
            <Box  className='code'>
            { type === 'create' ?
              <p>Code : {search_buyer.length && search_buyer[0].code}</p> :
              <p>Code : {search_buyer.preferred.code || "N/A"}</p> }
            </Box>
          </Box>
          <Box className='form-preferred'>
            <Box className='form'>
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} md={4} xs={12}>
                  <Box className="title-hints-info">
                    <h6>Discount</h6>
                    <HintsInfo message="Percentage"/>
                    <StarRequired/>
                  </Box>
                  <TextField
                      margin="dense"
                      fullWidth
                      id="initial_discount"
                      name="initial_discount"
                      size="small"
                      value={companySetting.initial_discount}
                      onChange={onChangeFormValuePercentage}
                      type="text"
                      required
                  />
              </Grid> 
              <Grid item xl={4} lg={4} md={4} xs={12}>
                  <Box className="title-hints-info">
                    <h6>Payment Term</h6>
                    <HintsInfo message="Days"/>
                    <StarRequired/>
                  </Box>
                  <TextField
                      margin="dense"
                      fullWidth
                      id="payment_term"
                      value={companySetting.payment_term}
                      name="payment_term"
                      size="small"
                      onChange={onChangeFormValueTerm}
                      required
                  />
              </Grid>
              <Grid item xl={4} lg={4} md={4} xs={12}>
                <Box className="title-hints-info">
                    <h6>Down Payment</h6>
                    <HintsInfo message="Percentage"/>
                    <StarRequired/>
                </Box>
                <TextField
                    margin="dense"
                    fullWidth
                    id="down_payment"
                    name="down_payment"
                    size="small"
                    value={companySetting.down_payment}
                    onChange={onChangeFormValuePercentage}
                    required
                />
              </Grid> 
              <Grid item xl={6} lg={6} md={6} xs={12}>
                  <Box className="title-hints-info">
                      <h6>Credit Limit</h6>
                      <HintsInfo message="Rupiah"/>
                      <StarRequired/>
                  </Box>
                  <Stack flexDirection="row" alignItems="center">
                      <FormGroup>
                          <FormControlLabel 
                              control={
                                  <Switch
                                      checked={companySetting.credit_limit_isActive}
                                      onChange={handleChange}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                      name="credit_limit_isActive"
                                  />
                              } 
                              label={companySetting.credit_limit_isActive ? "Active" : "Inactive"} 
                          />
                      </FormGroup>
                      <Box mx={1} />
                      <TextField
                          disabled={!companySetting.credit_limit_isActive}
                          margin="dense"
                          fullWidth
                          id="credit_limit_amount"
                          placeholder={companySetting.credit_limit_amount}
                          value={parseFloat(companySetting.credit_limit_amount).toLocaleString()}
                          name="credit_limit_amount"
                          size="small"
                          onChange={onChangeFormValue}
                          required
                      />
                  </Stack>
              </Grid>
              <Grid item xl={6} lg={6} md={6} xs={12}>
                  <Box className="title-hints-info">
                      <h6>Minimum Total Order</h6>
                      <HintsInfo message="Rupiah"/>
                      <StarRequired/>
                  </Box>
                  <Stack flexDirection="row" alignItems="center">
                      <FormGroup>
                          <FormControlLabel 
                              control={
                                  <Switch
                                      checked={companySetting.minimum_total_order_isActive}
                                      onChange={handleChange}
                                      name="minimum_total_order_isActive"
                                      inputProps={{ 'aria-label': 'controlled' }}
                                  />
                              } 
                              label={companySetting.minimum_total_order_isActive ? "Active" : "Inactive"} 
                          />
                      </FormGroup>
                      <Box mx={1} />
                      <TextField
                          disabled={!companySetting.minimum_total_order_isActive}
                          margin="dense"
                          fullWidth
                          id="minimum_total_order_amount"
                          placeholder={companySetting.minimum_total_order_amount}
                          value={parseFloat(companySetting.minimum_total_order_amount).toLocaleString()}
                          name="minimum_total_order_amount"
                          size="small"
                          onChange={onChangeFormValue}
                          required
                      />
                  </Stack>
              </Grid>
            </Grid>

            </Box>
          </Box>
          <Box className='form-preferred' mt={2}>
              <Box><h4>Setting Invoice Generation</h4></Box>

              <AutoGenerationSetting 
                selectedTrigger={selectedTrigger}
                setSelectedTrigger={setSelectedTrigger}
                selectedPeriod={selectedPeriod}
                setSelectedPeriod={setSelectedPeriod}
                errorTrigger={errorTrigger}
                setErrorTrigger={setErrorTrigger}
                errorPeriod={errorPeriod}
                setErrorPeriod={setErrorPeriod}
              />
          </Box>
          <Box className='form-preferred' mt={2}>
            <Box className='form-product' mt={2}>
              <Box>
                <ProductsField 
                  productItems={productItems}
                  setProductItems={setProductItems}
                  bulkUpload={bulkUpload}
                  setBulkUpload={setBulkUpload}
                  fileExcel={fileExcel}
                  setFileExcel={setFileExcel}
                  edit={type === 'edit' ? true : false}
                />
              </Box> 
            </Box>
          </Box>

          <Stack mt={4} flexDirection="row" justifyContent="flex-end">
            <Box mt={2} width="fit-content" id='submits-btn-preferred'>
              <Box>
                <ButtonCreate 
                  name={type === 'create' ? "Submit Preferred" : "Save Preferred"}
                  type="submit"
                />
              </Box>
            </Box>
          </Stack>
      </Box>
  )
}

export default FormPreferred