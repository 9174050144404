import React from 'react';
import ReactDOM from 'react-dom';
import { store } from './app/store';
import { Provider } from 'react-redux';
import AppRoute from './routes';
import './assets/scss/root.scss'
import { ThemeProvider } from '@mui/material/styles';
import theme  from './theme/index'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// import Bugsnag from '@bugsnag/js'
// import {BugsnagPluginReactResult} from '@bugsnag/plugin-react'
// import BugsnagPluginReact from '@bugsnag/plugin-react'

// Bugsnag.start({
//   apiKey: `${process.env.REACT_APP_API_BUGSNAG_KEY}`,
//   plugins: [new BugsnagPluginReact()]
// })

// const plugin = Bugsnag.getPlugin("react") as BugsnagPluginReactResult
// const ErrorBoundary = plugin.createErrorBoundary(React)

if (process.env.NODE_ENV === 'development') {
  console.log('Running in development mode');
} else if (process.env.NODE_ENV === 'production') {
  console.log('Running in production mode');
} else {
  console.log('Running in an unknown environment');
}


ReactDOM.render(
  <React.StrictMode>
    {/* <ErrorBoundary> */}
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppRoute />
      </ThemeProvider>
    </Provider>
    {/* </ErrorBoundary> */}
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
