import { Stack } from '@mui/material'
import { useLocation } from 'react-router-dom';
import CreateWarehouse from './CreateWarehouse';
import DetailWarehouse from './DetailWarehouse';
import EditWarehouse from './EditWarehouse';
import ListWarehouse from './ListWarehouse';

function MainPageWarehouse() {

    const location : any = useLocation()
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    
    let query = useQuery();
    let active_page = query.get('page')

    const switchPage = (page:any) => {
        switch (page) {
        case "create":
            return <CreateWarehouse/>
        case "detail":
            return <DetailWarehouse/>
        case "edit":
            return <EditWarehouse/>
        default:
            return <ListWarehouse/>
        }
    }
 
    return (
        <Stack>
            {switchPage(active_page)}
        </Stack>
    )
}

export default MainPageWarehouse
