import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import ViewImage from '../../../../components/ViewImage';
import { submitProofPayment } from '../redux/fulfillmentReducers';
import { checkValidation } from '../../../../utilities/userFeaturesValidation';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogPayment : React.FC<any> = ({ open, setOpen, data, fileUrl, setFileUrl  }) => {

    const dispatch = useDispatch()
    const [openView, setOpenView] = React.useState(false);

    function handleClose () {
        setOpen(false);
        setFileUrl({name : '', url: '', type: 'upload'})
    };
    
    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">Confirm Proof Payment</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
          <Box className="proofpayment">
            { fileUrl.url === "" ? 
            null
            :
            <Stack flexDirection="column" className='image-preview' >
              <img alt="file" src={fileUrl.url} onClick={()=> setOpenView(true)} />
              <Box className='btn-remove-img'
                  onClick={() => setFileUrl({...fileUrl, name : '', url : ''})}
              >
              </Box>
            </Stack> }
          </Box> 
        </DialogContent>
        <DialogActions>
          {checkValidation('CONDPORDFUL587337') &&
          <Button 
            color="primary"
            size="small" autoFocus variant='contained' 
            disabled={fileUrl.url === '' ? true : false}
            onClick={() => dispatch(submitProofPayment(data.idPo))}
          >
            Confirm Payment
          </Button> }
        </DialogActions>
      </BootstrapDialog>
      <ViewImage open={openView} setOpen={setOpenView} url={fileUrl.url} />
    </div>
  );
}

export default DialogPayment;