import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';
const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }

export const postActionCompanyDetail = createAsyncThunk(
    'company-detail/post',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/company`, body, {headers})
            if(response.data) {
                localStorage.setItem('relogin', 'true')
                let message = "Success register company"
                return {data : true, message : message}
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);



export const postCompanyDetail = createAsyncThunk(
    'company-detail',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/vendor/company`, body, {headers})
            if(response.data) {
                let message = response.data.message
                return {data : true, message : message}
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const postLegalDocument = createAsyncThunk(
    'legal-document',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/vendor/legaldocs`, body, {headers})
            if(response.data) {
                let message = response.data.message
                try {
                    let update = {
                        isFirstTime : false
                    }
                    const first_time : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/user-vendor/${userCredentials.auth_id}`, update, {headers})
                    if(first_time.data.errors === null) {
                        return {data : true, message : message}
                    } else {
                        return rejectWithValue(first_time.data.message)
                    }
                } catch (error) {
                    return rejectWithValue(error)
                }
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const postAddUserManagement = createAsyncThunk(
    'user-management',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/vendor/user`, body, {headers})
            if(response.data) {
                return {data : true, message : "Success added user team member."}
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getCompanyCategoryVendor = createAsyncThunk(
    'category-vendir/get',
    async (_,{ rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/master/category?is_buyer=0`, {headers})
            if(response.data) {
                return response.data.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);





