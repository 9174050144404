import { createSlice } from '@reduxjs/toolkit';
import { 
  downloadExcelTemplate, getAllProductBySearch, getAllProductByWarehouses, 
  getMasterCategory, getMasterCategoryList, getMasterTax, getProductBySlug, 
  getTotalAllProducts, 
  postCreateProducts, removeProduct, updateProducts, 
  uploadBulkProduct
} from './reducers/productsReducers';
import { errorSweetAlert } from '../../../utilities/errorhandling';
import swal from 'sweetalert';

const initialState: any = {
  data: [], 
  total_data : 0,
  total_products : 0,
  loading_total_products : false,
  loading : false,
  loaded : false,
  tax : [],
  loading_tax : false,
  category_tree : [],
  loading_category_tree : false,
  success_create: false,
  message_create: "",
  loading_create: false,
  success_update: false,
  message_update: "",
  loading_update: false,
  success_remove: false,
  loading_remove: false,
  product_detail: {},
  product_detail_exist : false,
  loading_product_detail : true,
  bulk_product: false,
  loading_bulk_product : false,
  category : [],
  loading_category : false,
  download_template : "",
  loading_download_template : false,
};

export const getProductsSlice = createSlice({
  name: 'products',
  initialState, 
  reducers: {
    removeUrlDownloadTemplate: (state) => {
      state.download_template = ""
    },
    defaultMessageSuccess: (state) => {
      state.success_create = false
      state.success_update = false
      state.success_remove = false
    },
  },
  extraReducers: (builder) => {
    builder
      // get all product
      .addCase(getAllProductByWarehouses.pending, (state) => {
        state.loading = true;
        state.loaded = false
        state.success_update = false;
        state.success_remove = false;
        state.bulk_product = false;
        state.data = [];
        state.total_data = 0;
      })
      .addCase(getAllProductByWarehouses.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data.length > 0 ? action.payload.data : [];
        state.total_data = action.payload.data.length > 0 ? action.payload.total.row : 0;
      })
      .addCase(getAllProductByWarehouses.rejected, (state, action : any) => {
        state.loading = false;
        errorSweetAlert(action.payload)
      })

       // get all product by search
      .addCase(getAllProductBySearch.pending, (state) => {
        // state.loading = true;
        state.loaded = false
      })
      .addCase(getAllProductBySearch.fulfilled, (state, action:any) => {
        // state.loading = false;
        // state.data = action.payload.data;
        state.loaded = true
      })
      .addCase(getAllProductBySearch.rejected, (state, action : any) => {
        // state.loading = false;
        errorSweetAlert(action.payload)
      })

      // get all tax
      .addCase(getMasterTax.pending, (state) => {
        state.loading_tax = true;
      })
      .addCase(getMasterTax.fulfilled, (state, action:any) => {
        state.loading_tax = false;
        state.tax = action.payload.data;
      })
      .addCase(getMasterTax.rejected, (state, action : any) => {
        state.loading_tax = false;
        errorSweetAlert(action.payload)
      })

      // get product by slug
      .addCase(getProductBySlug.pending, (state) => {
        state.loading_product_detail = true;
        state.product_detail_exist = false
      })
      .addCase(getProductBySlug.fulfilled, (state, action:any) => { 
        state.loading_product_detail = false;
        state.product_detail_exist = action.payload.exist
        state.product_detail = action.payload.data;
      })
      .addCase(getProductBySlug.rejected, (state, action : any) => {
        state.loading_product_detail = false;
        errorSweetAlert(action.payload)
      })

      // get all category tree
      .addCase(getMasterCategory.pending, (state) => {
        state.loading_category_tree = true;
      })
      .addCase(getMasterCategory.fulfilled, (state, action:any) => {
        state.loading_category_tree = false;
        state.category_tree = action.payload.data;
      })
      .addCase(getMasterCategory.rejected, (state, action : any) => {
        state.loading_category_tree = false;
        errorSweetAlert(action.payload)
      })

      // get all category tree
      .addCase(getMasterCategoryList.pending, (state) => {
        state.loading_category = true;
      })
      .addCase(getMasterCategoryList.fulfilled, (state, action:any) => {
        state.loading_category = false;
        state.category = action.payload.data;
      })
      .addCase(getMasterCategoryList.rejected, (state, action : any) => {
        state.loading_category = false;
        errorSweetAlert(action.payload)
      })
      
      // create products
      .addCase(postCreateProducts.pending, (state) => {
        state.loading_create = true;
        state.success_create = false
        state.message_create = ""
      })
      .addCase(postCreateProducts.fulfilled, (state, action:any) => {
        state.loading_create = false;
        state.success_create = action.payload.data;
        state.message_create = action.payload.message;
      })
      .addCase(postCreateProducts.rejected, (state, action : any) => {
        state.loading_create = false;
        errorSweetAlert(action.payload)
      })

       // update products
      .addCase(updateProducts.pending, (state) => {
        state.loading_update = true;
        state.message_update = "";
      })
      .addCase(updateProducts.fulfilled, (state, action:any) => {
        state.loading_update = false;
        state.success_update = action.payload.data;
        state.message_update = action.payload.message;
      })
      .addCase(updateProducts.rejected, (state, action : any) => {
        state.loading_update = false;
        errorSweetAlert(action.payload)
      })
       // remove products
       .addCase(removeProduct.pending, (state) => {
        state.loading_remove = true;
      })
      .addCase(removeProduct.fulfilled, (state, action:any) => {
        state.loading_remove = false;
        state.success_remove = action.payload.data;
      })
      .addCase(removeProduct.rejected, (state, action : any) => {
        state.loading_remove = false;
        errorSweetAlert(action.payload)
      })

      // bulk upload product
      .addCase(uploadBulkProduct.pending, (state) => {
        state.loading_bulk_product = true;
        state.bulk_product = false;
      })
      .addCase(uploadBulkProduct.fulfilled, (state, action:any) => {
        state.loading_bulk_product = false;
        state.bulk_product = action.payload.data;
        swal('Success', action.payload.message, 'success')
      })
      .addCase(uploadBulkProduct.rejected, (state, action : any) => {
        state.loading_bulk_product = false;
        errorSweetAlert(action.payload)
      })

      // download template
      .addCase(downloadExcelTemplate.pending, (state) => {
        state.loading_download_template = true;
      })
      .addCase(downloadExcelTemplate.fulfilled, (state, action:any) => {
        state.loading_download_template = false;
        state.download_template = action.payload
      })
      .addCase(downloadExcelTemplate.rejected, (state, action : any) => {
        state.loading_download_template = false;
        errorSweetAlert(action.payload)
      })

      // get total products
      .addCase(getTotalAllProducts.pending, (state) => {
        state.loading_total_products = true;
      })
      .addCase(getTotalAllProducts.fulfilled, (state, action:any) => {
        state.loading_total_products = false;
        state.total_products = action.payload
      })
      .addCase(getTotalAllProducts.rejected, (state, action : any) => {
        state.loading_total_products = false;
        errorSweetAlert(action.payload)
      })


  },
});

export const { removeUrlDownloadTemplate, defaultMessageSuccess } = getProductsSlice.actions;

export default getProductsSlice.reducer;
