import { Box, Grid, Skeleton } from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import calender from '../../../../../assets/img/icon/calendar.png'
import down from '../../../../../assets/img/icon/down.png'
import up from '../../../../../assets/img/icon/up.png'
import store from '../../../../../assets/img/icon/store.png'
import { RootState } from '../../../../../app/store'
import ItemCard from '../../../fulfillment/components/ItemCard'
import ChatButton from '../../../../../components/ChatButton'
import PreferredButton from '../../../../../components/PreferredButton'

const OrderCard : React.FC<any> = ({ item, viewItems, setViewItems, onClickSelected, referenceDocument }) =>  {

    const { items, loading_items, } = useSelector((state : RootState) => state.fulfillment)

    const [checked, setchecked] = useState(false);

    function checkIdInArray(id:string, arr:any) {
        let run = false
        for(let i = 0; i < arr.length; i++) {
            if(arr[i].packageId === id) {
                run = true
                break
            }
        }
        return run
    }
    
    return (
    <div 
        className={`container-item-order-inv ${checkIdInArray(item._id, referenceDocument) ? 'selected-item' : ''} `} 
    >
        <Box className="top-div">
            <Box className='left'  onClick={() => onClickSelected(item)}>
                <Box className='radio-input'>
                    <input type="radio" checked={checkIdInArray(item._id, referenceDocument)} onChange={() =>setchecked(!checked)} />
                </Box>
                <h6>{item.code}</h6>
            </Box>
            <Box className='right'>
                <img src={calender} alt='calender' />
                <p>{moment(item.date).format('lll')}</p>
            </Box>
        </Box>
        <Box className="center-div">
            <Grid container spacing={1}>
                <Grid item xs={12} md={8} sm={12} lg={8} xl={8}>
                    <Box className='left-box'>
                        <Box className='store'>
                            <img src={item.buyer.logo || store} alt="store" />
                            <Box className='name'>
                                <h2>{item.buyer.legalName}</h2>
                                <Box className='chat'>
                                    <ChatButton company={item.buyer} />
                                    <PreferredButton company={item.buyer} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} sm={12} lg={4} xl={4}>
                    <Box className='content-right-inv'>
                        <Box className='content'>
                            <p>Total Purchase</p>
                            <h2>Rp.{(Math.floor(item.sub_total)).toLocaleString()}</h2>
                        </Box>
                        <Box className='content'>
                            <p>Department</p>
                            <h5>: {item.departement || "Not defined"}</h5>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        <Box className='bottom-div'>
            <Box className='left-div'>
                <p>Payment Terms {item.payment_term} Days</p>
            </Box>
            
            <Box className='right-div'>
                { viewItems.open && viewItems.id === item._id ?
                <Box 
                    className='view-btn' 
                    onClick={() => {
                        setViewItems({  
                            open : false,
                            id : item._id 
                        })
                    }}
                >
                    <p>Hide Purchase Items</p>
                    <img src={up} alt="down" />
                </Box>
                :
                <Box 
                    className='view-btn' 
                    onClick={() => {
                        setViewItems({
                            open : true,
                            id : item._id 
                        })
                    }}
                >
                    <p>View Purchase Items</p>
                    <img src={down} alt="down" />
                </Box>
                }
            </Box> 
        </Box>
        { viewItems.open && viewItems.id === item._id ?
        <Box className='card-product-items'>
            { loading_items ? 
            <LoadingItems />
            : 
            <Box>
                <Box className='wrapper-item'>
                    {items.length && items.map((value : any, index : number) => (
                        <ItemCard ele={value} index={index} lastStatus={item.lastStatus}  />
                    ))}
                </Box>
                {items.length && items.length === 0 && <p className='no-data'>No Data</p>}
            </Box>
            }
        </Box> : null }
    </div>
  )
}


const LoadingItems = () => {
    return (
        <Box>
           <Box m={0} p={0}> <Skeleton height={60} /></Box>
           <Box m={0} p={0}> <Skeleton height={60} /></Box>
           <Box m={0} p={0}> <Skeleton height={60} /></Box>
        </Box>
    )
}

export default OrderCard