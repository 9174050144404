import { Box, Grid, TextField } from '@mui/material'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const StoreSchedule : React.FC<any> = ({ operationalTime, proceedOperationTime, name }) => {

    const onChangeTime = (itm : string, value: any ) => {
        let copy_array = [...operationalTime]
        const newData = copy_array.map((obj : any, i : any) => {
            if(obj.day === itm)
            return {
                ...obj,
                [value.target.name]: value.target.value,
            }
            return obj
        });
        proceedOperationTime(newData) 
      
    }

    const onClickActive = (itm : string, value: any ) => {
        if(value.target.checked) {
            let copy_array = [...operationalTime]
            const newData = copy_array.map((obj : any, i : any) => {
                if(obj.day === itm)
                return {
                    ...obj,
                    active : value.target.checked,
                }
                return obj
            });
            proceedOperationTime(newData)
        } else {
            let copy_array = [...operationalTime]
            const newData = copy_array.map((obj : any, i : any) => {
                if(obj.day === itm)
                return {
                    ...obj,
                    active : value.target.checked,
                }
                return obj
            });
            proceedOperationTime(newData)
        }
    }


    return (
    <div>
        <Box><h4>{name}</h4></Box>
        
        <Grid flexDirection="column">
            {operationalTime.map((day:any, index:any) => (
            <Grid display="flex" flexDirection="row" alignItems="center" my={1} key={index}>
                <Box width={200}>
                    <FormGroup>
                        <FormControlLabel 
                            control={<Switch checked={day.active} />} 
                            label={day.day} 
                            onChange={(value) => onClickActive(day.day, value)}
                        />
                    </FormGroup>
                </Box>
                <Box px={1}>
                    <TextField
                        id="time"
                        label="Open"
                        type="time"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                        size='small'
                        name='open'
                        disabled={!day.active}
                        value={day.open}
                        onChange={(value) => onChangeTime(day.day, value)}
                    />
                </Box>
                <Box px={1}>
                    <TextField
                        id="time"
                        label="Close"
                        type="time"
                        defaultValue="17:00"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                        size='small'
                        name='close'
                        disabled={!day.active}
                        value={day.close}
                        onChange={(value) => onChangeTime(day.day, value)}
                    />
                </Box>
            </Grid> 
            ))}
        </Grid>
    </div>
  )
}

export default StoreSchedule