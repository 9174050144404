import {
    Container,
    Stack,
    Button,
    Box,
    Paper
} from '@mui/material';
import notfound from '../assets/img/icon/no-content.png'

const DataNotFound : React.FC<any> = ({ title }) => {
    return (
        <Paper sx={{p:3, my:2}}>
        <Container component="main" maxWidth="xs">
            <Stack sx={{ 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign:'center'
            }}>
                <Box pt={2}> 
                  <img alt="notfound" src={notfound} width="150px" />
                </Box>
                <Box py={2}>
                    <Box>{title}</Box>
                </Box>
                <div>
                  <Button 
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => window.history.back()}
                  >Go Back</Button>
                </div>
                
            </Stack>
        </Container>
        </Paper>
    )
}

export default DataNotFound;
