import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getConversationRoom } from './chatReducers';
import { errorSweetAlert } from '../../../../utilities/errorhandling';


const initialState: any = {
    isEstablishingConnection: false,
    isConnected: false,
    conversation_lists: [],
    conversation_active : {},
    conversation_room: [],
    new_message : [],
    loading_conversation_room: false,
    open_floating : false
};

export const chatReducers = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    initConvesationLists: ((state, {payload}: PayloadAction<any>) => {
        state.conversation_lists = payload;
    }),
    addConvesationLists: ((state, {payload}: PayloadAction<any>) => {
        state.conversation_lists.push(payload)
    }),
    initNewMessage: ((state, {payload}: PayloadAction<any>) => {
        state.new_message = payload;
    }),
    initConversationActive: ((state, {payload}: PayloadAction<any>) => {
        state.conversation_active = payload;
    }),
    receiveNewMessage: ((state, {payload}:any) => {
        const active_room = JSON.parse(JSON.stringify(state.conversation_active));
        const conversation_lists = JSON.parse(JSON.stringify(state.conversation_lists));
        const isEmpty = (obj : any) => {
          for(var key in obj) {
              if(obj.hasOwnProperty(key))
                  return true;
          }
          return false;
        }

        // replace last message
        const list_chats = conversation_lists.map((item : any) => {
          if(item.company_id === payload.chat.from_company_id || item.company_id === payload.chat.to_company_id) {
            return {
              ...item,
              last_message: payload.chat,
            }
          }
          return item;
        })
        state.conversation_lists = list_chats;

        if(isEmpty(active_room)){
          if(active_room.company_id === payload.chat.from_company_id || active_room.company_id === payload.chat.to_company_id) {
            state.conversation_room.push(payload.chat);
          } else {
            state.new_message.push(payload.chat);
          }
        } else {
          state.new_message.push(payload.chat);
        }
    }),
    updateNewMessage: ((state, { payload }: PayloadAction<any>) => {
        state.new_message = payload;
    }),
    onFloatingChat : ((state, { payload }: PayloadAction<any>) => {
        state.open_floating = payload;
    }),
    clearConversationRoom : ((state) => {
        state.conversation_room = [];
    })
  },
  extraReducers: (builder) => {
    builder
      // get conversation chat room
      .addCase(getConversationRoom.pending, (state) => {
        state.loading_conversation_room = true;
        state.conversation_room = [];
      })
      .addCase(getConversationRoom.fulfilled, (state, action:any) => {
        state.loading_conversation_room = false;
        state.conversation_room = action.payload.data; 
        const new_update = action.payload.new_message.filter((itm : any) => !action.payload.data.find((rm : any) => (itm._id === rm._id) ))
        state.new_message = new_update;
      })
      .addCase(getConversationRoom.rejected, (state, action : any) => {
        state.loading_conversation_room = false;
        errorSweetAlert(action.payload)
      })
  },
});

export const { 
    initConvesationLists, 
    initNewMessage, 
    initConversationActive, 
    receiveNewMessage,
    addConvesationLists,
    onFloatingChat,
    clearConversationRoom,
    updateNewMessage
} = chatReducers.actions;

export default chatReducers.reducer;
