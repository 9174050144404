import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';

export const getConversationRoom = createAsyncThunk(
    'get-chat-room/get', 
    async (payload:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SOCKET_API}/chat/${payload.company_id}`, { 
                headers : {
                    'Authorization' : `Bearer ${userCredentials.id_token}`
                }
            })
            if(response.data) { 
                return { data:  response.data.data, new_message : payload.new_message }
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

