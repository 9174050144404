import { Stack, Box, Button, Grid, Skeleton,  } from '@mui/material'
import {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import Select from 'react-select' 
import LoaderBackdrop from '../../../../components/LoaderBackdrop';
import { updateSettingInvoice } from '../reducers/profilesReducers';
import SnackBarAlert from '../../../../components/SnackBarAlert';
import { updatePlatformSnackBar } from '../profileSlice';

const SettingInvoice : React.FC<any> = ({profile, refetch, setRefetch}) => {

    const dispatch = useDispatch()
    const { loading_invoice_setting, invoice_setting , loading_setting_platform } = useSelector((state : RootState) => state.profile)

    const optionsTrigger  = [
        { value : "Manual", label : "Manual"},
        { value : "Auto", label : "Auto"},
    ]

    const optionsPeriod  = [
        { value : "EOW", label : "End of Week"},
        { value : "EOM", label : "End of Month"},
    ]

    const [selectedTrigger, setSelectedTrigger] = useState<any>([]);
    const [selectedPeriod, setSelectedPeriod] = useState<any>([]);
    const [errorTrigger, setErrorTrigger] = useState(false);
    const [errorPeriod, setErrorPeriod] = useState(false);

    const handleChangeTrigger = (value: any) : void => {
        setErrorTrigger(false)
        setSelectedTrigger(value)
    }

    const handleChangePeriod = (value: any) : void => {
        setErrorPeriod(false)
        setSelectedPeriod(value)
    }


    const onClickSave = () => {
        if(selectedTrigger.length === 0) {
            setErrorTrigger(true)
        } else if (selectedPeriod.length === 0) {
            setErrorPeriod(true)
        } else {
            const params = {
                body : {
                    options  : selectedTrigger.value,
                    config : {
                        period : selectedPeriod.value
                    }
                }, 
                company : profile._id
            }
            dispatch(updateSettingInvoice(params))
        }
        // dispatch(updateSettingPlatform(body))
    }

    useEffect(() => {
        if(profile.setting.length) {
            if(profile.setting[0].invoice && profile.setting[0].invoice) {
                setSelectedPeriod({value: profile.setting[0].invoice.config.period, label: profile.setting[0].invoice.config.period === "EOM" ? 'End of Month' : 'End of Week'})
                setSelectedTrigger({value: profile.setting[0].invoice.options, label: profile.setting[0].invoice.options})
            } else {
                setSelectedPeriod({value: "EOM", label: 'End of Month'})
                setSelectedTrigger({value: "Manual", label: 'Manual'})
            }
        }
        // eslint-disable-next-line
    }, [profile]);

    useEffect(() => {
        if(invoice_setting) {
            setRefetch(!refetch)
            setTimeout(() => {
                dispatch(updatePlatformSnackBar())
            }, 1500);
        }
        // eslint-disable-next-line
    }, [invoice_setting]);

    return (
        <Stack sx={{ width: '100%' }}>
            <Grid container spacing={2}>
               <LoaderBackdrop open={loading_invoice_setting} />
                <Grid item xl={4} lg={4} md={6} xs={12}>
                    <Box ><h4>Invoice Mode</h4></Box>
                    <Box pt={1}>
                        { loading_setting_platform ? 
                        <Box mt={0} pt={0}><Skeleton height={60} /></Box>
                        :
                        <Select
                            placeholder="Select Invoice Mode"
                            value={selectedTrigger}
                            isSearchable={true}
                            options={optionsTrigger}
                            onChange={handleChangeTrigger}
                            id="select-style-cat"
                        /> }
                    { errorTrigger ? <div className="error-p"><p>Invoice Mode is required</p></div> : null }
                    </Box>
                </Grid>
                <Grid item xl={4} lg={4} md={6} xs={12}>
                    <Box ><h4>Period</h4></Box>
                    <Box pt={1}>
                        { loading_setting_platform ? 
                        <Box mt={0} pt={0}><Skeleton height={60} /></Box>
                        :
                        <Select
                            placeholder="Select Period"
                            value={selectedPeriod}
                            isSearchable={true}
                            options={optionsPeriod}
                            onChange={handleChangePeriod}
                            isDisabled={selectedTrigger.value === "Manual" ? true : false}
                            id="select-style-cat"
                        /> }
                    { errorPeriod ? <div className="error-p"><p>Period is required</p></div> : null }
                    </Box>
                </Grid>
               
            </Grid>

            <Stack 
                sx={{my:3}} 
                flexDirection="row" 
                justifyContent="flex-end"
            >
                <Button 
                    onClick={onClickSave} 
                    disabled={loading_setting_platform} 
                    variant='contained'
                >{loading_setting_platform ? "Loading" : "Save"}
                </Button>
            </Stack> 

            <SnackBarAlert message={`Success update Setting Invoice Generation.`} initialState={invoice_setting} />

        </Stack>
    )
}

export default SettingInvoice


