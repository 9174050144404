import { Stack } from '@mui/material'
import { useLocation } from 'react-router-dom';
import HomeDashboard from './HomeDashboard';
import NotificationPage from './notification/NotificationPage';
import ErrorRouting from '../../error404/ErrorRouting';


function MainHomePage() {

    const location : any = useLocation()
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    
    let query = useQuery();
    let active_page = query.get('page')

    const switchPage = () => {
        if (active_page === 'notification') {
            return <NotificationPage/>
        } else if (active_page === 'error') {
            return <ErrorRouting/>
        } else {
            return <HomeDashboard/>
        }
    }
 
    return (
        <Stack>
            {switchPage()}
        </Stack>
    )
}

export default MainHomePage
