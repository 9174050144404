import { Stack, Box, Grid } from '@mui/material'
import Select from 'react-select' 


const AutoGenerationSetting : React.FC<any> = ({ 
    setSelectedTrigger, 
    setSelectedPeriod,
    selectedTrigger,
    selectedPeriod,
    errorTrigger,
    errorPeriod,
    setErrorPeriod,
    setErrorTrigger,
 }) => {

    const optionsTrigger  = [
        { value : "Manual", label : "Manual"},
        { value : "Auto", label : "Auto"},
    ]

    const optionsPeriod  = [
        { value : "EOW", label : "End of Week"},
        { value : "EOM", label : "End of Month"},
    ]

    const handleChangeTrigger = (value: any) : void => {
        setErrorTrigger(false)
        setSelectedTrigger(value)
    }

    const handleChangePeriod = (value: any) : void => {
        setErrorPeriod(false)
        setSelectedPeriod(value)
    }



    return (
        <Stack mt={1} sx={{ width: '100%' }}>
            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} xs={12}>
                    <Box >Invoice Mode</Box>
                    <Box pt={1}>
                        <Select
                            placeholder="Select Invoice Mode"
                            value={selectedTrigger}
                            isSearchable={true}
                            options={optionsTrigger}
                            onChange={handleChangeTrigger}
                            id="select-style-cat"
                        /> 
                    { errorTrigger ? <div className="error-p"><p>Invoice Mode is required</p></div> : null }
                    </Box>
                </Grid>
                <Grid item xl={6} lg={6} md={6} xs={12}>
                    <Box >Period</Box>
                    <Box pt={1}>
                        <Select
                            placeholder="Select Period"
                            value={selectedPeriod}
                            isSearchable={true}
                            options={optionsPeriod}
                            onChange={handleChangePeriod}
                            isDisabled={selectedTrigger.value === "Manual" ? true : false}
                            id="select-style-cat"
                        /> 
                    { errorPeriod ? <div className="error-p"><p>Period is required</p></div> : null }
                    </Box>
                </Grid>
            </Grid>
        </Stack>
    )
}

export default AutoGenerationSetting
