import { Box, Grid, Skeleton } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import calender from '../../../../assets/img/icon/calendar.png'
import down from '../../../../assets/img/icon/down.png'
import up from '../../../../assets/img/icon/up.png'
import store from '../../../../assets/img/icon/store.png'
import { RootState } from '../../../../app/store'
import ItemCard from './ItemCard'
import { getItemFulfillment, printDownloadPDF, submitPickFulfillment } from '../redux/fulfillmentReducers'
import { changeToDefaultItems } from '../redux/fulfillmentsSlice'
import { userCredentials } from '../../../../utilities/config'
import DialogPayment from './DialogPayment'
import DialogShipping from './DialogShipping'
import { createCalculateInvoice, getInvoiceNumber } from '../../finance/invoices/redux/invoicesReducers'
import { useHistory } from 'react-router-dom'
import { getAddressCompany } from '../../masters/mastersReducers'
import swal from 'sweetalert'
import { closeOpenInvoice } from '../../finance/invoices/redux/invoicesSlice'
import LoaderBackdrop from '../../../../components/LoaderBackdrop'
import ChatButton from '../../../../components/ChatButton'
import DownloadPdf from '../../../../components/DownloadPdf'
import more from '../../../../assets/img/icon/more.png'
import HistoryStatus from './HistoryStatus'
import ViewNote from './ViewNote'
import PreferredButton from '../../../../components/PreferredButton'
import { checkValidation } from '../../../../utilities/userFeaturesValidation'

const OrderCard : React.FC<any> = ({ item, viewItems, setViewItems }) =>  {
    const dispatch = useDispatch() 
    const history = useHistory()

    const { 
        items, default_items, loading_items, approve_payment, update_status 
    } = useSelector((state : RootState) => state.fulfillment)
    const { 
        open_invoice, loading_calculate_inv
    } = useSelector((state : RootState) => state.invoice)


    const [referenceDocument, setReferenceDocument] = useState<any>([]);
    const [loadingCreate, setLoadingCreate] = useState(false);

    const [update, setUpdate] = useState(false);
    const [dialogPayment, seDialogPayment] = useState(false);
    const [fileUrl, setFileUrl] = React.useState({
        name : '',
        url : ''
    });
    const [openStatus, setOpenStatus] = useState({
        open: false,
        data : []
    });
    const [openNote, setOpenNote] = useState({
        open : false, 
        note : ""
    });

    const [dialogShipping, setDialogShipping] = useState(false);

    function inboundItems(itm:any, default_items:any) {
        let inbound : any = []
        let run = false
        for(let i = 0; i < itm.length; i++) {
            for(let j = 0; j < default_items.length; j++) {
                if(itm[i]._id === default_items[j]._id && (itm[i].quantity !== default_items[j].quantity || itm[i].retail_price !== default_items[j].retail_price || itm[i].discount !== default_items[j].discount )) {
                    run = true
                    break
                }
            }
            if(run) {
                inbound.push({
                    _id : itm[i]._id,
                    inbound : {
                        retail_price:  itm[i].retail_price,
                        quantity:  itm[i].quantity,
                        discount:  itm[i].discount,
                        extraDiscount:  itm[i].extraDiscount,
                        discount_type:  itm[i].discount_type,
                        note_inbound :  itm[i].note_inbound || "N/A"
                    }
                })
                run = false
            }
        }
        return inbound
    }

    const onClickSubmitPick = (id:string, name:string) => {
        const params ={
            id : id,
            body : {
                "user": userCredentials.fullname,
                "note": "Submit Pick",
                "lastStatus": name === "Pick" ? "New" : "Pick",
                "items": inboundItems(items, default_items)
            }
        }
        dispatch(submitPickFulfillment(params))
    }


    const onClickPrintPDF = (item:any) => {
        const params = {
            type : item.lastStatus === "Delivered" ? "goods-receipt" : item.lastStatus === "Shipped" ? "delivery-note" : "package-order",
            body : {
                "data": {
                    "id": item.lastStatus === "Delivered" ? item.receive_note && item.receive_note._id  : item.lastStatus === "Shipped" ? item.delivery_note && item.delivery_note._id  : item._id ,
                    "company": "null"
                }   
            }
        }
        dispatch(printDownloadPDF(params))
    }

    const onClickPrintPDFPickList = (itms:any) => {
        const params = {
            type : "pick-list",
            body : {
                "data": {
                    "ids": [item._id],
                    "company": "null"
                }  
            }
        }
        dispatch(printDownloadPDF(params))
    }


    const getItemsFulfillment =  async(id:string) => {
        const item : any = await dispatch(getItemFulfillment(id))
        if(item.meta.requestStatus === 'fulfilled'){
            return item.payload
        }
    }

    const getVendorDetail =  async(id:string) => {
        let params = {
            id : id, 
            type : 'vendor'
        }
        const vendor : any = await dispatch(getAddressCompany(params))
        if(vendor.meta.requestStatus === 'fulfilled'){
            return {
                address : vendor.payload.data[0],
                logo : vendor.payload.data[0].company.logo,
                legalName : vendor.payload.data[0].company.legalName,
                _id : vendor.payload.data[0].company._id,
                code : vendor.payload.data[0].company.code,
            }
        }
    }

    const getBuyerDetail =  async(id:string) => {
        let params = {
            id : id, 
            type : 'buyer'
        }
        const vendor : any = await dispatch(getAddressCompany(params))
        if(vendor.meta.requestStatus === 'fulfilled'){
            return {
                address : vendor.payload.data[0],
                logo : vendor.payload.data[0].company.logo,
                legalName : vendor.payload.data[0].company.legalName,
                _id : vendor.payload.data[0].company._id,
                code : vendor.payload.data[0].company.code,
                name : vendor.payload.data[0].company.name
            }
        }
    }

    const sumTotalItem = async (data : any) => {
        let items = await getItemsFulfillment(data._id)
        return await items.reduce((total : any, data : any) => {
            return total + (data.total - data.total_tax)
        }, 0)
    } 

    const onClickSelected = async (data : any) => {
        setLoadingCreate(true)
        let temp = [...referenceDocument];
        if(temp.length === 0) {
            let value = {
                buyer : await getBuyerDetail(data.buyer._id),
                vendor : await getVendorDetail(userCredentials.company_id),
                packageId : data._id,
                packageCode : data.code,
                date_order : data.date,
                payment_term : data.payment_term,
                shipping_cost : data.shipping_cost,
                sub_total : await sumTotalItem(data),
                sub_tax : data.sub_tax,
                items : await getItemsFulfillment(data._id),
                down_payment : data.down_payment
            }
            temp.push(value);
            setReferenceDocument(temp)
        } else {
            if (temp.find((item : any) => item.payment_term === data.payment_term && item.buyer._id === data.buyer._id)) {
                if (temp.find((item : any) => item.packageId === data._id)) {
                    let filter = temp.filter((item : any) => item.packageId !== data._id)
                    setReferenceDocument(filter)
                } else {
                    let value = {
                        buyer : await getBuyerDetail(data.buyer._id),
                        vendor : await getVendorDetail(userCredentials.company_id),
                        packageId : data._id,
                        packageCode : data.code,
                        date_order : data.date,
                        payment_term : data.payment_term,
                        items : await getItemsFulfillment(data._id),
                        shipping_cost : data.shipping_cost,
                        sub_total : await sumTotalItem(data),
                        sub_tax : data.sub_tax,
                        down_payment : data.down_payment
                    }
                    temp.push(value);
                    setReferenceDocument(temp)
                    setLoadingCreate(true)
                }
            } else {
                swal('Error', 'You can only merge same buyer and same payment term!', 'error')
            }
        }
    }


    function getDueDate(params: any ) {
        let result = new Date();
        result.setDate(result.getDate() + parseInt(params.payment_term));
        return result;
    }

    function getOnlyReference(arr: any) {
        const newData = arr.map((obj : any) => {
            return {
                packageId : obj.packageId,
                packageCode : obj.code,
                date_order : obj.date_order,
                payment_term : parseInt(obj.payment_term),
            }
        });
        return newData;
    }

    function getArrayItems(arr:any) {
        let result : any = [];
        arr.forEach((item : any) => {
            result.push(...item.items)
        })
        return result;
    }


    const onClickCreateInvoice = async () => {
        let buyer_name = referenceDocument[0].buyer.legalName
        const inv_number : any = await dispatch(getInvoiceNumber(buyer_name))
        if(inv_number.meta.requestStatus === 'fulfilled') {
            const data = {
                invoiceNumber: await inv_number.payload,
                dueDate:  moment(getDueDate(referenceDocument[0])).format(),
                buyer: referenceDocument[0].buyer,
                vendor: referenceDocument[0].vendor,
                reference: getOnlyReference(referenceDocument),
                shippingCosts: 0,
                downPayment: 0,
                items: getArrayItems(referenceDocument),
                subTotal: 0,
                grandTotal: 0,
                note: "",
                creator: userCredentials.fullname,
                createdAt : moment().format(),
                type : "Final Payment"
            }
            dispatch(createCalculateInvoice(data))
        }
    }

    useEffect(() => {
        if(referenceDocument.length > 0) {
            setTimeout(() => {
                setLoadingCreate(false)
                onClickCreateInvoice()
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [referenceDocument]);

    useEffect(() => {
        if(open_invoice) {
            history.push({
                pathname: "/dashboard/finance/invoice",
                search: `?page=create`, 
            })
            dispatch(closeOpenInvoice())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open_invoice]);

    useEffect(() => {
        if(approve_payment) {
            seDialogPayment(false)
            setFileUrl({url:"", name:""})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approve_payment]);

    useEffect(() => {
        if(update_status) {
            setDialogShipping(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update_status]);
    

    function checkInboud(items:any) {
        let result = false;
        items.forEach((item : any) => {
            if(item.inbound && item.inbound.quantity ) {
                result = true;
            }
        })
        return result 
    }
    
    return (
    <div className={`container-item-purchase ${item.orderStatus === "Waiting Down Payment" ? 'disable-item' : ''}`}>
        <LoaderBackdrop loading={loadingCreate || loading_calculate_inv} />
        <Box className="top-div">
            <Box className='left'>
                <h6>{item.code}</h6>
                <Box className='statuses'>
                    {item.orderStatus === "Waiting Down Payment" ? 
                    <h5 className='color-yellow'>
                        { item.proof_of_advance_payment && item.proof_of_advance_payment.file ? "Waiting Payment Confirmation" : " Waiting Down Payment" }
                    </h5> :
                    <h5>{item.lastStatus}</h5> }
                </Box>
            </Box>
            <Box className='right'>
                <img src={calender} alt='calender' />
                <p>{moment(item.date).format('lll')}</p>
                <Box className='history' onClick={() => setOpenStatus({open:true, data: item.statuses})}>
                    <img src={more} alt='more' />
                </Box>
            </Box>
        </Box>
        <Box className="center-div">
            <Grid container spacing={1}>
                <Grid item xs={12} md={8} sm={12} lg={8} xl={8}>
                    <Box className='left-box'>
                        <Box className='store'>
                            <img src={item.buyer.logo || store} alt="store" />
                            <Box className='name'>
                                <h2>{item.buyer.legalName}</h2>
                                <Box className='chat'>
                                    <ChatButton company={item.buyer} />
                                    <PreferredButton company={item.buyer} />
                                </Box>
                            </Box>
                        </Box>
                        { item.address.label !== undefined && item.address.label !== null ?
                        <Box className='address'>
                            <h2>{item.address.label}</h2> 
                            <p>{item.address.street}, {item.address.city.toLowerCase() || "N/A"}</p>
                            <p>{item.address.province}, {item.address.country}, {item.address.postcode}</p>
                        </Box> :
                        <Box className='address'>
                            <h2>N/A</h2> 
                        </Box> }
                        
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} sm={12} lg={4} xl={4}>
                    <Box className='content-right'>
                        <Box className='content'>
                            <p>Down Payment</p>
                            <h3>-Rp.{(Math.floor(item.down_payment)).toLocaleString()}</h3>
                        </Box>
                        <Box className='content'>
                            <p>Shipping Cost</p>
                            <h3>+Rp.{(Math.floor(item.shipping_cost)).toLocaleString()}</h3>
                        </Box>
                        <Box className='content'>
                            <p>Total Tax</p>
                            { item.lastStatus === "Delivered" || item.lastStatus === "Completed" ? 
                            <h3>+Rp.{(Math.floor(item.sub_tax_receive)).toLocaleString()}</h3> :
                            <h3>+Rp.{(Math.floor(item.sub_tax)).toLocaleString()}</h3> }
                        </Box>
                        <Box className='linedivder' />
                        <Box className='content'>
                            <p>Total Purchase</p>
                            { item.lastStatus === "Delivered" || item.lastStatus === "Completed" ? 
                            <h2>Rp.{(Math.floor(item.sub_total_receive)).toLocaleString()}</h2>  :
                            <h2>Rp.{(Math.floor(item.sub_total)).toLocaleString()}</h2> }
                        </Box>
                        <Box className='content'>
                            <p>Department</p>
                            <h5>{item.departement || "Not defined"}</h5> 
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        <Box className='bottom-div'>
            <Box className='left-divv'>
                <p>Payment Terms {item.payment_term} Days</p> 
                <Box 
                    className='view-note' 
                    onClick={() => setOpenNote({open:true, note: item.notes || "N/A"})}
                >
                    <h6>View Note</h6>
                </Box>
            </Box>
            <Box className='right-div'>
                { item.orderStatus === "Waiting Down Payment" ?
                <Box className='btn-inv-submit'>
                    <button 
                        onClick={() => {
                            history.push({
                                pathname: '/dashboard/finance/invoice',
                                search: `?page=detail`, 
                                state : {
                                    id : item.idPo,
                                    type : "by-order"
                                }
                            })
                        }}
                    >
                        <p>View Invoice</p>
                    </button> 
                </Box> : null }
                { item.lastStatus === "Delivered" &&
                <Box>
                    { item.receive_note && item.receive_note.useInvoice ? 
                    <Box className='btn-inv-submit'>
                        <button 
                            onClick={() => {
                                history.push({
                                    pathname: '/dashboard/finance/invoice',
                                    search: `?page=detail`, 
                                    state : {
                                        id : item._id,
                                        type : "by-package"
                                    }
                                })
                            }}
                        >
                            <p>View Invoice</p>
                        </button> 
                    </Box>:
                    <Box className='btn-pr-submit'>
                        { checkValidation('CREINV438542') &&
                        <button onClick={() => onClickSelected(item)}>
                            <p>Create Invoice</p>
                        </button>  }
                    </Box> }
                </Box> }
                { item.orderStatus === "Waiting Down Payment" ? null :
                <>
                {checkValidation('DOWNPDFOR5341234') &&
                <DownloadPdf 
                    name="Download PDF"
                    onClick={() => onClickPrintPDF(item)}
                /> }
                </> }
                <Box className='line-center' />
                { viewItems.open && viewItems.id === item._id ?
                <Box 
                    className='view-btn' 
                    onClick={() => {
                        setViewItems({  
                            open : false,
                            id : item._id 
                        })
                    }}
                >
                    <p>Hide Purchase Items</p>
                    <img src={up} alt="down" />
                </Box>
                :
                <Box 
                    className='view-btn' 
                    onClick={() => {
                        setViewItems({
                            open : true,
                            id : item._id 
                        })
                    }}
                >
                    <p>View Purchase Items</p>
                    <img src={down} alt="down" />
                </Box>
                }
            </Box> 
        </Box>
        { viewItems.open && viewItems.id === item._id ?
        <Box className='card-product-items'>
            { loading_items ? 
            <LoadingItems />
            : 
            <Box>
                { item.orderStatus === "Waiting Down Payment" ? null :
                <Box className='container-btn-item'>
                    { item.lastStatus === "New" ? 
                    <>
                    { !update ? 
                    <>
                    <Box className="btn-pr-submit">
                        {checkValidation('SUBPILIOR5423452') &&
                        <button onClick={()=> setUpdate(true)}>
                            <p>Update Pick List</p>
                        </button> }
                    </Box> 
                    <Box className="btn-item-submit">
                        {checkValidation('SUBPILIOR5423452') &&
                        <button onClick={() => onClickSubmitPick(item._id, "Pick")}>
                            <p>Submit Pick List</p>
                        </button> }
                    </Box>
                    </>:
                    <Box className="btn-pr-reject">
                        <button onClick={()=> {
                            setUpdate(false)
                            dispatch(changeToDefaultItems(default_items))
                        }}>
                            <p>Cancel</p>
                        </button>
                    </Box> }
                    </>
                    : item.lastStatus === "Pick" ?
                    <Box className="btn-item-submit">
                        {checkValidation('SUBDEVNOOR123241') &&
                        <button 
                            disabled={checkInboud(items)}
                            onClick={() => setDialogShipping(true)}
                        >
                            <p>Submit Delivery Note</p>
                        </button> }
                    </Box>
                    : null
                    }

                    { item.lastStatus === "Pick" &&
                    <DownloadPdf 
                        name="Download Pick List"
                        onClick={() => onClickPrintPDFPickList(items)}
                    /> }
                </Box> }

                <Box className='wrapper-item'>
                    {items.length && items.map((value : any, index : number) => (
                        <Box key={index}>
                            <ItemCard ele={value} index={index} update={update} lastStatus={item.lastStatus}  />
                        </Box>
                    ))}
                </Box>

                { update &&
                <Box className='container-btn-submit'>
                    <Box className="btn-item-submit">
                        <button onClick={() => onClickSubmitPick(item._id, "Pick")}>
                            <p>Submit Change</p>
                        </button>
                    </Box>
                </Box> }

                {items.length && items.length === 0 && <p className='no-data'>No Data</p>}
            </Box>
            }
        </Box> : null }
        <DialogPayment open={dialogPayment} setOpen={seDialogPayment} data={item} setFileUrl={setFileUrl} fileUrl={fileUrl} />
        <DialogShipping open={dialogShipping} setOpen={setDialogShipping} data={item}  />
        <HistoryStatus open={openStatus} setOpen={setOpenStatus} />
        <ViewNote open={openNote} setOpen={setOpenNote} />
    </div>
  )
}


const LoadingItems = () => {
    return (
        <Box>
           <Box m={0} p={0}> <Skeleton height={60} /></Box>
           <Box m={0} p={0}> <Skeleton height={60} /></Box>
           <Box m={0} p={0}> <Skeleton height={60} /></Box>
        </Box>
    )
}

export default OrderCard