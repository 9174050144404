import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Skeleton, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { getDetailCustomRoles } from '../redux/rolesReducers';
import ModuleDetail from './ModuleDetail';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};


const DialogViewRoles : React.FC<any> = ({ 
  open, setOpen, IdRole
 }) => {

    const dispatch = useDispatch()
    const { detail, loading_detail } = useSelector((state : RootState) => state.roles)

    function handleClose () {
        setOpen(false);
    };

    useEffect(() => {
      if(open && IdRole !== "") {
        dispatch(getDetailCustomRoles(IdRole))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, IdRole]);

    // function is not empty
    function isNotEmpty(obj:any) {
      for(var key in obj) {
        if(obj.hasOwnProperty(key))
          return true;
      }
      return false;
    }

    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">Detail Permission Role</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
          { loading_detail ?
          <LoaderView />
          :
          <Box className="create-roles" mb={2}>
            { isNotEmpty(detail) &&
              <>
              <Box pl={1} py={2}>
                <h4>Role Name = {detail.name}</h4>
              </Box>
              <ModuleDetail 
                detail={detail.hasNavigation}
              /> 
              </>
            }
          </Box> 
          }
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}


const LoaderView = () => {
  return (
    <div>
      <Skeleton variant='text' height={60} />
      <Skeleton variant='text' height={40} />
      <Skeleton variant='text' height={40} />
      <Skeleton variant='text' height={40} />
      <Skeleton variant='text' height={40} />
      <Skeleton variant='text' height={40} />
      <Skeleton variant='text' height={40} />
      <Skeleton variant='text' height={40} />
      <Skeleton variant='text' height={40} />
      <Skeleton variant='text' height={40} />
    </div>
  )
}

export default DialogViewRoles;