/* istanbul ignore file */
import crypto from 'crypto-js'
import moment from 'moment'

const checkDataFromLocalStorage = localStorage.getItem('secret_credentials') ? localStorage.getItem('secret_credentials') : null
const decriptValue  = checkDataFromLocalStorage !== null ? crypto.AES.decrypt(checkDataFromLocalStorage, `${process.env.REACT_APP_CRYPTO_SECRET}`) : null

export const userCredentials = decriptValue !== null ? JSON.parse(decriptValue.toString(crypto.enc.Utf8)) : null


export const checkLoginSessionTime = () => {
    return !moment(userCredentials.login_start_date).isAfter(moment().subtract(8, 'hours'));
}

