import { useEffect, useState } from 'react';
import { 
    Grid, 
    Stack,
 } from '@mui/material';
import Popover from '@mui/material/Popover';
import { Box } from '@mui/system'
import { TableColumn } from 'react-data-table-component';
import BreadCrumbs from '../../../components/BreadCrumbs'
import DataTableBase from '../../../components/TableData'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { useHistory } from 'react-router-dom';
import { 
    downloadExcelTemplate,
    getAllProductByWarehouses,
    removeProduct, 
} from './reducers/productsReducers';
import Select from 'react-select'
import { getMasterWarehouse } from '../masters/mastersReducers';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import { removeUrlDownloadTemplate } from './productsSlice';
import BulkUpload from './components/BulkUpload';
import ButtonAction from '../../../components/ButtonAction';
import RemoveConfirmation from '../../../components/RemoveConfirmation';
import HeadlineTitle from '../../../components/HeadlineTitle';
import { checkValidation } from '../../../utilities/userFeaturesValidation';
import SearchComponent from '../../../components/SearchComponent';
import ButtonCreate from '../../../components/ButtonCreate';

  
const ProductLists = () => {
 
    const history = useHistory()
    const dispatch = useDispatch()
    const { 
        data, total_data, loading, loading_download_template, download_template,
        loading_remove, success_remove 
    } = useSelector((state : RootState) => state.products)
    const { warehouse, loading_warehouse } = useSelector((state : RootState) => state.masters)

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);

    const [valueSearch, setValueSearch] = useState("");
    const [selectedWarehouse, setSelectedWarehouse] = useState<any>([]);
    const [openRemove, setOpenRemove] = useState({
        open : false,
        name : "",
        id : ""
    });

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [typeDownload, setTypeDownload] = useState("");

    const handleChangeWarehouse = (value: any) : void => {
        setSelectedWarehouse(value)
        setValueSearch("")
    }
    const [openUpload, setOpenUpload] = useState(false);
    
    const onChangePerPage = (page:any) => {
        setPage(page-1)
    }

    const onClickUpdate = (data: any) => {
        history.push({
            pathname : `/dashboard/products`,    
            search: `?page=edit`, 
            state : {
                slug : `${data.slug}`,
            }
        }) 
    }

    const onClickDetail = (data: any) => {
        history.push({
            pathname : `/dashboard/products`,
            search: `?page=detail`, 
            state : {
                slug : `${data.slug}`,
            }
        })
    }


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const downloadBulkTemplate = async (type : string) => {
        setTypeDownload(type)
        let body = {
            template : type,
            warehouse : selectedWarehouse.value
        }
        dispatch(downloadExcelTemplate(body))
    }

    useEffect(() => {
        if(download_template !== "") {
            window.location.assign(download_template)
            dispatch(removeUrlDownloadTemplate())
        }
        // eslint-disable-next-line
    }, [download_template]);

    useEffect(() => {
        if(selectedWarehouse.length !== 0 || success_remove) {
            let param = {
                warehouse_id : selectedWarehouse.value,
                limit : limit, 
                page : page,
                search : valueSearch
            }
            dispatch(getAllProductByWarehouses(param))
        }
        // eslint-disable-next-line
    }, [selectedWarehouse, limit, page, success_remove, valueSearch]);

    useEffect(() => {
        dispatch(getMasterWarehouse())
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if(warehouse.length !== 0) {
            setSelectedWarehouse(warehouse[0])
        }        
        // eslint-disable-next-line
    }, [warehouse]);

    function getStock (warehouses:any) {
        if(data.length !== 0 && selectedWarehouse.length !== 0) {
            let stock = warehouses.filter((item:any) => item.warehouse._id === selectedWarehouse.value)
            return stock[0].stock.toLocaleString()
        }
        return 0
    }

    function getPrice (warehouses:any) {
        if(data.length !== 0 && selectedWarehouse.length !== 0) {
            let stock = warehouses.filter((item:any) => item.warehouse._id === selectedWarehouse.value)
            return stock[0].retail_price.toLocaleString()
        }
        return 0
    }

    const columns: TableColumn<any>[] = [
        {
            name: 'No',
            width: '65px',
            cell: (row, index) => (
                <p>
                { page > 0 ? 
                    <>
                    {index + 1 + (limit * (page))}.
                    </>
                    :
                    <> 
                    {index + 1}.
                    </>
                }
                </p>
            )
        },
        {
            name: 'SKU',
            width: '100px',
            selector: row => row.sku,
        },
        {
            name: 'Name',
            cell: (row) => (
                <Box 
                    sx={{cursor:'pointer', color: '#0091d6'}}
                    onClick={() => onClickDetail(row)} 
                >
                    {row.name}
                </Box>
            )
        },
        {
            name: 'Brand',
            width: '100px',
            selector: row => row.brand,
        },
        {
            name: 'Price',
            width: '150px',
            cell: (row) => (
                <p>Rp. { getPrice(row.warehouses)} </p>
            )
        },
        {
            name: 'Stock',
            width: '100px',
            cell: (row) => (
                <p>{ getStock(row.warehouses)} </p>
            )
        },
        {
            name: 'Tax',
            width: '90px',
            selector: row => row.tax.label,
        },
        {
            name: 'PayTerm',
            width: '100px',
            cell: (row) => (
                <p>{ row.payment_term} days</p>
            )
        },
        {
            name: 'Action',
            width: '200px',
            cell: (row) => (
                <Stack direction="row" spacing={2}>
                    <ButtonAction 
                        type="detail" 
                        onClick={() => onClickDetail(row)}
                    />
                    { checkValidation('UPDPRO513959') && 
                    <ButtonAction 
                        type="edit" 
                        onClick={() => onClickUpdate(row)}
                    /> }
                    { checkValidation('DELPRO843564') && 
                    <ButtonAction 
                        type="remove" 
                        onClick={() => setOpenRemove({...openRemove, open : true, name : row.name, id : row._id})}
                    /> }
                </Stack>
            ),
        },
    ];


    return (
        <Box m={2}>
            <LoaderBackdrop loading={loading_warehouse} />
            <BreadCrumbs
                isPage={false}
                current="Product Page"
            />
            <Stack direction="row" justifyContent="space-between" alignItems="center" py={1} flexWrap="wrap">
                <Box>
                   <HeadlineTitle title='Product Lists' variant="h6" bold='bold' />
                </Box>

                <Stack flexDirection="row" alignItems="center" flexWrap='wrap' rowGap={1}>
                    {checkValidation('DOWNBLTEM214234') &&
                    <ButtonCreate 
                        outlined={true}
                        onClick={handleClick}
                        name="Download Template"
                    /> }
                    <>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            sx={{ mt: 2 }}
                            className="menu-pop-over"
                        >
                            <div className="box-menu">
                                <Box pt={1} pb={1}>
                                    { arrayTemplate.map((item, idx) => (
                                        <Box key={idx}>
                                            <Box pb={1}>
                                                <ButtonCreate
                                                    outlined={true}
                                                    onClick={() => downloadBulkTemplate(item.value)}
                                                    name={loading_download_template && typeDownload === item.value ? "Loading.." : item.label}
                                                    disabled={loading_download_template && typeDownload === item.value ? true : false}
                                                /> 
                                            </Box>
                                        </Box>
                                    )) }
                                </Box>
                            </div>
                        </Popover>
                    </>
                    <Box mr={1} />
                    {checkValidation('BULPLPRO235145') &&
                    <ButtonCreate 
                        onClick={() => setOpenUpload(true)}
                        name="Bulk Upload"
                    />}
                    <Box mr={1} />
                    {checkValidation('CREPRO251310') &&
                    <ButtonCreate 
                        name="Add Single Product"
                        onClick={() => {
                            history.push({
                                pathname: "/dashboard/products",
                                search: `?page=create`, 
                            })
                        }}
                    /> }
                </Stack>
            </Stack>

            <Box>
                <Grid container justifyContent="center" spacing={1}>
                    <Grid item xs={12} md={6} lg={3} sm={6}>
                        <Box className="select-warehouse-product">
                            { loading_warehouse ?
                            <Select
                                placeholder="Loading..."
                            /> :
                            <Select
                                placeholder="Select Warehouse"
                                options={warehouse && warehouse}
                                isSearchable={true} 
                                value={selectedWarehouse}
                                onChange={handleChangeWarehouse}
                                id={`select-style-searchby`}
                            /> }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={9} sm={6}>
                        <SearchComponent 
                            valueSearch={valueSearch}
                            setValueSearch={setValueSearch}
                            placeholder="Search Product"
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{pt:2}}>
                <DataTableBase 
                    columns={columns}
                    data={data}
                    progressPending={loading} 
                    pagination
                    paginationServer
                    onChangePage={(value) => onChangePerPage(value)}
                    onChangeRowsPerPage={(value) => setLimit(value)}
                    paginationPerPage={limit}
                    noDataComponent="You dont have any product yet."
                    paginationTotalRows={total_data}
                    selectableRows
                    persistTableHead
                /> 
            </Box>

            <BulkUpload 
                openUpload={openUpload}
                setOpenUpload={setOpenUpload}
                warehouse={warehouse}
            />

            <RemoveConfirmation 
                openRemove={openRemove}
                setOpenRemove={setOpenRemove}
                label="Product"
                loading={loading_remove}
                success={success_remove}
                message="Success Remove Product!"
                onClick={() => dispatch(removeProduct(openRemove.id))}
            />
 
        </Box>
    )
}

const arrayTemplate = [
    { value : "CREATE", label: "Creation" },
    { value : "STOCK", label: "Update Stock" },
    { value : "PRICE", label: "Update Price" },
    { value : "FULL UPDATE", label: "Update All" },
]

export default ProductLists
