import {  Box } from '@mui/material'
import empty from '../assets/img/icon/empty.png'

const EmptyData : React.FC<any> = ({message, caption}) => {

    return (
        <Box className='not-found-data' >
            <img src={empty} alt="empty member"/>
            <h1>{message}</h1>
            <p>{caption}</p>
        </Box>
    )
}

export default EmptyData