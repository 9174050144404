import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../../utilities/config';

const headers : any = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }


export const getInvoiceNumber = createAsyncThunk(
    'invoice-number/get',
    async (name:string, { rejectWithValue }) => {
        try {
            let body = {
                buyer : name
            }
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/invoice/number`, body, {
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getFulfillmentInvoice = createAsyncThunk(
    'fulfillment-readyToInV/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/fulfillment/readyToInvoice`, {
                params,
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getFulfillmentInvoiceLoadMore = createAsyncThunk(
    'fulfillment-readyToInV-laod-more/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/fulfillment/readyToInvoice`, {
                params,
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getInvoicesData = createAsyncThunk(
    'invoices-vendor/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/vendor`, {
                params,
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getInvoicesDataLoadMore = createAsyncThunk(
    'invoices-vendor-load-more/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/vendor`, {
                params,
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getInvoiceDetail = createAsyncThunk(
    'invoices-detail/get',
    async (id:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/${id}/details`, {
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getInvoiceDetailByPackage = createAsyncThunk(
    'invoices-detail-bypackage/get',
    async (value:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/${value.id}/${value.type}`, {
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getAccountReceivable = createAsyncThunk(
    'invoices-receivable/get',
    async (_, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/account-receive/summary-without-range`, {
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getTotalSales = createAsyncThunk(
    'invoices-sales/get',
    async (_, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/vendor/summaryInvoicePaid`, {
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getStatistivInvoice = createAsyncThunk(
    'invoices-statistic/get',
    async (_, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/statisticVendor`, {
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const createNewInvoice = createAsyncThunk(
    'invoice-create/post',
    async (body : any, { rejectWithValue }) => {
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/invoice`, body, {
                headers
            })
            if(response.data) {
                return true
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const createCalculateInvoice = createAsyncThunk(
    'invoice-calculate/post',
    async (body : any, { rejectWithValue }) => {
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/invoice/calculateInvoice`, body, {
                headers
            })
            if(response.data) {
                return {...response.data, vendor: body.vendor}
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const submitConfirmPaymentInvoice = createAsyncThunk(
    'invoice-confirm/post',
    async (id:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/transactions/approve/${id}`, null, {
                headers
            })
            if(response.data) {
                return true
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const printDownloadInvPDF = createAsyncThunk(
    'download-inv-pdf/print',
    async (id:string, { rejectWithValue }) => {
        let type = "Final Payment"
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/printPdf/${id}/${type}`, {
                headers
            })
            if(response.data) { 
                return response.data
            }
          } catch (err : any) {
            if (err.response) {
                return rejectWithValue(err.response.data.message)
            } else if (err.request) {
                return rejectWithValue(err)
            } else {
                return rejectWithValue(err)
            }
        }
    }
);