import { Stack } from '@mui/material'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../../app/store';
import LoaderBackdrop from '../../../../components/LoaderBackdrop';
import SnackBarAlert from '../../../../components/SnackBarAlert';
import InvoiceDetail from './InvoiceDetail';
import PackageDelivered from './PackageDelivered';
import PageInvoices from './PageInvoices';

function MainPageInvoice() {
    const { loading_approve_payment, approve_payment} = useSelector((state : RootState) => state.invoice)

    const location : any = useLocation()
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    
    let query = useQuery();
    let active_page = query.get('page')
 
    const switchPage = () => {
        if (active_page === 'create' || active_page === 'detail') {
            return <InvoiceDetail />
        } else if (active_page === 'delivered') {
            return <PackageDelivered/>
        } else {
            return <PageInvoices/>
        }
    }
 
    return (
        <Stack>
            <LoaderBackdrop loading={loading_approve_payment} />
            {switchPage()}
            <SnackBarAlert message="Invoice payment is successfully confirmed." initialState={approve_payment} />
        </Stack>
    )
}

export default MainPageInvoice
