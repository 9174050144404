// @ts-ignore
import { MainContainer } from '@chatscope/chat-ui-kit-react'
import { Box, Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import BreadCrumbs from '../../../components/BreadCrumbs'
import HeadlineTitle from '../../../components/HeadlineTitle'
import ChatAction from './components/ChatAction'
import ChatList from './components/ChatList'
import { useEffect } from 'react'
import { getSettingPlatform } from '../masters/mastersReducers'
import LoaderBackdrop from '../../../components/LoaderBackdrop'

function ChatPage() {
    const dispatch = useDispatch();
    const { conversation_lists} = useSelector((state:RootState) => state.chats);
    const { setting_platform, loading_setting_platform} = useSelector((state:RootState) => state.masters);
    
    useEffect(() => {
        dispatch(getSettingPlatform());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
    <Box m={2}>
        <BreadCrumbs 
            isPage={false}
            current="Message"
        />
        <Stack direction="row" justifyContent="space-between" py={1} >
            <Box>
                <HeadlineTitle 
                    title='Message'
                    variant="h6"
                    bold="bold"
                />
            </Box>
        </Stack>
        { loading_setting_platform ? 
        <LoaderBackdrop loading={true} /> : 
        <>
        {  setting_platform &&  setting_platform?.automation_fulfillment ? 
        <Box className='not-found-data' >
            <h1>Permission Denied!</h1>
            <p>Managed Vendor cant send chat message, if you want to operate in the platform Contact us.</p>
        </Box>
        :
        <Box className='chat-container'>
            <MainContainer responsive style={{borderRadius: 10}}>
                {conversation_lists.length > 0 ? 
                <>
                    <ChatList/>
                    <ChatAction width={45}/>
                </> 
                : 
                <>
                    <ChatAction width={50}/>
                </> }
            </MainContainer>
        </Box>
        }
        </> }
    </Box>
  )
}

export default ChatPage
