import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    Sidebar,
    Search,
    ConversationList,
    Conversation,
    Avatar,
 } from '@chatscope/chat-ui-kit-react';
import profile from '../../../../assets/img/icon/profile.png'
import { listChat } from '../interface';
import { Box } from '@mui/material';
import { RootState } from '../../../../app/store';
import { getConversationRoom } from '../redux/chatReducers';
import { initConversationActive } from '../redux/chatSlices';


const ChatList = () => {
    const dispatch = useDispatch()
    const { conversation_lists, new_message, conversation_active,  } = useSelector((state:RootState) => state.chats);

    const [search,setSearch] = useState('');

    function getUnreadCount(company_id: string) {
        let unread = [...new_message]
        return unread.filter((item: any) => item.from_company_id === company_id).length
    }

    function chat_prop(element: any) {
        return {
            onClick() {
                dispatch(initConversationActive(element))
                let payload = { company_id : element.company_id, new_message }
                dispatch(getConversationRoom(payload))
            },
            name : element.company_name,
            info: element.last_message.attachment ? "[image]" : element.last_message.text,
            // lastActivityTime: moment(element.last_message?.createdAt).fromNow(),
            unreadCnt: getUnreadCount(element.company_id)
        }
    }

    const handleSearch = (l:listChat) =>
        search.length === 0
        || (l.company_name && l.company_name?.toLowerCase().indexOf(search.toLowerCase()) > -1)
    

    return(
        <Sidebar position="left" scrollable={false}>
            {conversation_lists.length > 0
                ? <Search placeholder="Search..." onChange={setSearch}/>
                : <Box sx={{textAlign:'center', pt:3}}>No Conversation yet</Box>
            }
            <ConversationList>
                    {conversation_lists.filter(handleSearch).map((item: any, idx: number) =>
                        <Conversation 
                            key={idx} {...chat_prop(item)} 
                            active={conversation_active?.company_id === item.company_id}
                        >
                            <Avatar 
                                src={item.company_logo || profile} 
                                name={item.company_name} size="md"
                            />                  
                        </Conversation>
                    )}
            </ConversationList>
        </Sidebar>
    )
}

export default ChatList