import { createSlice } from '@reduxjs/toolkit';
import { createNewCustomRoles, getAllCustomRoles, getAllFeatureDashboard, getAllFeatureNavigations, 
  getAllNavigations, getDetailCustomRoles, updateExistingCustomRoles,  
} from './rolesReducers';
import { errorSweetAlert } from '../../../../utilities/errorhandling';

const initialState: any = {
  data: [], 
  loading : false,
  update : false, 
  loading_update : false,
  create : false,
  loading_create: false,
  navigations: [],
  loading_navigations: false,
  features_default : [],
  loading_features_default: false,
  loading_features : false,
  features : [],
  detail : {},
  loading_detail : false,
};

export const rolesSlice = createSlice({
  name: 'roles',
  initialState, 
  reducers: {
    clearMessageRole : (state) => {
      state.create = false;
      state.update = false;
    }
  },
  extraReducers: (builder) => {
    builder
      // get roles
      .addCase(getAllCustomRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCustomRoles.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllCustomRoles.rejected, (state, action : any) => {
        state.loading = false;
        errorSweetAlert(action.payload)
      })

      // get roles detail
      .addCase(getDetailCustomRoles.pending, (state) => {
        state.loading_detail = true;
      })
      .addCase(getDetailCustomRoles.fulfilled, (state, action:any) => {
        state.loading_detail = false;
        state.detail = action.payload;
      })
      .addCase(getDetailCustomRoles.rejected, (state, action : any) => {
        state.loading_detail = false;
        errorSweetAlert(action.payload)
      })

      // get navigations
      .addCase(getAllNavigations.pending, (state) => {
        state.loading_navigations = true;
      })
      .addCase(getAllNavigations.fulfilled, (state, action:any) => {
        state.loading_navigations = false;
        state.navigations = action.payload;
      })
      .addCase(getAllNavigations.rejected, (state, action : any) => {
        state.loading_navigations = false;
        errorSweetAlert(action.payload)
      })

      // get features 
      .addCase(getAllFeatureNavigations.pending, (state) => {
        state.loading_features = true;
      })
      .addCase(getAllFeatureNavigations.fulfilled, (state, action:any) => {
        state.loading_features = false;
        state.features = action.payload;
      })
      .addCase(getAllFeatureNavigations.rejected, (state, action : any) => {
        state.loading_features = false;
        errorSweetAlert(action.payload)
      })

      // get features default
      .addCase(getAllFeatureDashboard.pending, (state) => {
        state.loading_features_default = true;
      })
      .addCase(getAllFeatureDashboard.fulfilled, (state, action:any) => {
        state.loading_features_default = false;
        state.features_default = action.payload;
      })
      .addCase(getAllFeatureDashboard.rejected, (state, action : any) => {
        state.loading_features_default = false;
        errorSweetAlert(action.payload)
      })

      // update roles
      .addCase(updateExistingCustomRoles.pending, (state) => {
        state.loading_update = true;
        state.update = false;
      })
      .addCase(updateExistingCustomRoles.fulfilled, (state, action:any) => {
        state.loading_update = false;
        state.update = action.payload
      })
      .addCase(updateExistingCustomRoles.rejected, (state, action : any) => {
        state.loading_update = false;
        errorSweetAlert(action.payload)
      })

      // createa roles
      .addCase(createNewCustomRoles.pending, (state) => {
        state.loading_create = true;
        state.create = false;
      })
      .addCase(createNewCustomRoles.fulfilled, (state, action:any) => {
        state.loading_create = false;
        state.create = action.payload
      })
      .addCase(createNewCustomRoles.rejected, (state, action : any) => {
        state.loading_create = false;
        errorSweetAlert(action.payload)
      })

      
  },
});

export const { clearMessageRole } = rolesSlice.actions;
export default rolesSlice.reducer;
