import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Stack, Box, Skeleton, 
  // Switch, FormGroup , FormControlLabel 
} from '@mui/material';
import EmptyData from '../../../../components/EmptyData';
import { useDispatch, useSelector } from 'react-redux';
import { getOnePreferredProducts, updatePreferredBuyerList } from '../redux/buyerListsReducer';
import { RootState } from '../../../../app/store';
import { getProductForDiscount } from '../../masters/mastersReducers';
import FormPreferred from './FormPreferred';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogEdit : React.FC<any> = ({ 
  open, setOpen, name, detail, setBulkUpload, bulkUpload, edit

}) => {

    const dispatch = useDispatch()

    const { loading_preferred_products, preferred_products, update } = useSelector((state : RootState) => state.preferred)
    const { products } = useSelector((state : RootState) => state.masters)

    const [companySetting, setCompanySetting] = useState<any>({
      initial_discount : "",
      payment_term : "",
      status : "ACTIVE", 
      down_payment : "",
      credit_limit_amount : 0,
      credit_limit_isActive : false,
      minimum_total_order_amount : 0,
      minimum_total_order_isActive : false,
    });

    const [productItems, setProductItems] = useState<any>([]);
    const [selectedTrigger, setSelectedTrigger] = useState<any>([]);
    const [selectedPeriod, setSelectedPeriod] = useState<any>([]);
    const [errorTrigger, setErrorTrigger] = useState(false);
    const [errorPeriod, setErrorPeriod] = useState(false);

    function handleClose () {
        setOpen(false);
        setProductItems([])
        setCompanySetting({
          ...companySetting,
          initial_discount : "",
          payment_term : "",
          product : [],
          status : "ACTIVE",
        })
    };


    function convertProductsArray (data:any) {
      let arr : any = []
      data.map((item:any) => {
          arr.push({
            _id : item._id,
            sku : item.sku,
            name : item.name,
            discount : {
              label : item.discount_type.value,
              value : parseInt(item.discount_value)
            }
          })
        return arr
      })
      return arr
    }

    function onClickSubmit(e:any) {
      e.preventDefault()
      if(selectedTrigger.length === 0) {
        setErrorTrigger(true)
      } else if(selectedPeriod.length === 0) {
        setErrorPeriod(true)
      } else {
        let params = {
          body : {
            company : "null",
            preferred : preferred_products && preferred_products.preferred._id,
            initial_discount : companySetting.initial_discount,
            payment_term : {
              value : companySetting.payment_term,
              label : `${companySetting.payment_term} Days`,
            },
            product : convertProductsArray(productItems),
            status : companySetting.status,
            down_payment: companySetting.down_payment,
            credit_limit: {
              isActive: companySetting.credit_limit_isActive,
              amount: companySetting.credit_limit_amount
            },
            minimum_total_order: {
              isActive: companySetting.minimum_total_order_isActive,
              amount: companySetting.minimum_total_order_amount
            },
            invoice : {
              options  : selectedTrigger.value,
              config : {
                  period : selectedPeriod.value
              }
            }
          },
          id : preferred_products && preferred_products._id
        }
        dispatch(updatePreferredBuyerList(params))
      }
    }

    // function isnotempty
    function isNotEmpty (obj:any) {
      for(var key in obj) {
          if(obj.hasOwnProperty(key))
              return true;
      }
      return false;
    }


    function backConvertProductsArray (data:any) {
      let arr : any = []
      data.map((item:any) => {
          arr.push({
            _id : item._id,
            sku : item.sku,
            name : item.name,
            discount_type : {
              value : item.discount.label,
              label : item.discount.label,
            },
            discount_value : item.discount.value,
          })
          return arr
      })
      return arr
    }

    useEffect(() => {
      if(isNotEmpty(preferred_products)) {
        setCompanySetting({
          ...companySetting,
          initial_discount : preferred_products.initial_discount || 0,
          payment_term : preferred_products.payment_term.value || { value : 0, label : "0 Days"},
          down_payment : preferred_products.down_payment || 0,
          credit_limit_amount : (preferred_products.credit_limit && preferred_products.credit_limit.amount) || 0,
          credit_limit_isActive : (preferred_products.credit_limit && preferred_products.credit_limit.isActive) || false,
          minimum_total_order_amount : (preferred_products.minimum_total_order && preferred_products.minimum_total_order.amount) || 0,
          minimum_total_order_isActive : (preferred_products.minimum_total_order && preferred_products.minimum_total_order.isActive) || false,
          status : "ACTIVE",
        })
        setProductItems(backConvertProductsArray(preferred_products.product))
        if(preferred_products.invoice) {
          setSelectedTrigger({
            value : preferred_products.invoice.options,
            label : preferred_products.invoice.options
          })
          setSelectedPeriod({
            value : preferred_products.invoice.config.period,
            label : preferred_products.invoice.config.period === "EOM" ? "End of Month" : "End of Week"
          })
        } else {
          setSelectedTrigger({
            value : "Manual",
            label : "Manual"
          })
          setSelectedPeriod({
            value : "EOM",
            label : "End of Monthly"
          })
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preferred_products]);


    useEffect(() => {
      if(update) {
          handleClose()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update]);

    useEffect(() => {
      if(open && detail._id !== undefined) {
        dispatch(getOnePreferredProducts(detail._id))
        if(products.length === 0) {
            dispatch(getProductForDiscount())
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail, open]);

    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="lg"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">{name}</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
          <Box className="container-content-preferred">
            <form onSubmit={onClickSubmit}>
            { loading_preferred_products ? 
            <Box className="card-content">
              <Box p={0.5}>
                <Skeleton animation="wave" variant="rectangular" width="100%" height={150} sx={{ borderRadius: 3 }}/>
                <Box mt={1}>
                <Skeleton animation="wave" variant="rectangular" width="100%" height={260} sx={{ borderRadius: 3 }}/>
                </Box>
              </Box>
            </Box>
            :
            <Box className="card-content">
              { !isNotEmpty(preferred_products) ? 
                <EmptyData 
                  message="Company not found."
                  caption="Please search company only by code. Make sure you got the right company code."
                />
                :
                <FormPreferred 
                  search_buyer={preferred_products}
                  companySetting={companySetting}
                  setCompanySetting={setCompanySetting}
                  productItems={productItems}
                  setProductItems={setProductItems}
                  setBulkUpload={setBulkUpload}
                  bulkUpload={bulkUpload}
                  selectedTrigger={selectedTrigger}
                  setSelectedTrigger={setSelectedTrigger}
                  selectedPeriod={selectedPeriod}
                  setSelectedPeriod={setSelectedPeriod}
                  errorTrigger={errorTrigger}
                  setErrorTrigger={setErrorTrigger}
                  errorPeriod={errorPeriod}
                  setErrorPeriod={setErrorPeriod}
                  type="edit"
                  edit={false}
                /> 
                }
            </Box> }
          </form>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export default DialogEdit;
