import { 
    Paper,
    Box,
    Button,
    Skeleton,
    TextField
} from '@mui/material';
import { remove } from 'lodash'
import Select from 'react-select'
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { useEffect, useState } from 'react';
import { changeStepWarehouse } from '../warehouseSlice';
import LoaderBackdrop from '../../../../components/LoaderBackdrop';
import { fetchMasterSchedule, getAddressProvince } from '../../masters/mastersReducers';
import StoreSchedule from './StoreSchedule';
import ButtonAction from '../../../../components/ButtonAction';
import { 
    objectSchedule, 
    ISelectOption,
    localDataTypes,
    objectOperationTime,
} from './warehouseTypes';


const DeliverySchedules = () => {

    const { schedule, loading_schedule } = useSelector((state : RootState) => state.masters)

    const dispatch = useDispatch()

    const [province, setProvince] = useState([]);
    const [localData, setLocalData] = useState<localDataTypes>();

    const [deliverySchedules, setDeliverySchedules] = useState<objectSchedule[]>([]);
    const [loading, setLoading] = useState(false);

    const handleChangeProvince = (value: ISelectOption, index : number) : void => {
        let copy_array = [...deliverySchedules]
        const newData : objectSchedule[] = copy_array.map((obj : objectSchedule, i : number) => {
            if(i === index)
            return {
                ...obj,
                loading_city : true,
            }
            return obj
        });
        setDeliverySchedules(newData)
        proceedCity(value, index)
    }

    const proceedCity = (value:ISelectOption, index :number ) => {
        let copy_array = [...deliverySchedules]
        const new_Data : any[]  = copy_array.map((obj : objectSchedule, i : number) => {
            if(i === index)
            return {
                ...obj,
                loading_city : false,
                cities : value.cities,
                city_options : value.cities,
                province : value.label
            }
            return obj
        }); 
        setDeliverySchedules(new_Data)
    }

    const proceedOperationTimeAction = (params : objectOperationTime, index :number) => {
        let copy_array = [...deliverySchedules]
        const newData : any = copy_array.map((obj : objectSchedule, i : number) => {
            if(i === index)
            return {
                ...obj,
                operationTime : params,
            }
            return obj
        }); 
        setDeliverySchedules(newData)
    }

    const handleChangeCity = (value: ISelectOption, index : number) : void => {
        let copy_array = [...deliverySchedules]
        const newData = copy_array.map((obj : any, i : number) => {
            if(i === index)
            return {
                ...obj,
                cities: value,
            }
            return obj
        });
        setDeliverySchedules(newData)
    }

    const onClickAddNewField = () => {
        let copy_item = [...deliverySchedules]
        let new_object = {
            name : "Default",
            province : "Select Province",
            cities : [],
            city_options : [],
            loading_city : false,
            operationTime : schedule && schedule.map((day:any) =>  ({ ...day, active: true  }))
        }
        copy_item.unshift(new_object)
        setDeliverySchedules(copy_item)
    }

    const onClickRemoveItem = (idx : number) => {
        let copy_item = [...deliverySchedules]
        const items_remove = remove(copy_item, function(obj : objectSchedule, index : number) {
            return index !== idx
        });
        setDeliverySchedules(items_remove)
    }

    const checkValidationCities = (delivery_aray : objectSchedule[]) => {
        let result = false
        for(let i = 0; i < delivery_aray.length; i++) {
            if(delivery_aray[i].cities.length === 0) {
                result = true
                break
            }
        }
        return result
    }


    const handleNextSubmit = () => {
        if(deliverySchedules.length === 0) {
            swal('Error', 'Please add at least one coverage', 'error')
        } else if(deliverySchedules[0].province === "Select Province") {
            swal('Error', 'Please add at least one coverage', 'error')
        } else {
            let data_form = {
                ...localData,
                schedule: deliverySchedules.filter((item : objectSchedule) => item.province !== "Select Province"),
            }
            if(checkValidationCities(deliverySchedules)) {
                swal('Error', 'There is a schedule with empty cities. Please add at least one city!', 'error')
            } else {
                localStorage.setItem('warehouse', JSON.stringify(data_form))
                dispatch(changeStepWarehouse(3))
            }
        }
    }

    useEffect(() => {
        const local_data = localStorage.getItem('warehouse')
        const checkLocalData = async () => {
            setLoading(true)
            const data = local_data === null ? null : JSON.parse(local_data)
            if(data !== null) {
                setLocalData(data)
                setDeliverySchedules(data.schedule)
                setProvince(data.coverage.map((item : any) => ({ value : item.province, label : item.province, cities : item.cities })))
                setLoading(false)
            } else {
                setLoading(false)
            }
        }
        checkLocalData()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(getAddressProvince())    
        dispatch(fetchMasterSchedule())
        // eslint-disable-next-line
    }, []);

    return ( 
    <Box mt={2} className="modules-itemss">
        <LoaderBackdrop loading={loading || loading_schedule} />
        <Paper sx={{p:3, mt:2}}>
        <Button 
            variant="outlined" color="primary"
            size="small"
            onClick={() => onClickAddNewField()}
        >
            Add More Schedule
        </Button>
        <Box className="modules-items-second" >
        { deliverySchedules.map((row :objectSchedule, i : number) => (
            <Box className='box-schedule'>
                <Box>
                    <h4>Name</h4>
                    <TextField 
                        placeholder="Schedule Name"
                        margin='dense'
                        size='small'
                        sx={{width: '100%', height: 30, marginTop: 0}}
                        value={row.name}
                        onChange={(e) => setDeliverySchedules(deliverySchedules.map((obj : objectSchedule, index : number) => {
                            if(index === i) {
                                return {
                                    ...obj,
                                    name : e.target.value
                                }
                            }
                            return obj
                        }))}
                    />
                </Box>
                <Box>
                    <h4>Province</h4>
                    <Select
                        placeholder="Select Province"
                        value={{ value: row.province, label: row.province }}
                        options={province && province}
                        onChange={(value:any) => handleChangeProvince(value,i)}
                    />
                </Box>
                <Box>
                    <h4>Cities</h4>
                    { row.loading_city ? 
                    <Skeleton height={60} /> :
                    <Box >
                        { row.city_options.length === 0 ? 
                        <Select
                            placeholder="Please select province first"
                        /> 
                        :
                        <Select
                            placeholder="You can select multiple city"
                            value={row.cities}
                            isSearchable={true}
                            onChange={(e:any) => handleChangeCity(e, i)}
                            isMulti
                            options={row.city_options}
                            noOptionsMessage={() => "There is no another City."}
                            id="select-style-coverage-0"
                        />  }
                    </Box> 
                    }
                </Box>
                <Box>
                    <StoreSchedule
                        operationalTime={row.operationTime}
                        proceedOperationTime={(data:objectOperationTime) => proceedOperationTimeAction(data, i)}
                        name="Schedule"
                    />
                </Box>
                <Box>
                    <ButtonAction 
                        type="remove"
                        onClick={() => onClickRemoveItem(i)}
                    />
                </Box>
            </Box>
        ))}
        </Box>


            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    sx={{ mr: 1 }}
                    variant="contained"
                    onClick={() => dispatch(changeStepWarehouse(0))}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button 
                    variant="contained"
                    onClick={handleNextSubmit}
                >
                    Next
                </Button>
            </Box>
        </Paper>
    </Box>
  );
}

export default DeliverySchedules