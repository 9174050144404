import { Box } from '@mui/material'
import React from 'react'

const DownloadPdf : React.FC<any> = ({
    name, onClick
}) => {
  return (
    <Box 
        className="print-btn-pdfdownload" 
        onClick={onClick}
    >
        <p>{name}</p>
    </Box> 
  )
}

export default DownloadPdf