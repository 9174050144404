import { createSlice } from '@reduxjs/toolkit';
import { getFulfillmentData, getItemFulfillment, getStatusCount, printDownloadPDF, submitPickFulfillment, submitProofPayment, updateStatusFulfillment } from './fulfillmentReducers';
import { errorSweetAlert } from '../../../../utilities/errorhandling';

const initialState: any = {
  fulfillment : [], 
  total_fulfillment : 0,
  total_infinite : 0,
  loaded_fulfillment : false,
  loading_fulfillment : false,
  status : [],
  loading_status : false,
  items : [],
  default_items : [],
  loading_items : false,
  pick : false, 
  loading_pick : false,
  update_status : false,
  status_name : "",
  loading_update_status : false,
  download_pdf : "",
  loading_download_pdf : false,
  approve_payment : false, 
  loading_approve_payment : false
}; 


export const fulfillmentSlices = createSlice({
  name: 'fulfillment',
  initialState,
  reducers: {
    // update quantity special discount
    updateQuantityDiscountSpecial : (state, action:any) => {
      const { id, quantity, items } = action.payload
      const newData = items.map((obj : any) => {
        if(obj._id === id) {
          const total_tax = ( obj.discount * quantity) * obj.tax.value
          const total = obj.discount * quantity
          return {
           ...obj,
           discount : obj.discount,
           quantity: quantity,
           total : Math.round(total + total_tax),
           total_tax : Math.round(total_tax),
         }}
        return obj
      });
      state.items = newData
    },
    // update price special discount
    updatePriceDiscountSpecial: (state, action:any) => {
      const { id, price, items } = action.payload
      const newData = items.map((obj : any) => {
        if(obj._id === id) {
          let discount = parseInt(price)
          const total_tax = (discount * obj.quantity) * obj.tax.value
          const total = discount * obj.quantity
          return {
           ...obj,
           discount : discount,
           total : Math.round(total + total_tax),
           total_tax : Math.round(total_tax),
         }}
        return obj
      });
      state.items = newData
    },

    // update quantity percentage discount
    updateQuantityDiscountPercentage : (state, action:any) => {
      const { id, quantity, items } = action.payload
      const newData = items.map((obj : any) => {
        if(obj._id === id) {
          let discount_percentage = obj.extraDiscountType === "AMOUNT" ? ((obj.discount / 100) * obj.retail_price + obj.extraDiscount) : (((obj.discount + obj.extraDiscount) / 100) * obj.retail_price)
          const total = (obj.retail_price - discount_percentage) * quantity
          const total_tax = total * obj.tax.value
          return {
           ...obj,
           discount_price : Math.round(discount_percentage * quantity),
           quantity: quantity,
           total : Math.round(total + total_tax),
           total_tax : Math.round(total_tax),
         }}
        return obj
      });
      state.items = newData
    },
    // update price percentage discount
    updatePriceDiscountPercentage : (state, action:any) => {
      const { id, price, items } = action.payload
      const newData = items.map((obj : any) => {
        if(obj._id === id) {
          let retail_price = parseInt(price)
          let discount_price = obj.extraDiscountType === "AMOUNT" ? ((obj.discount / 100) * retail_price + obj.extraDiscount) : (((obj.discount + obj.extraDiscount) / 100) * retail_price)
          const total = (retail_price - discount_price) * obj.quantity
          const total_tax = total * obj.tax.value
          return {
           ...obj,
           retail_price : retail_price,
           discount_price : Math.round(discount_price * obj.quantity),
           total : Math.round(total + total_tax),
           total_tax : Math.round(total_tax),
         }}
        return obj
      });
      state.items = newData
    },


    // update quantity amount discount
    updateQuantityDiscountAmount : (state, action:any) => {
      const { id, quantity, items } = action.payload
      const newData = items.map((obj : any) => {
        if(obj._id === id) {
          let discount_amount = (obj.discount + obj.extraDiscount)
          const total = (obj.retail_price - discount_amount) * quantity
          const total_tax = total * obj.tax.value
          return {
           ...obj,
           quantity: quantity,
           total : Math.round(total + total_tax),
           total_tax : Math.round(total_tax),
         }}
        return obj
      });
      state.items = newData
    },
    // update price amount discount
    updatePriceDiscountAmount : (state, action:any) => {
      const { id, price, items } = action.payload
      const newData = items.map((obj : any) => {
        if(obj._id === id) {
          let retail_price = parseInt(price)
          let discount_price = obj.discount + obj.extraDiscount
          const total = (retail_price - discount_price) * obj.quantity
          const total_tax = total * obj.tax.value
          return {
           ...obj,
           retail_price : retail_price,
           discount_price : Math.round(discount_price * obj.quantity),
           total : Math.round(total + total_tax),
           total_tax : Math.round(total_tax),
         }}
        return obj
      });
      state.items = newData
    },



    updateValueAlert: (state) => {
      state.pick = false
      state.download_pdf = "";
      state.approve_payment = false;
      state.update_status = false;
    },
    changeToDefaultItems: (state, action) => {
      state.items = action.payload
    },
    changeNoteItem : (state, action:any) => {
      const { id, note, items } = action.payload
      const newData = items.map((obj : any) => {
        if(obj._id === id) {
          return {
           ...obj,
           note_inbound : note,
         }}
        return obj
      });
      state.items = newData
    },
    addLengthInfiniteOrder (state,action) {
      let existing = state.total_infinite + action.payload
      state.total_infinite = existing
    }
  },
  extraReducers: (builder) => {
    builder
      // get fulfillment data
      .addCase(getFulfillmentData.pending, (state) => {
        state.loading_fulfillment = true;
        state.loaded_fulfillment = false;
        state.detail = {};
        state.fulfillment = [];
      })
      .addCase(getFulfillmentData.fulfilled, (state, action:any) => {
        state.loading_fulfillment = false;
        state.fulfillment = action.payload.data.length > 0 ? action.payload.data : [];
        state.total_fulfillment = action.payload.data.length > 0 ? action.payload.total.total : 0;
        state.total_infinite = action.payload.data.length > 0 ? action.payload.data.length : 0;
        state.loaded_fulfillment = true;
      })
      .addCase(getFulfillmentData.rejected, (state, action : any) => {
        state.loading_fulfillment = false;
        errorSweetAlert(action.payload)
      })

      // get status
      .addCase(getStatusCount.pending, (state) => {
        state.loading_status = true;
      })
      .addCase(getStatusCount.fulfilled, (state, action:any) => {
        state.loading_status = false;
        state.status = action.payload
      })
      .addCase(getStatusCount.rejected, (state, action : any) => {
        state.loading_status = false;
        errorSweetAlert(action.payload)
      })

       // get itemss
      .addCase(getItemFulfillment.pending, (state) => {
        state.loading_items = true;
        state.items = [];
        state.default_items = [];
      })
      .addCase(getItemFulfillment.fulfilled, (state, action:any) => {
        state.loading_items = false;
        state.items = action.payload
        state.default_items = action.payload
      })
      .addCase(getItemFulfillment.rejected, (state, action : any) => {
        state.loading_items = false;
        errorSweetAlert(action.payload)
      })

       // submit pick
      .addCase(submitPickFulfillment.pending, (state) => {
        state.loading_pick = true;
        state.pick = false;
      })
      .addCase(submitPickFulfillment.fulfilled, (state, action:any) => {
        state.loading_pick = false;
        state.pick = action.payload
      })
      .addCase(submitPickFulfillment.rejected, (state, action : any) => {
        state.loading_pick = false;
        errorSweetAlert(action.payload)
      })

       // submit pick
      .addCase(updateStatusFulfillment.pending, (state) => {
        state.loading_update_status = true;
        state.update_status = false;
        state.status_name = ""
      })
      .addCase(updateStatusFulfillment.fulfilled, (state, action:any) => {
        state.loading_update_status = false;
        state.update_status = action.payload.data;
        state.status_name = action.payload.status;
      })
      .addCase(updateStatusFulfillment.rejected, (state, action : any) => {
        state.loading_update_status = false;
        errorSweetAlert(action.payload)
      })

       // print pdf
      .addCase(printDownloadPDF.pending, (state) => {
        state.loading_download_pdf = true;
        state.download_pdf = "";
      })
      .addCase(printDownloadPDF.fulfilled, (state, action:any) => {
        state.loading_download_pdf = false;
        state.download_pdf = action.payload;
      })
      .addCase(printDownloadPDF.rejected, (state, action : any) => {
        state.loading_download_pdf = false;
        errorSweetAlert(action.payload)
      })

      // update approve payment
      .addCase(submitProofPayment.pending, (state) => {
        state.loading_approve_payment = true;
        state.approve_payment = false;
      })
      .addCase(submitProofPayment.fulfilled, (state, action:any) => {
        state.loading_approve_payment = false;
        state.approve_payment = action.payload;
      })
      .addCase(submitProofPayment.rejected, (state, action : any) => {
        state.loading_approve_payment = false;
        errorSweetAlert(action.payload)
      })


  },
});

export const { 
  updateQuantityDiscountSpecial, updatePriceDiscountSpecial,
  updatePriceDiscountPercentage, updateQuantityDiscountPercentage,
  updateQuantityDiscountAmount, updatePriceDiscountAmount,
  changeToDefaultItems, 
  updateValueAlert, 
  changeNoteItem, addLengthInfiniteOrder
} = fulfillmentSlices.actions;

export default fulfillmentSlices.reducer;
