import { createSlice } from '@reduxjs/toolkit';
import { blockUserTeams, createNewTeamMember, getAllUsersTeams, updateTeamMember } from './teamsReducers';
import { errorSweetAlert } from '../../../../utilities/errorhandling';

const initialState: any = {
  data: [], 
  loading : false,
  update : false, 
  loading_update : false,
  create : false,
  name_update : "",
  is_owner: false,
  loading_create: false,
  status : false,
  loading_status: false
};

export const stepRegisterVendor = createSlice({
  name: 'teams',
  initialState, 
  reducers: {
    clearMessageTeam : (state) => {
      state.create = false;
      state.update = false;
    }
  },
  extraReducers: (builder) => {
    builder
      // get all users
      .addCase(getAllUsersTeams.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUsersTeams.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getAllUsersTeams.rejected, (state, action : any) => {
        state.loading = false;
        errorSweetAlert(action.payload)
      })

      // update users
      .addCase(updateTeamMember.pending, (state) => {
        state.loading_update = true;
        state.update = false;
      })
      .addCase(updateTeamMember.fulfilled, (state, action:any) => {
        state.loading_update = false;
        state.update = action.payload;
      })
      .addCase(updateTeamMember.rejected, (state, action : any) => {
        state.loading_update = false;
        errorSweetAlert(action.payload)
      })

      // createa users
      .addCase(createNewTeamMember.pending, (state) => {
        state.loading_create = true;
        state.create = false;
      })
      .addCase(createNewTeamMember.fulfilled, (state, action:any) => {
        state.loading_create = false;
        state.create = action.payload;
      })
      .addCase(createNewTeamMember.rejected, (state, action : any) => {
        state.loading_create = false;
        errorSweetAlert(action.payload)
      })

      // disable/enable users
      .addCase(blockUserTeams.pending, (state) => {
        state.loading_status = true;
        state.status = false;
      })
      .addCase(blockUserTeams.fulfilled, (state, action:any) => {
        state.loading_status = false;
        state.status = action.payload;
      })
      .addCase(blockUserTeams.rejected, (state, action : any) => {
        state.loading_status = false;
        errorSweetAlert(action.payload)
      })
      
  },
});

export const { clearMessageTeam } = stepRegisterVendor.actions;
export default stepRegisterVendor.reducer;
