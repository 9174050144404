import { 
    Box,
    Stack,
    Skeleton,
} from '@mui/material';
import { useState } from 'react';
import swal from 'sweetalert';
import { v4 as uuid } from 'uuid';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import imagebtn from '../../../../assets/img/icon/image.png'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
const Input = styled('input')({
  display: 'none',
});

const ImagesItems : React.FC<any> = ({
    imagesItems,
    setImagesItems,
    setErrorImages
}) => {

    const [loading, setLoading] = useState(false);
    const [index, setIndex] = useState("");

    const onChangeValue = (index : any, value : any) => {
        let copy_array = [...imagesItems]
        const newData = copy_array.map((obj : any, i : any) => {
            if(i === index)
            return {
                ...obj,
                [value.target.name]: value.target.value,
            }
            return obj
        });
        setImagesItems(newData)
    }

    const onChangeValueFile = (index : any, e : any) => {
        setLoading(true)
        setIndex(index)
        const copy_array = [...imagesItems]
        const random = uuid();
        setTimeout(() => {
            var S3 = require("aws-sdk/clients/s3");
            const s3bucket = new S3({
                endpoint: process.env.REACT_APP_S3_BUCKET_ENDPOINT,
                accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
                secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET
            });
        
            if (e.target.files && e.target.files[0]) {
            const blob = e.target.files[0]
            const file_name = blob.name.replace(/\s/g, "")
            const params = { Body: blob, 
                            Bucket: process.env.REACT_APP_S3_BUCKET_NAME, 
                            Key: process.env.REACT_APP_S3_FOLDER_NAME + random + file_name
                            };
            s3bucket.putObject(params)
            .on('build', (request : any) => {
                request.httpRequest.headers.Host = process.env.REACT_APP_S3_API_URL
                request.httpRequest.headers['Content-Length'] = blob.size;
                request.httpRequest.headers['Content-Type'] = blob.type;
                request.httpRequest.headers['Access-Control-Allow-Origin']= '*';
                request.httpRequest.headers['x-amz-acl'] = 'public-read';
            })
            .send((err : any, data : any) => {
                if (err){  
                    swal(`${err}`, `${err.stack}`, 'error')
                } 
                else {      
                    const imageUrl = `${process.env.REACT_APP_S3_CDN_URL}${process.env.REACT_APP_S3_FOLDER_NAME}${random}${file_name}`
                    const newData = copy_array.map((obj : any, i : any) => {
                        if(i === index)
                        return {
                            ...obj,
                            image : imageUrl,
                        }
                        return obj
                    });
                    setErrorImages(false)
                    setImagesItems(newData)
                    setLoading(false)
                }}
            )} 
        }, 1000);
    }

    // const onClickAddNewField = () => {
    //     let copy_item = [...imagesItems]
    //     let new_object = {
    //         image: "",
    //     }
    //     copy_item.push(new_object)
    //     setImagesItems(copy_item)
    // }

  return (
    <Stack flexDirection="row" alignItems="center" flexWrap="wrap">
        { imagesItems.map((row :any, i : any) => (
        <Box mr={1} mb={1} key={i}>
            { loading && i === index ? 
            <Skeleton 
                variant="rectangular" 
                width={131} height={125} 
                sx={{ borderRadius: '4px' }}
            /> :
            <Box>
                { row.image === "" ?
                <Box className='box-images-btn'>
                    <label htmlFor={`icon-button-files-${i}`} >
                        <Input accept="image/*" id={`icon-button-files-${i}`} type="file"  onChange={(e) => onChangeValueFile(i,e)} />
                            <IconButton color="primary" aria-label="upload picture" component="span">
                            <img src={imagebtn} alt="icon"/>
                        </IconButton>
                        <p>Max Size 1MB</p>
                    </label>
                </Box>
                :
                <Stack flexDirection="column" className='image-preview' >
                    <Box 
                        component="img"
                        src={row.image}
                    />
                    <Box className='btn-remove-img'
                        onClick={() => onChangeValue(i,{target : {value : "", name : "image"}})}
                    >
                     <DeleteForeverIcon /> 
                    </Box>
                </Stack> }
            </Box> 
            }
        </Box>
        ))}
    </Stack>
  );
}

export default ImagesItems