import { createSlice } from '@reduxjs/toolkit';
import { 
  createNewTokenCredential, 
  getAllTokenCredentials,
  removeTokenCredential, 
  updateTokenCredential 
} from './tokenCredentialReducers';
import { errorSweetAlert } from '../../../../utilities/errorhandling';

const initialState: any = {
  tokens : [], 
  total_tokens : 0,
  loading_tokens : false,
  create_token : false,
  loading_create_token : false,
  update_token : false,
  loading_update_token : false,
  remove_token : false,
  loading_remove_token : false,
};

export const tokenCredentialsSlices = createSlice({
  name: 'token-credentials',
  initialState,
  reducers: {
    resetTokenCreation : (state) => {
      state.create_token = false;
    }
  },
  extraReducers: (builder) => {
    builder
      // get all purchase request server
      .addCase(getAllTokenCredentials.pending, (state) => {
        state.loading_tokens = true;
        state.create_token = false;
      })
      .addCase(getAllTokenCredentials.fulfilled, (state, action:any) => {
        state.loading_tokens = false;
        state.tokens = action.payload.data.length > 0 ? action.payload.data : [];
        state.total_tokens = action.payload.data.length > 0 ? action.payload.total.row : 0;
      })
      .addCase(getAllTokenCredentials.rejected, (state, action : any) => {
        state.loading_tokens = false;
        errorSweetAlert(action.payload)
      })

      // create new purchase
      .addCase(createNewTokenCredential.pending, (state) => {
        state.loading_create_token = true;
      })
      .addCase(createNewTokenCredential.fulfilled, (state, action:any) => {
        state.loading_create_token = false;
        state.create_token = action.payload;
      })
      .addCase(createNewTokenCredential.rejected, (state, action : any) => {
        state.loading_create_token = false;
        errorSweetAlert(action.payload)
      })

      // update purchase
      .addCase(updateTokenCredential.pending, (state) => {
        state.loading_update_token = true;
      })
      .addCase(updateTokenCredential.fulfilled, (state, action:any) => {
        state.loading_update_token = false;
        state.update_token = action.payload;
      })
      .addCase(updateTokenCredential.rejected, (state, action : any) => {
        state.loading_update_token = false;
        errorSweetAlert(action.payload)
      })

      // remove purchase
      .addCase(removeTokenCredential.pending, (state) => {
        state.loading_remove_token = true;
      })
      .addCase(removeTokenCredential.fulfilled, (state, action:any) => {
        state.loading_remove_token = false;
        state.remove_token = action.payload;
      })
      .addCase(removeTokenCredential.rejected, (state, action : any) => {
        state.loading_remove_token = false;
        errorSweetAlert(action.payload)
      })

  },
});


export const { resetTokenCreation } = tokenCredentialsSlices.actions;

export default tokenCredentialsSlices.reducer;
