import { 
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableCell,
    Box,
    TextField,
    Button
} from '@mui/material';
import { remove } from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { RootState } from '../../../../app/store';
import ButtonAction from '../../../../components/ButtonAction';
import ButtonCreate from '../../../../components/ButtonCreate';
import LoaderBackdrop from '../../../../components/LoaderBackdrop';
import { getProductForDiscount } from '../../masters/mastersReducers';
import AutoCompleteProduct from './AutoCompleteProduct';
import Select from 'react-select'
import swal from 'sweetalert';
import { useEffect } from 'react';
import imagebtn from '../../../../assets/img/icon/excel.png' 
import imgxls from '../../../../assets/img/icon/xls.png'
import { downloadTemplatePreferred } from '../redux/buyerListsReducer';
import { removeDownloadExcel } from '../redux/buyerListsSlice';

const Input = styled('input')({
    display: 'none',
});

const ProductsField : React.FC<any> = ({
    productItems,
    setProductItems,
    bulkUpload, setBulkUpload,
    fileExcel, setFileExcel, edit
}) => {
    const dispatch = useDispatch()
    const { products, loading_products } = useSelector((state : RootState) => state.masters)
    const { template_excel } = useSelector((state : RootState) => state.preferred)
   
    const onChangeValueFile = (e : any) => {
        setFileExcel(e.target.files)
    }

    const onClickAddNewField = () => {
        let copy_item = [...productItems]
        let new_object = {
            _id : "",
            sku : "",
            name : "",
            discount_type : { 
                label : "PERCENTAGE",
                value : "PERCENTAGE"
            },
            discount_value : "0"
        }
        copy_item.push(new_object)
        setProductItems(copy_item)
    }

    const onClickRemoveItem = (value : any) => {
        let copy_item = [...productItems]
        const items_remove = remove(copy_item, function(obj : any, index : any) {
            return index !== value
        });
        setProductItems(items_remove)
    }

    const onChangeProduct = (value : any, index : any) => {
        let copy_array = [...productItems]
        const newData = copy_array.map((obj : any, i : any) => {
            if(i === index)
            return {
                ...obj,
                _id: value._id,
                sku : value.sku,
                name : value.name,
            }
            return obj
        });
        setProductItems(newData)
    }


    const onChangeSelectType = (index : any, value : any) => {
        let copy_array = [...productItems]
        const newData = copy_array.map((obj : any, i : any) => {
            if(i === index)
            return {
                ...obj,
                discount_type : value,
                discount_value : "0"
            }
            return obj
        }); 
        setProductItems(newData)
    }

    const onChangeValueDiscount = (index : any, e : any, type : any) => {
        let copy_array = [...productItems]
        const { name, value } = e.target 
        if(type.label === "Percentage"){
            if(value > 100) {
                swal('Error', "Percentage can't be more than 100!", 'error')
            } else {
                const newData = copy_array.map((obj : any, i : any) => {
                    if(i === index)
                    return {
                        ...obj,
                        [name]: value === isNaN || value === "" ? 0 : parseFloat(value),
                    }
                    return obj
                }); 
                setProductItems(newData)
            }
        } else {
            const newData = copy_array.map((obj : any, i : any) => {
                if(i === index)
                return {
                    ...obj,
                    [name]: value === isNaN || value === "" ? 0 : value.replace(/,/g, ""),
                }
                return obj
            }); 
            setProductItems(newData)
        }
    }

    
    useEffect(() => {
        if(template_excel !== "" ) {
            window.open(template_excel, "_blank");
            dispatch(removeDownloadExcel())
        }
        // eslint-disable-next-line
    }, [template_excel]);

    return (
    <Box>
        <LoaderBackdrop loading={loading_products} />
        <Box sx={{ display: 'flex', flexWrap: 'wrap', columnGap: '5px', rowGap: '5px' }} width="fit-content" pb={2}>
          <ButtonCreate 
            name="Add Single Product"
            type="button"
            outlined={bulkUpload}
            onClick={() => {
                if(!edit) {
                    setBulkUpload(false)
                }
                onClickAddNewField()
                if(products.length === 0) {
                    dispatch(getProductForDiscount())
                }
            }}
          />
          { edit ? null : 
          <>
          <Box mx={0.5} />
          <ButtonCreate 
            name="Bulk Upload Product"
            type="button"
            outlined={!bulkUpload}
            onClick={() => {
                setBulkUpload(true)
                setProductItems([])
            }}
          />
          <Box mx={0.5} />
          <ButtonCreate 
            name="Download Template Product"
            type="button"
            outlined={true}
            onClick={() => {
                dispatch(downloadTemplatePreferred())
            }}
          />
          </> }
        </Box>

        { bulkUpload ? 
        <Box>
            <Box mb={2}>
                <Box pb={1} fontWeight="500">Excel File</Box>
                <Box>
                { fileExcel === null ? 
                <Box>
                    <label htmlFor="icon-button-file" >
                        <Box className='upload-area-bulk'>
                            <Input 
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
                                id="icon-button-file" type="file" 
                                onChange={(e) => onChangeValueFile(e)} 
                            />

                            <img src={imagebtn} alt="icon"/>
                            <Box pt={1}>Browse Excel File </Box>
                        </Box>
                    </label>
                </Box> : 
                <Box className='file-area-bulk'>
                    <img src={imgxls} alt="icon"/>
                    <Box> {fileExcel[0].name}</Box> 
                    <Box pt={1}>
                        <Button
                            variant="outlined"
                            color='error'
                            size="small"
                            onClick={() => setFileExcel(null)}
                        >Remove File</Button>
                    </Box>
                </Box> }
                </Box>
            </Box>
        </Box> :
        <Box>
            { productItems.length > 0 ?
            <TableContainer component={Paper} sx={{minHeight: 300}}>
            <Table aria-label="simple table"  > 
                <TableHead>
                    <TableRow >
                        <TableCell>Product</TableCell>
                        <TableCell width="180px">Discount Type</TableCell>
                        <TableCell width="180px">Discount Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                { productItems.map((row :any, i : any) => (
                    <TableRow key={i} >
                        <TableCell component="th" scope="row">
                            { products.length > 0 && 
                            <AutoCompleteProduct
                                dataProducts={products}
                                onChangeProduct={onChangeProduct}
                                index={i}
                                default_value={row.name}
                            /> }
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Select
                                placeholder="Select Type"
                                options={optionsDiscount && optionsDiscount}
                                isSearchable={true}
                                value={row.discount_type}
                                onChange={(e) => onChangeSelectType(i, e)}
                                id={`select-style-warehouse-product-${i}`}
                            />
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <TextField 
                                placeholder='15'
                                size="small"
                                name="discount_value"
                                type="text"
                                onChange={(e) => onChangeValueDiscount(i, e, row.discount_type)}
                                value={parseFloat(row.discount_value).toLocaleString()}
                                fullWidth
                                inputProps={{ min: 0 }}
                                required
                            />
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <ButtonAction onClick={() => onClickRemoveItem(i)}/>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table> 
            </TableContainer> : "You can add discount to specific product."}
        </Box> }
    </Box>
  );
}

const optionsDiscount = [
    { label : "PERCENTAGE", value : "PERCENTAGE" },
    { label : "AMOUNT", value : "AMOUNT" },
    { label : "SPECIAL", value : "SPECIAL" }
]

export default ProductsField