import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Forgot from '../features_app/auth/forgot/Forgot';
import BuyerLists from '../features_app/dashboard/buyer_lists/BuyerLists';
import ChatPage from '../features_app/dashboard/chat/ChatPage';
import ProfilePage from '../features_app/dashboard/profile/ProfilePage';
import StepRegister from '../features_app/dashboard/step_register/StepRegister';
import Error404 from '../features_app/error404/Error404';
import DashboardLayout from '../utilities/DashboardLayout';
import MainProductPage from '../features_app/dashboard/products/MainProductPage';
import SelectCompany from '../features_app/dashboard/select_company/SelectCompany';
import MainHomePage from '../features_app/dashboard/home/MainIHomePage';
import ChartsPage from '../features_app/dashboard/reports/charts/ChartsPage';
import ChartsDownload from '../features_app/dashboard/reports/download/ChartsDownload';
import InvitationPage from '../features_app/dashboard/invitation/InvitationPage';
import SignInPage from '../features_app/auth/signin/signin';
import SignUpPage from '../features_app/auth/signup/signup';
import MainPageWarehouse from '../features_app/dashboard/warehouse/MainPageWarehouse';
import ProductDetail from '../features_app/dashboard/products/ProductsDetail';
import PageFulfillment from '../features_app/dashboard/fulfillment/PageFulfillment';
import MainPageInvoice from '../features_app/dashboard/finance/invoices/MainPageInvoice';
import TeamsPage from '../features_app/dashboard/teams/TeamsPage';
import PageTokenCredentials from '../features_app/dashboard/token_credentials/PageTokenCredentials';
import RootAdmin from '../features_app/auth/root/RootAdmin';
import MainRolePage from '../features_app/dashboard/roles/MainRolePage';
import MediaCenter from '../features_app/dashboard/media_center/MediaCenterPage';
import ExtensionsPage from '../features_app/dashboard/extensions/ExtensionsPage';

function AppRoute() {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={SignInPage} />
          <Route exact path="/root-access" component={RootAdmin} />
          <Route exact path="/register" component={SignUpPage} />
          <Route exact path="/forgot-password" component={Forgot} />
          <DashboardLayout exact path="/company-registration" component={StepRegister} />
          <DashboardLayout exact path="/login/select-company" component={SelectCompany} />
          <DashboardLayout exact path="/dashboard" component={MainHomePage} />
          <DashboardLayout exact path="/dashboard/orders" component={PageFulfillment} />
          <DashboardLayout exact path="/dashboard/teams" component={TeamsPage} />
          <DashboardLayout exact path="/dashboard/products" component={MainProductPage} />
          <DashboardLayout exact path="/dashboard/detail-product/:slug" component={ProductDetail} />
          <DashboardLayout exact path="/dashboard/profile" component={ProfilePage} />
          <DashboardLayout exact path="/form/application" component={ProfilePage} />
          <DashboardLayout exact path="/dashboard/custom-role" component={MainRolePage} />
          <DashboardLayout exact path="/dashboard/finance/invoice" component={MainPageInvoice} />
          <DashboardLayout exact path="/dashboard/message" component={ChatPage} />
          <DashboardLayout exact path="/dashboard/warehouse" component={MainPageWarehouse} />
          <DashboardLayout exact path="/dashboard/extensions" component={ExtensionsPage} />
          <DashboardLayout exact path="/dashboard/invitation" component={InvitationPage} />
          <DashboardLayout exact path="/dashboard/buyer-list" component={BuyerLists} />
          <DashboardLayout exact path="/dashboard/report-charts" component={ChartsPage} />
          <DashboardLayout exact path="/dashboard/report-download" component={ChartsDownload} />
          <DashboardLayout exact path="/dashboard/token" component={PageTokenCredentials} />
          <DashboardLayout exact path="/dashboard/media-center" component={MediaCenter} />
          <Route exact path="*" component={Error404} />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default AppRoute;
