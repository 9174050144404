import { useState, useEffect } from 'react';
import { Stack, Box, TextField } from '@mui/material';
import BreadCrumbs from '../../../components/BreadCrumbs'
import HeadlineTitle from '../../../components/HeadlineTitle';
import ButtonCreate from '../../../components/ButtonCreate';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import SnackBarAlert from '../../../components/SnackBarAlert';
import { clearMessageRole } from './redux/rolesSlice';
import ModuleItems from './components/ModuleItems';
import swal from 'sweetalert';
import { createNewCustomRoles } from './redux/rolesReducers';
import StarRequired from '../../../components/StarRequired';
import { useHistory } from 'react-router-dom';
import LoaderBackdrop from '../../../components/LoaderBackdrop';


const CreateRoles = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const { create, loading_create } = useSelector((state : RootState) => state.roles)
    const [roleName, setRoleName] = useState("");
    const [moduleItems, setModuleItems] = useState<any>([]);

    const onSubmit = (e:any) => {
      e.preventDefault()
      if(moduleItems.length <= 1) {
          swal('Error', "Please at least add one more navigation!", "error")
      } else {
          let module_array : any = [...moduleItems]
          let array_send = []
          for(let element of module_array) {
            if(element.selectedParent.value !== undefined) {
              let array_childs = []
              let array_features = []
              if(element.selectedChild.length !== 0) {
                  for(let i of element.selectedChild) {
                      array_childs.push(i.value)
                  }
              }
              if(element.selectedFeatures.length !== 0) {
                  for(let i of element.selectedFeatures) {
                      array_features.push(i.value)
                  }
              }
              array_send.push({
                  navigation: element.selectedParent.value,
                  features: array_features,
                  child : array_childs,
              })
            }
          }
          let body_send = {
              name : roleName,
              default: false,
              navigation_roles : array_send,
              platform : "BUYER",
              
          }
          dispatch(createNewCustomRoles(body_send))
      }
    }

    useEffect(() => {
        if(create) {
            setTimeout(() => {
                history.push('/dashboard/custom-role')
                dispatch(clearMessageRole())
            }, 1500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create]);

    return (
        <Box m={2}>
            <LoaderBackdrop loading={loading_create} />
            <BreadCrumbs
                isPage={true}
                current="Add Custom Roles"
                page='Custom Roles'
                link='/dashboard/custom-role'
            />
            <Stack direction="row" justifyContent="space-between" py={1} alignItems="center" flexWrap="wrap" >
                <Box>
                    <HeadlineTitle title='Add Custom Roles' variant="h6" bold="bold" />
                </Box>

                <Box>
                    <ButtonCreate 
                        name="Back to Roles"
                        onClick={() => history.push('/dashboard/custom-role')}
                        outlined={true}
                    />
                </Box>
            </Stack>

            
            <Box >
            <form onSubmit={onSubmit}>
                <Box >
                    <Box >
                        <Box pt={1}><h4>Role Name <StarRequired/></h4></Box>
                        <TextField
                        margin="dense"
                        fullWidth
                        id="name"
                        placeholder="Warehouse Manager"
                        name="name"
                        size='small'
                        onChange={(e) => setRoleName(e.target.value)}
                        value={roleName}
                        required
                        />
                        <Box mt={2} >
                        <ModuleItems 
                            moduleItems={moduleItems}
                            setModuleItems={setModuleItems}
                            type={"Add"}
                        />
                        </Box>
                    </Box> 
                </Box>
                <Box my={1} sx={{width: 'fit-content'}}>
                    <ButtonCreate 
                        name="Submit Role"
                        type="submit"
                    />
                </Box>
                </form>
            </Box> 
            <SnackBarAlert message={`New Custom Role is successfuly created`} initialState={create} />
        </Box>
    )
}
  


export default CreateRoles
