import { Stack } from '@mui/material'
import { useLocation } from 'react-router-dom';
import CreateRoles from './CreateRoles';
import EditRoles from './EditRoles';
import RolesPage from './RolesPage';

function MainRolePage() {

    const location : any = useLocation()
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    
    let query = useQuery();
    let active_page = query.get('page')
    
    const switchPage = (page:any) => {
        switch (page) {
        case "create":
            return <CreateRoles/>
        case "edit":
            return <EditRoles/>
        default:
            return <RolesPage/>
        }
    }
 
    return (
        <Stack>
            {switchPage(active_page)}
        </Stack>
    )
}

export default MainRolePage
