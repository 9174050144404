import { useEffect, useState } from 'react';
import { 
    Button, 
    Dialog,
    Stack,
    Box
 } from '@mui/material';
 import Select from 'react-select'
 import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import imagebtn from '../../../../assets/img/icon/excel.png' 
import imgxls from '../../../../assets/img/icon/xls.png'
import { uploadBulkProduct } from '../reducers/productsReducers';
import StarRequired from '../../../../components/StarRequired';

 const Input = styled('input')({
    display: 'none',
  });

const BulkUpload :React.FC<any> = ({
    openUpload, setOpenUpload, warehouse, 
}) => {
    const dispatch = useDispatch();
    const { loading_bulk_product, bulk_product } = useSelector((state : RootState) => state.products)

    const [fileExcel, setFileExcel] = useState<any>(null);
    const [nextStep, setNextStep] = useState(false);
    const [selectedWarehouse, setSelectedWarehouse] = useState<any>([]);
    const [selectedType, setSelectedType] = useState<any>([]);

    const handleChangeWarehouse = (value: any) : void => {
        setSelectedWarehouse(value)
        if(selectedType.length !== 0){
            setNextStep(true)
        }
    }

    const handleChangeType = (value: any) : void => {
        setSelectedType(value)
        if(selectedWarehouse.length !== 0) {
            setNextStep(true)
        } 
    }

    const onChangeValueFile = (e : any) => {
        setFileExcel(e.target.files)
    }

    const [valueSteps, setValueSteps] = useState<number>(0);

    const onClickNext = () => {
        setValueSteps(1)
    }

    const onClickBack = () => {
        setValueSteps(0)
        setFileExcel(null)
    }

    const onClickSubmit = (e : any) => {
        let data_send = {
            file : fileExcel,
            warehouse : selectedWarehouse.value,
            type : selectedType.value
        }
        dispatch(uploadBulkProduct(data_send))
    }

    function onCloseDialog () {
        setValueSteps(0) 
        setOpenUpload(false)
        setNextStep(false)
        setFileExcel(null)
        setSelectedType([])
        setSelectedWarehouse([])
    }

    useEffect(() => {
        if(bulk_product){
            onCloseDialog()
        }
        // eslint-disable-next-line
    }, [bulk_product]);

    return (
    <div>
        <Dialog open={openUpload} maxWidth="md" fullWidth={true}  >
            <Stack flexDirection="row" justifyContent="end">
                <Box p={2} 
                    onClick={() => onCloseDialog()}>
                    <CancelPresentationIcon sx={{color:'red', cursor:'pointer'}} />
                </Box>
            </Stack>
            <Stack sx={{ px:3 }}>
                <Stepper activeStep={valueSteps} sx={{display:'flex', flexWrap: 'wrap', rowGap: 1}}>
                    {steps.map((label) => (
                        <Step key={label} >
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box py={3} px={1}>
                    {   valueSteps === 0 ? 
                        <Stack sx={{ minHeight: 400 }}>
                            <Box pb={1} fontWeight="500">Upload Type <StarRequired/></Box>
                            <Select
                                placeholder="Select Type"
                                value={selectedType}
                                options={options_type && options_type}
                                onChange={handleChangeType}
                                id={`select-style-searchby`}
                            />
                            <Box pt={2}  pb={1} fontWeight="500">Warehouse <StarRequired/></Box>
                            <Select
                                placeholder="Select Warehouse"
                                value={selectedWarehouse}
                                options={warehouse && warehouse}
                                onChange={handleChangeWarehouse}
                                id={`select-style-searchby`} 
                            />
                        </Stack> :
                        <Stack sx={{ minHeight: 400 }}>
                            <Box pb={1} fontWeight="500">Excel File <StarRequired/></Box>
                            <Box>
                            { fileExcel === null ? 
                            <Stack>
                                <label htmlFor="icon-button-file" >
                                    <Stack className='upload-area-bulk'>
                                        <Input 
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
                                            id="icon-button-file" type="file" 
                                            onChange={(e) => onChangeValueFile(e)} 
                                        />

                                        <img src={imagebtn} alt="icon"/>
                                        <Box pt={1}>Browse Excel File </Box>
                                    </Stack>
                                </label>
                            </Stack> : 
                            <Stack className='file-area-bulk'>
                                <img src={imgxls} alt="icon"/>
                                <Box> {fileExcel[0].name}</Box> 
                                <Box pt={1}>
                                    <Button
                                        variant="outlined"
                                        color='warning'
                                        size="small"
                                        onClick={() => setFileExcel(null)}
                                    >Change File</Button>
                                </Box>
                            </Stack> }
                            </Box>
                        </Stack>
                    }
                </Box>
                <Box py={2} px={1}>
                    <Stack flexDirection="row" justifyContent="space-between">
                        <Box>
                            <Button
                                variant="outlined"
                                color='primary'
                                size="small"
                                disabled={valueSteps === 0 || loading_bulk_product ? true : false}
                                onClick={onClickBack}
                            >Back</Button>
                        </Box>
                        <Box>
                            { valueSteps === 0 ? 
                            <Button
                                variant="contained"
                                color='primary'
                                size="small"
                                onClick={onClickNext}
                                disabled={!nextStep}
                            >Next</Button> :
                            <Button
                                variant="contained"
                                color='primary'
                                size="small"
                                onClick={onClickSubmit}
                                disabled={fileExcel === null || loading_bulk_product ? true : false }
                            >{ loading_bulk_product ? "Loading.." : "Submit" }</Button> }
                        </Box>
                    </Stack>
                </Box>
            </Stack>
            
        </Dialog>
    </div>
  )
}

const options_type = [
    {value: "creation", label: "Bulk Creation"},
    {value: "update-full", label: "Bulk Update All"},
    {value: "update-stock", label: "Bulk Update Stock"},
    {value: "update-price", label: "Bulk Update Price"}
]

const steps = [
    'Select Upload Type & Warehouse',
    'Upload File',
]

export default BulkUpload