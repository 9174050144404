import {
    Container,
    CssBaseline,
    Stack,
    Box
} from '@mui/material';
import notfound from '../../assets/img/notfound.png'
import forbidden from '../../assets/img/forbidden.png'

import logo from '../../assets/img/logo-color-p.png'
import ButtonCreate from '../../components/ButtonCreate';
import { userCredentials } from '../../utilities/config';
import { useLocation } from 'react-router-dom';
import { all_route } from '../../utilities/getNavigation';

const Error404 = () => {

  const location = useLocation()

  function toCheckNavigationsPermition (pathname:any) {
    let access = false
    if(pathname.state !== null && pathname.state !== undefined) {
      if(all_route.find((ele:any) => ele === pathname.state.from.pathname)) {
        access = true
      } else {
        access = false
      }
    }
    return access
  }

    return (
      <div className="container-box-auth">
        <Container component="main" maxWidth="xs">
          <CssBaseline />
            <Stack sx={{ 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Box>
                    <a href='/'>
                        <img src={logo} alt="logo" width="180px" /> 
                    </a>
                </Box>
                { toCheckNavigationsPermition(location) ? 
                <div className="img-notfound"> 
                  <img alt="notfound" src={forbidden} />
                  <h5>Sorry you dont have a permission to view this page. Contact your owner to add this page permisson to your roles.</h5>
                </div> :
                <div className="img-notfound"> 
                  <img alt="notfound" src={notfound} />
                </div> }
                <div className="text-notfound">
                  <ButtonCreate 
                    name={ userCredentials !== null ? "Back to Dashboard" : "Back to Home"}
                    onClick={() => window.location.href = userCredentials !== null ? '/dashboard' : '/'}
                  />
                </div>
                
            </Stack>
        </Container>
      </div>
    )
}

export default Error404;
