import { useState, useEffect } from 'react';
import { Skeleton, Stack, Box, TextField } from '@mui/material';
import BreadCrumbs from '../../../components/BreadCrumbs'
import HeadlineTitle from '../../../components/HeadlineTitle';
import ButtonCreate from '../../../components/ButtonCreate';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import SnackBarAlert from '../../../components/SnackBarAlert';
import { clearMessageRole } from './redux/rolesSlice';
import ModuleItems from './components/ModuleItems';
import swal from 'sweetalert';
import { getDetailCustomRoles, updateExistingCustomRoles } from './redux/rolesReducers';
import StarRequired from '../../../components/StarRequired';
import { useHistory, useLocation } from 'react-router-dom';


const EditRoles = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const location : any = useLocation()
    const id_roles :any = location.state.id


    const { update, loading_update, detail, loading_detail } = useSelector((state : RootState) => state.roles)
    const [roleName, setRoleName] = useState("");
    const [moduleItems, setModuleItems] = useState<any>([]);

    const onSubmit = (e:any) => {
      e.preventDefault()
      if(moduleItems.length <= 1) {
          swal('Error', "Please at least add one more navigation!", "error")
      } else {
          let module_array : any = [...moduleItems]
          let array_send = []
          for(let element of module_array) {
            if(element.selectedParent.value !== undefined) {
              let array_childs = []
              let array_features = []
              if(element.selectedChild.length !== 0) {
                  for(let i of element.selectedChild) {
                      array_childs.push(i.value)
                  }
              }
              if(element.selectedFeatures.length !== 0) {
                  for(let i of element.selectedFeatures) {
                      array_features.push(i.value)
                  }
              }
              array_send.push({
                  navigation: element.selectedParent.value,
                  features: array_features,
                  child : array_childs,
              })
            }
          }
            let body_update = {
                body : {
                    name : roleName,
                    default: false,
                    navigation_roles : array_send,
                    platform : "BUYER"
                },
                id : id_roles
            }
            dispatch(updateExistingCustomRoles(body_update))
      }
    }

    function isNotEmpty(obj:any) {
      for(var key in obj) {
        if(obj.hasOwnProperty(key))
          return true;
      }
      return false;
    }


    const proceedOptionsFeatures = (data : any) => {
      let data_options = []
      for(let element of data) {
          data_options.push({ value: element._id, label: element.name })
      }
      return data_options
    }

    const proceedOptionsChild = (data : any) => {
        let data_options = []
        for(let element of data) {
            data_options.push({ value: element._id, label: element.name })
        }
        return data_options
    }

    const proceedOptionsParent = (data : any) => {
        let data_options = []
        for(let element of data) {
            data_options.push({ value: element._id, label: element.name, childs : element.childs })
        }
        return data_options
    }

    const onUpdateRoles = (row:any) => {
      let array_modules = []
      for(let element of row) {
          array_modules.push({
              selectedParent : { value : element.navigation._id, label: element.navigation.name  },
              selectedChild : element.child ? proceedOptionsChild(element.child) : [],
              selectedFeatures: proceedOptionsFeatures(element.features),
              optionFeatures : proceedOptionsParent(element.features_sibling),
              optionsChild : proceedOptionsParent(element.child_sibling),
              has_child_module : element.child.length > 0 ? true : false,
              mandatory: true,
          })
      }
      setModuleItems(array_modules)
      
    }

    useEffect(() => {
      if(id_roles !== undefined || id_roles !== null) {
        dispatch(getDetailCustomRoles(id_roles))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id_roles]);

    useEffect(() => {
      if(isNotEmpty(detail)) {
        onUpdateRoles(detail.hasNavigation)
        setRoleName(detail.name)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    
    useEffect(() => {
        if(update) {
            setTimeout(() => {
                history.push('/dashboard/custom-role')
                dispatch(clearMessageRole())
            }, 1500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update]);

    return (
        <Box m={2}>
            <LoaderBackdrop loading={loading_update} />
            <BreadCrumbs
                isPage={true}
                current="Edit Custom Roles"
                page='Custom Roles'
                link='/dashboard/custom-role'
            />
            <Stack direction="row" justifyContent="space-between" py={1} alignItems="center" flexWrap="wrap" >
                <Box>
                    <HeadlineTitle title='Edit Custom Roles' variant="h6" bold="bold" />
                </Box>

                <Box>
                    <ButtonCreate 
                        name="Back to Roles"
                        outlined={true}
                        onClick={() => history.push('/dashboard/custom-role')}
                    />
                </Box>
            </Stack>

            
            <Box >
            <form onSubmit={onSubmit}>
                <Box >
                    { loading_detail ?
                    <LoaderView />
                    :
                    <Box >
                        <Box pt={1}><h4>Role Name <StarRequired/></h4></Box>
                        <TextField
                            margin="dense"
                            fullWidth
                            id="name"
                            placeholder="Warehouse Manager"
                            name="name"
                            size='small'
                            onChange={(e) => setRoleName(e.target.value)}
                            value={roleName}
                            required
                        />
                        <Box mt={2} >
                        <ModuleItems 
                            moduleItems={moduleItems}
                            setModuleItems={setModuleItems}
                            type={"Edit"}
                        />
                        </Box>
                    </Box> }
                </Box>
                <Box my={1} sx={{width: 'fit-content'}}>
                    <ButtonCreate 
                        name="Save Role"
                        type="submit"
                    />
                </Box>
                </form>
            </Box> 
            <SnackBarAlert message={`New Custom Role is successfuly created`} initialState={update} />
        </Box>
    )
}

const LoaderView = () => {
    return (
      <div>
        <Skeleton variant='text' height={75} />
        <Box my={0.5} />
        <Skeleton variant='text' height={60} />
        <Skeleton variant='text' height={40} />
        <Skeleton variant='text' height={40} />
        <Skeleton variant='text' height={40} />
        <Skeleton variant='text' height={40} />
        <Skeleton variant='text' height={40} />
        <Skeleton variant='text' height={40} />
        <Skeleton variant='text' height={40} />
        <Skeleton variant='text' height={40} />
        <Skeleton variant='text' height={40} />
      </div>
    )
  }
  


export default EditRoles
