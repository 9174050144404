import { createSlice } from '@reduxjs/toolkit';
import { createCalculateInvoice, createNewInvoice, getAccountReceivable, getFulfillmentInvoice, getInvoiceDetail, getInvoiceDetailByPackage, 
  getInvoiceNumber, getInvoicesData, getStatistivInvoice, getTotalSales, printDownloadInvPDF, submitConfirmPaymentInvoice 
} from './invoicesReducers';
import { errorSweetAlert } from '../../../../../utilities/errorhandling';

// funtion to check local storage data_invoice
const checkLocalStorage = () => {
  let data_invoice = {};
  if(localStorage.getItem('data_invoice') !== null) {
    data_invoice = JSON.parse(localStorage.getItem('data_invoice') || '{}');
  }
  return data_invoice;
}


const initialState: any = {
  invoices : [], 
  total_invoices : 0,
  loading_invoices : false,
  invoice_detail : null,
  loading_invoice_detail : false,
  invoice_number : null,
  loading_invoice_number : false,
  create_invoice : false,
  loading_create_invoice : false,
  open_invoice_data : checkLocalStorage(),
  open_invoice : false,
  loading_calculate_inv : false,
  approve_payment: false,
  loading_approve_payment: false,
  receivable : [],
  loading_receivable : false,
  statistic_inv : {},
  loading_statistic_inv : false,
  download_inv : "",
  loading_download_inv : false,
  fulfilment_invoice : [],
  total_fulfilment_invoice : 0,
  loading_fulfilment_invoice : false,
  total_sales : 0,
  loading_total_sales : false
}; 

export const invoiceSlices = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    createOpenInvoice: (state, action) => {
      localStorage.setItem('data_invoice', JSON.stringify(action.payload))
      state.open_invoice_data = action.payload;
      state.open_invoice = true;
    },
    closeOpenInvoice: (state) => {
      state.open_invoice = false;
    },
    clearMessageInvoice: (state) => {
      state.create_invoice = false;
    },
    clearDownloadPdf: (state) => {
      state.download_inv = "";
    }
  },
  extraReducers: (builder) => {
    builder
      // get invoices data
      .addCase(getInvoicesData.pending, (state) => {
        state.loading_invoices = true;
        state.detail = {};
        state.approve_payment = false
      })
      .addCase(getInvoicesData.fulfilled, (state, action:any) => {
        state.loading_invoices = false;
        state.invoices = action.payload.data.length > 0 ? action.payload.data : [];
        state.total_invoices = action.payload.data.length > 0 ? action.payload.total.row : 0;
      })
      .addCase(getInvoicesData.rejected, (state, action : any) => {
        state.loading_invoices = false;
        errorSweetAlert(action.payload)
      })

      // get fulfillment invoice ready
      .addCase(getFulfillmentInvoice.pending, (state) => {
        state.loading_fulfilment_invoice = true;
      })
      .addCase(getFulfillmentInvoice.fulfilled, (state, action:any) => {
        state.loading_fulfilment_invoice = false;
        state.fulfilment_invoice = action.payload.data.length > 0 ? action.payload.data : [];
        state.total_fulfilment_invoice = action.payload.data.length > 0 ? action.payload.total : 0;
      })
      .addCase(getFulfillmentInvoice.rejected, (state, action : any) => {
        state.loading_fulfilment_invoice = false;
        errorSweetAlert(action.payload)
      })

      // get status
      .addCase(getInvoiceNumber.pending, (state) => {
        state.loading_invoice_number = true;
      })
      .addCase(getInvoiceNumber.fulfilled, (state, action:any) => {
        state.loading_invoice_number = false;
        state.invoice_number = action.payload
      })
      .addCase(getInvoiceNumber.rejected, (state, action : any) => {
        state.loading_invoice_number = false;
        errorSweetAlert(action.payload)
      })

       // get invoices detail
      .addCase(getInvoiceDetail.pending, (state) => {
        state.loading_invoice_detail = true;
        state.invoice_detail = null
      })
      .addCase(getInvoiceDetail.fulfilled, (state, action:any) => {
        state.loading_invoice_detail = false;
        state.invoice_detail = action.payload
        state.open_invoice_data = action.payload
        state.open_invoice = true;
      })
      .addCase(getInvoiceDetail.rejected, (state, action : any) => {
        state.loading_invoice_detail = false;
        errorSweetAlert(action.payload)
      })

       // get invoices detail by package
      .addCase(getInvoiceDetailByPackage.pending, (state) => {
        state.loading_invoice_detail = true;
        state.invoice_detail = null
      })
      .addCase(getInvoiceDetailByPackage.fulfilled, (state, action:any) => {
        state.loading_invoice_detail = false;
        state.invoice_detail = action.payload
        state.open_invoice_data = action.payload
        state.open_invoice = true;
      })
      .addCase(getInvoiceDetailByPackage.rejected, (state, action : any) => {
        state.loading_invoice_detail = false;
        errorSweetAlert(action.payload)
      })

       // craete invoice
      .addCase(createCalculateInvoice.pending, (state) => {
        state.loading_calculate_inv = true;
        state.open_invoice = false
      })
      .addCase(createCalculateInvoice.fulfilled, (state, action:any) => {
        localStorage.setItem('data_invoice', JSON.stringify(action.payload))
        state.open_invoice_data = action.payload;
        state.open_invoice = true;
        state.loading_calculate_inv = false;
      })
      .addCase(createCalculateInvoice.rejected, (state, action : any) => {
        state.loading_calculate_inv = false;
        errorSweetAlert(action.payload)
      })

       // calcultae invoice
      .addCase(createNewInvoice.pending, (state) => {
        state.loading_create_invoice = true;
        state.create_invoice = false;
      })
      .addCase(createNewInvoice.fulfilled, (state, action:any) => {
        state.loading_create_invoice = false;
        state.create_invoice = action.payload
        localStorage.removeItem('data_invoice')
      })
      .addCase(createNewInvoice.rejected, (state, action : any) => {
        state.loading_create_invoice = false;
        errorSweetAlert(action.payload)
      })

       // approve payment
      .addCase(submitConfirmPaymentInvoice.pending, (state) => {
        state.loading_approve_payment = true;
        state.approve_payment = false;
      })
      .addCase(submitConfirmPaymentInvoice.fulfilled, (state, action:any) => {
        state.loading_approve_payment = false;
        state.approve_payment = action.payload
      })
      .addCase(submitConfirmPaymentInvoice.rejected, (state, action : any) => {
        state.loading_approve_payment = false;
        errorSweetAlert(action.payload)
      })

       // get receivable
      .addCase(getAccountReceivable.pending, (state) => {
        state.loading_receivable = true;
      })
      .addCase(getAccountReceivable.fulfilled, (state, action:any) => {
        state.loading_receivable = false;
        state.receivable = action.payload
      })
      .addCase(getAccountReceivable.rejected, (state, action : any) => {
        state.loading_receivable = false;
        errorSweetAlert(action.payload)
      })

       // statuistik invoice
      .addCase(getStatistivInvoice.pending, (state) => {
        state.loading_statistic_inv = true;
      })
      .addCase(getStatistivInvoice.fulfilled, (state, action:any) => {
        state.loading_statistic_inv = false;
        state.statistic_inv = action.payload
      })
      .addCase(getStatistivInvoice.rejected, (state, action : any) => {
        state.loading_statistic_inv = false;
        errorSweetAlert(action.payload)
      })

      // download pdf
      .addCase(printDownloadInvPDF.pending, (state) => {
        state.loading_download_inv = true;
        state.download_inv = "";
      })
      .addCase(printDownloadInvPDF.fulfilled, (state, action:any) => {
        state.loading_download_inv = false;
        state.download_inv = action.payload
      })
      .addCase(printDownloadInvPDF.rejected, (state, action : any) => {
        state.loading_download_inv = false;
        errorSweetAlert(action.payload)
      })

      // statuistik paid invoice
      .addCase(getTotalSales.pending, (state) => {
        state.loading_total_sales = true;
      })
      .addCase(getTotalSales.fulfilled, (state, action:any) => {
        state.loading_total_sales = false;
        state.total_sales = action.payload
      })
      .addCase(getTotalSales.rejected, (state, action : any) => {
        state.loading_total_sales = false;
        errorSweetAlert(action.payload)
      })

  },
});

export const { createOpenInvoice, closeOpenInvoice, clearMessageInvoice, clearDownloadPdf } = invoiceSlices.actions;

export default invoiceSlices.reducer;
