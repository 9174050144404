import { Box } from '@mui/material'
import React from 'react'

const ButtonActions :React.FC<any> = ({ name, onClick, disabled, outlined, color, type }) => {
  
  return (
    <Box className={`button-action ${outlined && 'btn_outlined'} ${color && `btn_${color}` } `}>
        <button onClick={onClick} disabled={disabled} type={type || 'button'}>
            <p>{name}</p>
        </button>
    </Box>
  )
}

export default ButtonActions