import {  Box, Skeleton } from '@mui/material'
import React from 'react'


const CardStatistic : React.FC<any> = ({
    label, value, currency, status, loading, color, src
}) => {
    return (
        <div className='card-paper'>
                { status ?
                <Box className="card-statistic" sx={{ color: color }}>
                    <div><h4>{label}</h4></div>
                    { loading ?
                    <Skeleton height={40} width={30} /> :
                    <div><h1>{value}</h1></div> }
                </Box>
                :
                <Box p={2} className="card-info">
                    <div>
                        <h4>{label}</h4>
                        { loading ?
                            <Skeleton height={42} />
                        :
                        <div>
                        {!currency ? 
                            <h3>{value}</h3> :
                            <h3>Rp. {parseInt(value).toLocaleString()}</h3>
                        }
                        </div> }
                    </div>
                    <div>
                        <img src={src} alt="icon" />
                    </div>
                </Box>
                }
        </div>
    )
}

export default CardStatistic
