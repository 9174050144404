import { Box, Grid, Switch } from '@mui/material'
import React from 'react'
import store from '../../../../assets/img/icon/store.png'
import { checkValidation } from '../../../../utilities/userFeaturesValidation';
import { useDispatch } from 'react-redux';
import { updateIsActiveWarehouse } from '../reducers/warehouseReducers';

const CardWarehouse : React.FC<any> = ({ item, onClickDetail, onClickUpdate, onClickUpdateDefault }) =>  {
    const dispatch = useDispatch()
    const [checked, setChecked] = React.useState((item.isActive && item.isActive) || false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
      let params = {
            id : item._id,
            body : {
                isActive : event.target.checked
            }
      }
      dispatch(updateIsActiveWarehouse(params))
    };

    return (
    <Box className={`container-item-preferred`} sx={{ border: `2px solid ${!checked ? 'gray' : 'green'}` }}>
        <Box className="center-div">
            <Grid container spacing={1}>
                <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                    <Box className='left-box'>
                        <Box className='store'>
                            <img src={item.imageUrl || store} alt="store" />
                            <Box className='name'>
                                <h2>{item.name}</h2>
                                { item.isDefault ?
                                <Box className='status' sx={{  backgroundColor : '#43a047', width: 'fit-content' }}>
                                    <h6>Default Warehouse</h6>
                                </Box> :
                                <Box 
                                    className='status' sx={{  backgroundColor : '#fb8500', cursor: 'pointer' }} 
                                    onClick={() => onClickUpdateDefault(item._id)}
                                >
                                    <h6>Change to Default</h6>
                                </Box> }
                            </Box>
                        </Box>
                        <Box className='address'>
                            <h2>Address</h2> 
                            <p>{(item.address && item.address.street) || "-"}, {(item.address && item.address.city.toLowerCase()) || "-"}</p>
                            <p>{(item.address && item.address.province )|| "-"}, {(item.address && item.address.country) || ""}, {(item.address && item.address.postcode) || "-"}</p>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        <Box className='bottom-div'>
            <Box className='left-div' />
            <Box className='right-div'>
                <Box pr={2}>
                    <span>{checked ? "Open" : "Closed"} </span>
                    <Switch
                        checked={checked}
                        onChange={handleChange} 
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Box>
                { checkValidation('W-U-001') &&
                <Box className='btn-confirm'>
                    <button 
                        onClick={() => onClickUpdate(item)}

                    >
                        <p>Edit</p>
                    </button>
                </Box> }
                <Box className='btn-confirm' >
                    <button 
                        onClick={() => onClickDetail(item)}

                    >
                        <p>View Detail</p>
                    </button>
                </Box>
            </Box>
        </Box> 

    </Box>
  )
}

export default CardWarehouse