import { 
    Box,
    Stack,
    Paper,
    Button,
    Grid,
    TextField
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Select from 'react-select'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import TooltipHTML from '../../../../components/TooltipHTML';
import { remove } from 'lodash'
import warehouseimg from '../../../../assets/img/icon/warehouse.png'
import required from '../../../../assets/img/icon/required.png';
import swal from 'sweetalert';

const optionsDiscount = [
    { label : "Percentage", value : "PERCENTAGE" },
    { label : "Amount", value : "AMOUNT" },
    { label : "Special", value : "SPECIAL" }
]

const WarehouseItems : React.FC<any> = ({
    warehouseItems,
    setWarehouseItems,
    setErrorWarehouse,
    errorWarehouse
}) => {
    const { warehouse, loading_warehouse } = useSelector((state : RootState) => state.masters)

    const onChangeValue = (index : any, value : any) => {
        let copy_array = [...warehouseItems]
        const newData = copy_array.map((obj : any, i : any) => {
            if(i === index)
            return {
                ...obj,
                [value.target.name]: value.target.value,
            } 
            return obj
        }); 
        setWarehouseItems(newData)
    }

    const onChangeValuePrice = (index : any, e : any) => {
        let copy_array = [...warehouseItems]
        const { name, value } = e.target 
        const newData = copy_array.map((obj : any, i : any) => {
            if(i === index)
            return {
                ...obj,
                [name]: value === isNaN || value === "" ? 0 : value.replace(/,/g, ""),
                error_retail_price : false
            }
            return obj
        }); 
        setWarehouseItems(newData)
    }

    const onChangeValueStock = (index : any, e : any) => {
        let copy_array = [...warehouseItems]
        const { name, value } = e.target 
        const newData = copy_array.map((obj : any, i : any) => {
            if(i === index)
            return {
                ...obj,
                [name]: value === isNaN || value === "" ? 0 : parseFloat(value),
                error_stock : false
            }
            return obj
        }); 
        setWarehouseItems(newData)
    }

    const onChangeValueDiscount = (index : any, e : any, type : any) => {
        let copy_array = [...warehouseItems]
        const { name, value } = e.target 
        if(type.label === "Percentage"){
            if(value > 100) {
                swal('Error', "Percentage can't be more than 100!", 'error')
            } else {
                const newData = copy_array.map((obj : any, i : any) => {
                    if(i === index)
                    return {
                        ...obj,
                        [name]: value === isNaN || value === "" ? 0 : parseFloat(value),
                    }
                    return obj
                }); 
                setWarehouseItems(newData)
            }
        } else {
            const newData = copy_array.map((obj : any, i : any) => {
                if(i === index)
                return {
                    ...obj,
                    [name]: value === isNaN || value === "" ? 0 : value.replace(/,/g, ""),
                }
                return obj
            }); 
            setWarehouseItems(newData)
        }
    }


    const onChangeSelectWarehouse = (index : any, value : any, name : string) => {
        let copy_array = [...warehouseItems]
        const newData = copy_array.map((obj : any, i : any) => {
            if(i === index)
            return {
                ...obj,
                [name]: value,
                error_warehouse : false
            }
            return obj
        }); 
        setWarehouseItems(newData)
    }

    const onChangeSelectType = (index : any, value : any, name : string) => {
        let copy_array = [...warehouseItems]
        const newData = copy_array.map((obj : any, i : any) => {
            if(i === index)
            return {
                ...obj,
                [name]: value,
                discount_value : 0
            }
            return obj
        }); 
        setWarehouseItems(newData)
    }

    const onClickAddNewField = () => {
        let copy_item = [...warehouseItems]
        let new_object = {
            warehouse_select : {value: '', label: 'Select Warehouse'},
            error_warehouse : true,
            retail_price: "0",
            error_retail_price: true,
            stock : "0",
            error_stock: true,
            discount_type :  {label : "Percentage", value : "PERCENTAGE"},
            discount_value : "0",
            storage_rack : "",
            storage_bin : "",
            storage_level : "",
        }
        copy_item.push(new_object)
        setWarehouseItems(copy_item)
        setErrorWarehouse(false)
    }

    const onClickRemoveItem = (value : any) => {
        let copy_item = [...warehouseItems]
        const items_remove = remove(copy_item, function(obj : any, index : any) {
            return index !== value
        });
        setWarehouseItems(items_remove)
    }



  return (
    <div>
    <Paper elevation={2} sx={{p:3, borderRadius: 3}}>
        <Box py={1}>
            <Stack flexDirection="row" justifyContent="space-between">
                <Box pb={0.6} fontWeight="bold">
                    Product Warehouses <span><img src={required} width="10px" alt='icon'/></span>
                </Box>
                { warehouseItems.length === 0 ? null :
                <Box>
                    <Button 
                        variant='outlined' color="info" 
                        size='small'
                        onClick={onClickAddNewField}
                    >
                        Add Warehouse
                    </Button>
                </Box> }
            </Stack>

            { warehouseItems.length === 0 ? 
            <Box className='box-empty-warehouse'>
                <Box component="img" src={warehouseimg}></Box>
                <Box fontSize={13} py={1}> Please Add at least one Warehouse to your product.</Box>
                <Button 
                    variant='outlined' color="info" 
                    size='small'
                    onClick={onClickAddNewField}
                >
                    Add Warehouse
                </Button>
            </Box>
            :
            <Box>
                {warehouseItems.map((item:any, idx:any) => (
                <Box className='box-warehouse' key={idx}>
                    <Box className='icon-remove' onClick={() => onClickRemoveItem(idx)}>
                        <DeleteForeverIcon/>
                    </Box>
                    <Box py={1}>
                        <Box pb={0.6} fontSize={13} fontWeight="bold">
                            Warehouse <span><img src={required} width="7px" alt='icon'/></span>
                        </Box>
                        { loading_warehouse ?
                        <Select
                            placeholder="Loading..."
                        /> :
                        <Select
                            placeholder="Select Warehouse"
                            options={warehouse && warehouse}
                            isSearchable={true}
                            value={item.warehouse_select}
                            onChange={(e) => onChangeSelectWarehouse(idx, e, 'warehouse_select')}
                            id={`select-style-warehouse-${idx}`}
                        /> }
                        { item.error_warehouse ? <div className="error-p"><p>Warehouse is required</p></div> : null }
                    </Box>
                    <Box py={1}>
                        <Grid container spacing={1}>
                            <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
                                <Box pb={0.6} fontSize={13} fontWeight="bold">
                                    Retail Price <span><img src={required} width="7px" alt='icon'/></span>
                                </Box>
                                <TextField 
                                    placeholder='1250000'
                                    size="small"
                                    name="retail_price"
                                    onChange={(e) => onChangeValuePrice(idx, e)}
                                    value={parseFloat(item.retail_price).toLocaleString()}
                                    fullWidth
                                    type="text"
                                    inputProps={{ min: 0 }}
                                />
                                { item.error_retail_price ? <div className="error-p"><p>Retail Price is required</p></div> : null }
                            </Grid>
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                                <Box pb={0.6} fontSize={13} fontWeight="bold">
                                    Stock <span><img src={required} width="7px" alt='icon'/></span>
                                </Box>
                                <TextField 
                                    placeholder='1000'
                                    size="small"
                                    name="stock"
                                    onChange={(e) => onChangeValueStock(idx, e)} 
                                    value={item.stock}
                                    fullWidth
                                    type="text"
                                    inputProps={{ min: 0 }}
                                />
                                { item.error_stock ? <div className="error-p"><p>Stock is required</p></div> : null }
                            </Grid>
                        </Grid>
                    </Box>
                    <Box py={1}>
                        <Grid container spacing={1}>
                            <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                                <Stack flexDirection="row" alignItems="center">
                                    <Box pb={0.6} fontSize={13} fontWeight="bold">Discount Type</Box>
                                    <Box>
                                        <TooltipHTML/>
                                    </Box>
                                </Stack>
                                <Select
                                    placeholder="Select Type"
                                    options={optionsDiscount && optionsDiscount}
                                    isSearchable={true}
                                    value={item.discount_type}
                                    onChange={(e) => onChangeSelectType(idx, e, 'discount_type')}
                                    id={`select-style-warehouse-type-${idx}`}
                                />
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                <Box pb={0.6} fontSize={13} fontWeight="bold">
                                    Discount Value 
                                </Box>
                                <TextField 
                                    placeholder='15'
                                    size="small"
                                    name="discount_value"
                                    type="text"
                                    onChange={(e) => onChangeValueDiscount(idx, e, item.discount_type)}
                                    value={parseFloat(item.discount_value).toLocaleString()}
                                    fullWidth
                                    inputProps={{ min: 0 }}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box py={1}>
                        <Grid container spacing={1}>
                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                <Box pb={0.6} fontSize={13} fontWeight="bold">Rack</Box>
                                <TextField 
                                    placeholder='A'
                                    size="small"
                                    name="storage_rack"
                                    onChange={(e) => onChangeValue(idx, e)}
                                    value={item.storage_rack}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                <Box pb={0.6} fontSize={13} fontWeight="bold">Bin</Box>
                                <TextField 
                                    placeholder='B'
                                    size="small"
                                    name="storage_bin"
                                    onChange={(e) => onChangeValue(idx, e)}
                                    value={item.storage_bin}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                <Box pb={0.6} fontSize={13} fontWeight="bold">Level</Box>
                                <TextField 
                                    placeholder='10'
                                    size="small"
                                    name="storage_level"
                                    onChange={(e) => onChangeValue(idx, e)}
                                    value={item.storage_level}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                ))}
            </Box> }
        { errorWarehouse ? <div className="error-p"><p>Warehouse is required.</p></div> : null }
        </Box>
    </Paper>
    </div>
  );
}

export default WarehouseItems