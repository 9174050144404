import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {  Stack, Box, Skeleton } from '@mui/material';
import searchimg from '../../../../assets/img/icon/search1.png'
import removeimg from '../../../../assets/img/icon/close.png'
import EmptyData from '../../../../components/EmptyData';
import { useDispatch, useSelector } from 'react-redux';
import { resetSearchBuyer } from '../redux/buyerListsSlice';
import { postPreferredBuyerList, uploadBulkPreferedList } from '../redux/buyerListsReducer';
import { RootState } from '../../../../app/store';
import FormPreferred from './FormPreferred';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogCreation : React.FC<any> = ({ 
  open, setOpen, name, valueSearch,
  search_buyer, setValueSearch, loading_search,

}) => {

    const dispatch = useDispatch()
    
    const { 
      create, update, bulk_upload
    } = useSelector((state : RootState) => state.preferred)

    const [selectedTrigger, setSelectedTrigger] = useState<any>({
      value : "Manual",
      label : "Manual"
    });
    const [selectedPeriod, setSelectedPeriod] = useState<any>({
      value : "EOM",
      label : "End of Monthly"
    });

    const [errorTrigger, setErrorTrigger] = useState(false);
    const [errorPeriod, setErrorPeriod] = useState(false);

    const [bulkUpload, setBulkUpload] = useState(false);
    const [inputSearch, setInputSearch] = useState("");
    const [companySetting, setCompanySetting] = useState<any>({
      initial_discount : 0,
      payment_term : 0,
      product : [],
      down_payment : "",
      credit_limit_amount : 0,
      credit_limit_isActive : false,
      minimum_total_order_amount : 0,
      minimum_total_order_isActive : false,
      status : "ACTIVE",
    });

    const [productItems, setProductItems] = useState<any>([]);
    const [fileExcel, setFileExcel] = useState<any>(null);

    function resetData() {
      setProductItems([])
      setValueSearch("")
      setInputSearch("")
      setCompanySetting({
        ...companySetting,
        initial_discount : "",
        payment_term : "",
        product : [],
        status : "ACTIVE",
      })
    }

    function handleClose () {
        setBulkUpload(false)
        setFileExcel(null)
        setOpen(false);
        setProductItems([])
        setValueSearch("")
        setInputSearch("")
        setCompanySetting({
          ...companySetting,
          initial_discount : "",
          payment_term : "",
          product : [],
          status : "ACTIVE",
        })
        dispatch(resetSearchBuyer())
    };


    function onSearch(e:any) {
      e.preventDefault()
      setValueSearch(inputSearch)
    }

    function convertProductsArray (data:any) {
      let arr : any = []
      data.map((item:any) => {
          arr.push({
            _id : item._id,
            sku : item.sku,
            name : item.name,
            discount : {
              label : item.discount_type.value,
              value : parseInt(item.discount_value)
            }
          })
        return arr
      })
      return arr
    }

    function onClickSubmit(e:any) {
      e.preventDefault()
      if(selectedTrigger.length === 0) {
        setErrorTrigger(true)
      } else if(selectedPeriod.length === 0) {
        setErrorPeriod(true)
      } else {
        if(bulkUpload) {
          let body_upload = {
            preferred : search_buyer.length && search_buyer[0]._id,
            company : "null",
            initial_discount : companySetting.initial_discount,
            down_payment: companySetting.down_payment,
            payment_term : {
              value : companySetting.payment_term,
              label : `${companySetting.payment_term} Days`,
            },
            file : fileExcel,
          }
          dispatch(uploadBulkPreferedList(body_upload))
        } else {
          let body = {
            preferred : search_buyer.length && search_buyer[0]._id,
            company : "null",
            initial_discount : companySetting.initial_discount,
            payment_term : {
              value : companySetting.payment_term,
              label : `${companySetting.payment_term} Days`,
            },
            product : convertProductsArray(productItems),
            status : companySetting.status,
            down_payment: companySetting.down_payment,
            credit_limit: {
              isActive: companySetting.credit_limit_isActive,
              amount: companySetting.credit_limit_amount
            },
            minimum_total_order: {
              isActive: companySetting.minimum_total_order_isActive,
              amount: companySetting.minimum_total_order_amount
            },
            invoice: {
              options  : selectedTrigger.value,
              config : {
                  period : selectedPeriod.value
              }
            }
          }
          dispatch(postPreferredBuyerList(body))
        }
      }
    }


    React.useEffect(() => {
      if(create || update || bulk_upload) {
          handleClose()
          dispatch(resetSearchBuyer())
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create, update, bulk_upload]);

    return (   
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="lg"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">{name}</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
          <Box className="container-content-preferred">
          { search_buyer.length === 0 ?
            <Box className="container-search">
              <form onSubmit={onSearch}>
                <Box className='search-box'>
                  <input 
                      placeholder="Search company by code .."
                      onChange={(e) => setInputSearch(e.target.value)}
                      value={inputSearch}
                  />
                  <Box>
                    { valueSearch === "" ? 
                    <Box className='notyping'>
                        <img alt='searchimg' className='searchimg' src={searchimg} onClick={onSearch} />
                    </Box>
                    :
                    <Box className='typing'>
                        <img 
                            alt='removeimg' className='removeimg' src={removeimg} 
                            onClick={() => {
                                setInputSearch("")
                                setValueSearch("")
                            }}
                        />
                        <Box className='divider-line'/>
                        <img alt='searchimg' className='searchimg' src={searchimg} onClick={onSearch} />
                    </Box> }
                  </Box>
                </Box>
              </form>
            </Box> : null }

            <form onSubmit={onClickSubmit}>
            { loading_search ? 
            <Box className="card-content">
              <Box p={0.5}>
                <Skeleton animation="wave" variant="rectangular" width="100%" height={310} sx={{ borderRadius: 3 }}/>
              </Box>
            </Box>
            :
            <Box className="card-content">
              { search_buyer.length === 0 ? 
                <EmptyData 
                  message="Company not found."
                  caption="Please search company only by code. Make sure you got the right company code."
                />
                :
                <FormPreferred 
                  search_buyer={search_buyer}
                  companySetting={companySetting}
                  setCompanySetting={setCompanySetting}
                  productItems={productItems}
                  setProductItems={setProductItems}
                  bulkUpload={bulkUpload}
                  setBulkUpload={setBulkUpload}
                  fileExcel={fileExcel}
                  setFileExcel={setFileExcel}
                  resetData={resetData}
                  selectedTrigger={selectedTrigger}
                  setSelectedTrigger={setSelectedTrigger}
                  selectedPeriod={selectedPeriod}
                  setSelectedPeriod={setSelectedPeriod}
                  resetSearchBuyer={resetSearchBuyer}
                  errorTrigger={errorTrigger}
                  setErrorTrigger={setErrorTrigger}
                  errorPeriod={errorPeriod}
                  setErrorPeriod={setErrorPeriod}
                  type="create"
                /> 
              }
            </Box> }
          </form>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export default DialogCreation;