import { Box } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import { getConversationRoom } from '../features_app/dashboard/chat/redux/chatReducers';
import { addConvesationLists, clearConversationRoom, initConversationActive, onFloatingChat } from '../features_app/dashboard/chat/redux/chatSlices';

const ChatButton : React.FC<any> = ({ company }) => {
    const dispatch = useDispatch()
    const { conversation_lists, new_message } = useSelector((state:RootState) => state.chats);
    
    const onClickStartChat = (buyer:any) => {
        let data = {
            company_id: buyer._id,
            company_name: buyer.legalName,
            company_logo : buyer.logo,
            last_message : {
                text: ""
            }
        }
        let conversation_exist = [...conversation_lists]
        let exist = conversation_exist.find((item:any) => item.company_id === buyer._id)
        dispatch(onFloatingChat(true))
        dispatch(initConversationActive(data))
        if(!exist) {
            dispatch(clearConversationRoom())
            dispatch(addConvesationLists(data))
        } else {
            let payload = { company_id : buyer._id, new_message }
            dispatch(getConversationRoom(payload))
        }
    }

    return (
    <Box 
        className="btn-chat" 
        onClick={() => onClickStartChat(company)}
    >
        <p>Chat Buyer</p>
    </Box>
  )
}

export default ChatButton