import { 
    Stack, 
    Paper, 
    Box, 
    Grid,
    Typography,
    Dialog,
    Button
} from '@mui/material';
import moment from 'moment';
import { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../app/store';
import BreadCrumbs from '../../../components/BreadCrumbs';
import { getMasterWarehouse } from '../masters/mastersReducers';
import SkeletonDetailProducts from './components/SkeletonProduct';
import { getProductBySlug } from './reducers/productsReducers';

const ProductDetail : React.FC<any> = () => {
    const [detailImage, setDetailImage] = useState<any>({
        open : false,
        img : ""
    });

    const dispatch = useDispatch()
    const location : any = useLocation()
    const slug_query :any = location.state

    const { 
        loading_product_detail, product_detail, 
    } = useSelector((state : RootState) => state.products)
    const { warehouse, loading_warehouse } = useSelector((state : RootState) => state.masters)

 
    const isEmpty = (obj : any) => {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return true;
        }
        return false;
    }

    useEffect(() => {
        if(slug_query !== undefined) {
            dispatch(getProductBySlug(slug_query.slug))
        } 
        // eslint-disable-next-line
    }, [slug_query]);

    useEffect(() => {
        dispatch(getMasterWarehouse())
        // eslint-disable-next-line
    }, []);

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs
                isPage={true}
                link="/dashboard/products"
                page="Product"
                current="Product Detail"
            />
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>Product Detail</h3>
                </Box>
            </Stack>

            { !loading_product_detail && !loading_warehouse ?
            <Box>
            { isEmpty(product_detail) &&
            <Box mt={2}>
                <Grid container spacing={3}>
                <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
                        <Paper elevation={2} sx={{p:3, borderRadius: 3, my: 1}}>
                            <Box pb={1} fontWeight="bold" fontSize={15}>Product Images</Box>
                            <Stack flexDirection="row" flexWrap="wrap" >
                            {product_detail.images.map((item:any, index:any) => (
                                <Box className='image-preview' mr={1} mb={1} key={index}>
                                    <Box 
                                        component="img"
                                        src={item}
                                    />
                                </Box> 
                            ))}
                            </Stack>
                        </Paper>
                        <Paper elevation={2} sx={{p:3, borderRadius: 3, mt:3}}>
                            <Box pb={1} fontWeight="bold" fontSize={15}>Warehouses</Box>
                            <Stack flexDirection="column" >
                            {product_detail.warehouses.filter((itm:any) => warehouse.some((ele:any) => ele.value === itm.warehouse._id)).map((item:any, index:any) => (
                                <Box className='warehouse-preview' mb={2} key={index}>
                                    <Stack flexDirection="row" alignItems="center">
                                        <Box
                                            component="img"
                                            src={item.warehouse.imageUrl}
                                        />
                                        <Box pl={1} fontSize={20}>{item.warehouse.name}</Box>
                                    </Stack>
                                    <Box pt={2}>
                                        <Grid container columnSpacing={3} rowSpacing={2}>
                                            <Grid item xl={4} lg={4} xs={12}>
                                                <Typography variant="body2" color="#95a4b2" fontWeight="400">Retail Price</Typography>
                                                <Typography variant="body1" fontWeight="500" fontSize={18}>Rp. {item.retail_price.toLocaleString()}</Typography>
                                            </Grid>
                                            <Grid item xl={4} lg={4} xs={12}>
                                                <Typography variant="body2" color="#95a4b2" fontWeight="400">Discount</Typography>
                                                { item.discount.label === "PERCENTAGE" ? 
                                                <Typography variant="body1" fontWeight="500" fontSize={18}>{item.discount.value} %</Typography> :
                                                <Typography variant="body1" fontWeight="500" fontSize={18}>Rp. {item.discount.value.toLocaleString()}</Typography> }
                                            </Grid>
                                            <Grid item xl={4} lg={4} xs={12}>
                                                <Typography variant="body2" color="#95a4b2" fontWeight="400">Stock</Typography>
                                                <Typography variant="body1" fontWeight="500" fontSize={18}>{item.stock.toLocaleString()}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box pt={1}>
                                        <Grid container columnSpacing={3} rowSpacing={2}>
                                            <Grid item xl={4} lg={4} xs={12}>
                                                <Typography variant="body2" color="#95a4b2" fontWeight="400">Rack</Typography>
                                                <Typography variant="body1" fontWeight="500" fontSize={18}>{item.storage.rack || "-"}</Typography>
                                            </Grid>
                                            <Grid item xl={4} lg={4} xs={12}>
                                                <Typography variant="body2" color="#95a4b2" fontWeight="400">Bin</Typography>
                                                <Typography variant="body1" fontWeight="500" fontSize={18}>{item.storage.bin || "-"}</Typography>
                                            </Grid>
                                            <Grid item xl={4} lg={4} xs={12}>
                                                <Typography variant="body2" color="#95a4b2" fontWeight="400">Level</Typography>
                                                <Typography variant="body1" fontWeight="500" fontSize={18}>{item.storage.level || "-"}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    
                                </Box> 
                            ))}
                            </Stack>
                        </Paper>

                    </Grid>
                    <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
                        <Paper elevation={2} sx={{p:3, borderRadius: 3, my: 1}}>
                            <Box py={1}>
                                <Grid container columnSpacing={3} rowSpacing={2}>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Name</Typography>
                                        <Typography variant="body1" fontWeight="500" fontSize={18}>{product_detail.name}</Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">SKU</Typography>
                                        <Typography variant="body1" fontWeight="500" fontSize={18}>{product_detail.sku}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Grid container columnSpacing={3} rowSpacing={2}>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Brand</Typography>
                                        <Typography variant="body1" fontWeight="500" fontSize={18}>{product_detail.brand}</Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Tax</Typography>
                                        <Typography variant="body1" fontWeight="500" fontSize={18}>{product_detail.tax.label}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Grid container columnSpacing={3} rowSpacing={2}>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Min. Order Quantity</Typography>
                                        <Typography variant="body1" fontWeight="500" fontSize={18}>{product_detail.minimum_order_quantity}</Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Down Payment</Typography>
                                        <Typography variant="body1" fontWeight="500" fontSize={18}>{product_detail.down_payment}%</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Grid container columnSpacing={3} rowSpacing={2}>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Payment Term</Typography>
                                        <Typography variant="body1" fontWeight="500" fontSize={18}>{product_detail.payment_term} days</Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Dimension</Typography>
                                        <Typography variant="body1" fontWeight="500" fontSize={18}>
                                            {product_detail.dimension.H} cm x {product_detail.dimension.W} cm x {product_detail.dimension.L} cm
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Grid container columnSpacing={3} rowSpacing={2}>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Weight</Typography>
                                        <Typography variant="body1" fontWeight="500" fontSize={18}>{product_detail.weight} kg</Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Size</Typography>
                                        <Typography variant="body1" fontWeight="500" fontSize={18}>{product_detail.size || "-"}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Grid container columnSpacing={3} rowSpacing={2}>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Volume</Typography>
                                        <Typography variant="body1" fontWeight="500" fontSize={18}>{product_detail.volume || "-"}</Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Origin Country</Typography>
                                        <Typography variant="body1" fontWeight="500" fontSize={18}>{product_detail.country_of_origin || "-"}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Grid container columnSpacing={3} rowSpacing={2}>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Made Date</Typography>
                                        <Typography variant="body1" fontWeight="500" fontSize={18}>{product_detail.made_in !== null ? moment(product_detail.made_in).format('l') : "-"}</Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Expired Date</Typography>
                                        <Typography variant="body1" fontWeight="500" fontSize={18}>{product_detail.expired_in !== null ? moment(product_detail.expired_in).format('l') : "-"}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Grid container columnSpacing={3} rowSpacing={2}>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Special Handling</Typography>
                                        <Typography variant="body1" fontWeight="500" fontSize={18}>{product_detail.special_handling || "-"}</Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Unit</Typography>
                                        <Typography variant="body1" fontWeight="500" fontSize={18}>{(product_detail.unit && product_detail.unit.name) || "-"}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Grid container columnSpacing={3} rowSpacing={2}>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Product Visibility</Typography>
                                        <Typography variant="body1" fontWeight="500" fontSize={18}>{product_detail.status || "-"}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Grid container columnSpacing={3} rowSpacing={2}>
                                    <Grid item xl={12} lg={12} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Description</Typography>
                                        <Box pt={1}> 
                                            { ReactHtmlParser(product_detail?.description) }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Grid>
                    
                    
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={detailImage.open}
                    onClose={() => setDetailImage({...detailImage, open : false, img : ""})}
                >
                    { detailImage.img === "" ? null : 
                    <Box p={3}>
                        <img 
                            id="img-review-Image" 
                            alt="detail gambar" 
                            src={detailImage.img} 
                            width="100%" height="100%" 
                        />
                        <Box pt={2} pb={1} />
                        <Grid container flexDirection="row" justifyContent="center" >
                            <Box pr={1} pl={1}/>
                            <Button 
                                variant='contained' color="primary" 
                                onClick={() => setDetailImage({...detailImage, open : false, img : ""})}
                            >
                                OK
                            </Button>
                        </Grid>
                    </Box> }
                </Dialog>
            </Box> }
            </Box> 
            :
            <SkeletonDetailProducts /> }
        </Box>
    )
}

export default ProductDetail;
