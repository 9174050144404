import { Box, Skeleton } from '@mui/material'

const LoadingInvoice = () => {
    return (
      <Box className="invoice-container">
        <Box className="logo-company">
          <Box className="logo">
            <Skeleton variant='circular'  height={75}  width={75} />
          </Box>
          <Box className="company">
            <Skeleton variant='text'  height={30}  width={150} />
            <Skeleton variant='text'  height={25}  width={200} />
            <Skeleton variant='text'  height={25}  width={220} />
            <Skeleton variant='text'  height={25}  width={200} />
          </Box>
        </Box>
  
        <Box className="invoice-info">
          <Box className="bill-to">
            <Skeleton variant='text'  height={30}  width={100} />
            <Skeleton variant='text'  height={25}  width={200} />
            <Skeleton variant='text'  height={25}  width={230} />
            <Skeleton variant='text'  height={25}  width={200} />
          </Box>
          <Box className="inv-number">
            <Skeleton variant='text'  height={35}  width={150} />
            <Box className="list">
              <Skeleton variant='text' width={175} />
            </Box>
            <Box className="list">
              <Skeleton variant='text' width={175} />
            </Box>
          </Box>
        </Box>
        <Box className="invoice-table">
          <Skeleton variant='text' height={30} />
          <Skeleton variant='text' height={30} />
          <Skeleton variant='text' height={30} />
          <Skeleton variant='text' height={30} />
        </Box>
  
        <Box className="document-referance">
          <Skeleton variant='text'  height={30}  width={150} />
          <Skeleton variant='text' height={30} width={250} />
        </Box>
  
        <Box className="invoice-footer">
          <p><Skeleton variant='text'  height={10} /></p>
        </Box>
      </Box>
    )
  }

export default LoadingInvoice