import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }

export const getAllExtensions = createAsyncThunk(
    'get/extensions',
    async (params:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/extensions`, {
                params,
                headers
            })
            if(response.data) {
                const credential : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/credential`, {
                    params,
                    headers
                })
                if(credential.data) {
                    const result = response.data.data.map((extension:any) => {
                        const exten = credential.data.data.filter((ele:any) => ele.isDeleted === false).find((op:any) => op.extension[0]._id === extension._id);
                        return exten ? { ...extension, used: true } : { ...extension, used : false  };
                    });
                    return result
                } else {
                    return rejectWithValue(credential || "Something went wrong!")
                }
            } else {
                return rejectWithValue(response || "Something went wrong!")
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);   


export const getAllInstalledExtensions = createAsyncThunk(
    'get/installed-extensions',
    async (params:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/credential`, {
                params,
                headers
            })
            if(response.data) {
                return response.data
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);   


export const installExtensionsAction = createAsyncThunk(
    'post/use-extensions',
    async (body:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/credential`, body, {
                headers
            })
            if(response.data) {
                return true
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);   

export const updateSettingExtensionsAction = createAsyncThunk(
    'post/update-setting-extensions',
    async (body:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/credential/${body.id}`, body.body, {
                headers
            })
            if(response.data) {
                return true
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);   


export const unInstallExtensionsAction = createAsyncThunk(
    'post/uninstall-extensions',
    async (id:string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.delete(`${process.env.REACT_APP_API_SERVER}/credential/${id}`, {
                headers
            })
            if(response.data) {
                return true
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);   
