import { Stack, Box, Button, Paper, TextField, Grid } from '@mui/material'
import {useState, useEffect} from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { 
    createBankAccount, 
    getCompanyDetail, 
    removeBankAccount, 
    updateBankAccount, 
    updateDefaultBankAccount 
} from '../reducers/profilesReducers';
import Select from 'react-select'
import SnackBarAlert from '../../../../components/SnackBarAlert';
import { updateAccountNumberSnackBar } from '../profileSlice';
import { getMasterBank } from '../../masters/mastersReducers';
import ButtonActions from '../../../../components/ButtonActions';
import ButtonCreate from '../../../../components/ButtonCreate';
import LoaderBackdrop from '../../../../components/LoaderBackdrop';
import { checkValidation } from '../../../../utilities/userFeaturesValidation';

const Bank : React.FC<any> = ({ profile }) => {

    const dispatch = useDispatch()
    const { 
        loading_create_account_number,
        create_account_number,
        loading_update_account_number,
        update_account_number,
        default_account_number,
        loading_default_account_number,
        remove_account_number,
        loading_remove_account_number
     } = useSelector((state : RootState) => state.profile)
    const { bank } = useSelector((state : RootState) => state.masters)

    
    const [open, setOpen] = useState({
        open : false,
        name : "" 
    });
    
    const [idBank, setIdBank] = useState("");

    const [valueBank, setValueBank] = useState<any>({
        bank_account_name: "",
        bank_number : ""
    });

    const [selectedBank, setSelectedBank] = useState<any>([]);

    const handleChangeBank = (value : any) => {
        setSelectedBank(value)    
    }

    const onChangeValue = (e:any) => {
        setValueBank({...valueBank, [e.target.name]: e.target.value })
    }

    const handleClose = () => {
        setOpen({...open, open: false, name : "" })
        setValueBank({...valueBank, bank_account_name: "", bank_number : ""})
        setSelectedBank([])
    }

    const handleOpen = (name : any) => {
        setOpen({...open, open: true, name : name })
    }

    const onClickSubmit = () => {
        if(open.name === "Create Bank") {
            let body = {
                "company": "null",
                "account_name": valueBank.bank_account_name,
                "account_number": valueBank.bank_number,
                "bank": {
                    "_id": selectedBank.id,
                    "label": selectedBank.label,
                    "iconUrl": selectedBank.iconUrl,
                    "number": selectedBank.value
                }
            }
            dispatch(createBankAccount(body))
        } else {
            let body = {
                id : idBank,
                body : {
                    "company": "null",
                    "account_name": valueBank.bank_account_name,
                    "account_number": valueBank.bank_number,
                    "bank": {
                        "_id": selectedBank.id,
                        "label": selectedBank.label,
                        "iconUrl": selectedBank.iconUrl,
                        "number": selectedBank.value
                    }
                }
            }
            dispatch(updateBankAccount(body))
        }
    }


    useEffect(() => {
        if(create_account_number || update_account_number || default_account_number || remove_account_number) {
            handleClose()
            dispatch(getCompanyDetail())
            setTimeout(() => {
                dispatch(updateAccountNumberSnackBar())
            }, 3000);
        }
        // eslint-disable-next-line
    }, [create_account_number, update_account_number, default_account_number, remove_account_number]);


    useEffect(() => {
        dispatch(getMasterBank())
        // eslint-disable-next-line
    }, []);

    return (
        <Stack mt={1}>
            <LoaderBackdrop loading={loading_remove_account_number || loading_default_account_number} />
            <Box>
                { checkValidation('CRECOMBAN581131') &&
                <Stack sx={{mb:3}} flexDirection="row" justifyContent="flex-start">
                    <ButtonCreate 
                        name="Add New Bank"
                        onClick={() => handleOpen("Create Bank")}
                    />
                </Stack> }
                { profile.accountNumbers.length === 0 ? 
                <Box>
                    You dont have any bank account.
                </Box> :
                <Grid container spacing={1}>
                    { profile.accountNumbers.map((itm:any, idx:any) => (
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} key={idx}>
                            <Paper sx={{p:2, borderRadius: 3, backgroundColor: '#fff'}} elevation={3}>
                            <Box className='box-bank'>
                                <Stack flexDirection="row" alignContent="center" flexWrap="wrap">
                                    <Box 
                                        component="img"
                                        src={itm.bank.iconUrl}
                                    />
                                    <Box >
                                        <Box className='bank-label'>{itm.bank.label}</Box>
                                        <Box className='bank-name'>{itm.account_number}</Box>
                                        <Box className='bank-name'>{itm.account_name}</Box>
                                    </Box>
                                </Stack>
                                <Stack flexDirection="row" alignContent="center" flexWrap="wrap" rowGap={1}>
                                    <Box mr={1}>
                                        { checkValidation('UPDCOMBAN660572') &&
                                        <ButtonCreate 
                                            name="Set Default"
                                            outlined={true}
                                            onClick={() => { 
                                                setIdBank(itm._id)
                                                dispatch(updateDefaultBankAccount(itm._id))
                                            }}
                                            disabled={loading_default_account_number || itm.isDefault}
                                        /> }
                                    </Box>
                                    <Box>
                                        { checkValidation('UPDCOMBAN660572') &&
                                        <ButtonActions 
                                            name="Edit Bank"
                                            onClick={() => {
                                                setIdBank(itm._id)
                                                setValueBank({...valueBank,
                                                    bank_account_name: itm.account_name,
                                                    bank_number : itm.account_number
                                                })
                                                setSelectedBank([{ 
                                                    value : itm.bank.number, 
                                                    label:  itm.bank.label,
                                                    iconUrl : itm.bank.iconUrl,
                                                    id : itm.bank._id
                                                }])
                                                handleOpen("Edit Bank")
                                            }}
                                        /> }
                                    </Box>
                                    { checkValidation('DELCOMBAN389375') &&
                                    <ButtonActions 
                                        name="Remove"
                                        color="error"
                                        onClick={() => dispatch(removeBankAccount(itm._id))}
                                    /> }
                                </Stack>
                            </Box>
                            </Paper>
                        </Grid>
                    ))}
                </Grid> }

                <Dialog open={open.open} maxWidth="sm" fullWidth={true}  >
                    <DialogTitle>{open.name}</DialogTitle>
                    <DialogContent sx={{minHeight: 400}}>
                        <Stack>
                            <Box pb={1}>
                                <Select
                                    placeholder="Choose Bank"
                                    value={selectedBank}
                                    isSearchable={true}
                                    options={bank && bank}
                                    onChange={handleChangeBank}
                                    id={`select-style-cat`}

                                />
                            </Box> 
                           <Box pt={1} pb={1}>
                           <Grid container spacing={2}>
                                <Grid item lg={12}>
                                    <TextField 
                                        label="Account Number"
                                        onChange={(e:any) => {
                                            if(e.target.value >= 0) {
                                                onChangeValue(e)
                                            }
                                        }}
                                        value={valueBank.bank_number}
                                        name="bank_number"
                                        type="number"
                                        size="small"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        fullWidth
                                    />
                               </Grid>
                           </Grid>
                           </Box>
                           <Box pt={1} pb={1}>
                           <Grid container spacing={2}>
                                <Grid item lg={12}>
                                    <TextField 
                                        label="Account Name"
                                        onChange={onChangeValue}
                                        value={valueBank.bank_account_name}
                                        name="bank_account_name"
                                        type="text"
                                        size="small"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        fullWidth
                                    />
                               </Grid>
                           </Grid>
                           </Box>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="error" variant='outlined'>Cancel</Button>
                        <Button 
                            onClick={onClickSubmit} 
                            variant="contained"
                            disabled={
                                valueBank.bank_account_name === "" || 
                                valueBank.bank_number === "" || 
                                loading_create_account_number || 
                                loading_update_account_number || 
                                loading_default_account_number ? true : false
                            }
                        >
                            { loading_create_account_number || loading_update_account_number || loading_default_account_number ? "Loading" : "Submit" } 
                        </Button>
                    </DialogActions>
                </Dialog>

                <SnackBarAlert message={`Success Create Account Number.`} initialState={create_account_number} />
                <SnackBarAlert message={`Success Update Account Number.`} initialState={update_account_number} />
                <SnackBarAlert message={`Success Update Default Account Number.`} initialState={default_account_number} />
                <SnackBarAlert message={`Success Remove Account Number.`} initialState={remove_account_number} />
            </Box>
        </Stack>
    )
}

export default Bank
