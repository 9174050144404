import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';
import { errorSweetAlert } from '../../../../utilities/errorhandling';


export interface CounterState {
  step_state: number;
  category : [];
  loading_category : boolean;
  type : [];
  loading_type: boolean;
  legal : [];
  loading_legal: boolean;
  local_data : boolean;
}

const initialState: CounterState = {
  step_state: 0,
  category : [], 
  loading_category : false,
  type : [],
  loading_type : false,
  legal : [],
  loading_legal : false,
  local_data : false
}


export const getActionCategoryCompany = createAsyncThunk(
  'category-company/get',
  async (_, { rejectWithValue }) => { 
      try {
          const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/category-company/vendor`, {
            headers : {
                'Authorization' : `Bearer ${userCredentials.id_token}`
            }
          })
          if(response.data) {
              let category = response.data
              let array_category = []
              for(let element of category) {
                  array_category.push({
                      label : element.name,
                      value : element._id,
                  })
              }
              return array_category
          } else {
              return rejectWithValue(response.data.message)
          }
        } catch (err : any) {
          if (!err.response) {
            throw err
          }
          return rejectWithValue(err.response)
      }
  }
);

export const getActionTypeCompany = createAsyncThunk(
  'type-company/get',
  async (_, { rejectWithValue }) => { 
      try {
          const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/type-company`, {
              headers : {
                  'Authorization' : `Bearer ${userCredentials.access_token}`
              }
          })
          if(response.data) {
              let type = response.data
              let array_type = []
              for(let element of type) {
                  array_type.push({
                      label : element.name,
                      value : element._id
                  })
              }
            return array_type
          } else {
              return rejectWithValue(response.data.message)
          }
        } catch (err : any) {
          if (!err.response) {
            throw err
          }
          return rejectWithValue(err.response)
      }
  }
);

export const getActionLegalTypeCompany = createAsyncThunk(
  'type-company-legal/get',
  async (type:string, { rejectWithValue }) => { 
      try {
          const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/legal`, {
              params : {
                  type : type
              },
              headers : {
                  'Authorization' : `Bearer ${userCredentials.access_token}`
              }
          })
          if(response.data) {
              let type = response.data
              let array_type = []
              for(let element of type) {
                  array_type.push({
                      label : element.name,
                      value : element._id
                  })
              }
            return array_type
          } else {
              return rejectWithValue(response.data.message)
          }
        } catch (err : any) {
          if (!err.response) {
            throw err
          }
          return rejectWithValue(err.response)
      }
  }
);

export const stepForm = createSlice({
  name: 'step',
  initialState,
  reducers: {
    changeStep: (state, action: PayloadAction<number>) => {
      state.step_state = action.payload
    },
    changeLocalData: (state, action: PayloadAction<boolean>) => {
      state.local_data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder

      // get company category
      .addCase(getActionCategoryCompany.pending, (state) => {
        state.loading_category = true;
      })
      .addCase(getActionCategoryCompany.fulfilled, (state, action:any) => {
        state.loading_category = false;
        state.category = action.payload
      })
      .addCase(getActionCategoryCompany.rejected, (state, action : any) => {
        state.loading_category = false;
        errorSweetAlert(action.payload)
      })
      // get company type
      .addCase(getActionTypeCompany.pending, (state) => {
        state.loading_type = true;
      })
      .addCase(getActionTypeCompany.fulfilled, (state, action:any) => {
        state.loading_type = false;
        state.type = action.payload
      })
      .addCase(getActionTypeCompany.rejected, (state, action : any) => {
        state.loading_type = false;
        errorSweetAlert(action.payload)
      })
      // get company type legal
      .addCase(getActionLegalTypeCompany.pending, (state) => {
        state.loading_legal = true;
      })
      .addCase(getActionLegalTypeCompany.fulfilled, (state, action:any) => {
        state.loading_legal = false;
        state.legal = action.payload;
        state.local_data = false
      })
      .addCase(getActionLegalTypeCompany.rejected, (state, action : any) => {
        state.loading_legal = false;
        errorSweetAlert(action.payload)
      })
  },
})

// Action creators are generated for each case reducer function
export const { changeStep, changeLocalData } = stepForm.actions

export default stepForm.reducer