import { Stack, Box, Grid } from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import calender from '../../../../../assets/img/icon/calendar.png'
import store from '../../../../../assets/img/icon/store.png'
import ChatButton from '../../../../../components/ChatButton'
import PreferredButton from '../../../../../components/PreferredButton'
import DialogConfirm from './DialogConfirm'

const CardInvoice : React.FC<any> = ({ item }) =>  {
    const history = useHistory()

    const [dialogPayment, setDialogPayment] = useState(false);
    const [fileUrl, setFileUrl] = React.useState({
        name : '',
        url : ''
    });

    function getStyle (name:string) {
        switch (name) {
            case 'Ready for Payment':
                return {  backgroundColor : '#ff9f43' }
            case 'Waiting to Confirm':
                return {  backgroundColor : '#0091d6' }
            case 'Approved':
                return {  backgroundColor : '#43a047' }
            case 'Reject':
                return {  backgroundColor : '#ee4054' }
            default:
                return {  backgroundColor : '#43a047' }
        }
    }

    const onDownloadEFaktur = () => {
        window.open("https://tinyurl.com/2c99eve9", "_blank");
    }

    function onClickViewDetail(id:string) {
        history.push({
            pathname: '/dashboard/finance/invoice',
            search: `?page=detail`, 
            state : {
                id : id,
                type : "id"
            }
        })
    }

    return (
    <Stack 
        className='container-item-invoice'
        sx={{ border: `1px solid ${item.type && item.type ===  "Down Payment" ? 'gray' : 'transparant'}` }}
    >
        <Box className="top-div">
            <Box className='left'>
                <img src={calender} alt='calender' />
                <p>{moment(item.createdAt).format('LL')}</p>
                <Box className='line-right' />
                <h5>{item.invoiceNumber}</h5> 
                <Box className='line-right' />
                <h5 className={item.type && item.type ===  "Down Payment" ? 'color-red' : 'color-black'}>Invoice { (item.type && item.type) || "Final Payment" }</h5>
            </Box>
            <Box className='right'>
                <Box className='status' sx={getStyle(item.lastStatus)}>
                    { item.type && item.type ===  "Draft" ? 
                    <h6>Draft</h6> :
                    <h6>{item.lastStatus === "Ready for Payment" ? "Waiting for Payment" : item.lastStatus}</h6>
                    }
                </Box>
            </Box>
        </Box>
        <Box className="center-div">
            <Grid container spacing={1}>
                <Grid item xs={12} md={10} sm={12} lg={8} xl={8}>
                    <Box className='left-box'>
                        <Box className='store'>
                            <img src={item.buyer.logo || store} alt="store" />
                            <Box className='name'>
                                <h2>{item.buyer.legalName}</h2>
                                <Box className='chat'>
                                    <ChatButton company={item.buyer} />
                                    <PreferredButton company={item.buyer} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={2} sm={12} lg={4} xl={4}>
                    <Box className='total-prices'>
                        <p>Total Amount</p>
                        <h3>Rp.{(Math.floor(item.grandTotal)).toLocaleString()}</h3>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        <Box className='bottom-div'>
            <Box className='left-div'>
                <p>Due Date : {moment(item.dueDate).format('ll')} </p>
            </Box>
            <Box className='right-div'>
                <Box className='btn-confirm' > 
                    <button onClick={() => onClickViewDetail(item._id)}>
                        <p>View Detail</p>
                    </button>
                </Box>
                { item.lastStatus === 'PAID' ? 
                <Box className="btn-confirm" >
                    <button onClick={onDownloadEFaktur}>
                        <p>Generate E-Faktur</p>
                    </button>
                </Box>
                :
                <Box className="btn-confirm" >
                    <button 
                        disabled={item.lastStatus === 'Waiting to Confirm' ? false : true}
                        onClick={() => setDialogPayment(true)}
                    >
                        <p>Check Payment</p>
                    </button>
                </Box> }
                
            </Box>
        </Box>

        <DialogConfirm open={dialogPayment} setOpen={setDialogPayment} infoPayment={item.infoPayment} fileUrl={fileUrl} setFileUrl={setFileUrl} />
    </Stack>
  )
}

export default CardInvoice