/* istanbul ignore file */
import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { 
  Route, 
  Redirect,
  useLocation
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';
import NavDashboard from '../components/NavDashboard'
import { userCredentials } from './config';
import ChatFloating from '../features_app/dashboard/chat/ChatFloating';

interface Props {
  component : React.ElementType;
  path?: string | string[];
  exact?: boolean;
}


const  DashboardLayout : React.FC<Props> = ({ component : Component, ...rest }) => {
  const location = useLocation()
  const { signin } = useSelector((state : RootState) => state.auth)

  const checkModule = () => {
    let access = false
    if(userCredentials.navigation_path.find((ele:any) => ele === rest.path)) {
      access = true
    } else {
      access = false
    }
    return access
  }

  const isAuthenticated = () => {
    if(signin && checkModule()) {
        return signin
    } else {
        return false
    }
  }

  
  function getStylePage () {
    switch(location.pathname) {
      case "/dashboard/products": { 
        switch(location.search) { 
          case "?page=create" : {
            return { 
              display: 'flex',
              minHeight: '100vh',
              width: '100%',
              backgroundColor: '#f5f5f5'
            }
          }
          case "?page=edit" : {
            return { 
              display: 'flex',
              minHeight: '100vh',
              width: '100%',
              backgroundColor: '#f5f5f5'
            }
          }
          case "?page=detail" : {
            return { 
              display: 'flex',
              minHeight: '100vh',
              width: '100%',
              backgroundColor: '#f5f5f5'
            }
          }
          case "" : {
            return { 
              display: 'flex'
            }
          }
        }
        break;
      }
      case "/dashboard/warehouse": {
        switch(location.search) { 
          case "?page=create" : {
            return { 
              display: 'flex',
              minHeight: '100vh',
              width: '100%',
              backgroundColor: '#f5f5f5'
            }
          }
          case "?page=edit" : {
            return { 
              display: 'flex',
              minHeight: '100vh',
              width: '100%',
              backgroundColor: '#f5f5f5'
            }
          }
          case "?page=detail" : {
            return { 
              display: 'flex',
              minHeight: '100vh',
              width: '100%',
              backgroundColor: '#f5f5f5'
            }
          }
          case "" : {
            return { 
              display: 'flex'
            }
          }
        }
        break;
      }
      case "/dashboard" : {
        return { 
          display: 'flex',
          minHeight: '100vh',
          width: '100%',
          // backgroundColor: '#f5f5f5'
        }
      }
      case "/dashboard/profile" : {
        return { 
          display: 'flex',
          minHeight: '100vh',
          width: '100%',
          backgroundColor: '#f5f5f5'
        }
      }
      default: {
        return { 
          display: 'flex'
        }
      }
    }      
  }

  return (

    <Route {...rest} 
        render={props => {
            if(isAuthenticated()){
                return (
                  <Box sx={getStylePage()}>
                    <NavDashboard />
                    <Box component="main" className='container-component' >
                      <Toolbar />
                      <Component {...props} />
                    </Box>
                    { location.pathname === '/dashboard' || 
                    location.pathname === '/dashboard/orders' || 
                    location.pathname === '/dashboard/finance/invoice' ||
                    location.pathname === '/dashboard/buyer-list' ? 
                    <ChatFloating /> : null }
                  </Box>
                )
            }
            else {
                return (
                    <Redirect 
                        to={{
                            pathname: "/error",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        }}
    />
    
  );
}



export default DashboardLayout;