import { Paper, Stack, Box, Button } from '@mui/material';
import BreadCrumbs from '../../../components/BreadCrumbs'
import { useLocation } from 'react-router-dom';
import Summary from './components/Summary';
import { dataWarehouseResponse } from './components/warehouseTypes';

const DetailWarehouse = () => {
    const location : any = useLocation()
    const data : dataWarehouseResponse = location.state.data

    return (
        <Box m={2}>
            <BreadCrumbs
                isPage={true}
                current="Warehouse Detail Page"
                link="/dashboard/warehouse"
                page="Warehouse"
            />
            <Stack direction="row" justifyContent="space-between" alignItems="center" pt={3} >
                <Box>
                    <h3>Warehouse Detail</h3>
                </Box>
                <Box>
                    <Button
                        variant='contained'
                        color='error'
                        size='small'
                        onClick={() => window.history.back()}
                    >
                        Back
                    </Button>
                </Box>
            </Stack>
 
            <Paper sx={{p:3, my: 2}}>
                <Summary localData={data} /> 
            </Paper>

        </Box>
    )
}

export default DetailWarehouse
