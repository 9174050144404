import { useState, useEffect } from 'react';
import { Grid, Skeleton, Stack } from '@mui/material';
import { Box } from '@mui/material'
import BreadCrumbs from '../../../components/BreadCrumbs'
import HeadlineTitle from '../../../components/HeadlineTitle';
import ButtonCreate from '../../../components/ButtonCreate';
import rolesimg from '../../../assets/img/icon/role.png'
import ButtonActions from '../../../components/ButtonActions';
import DialogViewRoles from './components/DialogViewRoles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { getAllCustomRoles } from './redux/rolesReducers';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import SnackBarAlert from '../../../components/SnackBarAlert';
import { useHistory } from 'react-router-dom';
import { checkValidation } from '../../../utilities/userFeaturesValidation';

const RolesPage = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { 
        data, loading, loading_features, loading_create, loading_update,
        create, update
    } = useSelector((state : RootState) => state.roles)

    const [viewDialog, setViewDialog] = useState(false);
    const [IdRole, setIdRole] = useState("");

    useEffect(() => {
        dispatch(getAllCustomRoles())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box m={2}>
            <LoaderBackdrop loading={loading_features || loading_create || loading_update} />
            <BreadCrumbs
                isPage={false}
                current="Custom Roles"
            />
            <Stack direction="row" justifyContent="space-between" py={1} alignItems="center" flexWrap="wrap" >
                <Box>
                    <HeadlineTitle title='Custom Roles' variant="h6" bold="bold" />
                </Box>
                { checkValidation('CRECUSROL206935') &&
                <Box>
                    <ButtonCreate 
                        name="Add New Role"
                        onClick={() => {
                            history.push({
                                pathname : `/dashboard/custom-role`,    
                                search: `?page=create`, 
                            }) 
                        }}
                    />
                </Box> }
            </Stack>

            { loading ?
            <LoaderRoles />
            :
            <Box className='roles-container'>
                { data.length &&
                <Grid container spacing={2}>
                    { data.map((item:any, index:number) => (
                    <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                        <Box className='roles-card' >
                            <Box className='roles-item' >
                                <Box className='roles-item__image'>
                                    <img src={rolesimg} alt="rolesimg" />
                                </Box>
                                <Box className='roles-item__name'>
                                    <h1>{item.name}</h1>
                                    <p>{item.default ? "Default Role" : "Custom Role"}</p>
                                </Box>
                            </Box>
                            <Box className='roles-item__action'>
                                <Box px={1}>
                                { checkValidation('UPDCUSROL705465') &&
                                    <ButtonActions 
                                        disabled={item.default ? true : false}
                                        name="Edit Role"
                                        outlined={true}
                                        onClick={() => {
                                            history.push({
                                                pathname : `/dashboard/custom-role`,
                                                search: `?page=edit`,
                                                state: {
                                                    id: item._id,
                                                }
                                            })
                                        }}
                                    />
                                }
                                </Box>
                                <Box px={1}>
                                    <ButtonActions 
                                        name="View Permissions"
                                        onClick={() => {
                                            setViewDialog(true)
                                            setIdRole(item._id)
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    ))}
                </Grid> }
            </Box> }

            <DialogViewRoles 
                open={viewDialog} 
                setOpen={setViewDialog} 
                IdRole={IdRole}
            />
            <SnackBarAlert message={`New Custom Role is successfuly created`} initialState={create} />
            <SnackBarAlert message={`Custom Role is successfuly updated`} initialState={update} />
        </Box>
    )
}


const LoaderRoles = () => {
    return (
        <Box className='roles-container'>
            <Grid container spacing={2}>
                { [0,1,2,3].map((_, index:number) => (
                <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                    <Box className='roles-card' >
                        <Box className='roles-item' >
                            <Box className='roles-item__image'>
                                <Skeleton variant="rectangular" width={100} height={50} />
                            </Box>
                            <Box className='roles-item__name'>
                                <Skeleton variant="text" width={150} height={30} />
                                <Skeleton variant="text" width={150} height={20}/>
                            </Box>
                        </Box>
                        <Box className='roles-item__action'>
                            <Box px={1}>
                            <Skeleton variant="text" width={100} height={40} />
                            </Box>
                            <Box px={1}>
                            <Skeleton variant="text" width={100} height={40} />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default RolesPage
