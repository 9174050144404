import { useState, useEffect } from 'react';
import { Grid, Skeleton, Stack } from '@mui/material';
import { Box } from '@mui/material'
import BreadCrumbs from '../../../components/BreadCrumbs'
import HeadlineTitle from '../../../components/HeadlineTitle';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { getAllExtensions, getAllInstalledExtensions, } from './redux/extensionsReducers';
import SnackBarAlert from '../../../components/SnackBarAlert';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import AllExtensions from './components/AllExtensions';
import InstalledExtensions from './components/InstalledExtensions';

const ExtensionsPage = () => {
    const dispatch = useDispatch()
    const { 
        data, loading, use, installed_extensions,
        uninstall, loading_uninstall, update
    } = useSelector((state : RootState) => state.extensions)

    const [activeTabs, setActiveTabs] = useState("All");

    const getActions = () => {
        let params = {
            skip : 0,
            limit : 1500,
        }
        dispatch(getAllExtensions(params))
        dispatch(getAllInstalledExtensions(params))
    }

    useEffect(() => {
        getActions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getActions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uninstall, update]);

    const tabs = [
        {
            label : "All Extensions",
            value : "All",
        },
        {
            label : "Installed Extensions",
            value : "Installed",
        },
    ]

    return (
        <Box m={2}>
            <LoaderBackdrop loading={loading_uninstall} />
            <BreadCrumbs
                isPage={false}
                current="Extensions Page"
            />
            <Stack direction="row" justifyContent="space-between" py={1} alignItems="center" flexWrap="wrap" >
                <Box>
                    <HeadlineTitle title='Extensions' variant="h6" bold="bold" />
                    <HeadlineTitle title='You can use extensions ready for Pasar Pemesanan below.' variant="p" bold="normal" />
                </Box>
            </Stack>

            <Box mb={4} className='tabs-extensions'>
                {tabs.map((item:any, index:number) => (
                    <Box 
                        key={index} className={item.value === activeTabs ? 'tabs-active' : 'tabs'}
                        onClick={() => setActiveTabs(item.value)}
                    >
                        <h2>{item.label}</h2>
                    </Box>
                ))}
            </Box>

            { activeTabs === "All" ? 
            <Box>
                { loading ?
                <LoaderRoles />
                :
                <AllExtensions data={data} />
                }
            </Box> : 
            <Box>
                { loading ?
                <LoaderRoles />
                :
                <InstalledExtensions installed_extensions={installed_extensions} />
                }
            </Box>
            }

            <SnackBarAlert message={`Success Install a new Extension`} initialState={use} />
            <SnackBarAlert message={`Success updated Extension`} initialState={update} />
            <SnackBarAlert message={`Success Uninstall Extension`} initialState={uninstall} />
        </Box>
    )
}


const LoaderRoles = () => {
    return (
        <Box className='roles-container'>
            <Grid container spacing={2}>
                { [0,1,2,3].map((_, index:number) => (
                <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                    <Box className='roles-card' >
                        <Box className='roles-item' >
                            <Box className='roles-item__image'>
                                <Skeleton variant="rectangular" width={100} height={50} />
                            </Box>
                            <Box className='roles-item__name'>
                                <Skeleton variant="text" width={150} height={30} />
                                <Skeleton variant="text" width={150} height={20}/>
                            </Box>
                        </Box>
                        <Box className='roles-item__action'>
                            <Box px={1}>
                            <Skeleton variant="text" width={100} height={40} />
                            </Box>
                            <Box px={1}>
                            <Skeleton variant="text" width={100} height={40} />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default ExtensionsPage
