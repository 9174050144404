import { 
    Stack, 
    Box, 
    Button
} from '@mui/material';
import BreadCrumbs from '../../../components/BreadCrumbs';
import StepperWarehouse from './components/StepperForm';

const EditWarehouse = () => {
    return (
        <Box m={2}>
            <BreadCrumbs
                isPage={true}
                link="/dashboard/warehouse"
                page="Warehouse"
                current="Edit Warehouse"
            />
            <Stack direction="row" justifyContent="space-between" alignItems="center" pt={3} >
                <Box>
                    <h3>Edit Warehouse</h3>
                </Box>
                <Box>
                    <Button
                        variant='contained'
                        color='error'
                        size='small'
                        onClick={() => window.history.back()}
                    >
                        Back
                    </Button>
                </Box>
            </Stack>

            <Stack sx={{pt:3}}>
                <StepperWarehouse/>
            </Stack>

        </Box>
    )
}

export default EditWarehouse;
