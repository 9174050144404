import { Grid, Stack, Skeleton, Alert, AlertTitle } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { RootState } from '../../../app/store'
import {userCredentials} from './../../../utilities/config'
import CardStatistic from './components/CardStatistic'
import receivableimg from '../../../assets/img/dashboard/receiver.png'
import box from '../../../assets/img/dashboard/box.png'
import deal from '../../../assets/img/dashboard/deal.png'
import sales from '../../../assets/img/dashboard/sales.png'
import { getAccountReceivable, getTotalSales } from '../finance/invoices/redux/invoicesReducers'
import { getPreferredListsData } from '../buyer_lists/redux/buyerListsReducer'
import { getTotalAllProducts } from '../products/reducers/productsReducers'
import { getStatusCount } from '../fulfillment/redux/fulfillmentReducers'

const array_status = [
    { label: "All Orders", value: "All" },
    { label: "New", value: "New" },
    { label: "Pick & Pack", value: "Pick" },
    { label: "Ready to Ship", value: "Ready to Ship" },
    { label: "Shipped", value: "Shipped" },
    { label: "Delivered", value: "Delivered" },
    { label: "Completed", value: "Completed" },
    { label: "Canceled", value: "Cancel" },
]

function HomeDashboard() {

    const dispatch = useDispatch()
    const history = useHistory()

    const { receivable, loading_receivable, total_sales, loading_total_sales } = useSelector((state : RootState) => state.invoice)
    const { total_products, loading_total_products } = useSelector((state : RootState) => state.products)
    const { total_data : total_preferred, loading : loading_preferred} = useSelector((state : RootState) => state.preferred)
    const { status, loading_status } = useSelector((state : RootState) => state.fulfillment)

    const username = userCredentials === null ? "" : userCredentials.fullname
    const company = userCredentials === null ? "" : userCredentials.company.legalName

    useEffect(() => {
        if(userCredentials.company.isVerified){
            const params = {
                limit : 1,
                skip : 0,
            }
            dispatch(getPreferredListsData(params))
            dispatch(getAccountReceivable())         
            dispatch(getTotalAllProducts())             
            dispatch(getTotalSales())        
            let param = {
                from : '',
                to : ''
            }
            dispatch(getStatusCount(param))        
        }                          
        // eslint-disable-next-line
    }, [userCredentials]);


    const getDataCount = (name:string) => {
        if(name === "All") {
            return status.length ? status.reduce((a:any, b:any) => a + b.count, 0) : 0
        } else {
            const data = status.filter((item:any) => item._id === name)
            return data.length ? data[0].count : 0
        }
    }


    return (
        <Box m={2}>
        { !userCredentials.company.isVerified ?
        <Box>
                <Box>
                <h3>Welcome back, {username}</h3>
                <Box pt={1} fontWeight="400">{company}</Box>
            </Box>
            <Box pt={2}>
                <Alert severity="success">
                <AlertTitle>Information</AlertTitle>
                    We are still reviewing your company information for verification process. Some features may not be available until your account is verified. <br/> Please wait for our confirmation. Thank you for your patience. 
                </Alert>
            </Box>
        </Box>
        :
        <Box >
           <h3>Welcome back, {username}</h3>
           <Box fontWeight="400">{company}</Box>

            <Stack my={2}>
                <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <Link to="/dashboard/finance/invoice">
                        <CardStatistic 
                            label="Account Receivable"
                            value={receivable.length && receivable[0].totalAmount}
                            loading={loading_receivable}
                            currency={true}
                            background="#fff"
                            src={receivableimg}
                        />
                        </Link>
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <Link to="/dashboard/finance/invoice">
                        <CardStatistic 
                            label="Total Sales"
                            value={total_sales && total_sales.totalAmount}
                            loading={loading_total_sales}
                            currency={true}
                            background="#fff"
                            src={sales}
                        />
                        </Link>
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <Link to="/dashboard/products">
                        <CardStatistic 
                            label="Total Products"
                            value={total_products} 
                            currency={loading_total_products}
                            loading={false}
                            background="#fff"
                            src={box}
                        />
                        </Link>
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <Link to="/dashboard/buyer-list">
                        <CardStatistic 
                            label="Prefered Buyer"
                            value={total_preferred}
                            currency={false}
                            loading={loading_preferred}
                            background="#fff"
                            src={deal}
                        />
                        </Link>
                    </Grid>
                </Grid>
            </Stack>

            <Box className="dashboard-status-order">
                <h2>Status Order</h2>
                { loading_status ? 
                <Grid container spacing={2}>
                    { array_status.map((item : any, idx:number) => (
                    <Grid item xl={3} lg={3} sm={6} md={4} xs={6} key={idx}>
                        <Box className="card-status">
                            <Box>
                                <Skeleton variant="rectangular" width={60} height={50} sx={{borderRadius:3}} />
                            </Box>
                            <Box ml={2}>
                                <Skeleton variant="text" width={100} height={40} />
                            </Box>
                        </Box>
                    </Grid>
                    ))}
                </Grid>
                :
                <Grid container spacing={2}>
                    { array_status.map((item : any, idx:number) => (
                    <Grid item xl={3} lg={3} sm={6} md={4} xs={6} key={idx}>
                        <Box className="card-status">
                            <Box 
                                className={`tv-screen ${getDataCount(item.value) > 0 && 'exist'}`}
                                onClick={() => {
                                    history.push({
                                        pathname: "/dashboard/orders",
                                        state : {
                                          payload : {},
                                          dashboard : true,
                                          status : item.value
                                        }
                                    })
                                }}
                            >
                                <h4>{getDataCount(item.value)}</h4>
                            </Box>
                            <Box>
                                <h3>{item.label}</h3>
                            </Box>
                        </Box>
                    </Grid>
                    ))}
                </Grid> }
            </Box>
        </Box> }
    </Box>
    )
}

export default HomeDashboard
