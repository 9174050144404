import { Box } from "@mui/material"
import React, { useEffect, useState } from 'react'
import plus from '../../../../assets/img/icon/plus.png'
import minus from '../../../../assets/img/icon/minus.png'
import { useDispatch, useSelector } from "react-redux"
import { 
    changeNoteItem, updateQuantityDiscountSpecial, 
    updateQuantityDiscountPercentage, updatePriceDiscountPercentage, updatePriceDiscountSpecial, 
    updateQuantityDiscountAmount, 
    updatePriceDiscountAmount} from "../redux/fulfillmentsSlice"
import { RootState } from "../../../../app/store"
import DialogNote from "./DialogNote"

const ItemCard : React.FC<any> = ({ele, idx, lastStatus, update }) => {
    const dispatch = useDispatch()
    const { items } = useSelector((state : RootState) => state.fulfillment)

    const [defaultQty, setDefaultQty ] = useState<number>(0);
    const [quantity, setQuantity] = useState<number>(ele.quantity);
    const [price, setPrice] = useState<any>(0);
    const [discountType, setDiscountType] = useState("");
    const [focused, setFocused] = React.useState(false)
    const [focusedPrice, setFocusedPrice] = React.useState(false)
    const [focusedNote, setFocusedNote] = React.useState(false)
    const [note, setNote] = useState("");
    const [detailNote, setDetailNote] = useState();
    const [dialogNote, setDialogNote] = useState(false);

    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false) 
    const onFocusPrice = () => setFocusedPrice(true)
    const onBlurPrice = () => setFocusedPrice(false)  
    const onFocusNote = () => setFocusedNote(true)
    const onBlurNote = () => setFocusedNote(false)

    const onChangeQuantity = (e : any, ele : any) => {
        let value = parseFloat(e.target.value || 0);
        let payload = {
            id : ele._id,
            quantity : value,
            items : items,
        }
        if(value >= 0) {
            if(discountType === "SPECIAL") {
                dispatch(updateQuantityDiscountSpecial(payload))
            } else if (discountType === "PERCENTAGE") {
                dispatch(updateQuantityDiscountPercentage(payload))
            } else {
                dispatch(updateQuantityDiscountAmount(payload))
            }
        }
    }

    
    const onChangePrice = (value : any) => {
        if(value === isNaN || value === "") {
          return setPrice("0")
        } else {
          return setPrice(value.replace(/,/g, ""));
        }
      }

    const onPlus = async (ele:any, qty:number) => {
        let payload = {
            id : ele._id,
            quantity : qty + 1,
            items : items,
        }
        
            if(discountType === "SPECIAL") {
                dispatch(updateQuantityDiscountSpecial(payload))
            } else if (discountType === "PERCENTAGE") {
                dispatch(updateQuantityDiscountPercentage(payload))
            } else {
                dispatch(updateQuantityDiscountAmount(payload))
            }
        
    }

    const onMinus = async (ele:any, qty:number) => {
        let payload = {
            id : ele._id,
            quantity : (Number(qty) - 1),
            items : items,
        }
        if(Math.trunc(qty) > 0) {
            if(discountType === "SPECIAL") {
                dispatch(updateQuantityDiscountSpecial(payload))
            } else if (discountType === "PERCENTAGE") {
                dispatch(updateQuantityDiscountPercentage(payload))
            } else {
                dispatch(updateQuantityDiscountAmount(payload))
            }
        }
    }

    useEffect(() => {
        setDiscountType(ele.discount_type)
        setQuantity(ele.quantity)
        if(ele.discount_type === "SPECIAL") {
            setPrice(ele.discount)
        } else {
            setPrice(ele.retail_price)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ele]);

    useEffect(() => {
        setDefaultQty(ele.quantity)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if(defaultQty !== 0) {
            if(!focused) {
                if(quantity < 0 ) {
                    setQuantity(ele.quantity)
                } else if(quantity > defaultQty) {
                    setQuantity(quantity)
                    let payload = {
                        id : ele._id,
                        quantity : quantity,
                        items : items,
                    }
                    if(discountType === "SPECIAL") {
                        dispatch(updateQuantityDiscountSpecial(payload))
                    } else if (discountType === "PERCENTAGE") {
                        dispatch(updateQuantityDiscountPercentage(payload))
                    } else {
                        dispatch(updateQuantityDiscountAmount(payload)) 
                    }
                } else {
                    let payload = {
                        id : ele._id,
                        quantity : quantity,
                        items : items,
                    }
                    if(discountType === "SPECIAL") {
                        dispatch(updateQuantityDiscountSpecial(payload))
                    } else if (discountType === "PERCENTAGE") {
                        dispatch(updateQuantityDiscountPercentage(payload))
                    } else {
                        dispatch(updateQuantityDiscountAmount(payload))
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focused, ]);

    useEffect(() => {
        if(!focusedPrice) {
            if(price !== ele.retail_price) {
                let payload = {
                    id : ele._id,
                    price : price,
                    items : items,
                } 
                if(discountType === "SPECIAL") {
                    dispatch(updatePriceDiscountSpecial(payload))
                } else if (discountType === "PERCENTAGE") {
                    dispatch(updatePriceDiscountPercentage(payload))
                } else {
                    dispatch(updatePriceDiscountAmount(payload))
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusedPrice]);

    useEffect(() => {
        if(!focusedNote) {
            let payload = {
                id : ele._id,
                note : note,
                items : items,
            }
            dispatch(changeNoteItem(payload))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusedNote, note]);


    // sum total price percentage change inbound
    function totalPercentageInbound(params:any) {
        if(params.extraDiscountType === "AMOUNT") {
            return (params.inbound.retail_price - ((params.discount / 100) * params.inbound.retail_price + params.extraDiscount)) * params.inbound.quantity
        } else {
            return (params.inbound.retail_price - (((params.discount + params.extraDiscount) / 100) * params.inbound.retail_price)) * params.inbound.quantity
        }
    }

    // sum total price amount change inbound
    function totalAmountInbound(params:any) {
        return ((params.inbound.retail_price - (params.discount + params.extraDiscount )) * params.inbound.quantity) || 0
    }

    // sum total price special change inbound
    function totalSpecialInbound(params:any) {
        return (params.inbound.discount * params.inbound.quantity) || 0
    }

    function checkLastObject(arrayOfObjects:any) {
        const lastObjectIndex = arrayOfObjects.length - 1;
        const lastObject = arrayOfObjects[lastObjectIndex];
        return lastObject;
    }

    function checkStatusExists(status:any, arrayOfObjects:any) {
        if(arrayOfObjects.length && checkLastObject(arrayOfObjects).name === status) {
            return true
        }
        return false
    }

    return (
        <Box 
            className={`
                item-product 
                ${update && 'active-update'} 
                ${checkStatusExists("Inbound Item", ele.statuses) && 'approval'} 
                ${checkStatusExists("Accept Item Change", ele.statuses) && lastStatus === "Pick" && 'approved'}
            `} 
            key={idx} 
        > 
            <Box 
                component="img"
                src={ele.images[0]}
            />
            <Box className='right-product'>
                <Box className='title-product'>
                    <h2>{ele.name}</h2>
                </Box>
                { ele.discount_type === "PERCENTAGE" && ele.discount !== 0  ?  
                <Box className={`price ${ele.inbound && ele.inbound.retail_price !== ele.retail_price && 'inbound'}`}>
                    { ele.extraDiscountType === "AMOUNT" ? 
                    <Box sx={{display:'flex'}}>
                        <Box className='discount'>
                            <h3>{ele.discount}%</h3> 
                        </Box> 
                        <Box mx={0.5}>&</Box>
                        <Box className='discount-amount'>
                            <h3>- Rp.{ele.extraDiscount.toLocaleString()}</h3>
                        </Box> 
                    </Box> :
                    <Box className='discount'>
                        <h3>{ele.discount + ele.extraDiscount}%</h3> 
                    </Box> }
                    { update ?
                    <Box className="input-price-discount"> 
                        <p>Rp.</p>
                        <input 
                            type="text" 
                            onFocus={onFocusPrice} 
                            onBlur={onBlurPrice} 
                            value={parseFloat(price).toLocaleString()}
                            onChange={(e) => onChangePrice(e.target.value)} 
                        />
                    </Box> :
                    <Box className='title-discount'>
                        <h2>Rp. {ele.retail_price.toLocaleString()}</h2>
                    </Box> }
                    { ele.extraDiscountType === "AMOUNT" ? 
                    <Box className='title-price'>
                        <h1>Rp. {(ele.retail_price - (ele.retail_price * ((ele.discount) / 100)) - ele.extraDiscount).toLocaleString()}</h1> 
                    </Box> :
                    <Box className='title-price'>
                        <h1>Rp. {(ele.retail_price - (ele.retail_price * ((ele.discount + ele.extraDiscount) / 100))).toLocaleString()}</h1> 
                    </Box> }
                    {/* <Box className='title-price'>
                        <h1>Rp. {Math.round((ele.retail_price - (ele.retail_price * ((ele.discount + ele.extraDiscount) / 100)))).toLocaleString()}</h1> 
                    </Box> */}
                </Box> 
                :  ele.discount_type === "AMOUNT" && ele.discount !== 0 ? 
                <Box className={`price ${ele.inbound && ele.inbound.retail_price !== ele.retail_price && 'inbound'}`}>
                    <Box className='discount-amount'>
                        <h3>- Rp.{(ele.discount + (ele.extraDiscount)).toLocaleString()}</h3>
                    </Box> 
                    { update ?
                    <Box className="input-price-discount">
                        <p>Rp.</p>
                        <input 
                            type="text" 
                            onFocus={onFocusPrice} 
                            onBlur={onBlurPrice} 
                            value={parseFloat(price).toLocaleString()}
                            onChange={(e) => onChangePrice(e.target.value)} 
                        />
                    </Box> :
                    <Box className='title-discount'>
                        <h2>Rp.{ele.retail_price.toLocaleString()}</h2>
                    </Box> }
                    <Box className='title-price'>
                        <h1>Rp.{(ele.retail_price - (ele.discount + ele.extraDiscount)).toLocaleString()}</h1> 
                    </Box>
                </Box> 
                :  ele.discount_type === "SPECIAL" ? 
                <Box className={`price ${ele.inbound && ele.inbound.discount !== ele.discount && 'inbound'}`}>
                    <Box className='discount'>
                        <h3>SPECIAL</h3>
                    </Box> 
                    { update ?
                    <Box className="input-price-discount">
                        <p>Rp.</p>
                        <input 
                            type="text" 
                            onFocus={onFocusPrice} 
                            onBlur={onBlurPrice} 
                            value={parseFloat(price).toLocaleString()}
                            onChange={(e) => onChangePrice(e.target.value)} 
                        />
                    </Box> :
                    <Box ml={1} className='title-price'>
                        <h1>Rp.{ele.discount.toLocaleString()}</h1>
                    </Box> }
                </Box> :
                <Box className={`price ${ele.inbound && ele.inbound.retail_price !== ele.retail_price && 'inbound'}`}>
                    { update ?
                    <Box className="input-price">
                        <p>Rp.</p>
                        <input 
                            type="text" 
                            onFocus={onFocusPrice} 
                            onBlur={onBlurPrice} 
                            value={parseFloat(price).toLocaleString()}
                            onChange={(e) => onChangePrice(e.target.value)} 
                        />
                    </Box> :
                    <Box className='title-price'>
                        <h1>Rp.{ele.retail_price.toLocaleString()}</h1> 
                    </Box> }
                </Box> }

                { ele.inbound && (ele.inbound.retail_price !== ele.retail_price || ele.inbound.discount !== ele.discount) &&
                <Box>
                    { ele.discount_type === "PERCENTAGE" && ele.discount !== 0  ?  
                    <Box className='price' pt={0.5}>
                        <Box className='discounts'>
                        </Box>
                        <Box className='title-discount'>
                            <h2>Rp. {ele.inbound.retail_price.toLocaleString()}</h2>
                        </Box> 
                        { ele.extraDiscountType === "AMOUNT" ? 
                            <Box className='title-price'>
                                <h1>Rp. {(ele.inbound.retail_price - ((ele.inbound.discount / 100) * ele.inbound.retail_price + ele.inbound.extraDiscount)).toLocaleString()}</h1> 
                            </Box> :
                            <Box className='title-price'>
                            <h1>Rp. {(ele.inbound.retail_price - (((ele.inbound.discount + ele.inbound.extraDiscount) / 100) * ele.inbound.retail_price)).toLocaleString()}</h1> 
                        </Box> }
                       
                    </Box> 
                    :  ele.discount_type === "AMOUNT" && ele.discount !== 0 ? 
                    <Box className='price' pt={0.5}>
                        <Box className='discounts'>
                        </Box> 
                        <Box pl={2} className='title-discount'>
                            <h2>Rp.{ele.inbound.retail_price.toLocaleString()}</h2>
                        </Box> 
                        <Box className='title-price'>
                            <h1>Rp.{(ele.inbound.retail_price - (ele.inbound.discount + ele.inbound.extraDiscount)).toLocaleString()}</h1> 
                        </Box>
                    </Box> 
                    :  ele.discount_type === "SPECIAL" ? 
                    <Box className='price' pt={0.5}>
                        <Box className='discounts'>
                        </Box> 
                        <Box pl={5} className='title-price'>
                            <h1>Rp.{ele.inbound.discount.toLocaleString()}</h1> 
                        </Box>
                    </Box> :
                    <Box className='price'>
                        <Box className='title-price'>
                            <h1>Rp.{ele.inbound.retail_price.toLocaleString()}</h1> 
                        </Box> 
                    </Box> }
                </Box> }

                <Box className={`bottom-content ${ele.inbound && (ele.inbound.quantity !== ele.quantity || ele.inbound.discount !== ele.discount || ele.inbound.retail_price !== ele.retail_price  ) && 'inbound'}`}>
                    <Box className="left-box">
                        { update ?
                        <Box className="input-note">
                            <input
                                type="text" 
                                placeholder="Note" 
                                onFocus={onFocusNote} 
                                onBlur={onBlurNote} 
                                value={note} 
                                onChange={(e) => setNote(e.target.value)} 
                            />
                        </Box> : null }
                    </Box>
                    <Box className="right-box">
                        { ele.total_tax === 0 ? null : 
                        <>
                        <Box className='total-tax'>
                            <h2>+PPN {ele.tax.label} = Rp.{ele.total_tax.toLocaleString()}</h2>
                        </Box>
                        <Box className='line-divide' />
                        </>
                        }
                        <Box className="total-prices">
                            <h2>Rp.{(ele.total - ele.total_tax).toLocaleString()}</h2>
                        </Box>
                        <Box className='line-divide' />
                        { update ?
                        <Box className="quantity-btn">
                            <Box 
                                className="minus-btn"
                                onClick={() => {
                                    onMinus(ele,quantity)
                                }}
                            >
                                <Box
                                    component="img"
                                    src={minus} 
                                    alt="minus"
                                />
                            </Box>
                            <Box className="input-qty">
                                <input 
                                    type="number" 
                                    onFocus={onFocus} 
                                    onBlur={onBlur} 
                                    value={quantity} 
                                    onChange={(e) => onChangeQuantity(e, ele)} 
                                />
                            </Box>
                            <Box
                                className="minus-btn" 
                                onClick={() => {
                                    onPlus(ele,quantity)
                                }}
                            >
                                <Box
                                    component="img"
                                    src={plus} 
                                    alt="plus"
                                />
                            </Box>
                            <Box ml={1}>
                                <p>{ele.unit || "N/A"}</p>
                            </Box>
                        </Box> 
                        :
                        <Box className={`total-qty ${ele.received && ele.received !== ele.quantity && (lastStatus === "Delivered" || lastStatus === "Completed") && 'total-qty-linethrough'}`}>
                            <h2>{ele.quantity.toLocaleString()} {ele.unit || "N/A"}</h2>
                        </Box>
                        }
                    </Box>
                </Box>

                { checkStatusExists("Inbound Item", ele.statuses) && 
                <Box className='bottom-content'>
                    <Box/>
                    <Box className="right-box">
                        { ele.discount_type === "PERCENTAGE" ?
                        <>
                        { ele.total_tax === 0 ? null : 
                        <>
                            <Box className='total-tax'>
                                <h2>+PPN {ele.tax.label} = Rp.{(totalPercentageInbound(ele) * ele.tax.value).toLocaleString()}</h2>
                            </Box>
                            <Box className='line-divide' />
                        </>
                        }
                        <Box className='total-prices'>
                            <h2>Rp.{totalPercentageInbound(ele).toLocaleString()}</h2> 
                        </Box> 
                        </>
                        :  ele.discount_type === "AMOUNT" && ele.discount !== 0 ? 
                        <>
                        { ele.total_tax === 0 ? null : 
                        <>
                            <Box className='total-tax'>
                                <h2>+PPN {ele.tax.label} = Rp.{(totalAmountInbound(ele) * ele.tax.value).toLocaleString()}</h2>
                            </Box>
                            <Box className='line-divide' />
                        </>
                        }
                        <Box className='total-prices'>
                            <h2>Rp.{totalAmountInbound(ele).toLocaleString()}</h2> 
                        </Box>
                        </>
                        :
                        <>
                        { ele.total_tax === 0 ? null : 
                        <>
                            <Box className='total-tax'>
                                <h2>+PPN {ele.tax.label} = Rp.{(totalSpecialInbound(ele) * ele.tax.value).toLocaleString()}</h2>
                            </Box>
                            <Box className='line-divide' />
                        </>
                        }
                        <Box className='total-prices'>
                            <h2>Rp.{totalSpecialInbound(ele).toLocaleString()}</h2> 
                        </Box> 
                        </> }

                        { ele.inbound.quantity === ele.quantity ? null : 
                        <>
                        <Box className='line-divide' />
                        <Box className='total-qty'>
                            <h2>{ele.inbound.quantity.toLocaleString()} {ele.unit || "N/A"}</h2>
                        </Box> 
                        </> }
                        <Box className='line-divide' />
                        <Box className="print-btn-note" >
                            <button onClick={() => {
                                setDetailNote(ele.inbound.note_inbound)
                                setDialogNote(true)
                            }} >
                                <p>View Note</p>
                            </button>
                        </Box>
                        <Box className='line-divide' />
                        <Box className="print-btn-status" >
                            <button>
                                <p>Waiting Approval</p>
                            </button>
                        </Box>
                    </Box>
                </Box> }

                { ele.received && (lastStatus === "Delivered" || lastStatus === "Completed") ?
                <Box className='bottom-content'>
                    <Box/>
                    <Box className="right-box">
                        <Box className="received-status" >
                            <p>Received </p>
                        </Box>
                        <Box className='line-divide' />
                        <Box className='total-qty-received'>
                            <h2>{ele.received.toLocaleString()} {ele.unit || "N/A"}</h2>
                        </Box>
                    </Box>
                </Box> : null }

                { checkStatusExists("Accept Item Change", ele.statuses) && lastStatus === "Pick"  ?
                <Box className='bottom-content'>
                    <Box/>
                    <Box className="right-box">
                        <Box className="print-btn-status" >
                            <button>
                                <p>Item Change Approved</p>
                            </button>
                        </Box>
                    </Box>
                </Box> : null }
            </Box>
            <DialogNote open={dialogNote} setOpen={setDialogNote} note={detailNote} />
        </Box>
    )
}

export default ItemCard