import { createAsyncThunk } from '@reduxjs/toolkit';
import { userCredentials } from '../../../../utilities/config';
import axios from 'axios';

const headers : any = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }


export const getAllTokenCredentials = createAsyncThunk(
    'tokens/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/access-token`, {
                params,
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);

export const createNewTokenCredential = createAsyncThunk(
    'tokens/create',
    async (body:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/access-token`, body, {
                headers
            })
            if(response) { 
                return true
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const updateTokenCredential = createAsyncThunk(
    'tokens/update',
    async (body:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/access-token/${body.id}`, body.data, {
                headers
            })
            if(response) { 
                return true
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const removeTokenCredential = createAsyncThunk(
    'tokens/remove',
    async (id:string, { rejectWithValue }) => {
        try {
            const response : any = await axios.delete(`${process.env.REACT_APP_API_SERVER}/access-token/${id}`, {
                headers
            })
            if(response) { 
                return true
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);
