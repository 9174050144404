import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import { updateAddressSnackBar } from '../profileSlice'
import swal from 'sweetalert'
import FormNumbering from './FormNumbering'
import { getSettingCustomNumberDN, updateSettingCustomNumberDN } from '../reducers/profilesReducers'

const DeliveryNoteNumbering : React.FC<any> = ({profile}) => {

    const dispatch = useDispatch()
    const { 
        loading_delivery_number, delivery_number, 
        loading_update_delivery_number, update_delivery_number 
    } = useSelector((state : RootState) => state.profile)

    const [valueForm, setValueForm] = useState<any>({
        counterNumberOfDigits : "4",
        counterMaxValue : 9999,
        counterResetValue : "1",
        startingNumber : "0001",
        counterReset : "yearly",
        result : ""
    });

    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState(false);

    const onClickSave = (e:any) => {
        e.preventDefault()
        let body = {
            "company": profile._id,
            "format": inputValue,
            "counterNumberOfDigits": parseInt(valueForm.counterNumberOfDigits),
            "counterResetValue": parseInt(valueForm.counterResetValue),
            "counterMaxValue": valueForm.counterMaxValue,
            "startingNumber": valueForm.startingNumber,
            "counterReset": valueForm.counterReset,
        }
        if(!error) {
            if(parseInt(valueForm.counterNumberOfDigits) !== valueForm.startingNumber.length) {
                swal('Error', 'Counter starting value must be the same with digits of counter number!', 'error')
                return
            } else if (parseInt(valueForm.counterNumberOfDigits) !== valueForm.counterMaxValue.toString().length) {
                swal('Error', 'Counter max value must be the same with digits of counter number!', 'error')
                return
            } else {
                dispatch(updateSettingCustomNumberDN(body))
            }
        }
    }

    function fetchData() {
        if(profile._id) {
            dispatch(getSettingCustomNumberDN(profile._id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(update_delivery_number) {
            setTimeout(() => {
                // fetchData()
                dispatch(updateAddressSnackBar())
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update_delivery_number]);


    useEffect(() => {
        if(!isEmpty(delivery_number)) {
            setValueForm({
                counterNumberOfDigits : delivery_number.counterNumberOfDigits,
                counterMaxValue : delivery_number.counterMaxValue,
                counterResetValue : delivery_number.counterResetValue,
                startingNumber : delivery_number.startingNumber,
                counterReset : delivery_number.counterReset,
                result : delivery_number.result
            })
            setInputValue(delivery_number.format)
        }
    }, [delivery_number]);

    // create a function check object is not empty
    const isEmpty = (obj:any) => {
        return Object.keys(obj).length === 0;
    }


    return (
    <div>
        <FormNumbering 
            valueForm={valueForm}
            setValueForm={setValueForm}
            inputValue={inputValue}
            setInputValue={setInputValue}
            onClickSave={onClickSave}
            loading_update={loading_update_delivery_number}
            loading={loading_delivery_number}
            update={update_delivery_number}
            error={error}
            setError={setError}
            name="Deliver Note"
        />
    </div>
  )
}

export default DeliveryNoteNumbering