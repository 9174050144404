import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {  Box, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  TextField,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { RootState } from '../../../app/store';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { userCredentials } from '../../../utilities/config';
import { signInAction } from '../../auth/redux/authReducers';


const validationSchema = yup
  .object({
    password: yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
  })
  .required();

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogResignin : React.FC<any> = ({ 
  open, setOpen, data
 }) => {

  const dispatch = useDispatch()
  const { signin, isRegistered, loading_signin, error_signin } = useSelector((state : RootState) => state.auth )
  
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (values: any): void => {
    let data_params = {
      email : userCredentials.email,
      password : values.password,
      shadow : true,
      company_id : data.company_id,
      role_id : data.role_id,
    }
    dispatch(signInAction(data_params))
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  
  useEffect(() => {
    if (signin && isRegistered) {
      setTimeout(() => {
        window.location.href = '/dashboard'
      },200);
    }
    // eslint-disable-next-line
  }, [isRegistered]);

    const handleClose = () => {
      setOpen(false);
    };

    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">Re-Sign in</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
          <Box py={1}>Please confirm your password again to continue!</Box>
          <Box className='form-auth' >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box >
                  <TextField
                      error={!!errors.password}
                      helperText={errors.password && errors.password.message}
                      {...register('password', { required: true })}
                      margin="dense"
                      fullWidth
                      name="password"
                      placeholder="Password"
                      id="password"
                      autoComplete="current-password"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                      endAdornment : (
                          <InputAdornment position="end">
                          <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                          >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                          </InputAdornment>
                      )
                      }}
                      size="small"
                  />

                  { error_signin === null ? null :
                  <Box pt={1} sx={{ minWidth: 350 }}>
                      <Alert severity="error">{error_signin}</Alert>
                  </Box> }
                  <Box className='button-auth'>
                    <button type='submit' disabled={loading_signin}>
                      <p> Re-Sign In </p>
                      { loading_signin && <CircularProgress size={20} color="inherit" style={{ marginLeft: 10}} /> }
                    </button>
                  </Box>
              </Box>
            </form>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export default DialogResignin;