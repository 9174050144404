import * as React from 'react';
import { Box, Paper, StepButton } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import BasicInformation from './BasicInformation';
import { RootState } from '../../../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import CoverageInformation from './CoverageInformation';
import SummarySection from './SummarySection';
import AssignUser from './AssignUser';
import { changeStepWarehouse } from '../warehouseSlice';
import { useLocation } from 'react-router-dom';
import DeliverySchedules from './DeliverySchedules';

const steps = ['Basic Information', 'Warehouse Coverage', 'Delivery Schedules', 'Assign User', 'Summary'];

export default function StepperWarehouse() {
    const dispatch = useDispatch()
    const { step } = useSelector((state : RootState) => state.warehouse)

    const location : any = useLocation()
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    
    let query = useQuery();
    let active_page = query.get('page')

    const onClickStep = (e: any) => {
      if(active_page === 'edit') {
        dispatch(changeStepWarehouse(e))
      }
    }
    return (
    <Box sx={{ width: '100%' }}>
        <Paper sx={{p:3, }}>
            <Stepper activeStep={step} sx={{ display: 'flex', flexWrap: 'wrap', rowGap: 1, columnGap: 1 }}>
              {steps.map((label, index) => (
                <Step key={label} disabled={false}>
                  <StepButton color="inherit" onClick={() => onClickStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
        </Paper>

      { step === 0 ? (
        <React.Fragment>
          <BasicInformation />
        </React.Fragment>
      ) : step === 1 ? (
        <React.Fragment>
          <CoverageInformation/>
        </React.Fragment>
      ) : step === 2 ? (
        <React.Fragment>
          <DeliverySchedules/>
        </React.Fragment>
      ) : step === 3 ? (
        <React.Fragment>
          <AssignUser/>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <SummarySection/>
        </React.Fragment>
      )}
    </Box>
  );
}
