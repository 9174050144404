import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import store from '../../../../assets/img/icon/store.png'
import ChatButton from '../../../../components/ChatButton';
import { checkValidation } from '../../../../utilities/userFeaturesValidation';
import DialogDetail from './DialogDetail';
import DialogEdit from './DialogEdit';

const CardPreferred : React.FC<any> = ({ item }) =>  {

    const [dialogDetail, setDialogDetail] = useState(false);
    const [dialogEdit, setDialogEdit] = useState(false);
    const [dialogName, setDialogName] = useState("");

    function getStyle (name:string) {
        switch (name) {
            case 'ACTIVE':
                return {  backgroundColor : '#43a047' }
            case 'REQUESTED':
                return {  backgroundColor : '#fb8500' }
        }
    }


    function amountCreditUsed(params:any) {
        let increase = params.filter((item:any) => item.type === "INCREASE")
        let decrease = params.filter((item:any) => item.type === "DECREASE")
        let totalIncrease = increase.reduce((a:any, b:any) => a + b.amount, 0)
        let totalDecrease = decrease.reduce((a:any, b:any) => a + b.amount, 0)
        return (totalIncrease - totalDecrease).toLocaleString()
    }

    return (
    <div className={`container-item-preferred ${item.status === "REQUESTED" && 'requested'}`}>
        <Box className="top-div">
            <Box className='left'>
                <h5>Company Code : {item.preferred.code || "N/A"}</h5>
            </Box>
            <Box className='right'>
                <Box className='status' sx={getStyle(item.status)}>
                    <h6>{item.status}</h6>
                </Box>
            </Box>
        </Box>
        <Box className="center-div">
            <Grid container spacing={1}>
                <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                    <Box className='left-box'>
                        <Box className='store'>
                            <img src={item.preferred.logo || store} alt="store" />
                            <Box className='name'>
                                <h2>{item.preferred.legalName}</h2>
                                <Box className='chat'>
                                    <ChatButton company={item.preferred} />
                                </Box>
                            </Box>
                        </Box>
                        <Box className='address'>
                            <h2>{(item.preferred && item.preferred.address.label) || "-"}</h2> 
                            <p>{(item.preferred && item.preferred.address.street) || "-"}, {(item.preferred && item.preferred.address.city.toLowerCase()) || "-"}</p>
                            <p>{(item.preferred && item.preferred.address.province )|| "-"}, {(item.preferred && item.preferred.address.country) || ""}, {(item.preferred && item.preferred.address.postcode) || "-"}</p>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                    <Box className='right-box'>
                        <Box className='total-discount'>
                            <p>Discount Applied</p>
                            <h3>: {item.initial_discount}%</h3>
                        </Box>
                        <Box className='total-discount'>
                            <p>Payment Term</p>
                            <h3>: {item.payment_term.label}</h3>
                        </Box>
                        <Box className='total-discount'>
                            <p>Down Payment</p>
                            <h3>: {item.down_payment || "0"}%</h3>
                        </Box>
                        <Box className='total-discount'>
                            <p>Minimum Total Order</p>
                            { item.minimum_total_order && item.minimum_total_order.isActive ?  
                            <h3>: Rp. {item.minimum_total_order ? item.minimum_total_order.amount.toLocaleString() : "0"}</h3> :
                            <h3>: Not Active</h3> }
                        </Box>
                        <Box className='total-discount'>
                            <p>Credit Limit</p>
                            { item.credit_limit && item.credit_limit.isActive ?  
                            <h3>: Rp. {item.credit_limit ? item.credit_limit.amount.toLocaleString() : "0"}</h3> :
                            <h3>: Not Active</h3> }
                        </Box>
                        { item.credit_limit && item.credit_limit.isActive ?  
                        <Box className='total-discount'>
                            <p>Credit Used</p>
                            <h3>: Rp. {amountCreditUsed((item.historyLimitCredit && item.historyLimitCredit) || [])}</h3> 
                        </Box> : null }
                        <Box className='total-discount'>
                            <p>Invoice Generation</p>
                            <h3>: {(item.invoice && item.invoice.options) || "Manual"} ({(item.invoice && item.invoice.config.period === "EOM" ? "End of Month" : "End of Week") || "End of Month"}) </h3>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        { item.status === "REQUESTED" ? 
        <Box className='bottom-div'>
            <Box className='left-div' />
            <Box className='right-div'>
                {checkValidation('UPDPREBUY772387') &&
                <Box className='btn-confirm' >
                    <button 
                        onClick={() => {
                            setDialogEdit(true)
                            setDialogName("Edit Preferred")
                        }}
                    >
                        <p>Confirm Requst</p>
                    </button>
                </Box> }
            </Box>
        </Box> :
        <Box className='bottom-div'>
            <Box className='left-div' />
            <Box className='right-div'>
                { checkValidation('UPDPREBUY772387') &&
                <Box 
                    className='view-btn' 
                    onClick={() => {
                        setDialogEdit(true)
                        setDialogName("Edit Preferred")
                    }}
                >
                    <p>Edit</p>
                </Box> }
                <Box className='btn-confirm' >
                    <button 
                        onClick={() => {
                            setDialogDetail(true)
                            setDialogName("Detail Product Discount")
                        }}
                    >
                        <p>View Product Discount</p>
                    </button>
                </Box>
            </Box>
        </Box> }

        <DialogDetail  
            open={dialogDetail} 
            setOpen={setDialogDetail} 
            name={dialogName} 
            detail={item}
        />

        <DialogEdit  
            open={dialogEdit} 
            setOpen={setDialogEdit} 
            name={dialogName} 
            detail={item}
        />
    </div>
  )
}

export default CardPreferred