import { 
    Stack, 
    Paper, 
    Box, 
    Grid,
    Skeleton,
    Typography,
} from '@mui/material';

const SkeletonDetailProducts = () => {

    return (
        <Box mt={2}>
                <Grid container spacing={3}>
                <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
                        <Paper elevation={2} sx={{p:3, borderRadius: 3, my: 1}}>
                            <Box pb={1} fontWeight="bold" fontSize={15}>Product Images</Box>
                            <Stack flexDirection="row" flexWrap="wrap" >
                            {[0,1,2,3,4].map((item:any, index:any) => (
                                <Box className='image-preview' mr={1} mb={1} key={index}>
                                    <Skeleton 
                                        variant="rectangular" 
                                        width={131} height={125} 
                                        sx={{ borderRadius: '4px' }}
                                    />
                                </Box> 
                            ))}
                            </Stack>
                        </Paper>
                        <Paper elevation={2} sx={{p:3, borderRadius: 3, mt:3}}>
                            <Box pb={1} fontWeight="bold" fontSize={15}>Warehouses</Box>
                            <Stack flexDirection="column" >
                            {[0,1].map((item:any, index:any) => (
                                <Box className='warehouse-preview' mb={2} key={index}>
                                    <Stack flexDirection="row" alignItems="center">
                                        <Skeleton 
                                            variant="rectangular" 
                                            width={50} height={50} 
                                            sx={{ borderRadius: '4px' }}
                                        />
                                        <Box pl={1} fontSize={20}>
                                            <Skeleton width={100} />
                                        </Box>
                                    </Stack>
                                    <Box pt={2}>
                                        <Grid container columnSpacing={3} rowSpacing={2}>
                                            <Grid item xl={4} lg={4} xs={12}>
                                                <Typography variant="body2" color="#95a4b2" fontWeight="400">Retail Price</Typography>
                                                <Typography variant="body1" fontWeight="500" fontSize={18}>
                                                    <Skeleton width={100} />
                                                </Typography>
                                            </Grid>
                                            <Grid item xl={4} lg={4} xs={12}>
                                                <Typography variant="body2" color="#95a4b2" fontWeight="400">Discount</Typography>
                                                <Skeleton width={100} />
                                            </Grid>
                                            <Grid item xl={4} lg={4} xs={12}>
                                                <Typography variant="body2" color="#95a4b2" fontWeight="400">Stock</Typography>
                                                <Skeleton width={100} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box pt={1}>
                                        <Grid container columnSpacing={3} rowSpacing={2}>
                                            <Grid item xl={4} lg={4} xs={12}>
                                                <Typography variant="body2" color="#95a4b2" fontWeight="400">Rack</Typography>
                                                <Skeleton width={100} />
                                            </Grid>
                                            <Grid item xl={4} lg={4} xs={12}>
                                                <Typography variant="body2" color="#95a4b2" fontWeight="400">Bin</Typography>
                                                <Skeleton width={100} />
                                            </Grid>
                                            <Grid item xl={4} lg={4} xs={12}>
                                                <Typography variant="body2" color="#95a4b2" fontWeight="400">Level</Typography>
                                                <Skeleton width={100} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    
                                </Box> 
                            ))}
                            </Stack>
                        </Paper>

                    </Grid>
                    <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
                        <Paper elevation={2} sx={{p:3, borderRadius: 3, my: 1}}>
                            <Box py={1}>
                                <Grid container columnSpacing={3} rowSpacing={2}>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Name</Typography>
                                        <Skeleton width={150} />
                                    </Grid>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">SKU</Typography>
                                        <Skeleton width={150} />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Grid container columnSpacing={3} rowSpacing={2}>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Brand</Typography>
                                        <Skeleton width={150} />
                                    </Grid>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Tax</Typography>
                                        <Skeleton width={150} />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Grid container columnSpacing={3} rowSpacing={2}>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Min. Order Quantity</Typography>
                                        <Skeleton width={150} />
                                    </Grid>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Down Payment</Typography>
                                        <Skeleton width={150} />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Grid container columnSpacing={3} rowSpacing={2}>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Payment Term</Typography>
                                        <Skeleton width={150} />
                                    </Grid>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Dimension</Typography>
                                        <Skeleton width={150} />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Grid container columnSpacing={3} rowSpacing={2}>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Weight</Typography>
                                        <Skeleton width={150} />
                                    </Grid>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Size</Typography>
                                        <Skeleton width={150} />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Grid container columnSpacing={3} rowSpacing={2}>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Volume</Typography>
                                        <Skeleton width={150} />
                                    </Grid>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Origin Country</Typography>
                                        <Skeleton width={150} />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Grid container columnSpacing={3} rowSpacing={2}>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Made Date</Typography>
                                        <Skeleton width={150} />
                                    </Grid>
                                    <Grid item xl={6} lg={6} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Expired Date</Typography>
                                        <Skeleton width={150} />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Grid container columnSpacing={3} rowSpacing={2}>
                                    <Grid item xl={12} lg={12} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Special Handling</Typography>
                                        <Skeleton width={150} />
                                    </Grid>
                                    <Grid item xl={12} lg={12} xs={12}>
                                        <Typography variant="body2" color="#95a4b2" fontWeight="400">Description</Typography>
                                        <Box pt={1}> 
                                            <Skeleton  />
                                            <Skeleton  />
                                            <Skeleton  />
                                            <Skeleton  />
                                            <Skeleton  />
                                            <Skeleton  />
                                            <Skeleton  />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Grid>
                    
                    
                </Grid>
        </Box>
    )
}

export default SkeletonDetailProducts;
