import { Skeleton, Box } from '@mui/material'
import React from 'react'

const CardPurchase : React.FC<any> = ({
    label, value, currency, status, loading, src
}) => {
    return (
        <div className='purchase-paper'>
            { status ?
            <Box pl={2} pr={2}>
                <div><h4>{label}</h4></div>
                { loading ?
                <Skeleton height={60} width={30} /> :
                <div><h1>{value}</h1></div> }
            </Box>
            :
            <Box p={1} className="purchase-info">
                <div>
                    <h4>{label}</h4>
                    { loading ?
                        <Skeleton height={42} />
                    :
                    <div>
                    {!currency ? 
                        <h3>{value}</h3> :
                        <h3>Rp. {parseInt(value).toLocaleString()}</h3>
                    }
                    </div> }
                </div>
                <div>
                    <img src={src} alt="icon" />
                </div>
            </Box>
            }
        </div>
    )
}

export default CardPurchase
