import { useEffect, useState } from 'react';
import { Avatar, Grid, Skeleton, Stack } from '@mui/material';
import { Box } from '@mui/material'
import BreadCrumbs from '../../../components/BreadCrumbs'
import HeadlineTitle from '../../../components/HeadlineTitle';
import ButtonCreate from '../../../components/ButtonCreate';
import DialogAddTeam from './components/DialogAddTeam'; 
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { blockUserTeams, getAllUsersTeams } from './redux/teamsReducers';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import SnackBarAlert from '../../../components/SnackBarAlert';
import { clearMessageTeam } from './redux/teamsSlice'; 
import EmptyData from '../../../components/EmptyData';
import { checkValidation } from '../../../utilities/userFeaturesValidation';

const TeamsPage = () => {
    const dispatch = useDispatch()
    const { 
        data, loading, loading_create, create, loading_update, update,
        loading_status, status
    } = useSelector((state: RootState) => state.users_team)

    const [limit] = useState(100);

    const [typeDialog, setTypeDialog] = useState("Add");
    const [openDialog, setOpenDialog] = useState(false);
    const [detail, setDetail] = useState<any>({});

    useEffect(() => {
        if(create || update || status) {
            setOpenDialog(false)
            setTypeDialog("")
            const params = {
                limit,
                skip : 0,
                sortTopic : "createdAt",
                sortValue : "ASC"
            }
            dispatch(getAllUsersTeams(params))
            setTimeout(() => {
                dispatch(clearMessageTeam())
            }, 1500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create, update, status]);

    useEffect(() => {
        const params = {
            limit,
            skip : 0,
            sortTopic : "createdAt",
            sortValue : "ASC"
        }
        dispatch(getAllUsersTeams(params))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUpdateStatusTeam = (item:any) => {
        let params = {
            id : item._id,
            body : {
                isBlock: !item.isBlocked
            }
        }
        dispatch(blockUserTeams(params))
    }

    return (
        <Box m={2}>
            <LoaderBackdrop loading={loading_create || loading_update || loading_status} />
            <BreadCrumbs
                isPage={false}
                current="Team Members"
            />
            <Stack direction="row" justifyContent="space-between" py={1} alignItems="center" flexWrap="wrap" >
                <Box>
                    <HeadlineTitle title='Team Members' variant="h6" bold="bold" />
                </Box>
                {checkValidation('CREUSETEA433312') &&
                <Box>
                    <ButtonCreate 
                        name="Add New Member"
                        onClick={() => {
                            setTypeDialog("Add")
                            setOpenDialog(true)
                        }}
                    />
                </Box> }
            </Stack>
            
            { loading ? 
            <LoadingCard />
            :
            <Box className='team-container'>
                <Grid container spacing={2}>
                    { data.map((item:any, index:number) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <Box className={`team-item ${!item.isBlocked && 'team-ownwer'}`} >
                            <Box className='team-item__image'>
                                <Avatar sx={{ width: 100, height: 100 }} alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                            </Box>
                            <Box className='team-item__name'>
                                <h1>{item.profile.name || "-"}</h1>
                                <p>{item.profile.email || "-"}</p>
                            </Box>
                            <Box className='team-item__role'>
                                <p>{item.hasRole.role.name || "-"}</p>
                            </Box>
                            <Box className='team-item__action'>
                                {checkValidation('UPDUSETEA874937') &&
                                <Box px={1}>
                                    <ButtonCreate 
                                        name="Edit" 
                                        onClick={() => {
                                            setDetail(item)
                                            setTypeDialog("Edit")
                                            setOpenDialog(true)
                                        }}
                                    />
                                </Box> }
                                {checkValidation('BLOUSETE153132') &&
                                <Box px={1}>
                                    <ButtonCreate 
                                        name={item.isBlocked  ? "Unblock" : "Block"}
                                        outlined={true}
                                        disabled={item.hasRole.role.name !== "Owner" ? false : true}
                                        onClick={() => onUpdateStatusTeam(item)}
                                    />
                                </Box> }
                            </Box>
                        </Box>
                    </Grid>
                    ))}
                    {data.length <= 1 &&
                    <Grid item xs={12} sm={6} md={8} lg={9} >
                        <EmptyData 
                            message="You dont have any team member yet."
                            caption="Add Team Member by clicking add button."
                        />
                    </Grid> }
                </Grid>
            </Box> }

            <DialogAddTeam 
                open={openDialog} 
                setOpen={setOpenDialog} 
                detail={detail} 
                type={typeDialog} 
            />

            <SnackBarAlert message={`New Team Member is successfuly created`} initialState={create} />
            <SnackBarAlert message={`Team Member is successfuly updated`} initialState={update} />
        </Box>
    )
}

const LoadingCard = () => {
    return (
        <Box className='team-container'>
            <Grid container spacing={2}>
                { [0,1,2,3].map((_, index:number) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Box className='team-item team-ownwers' >
                        <Box className='team-item__image'>
                            <Skeleton variant="circular" width={100} height={100} />
                        </Box>
                        <Box className='team-item__name'>
                            <Skeleton variant="text" width={200} height={30} />
                            <Skeleton variant="text" width={200} height={20} />
                        </Box>
                        <Box className='team-item__role'>
                            <Skeleton variant="text" width={175} height={25} />
                        </Box>
                        <Box className='team-item__action'>
                            <Box px={1}>
                                <Skeleton variant="text" width={100} height={40} />
                            </Box>
                            <Box px={1}>
                                <Skeleton variant="text" width={100} height={40} />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default TeamsPage
