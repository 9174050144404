import BreadCrumbs from "../../../components/BreadCrumbs";
import { useState, useEffect } from 'react';
import Stack from "@mui/material/Stack/Stack";
import Box from "@mui/material/Box/Box";
import HeadlineTitle from "../../../components/HeadlineTitle";
import ButtonCreate from "../../../components/ButtonCreate";
import DialogUploadImages from "./components/DialogUploadImages";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { getMediaImages } from "./redux/mediaCenterReducers";
import ImageList from "./components/ImageList";
import {Grid, Skeleton, Pagination} from "@mui/material";
import SnackBarAlert from "../../../components/SnackBarAlert";
import { resetMediaCenter } from "./redux/mediaCenterSlice";
import EmptyData from "../../../components/EmptyData";
import SearchComponent from "../../../components/SearchComponent";
import { checkValidation } from "../../../utilities/userFeaturesValidation";

function MediaCenter() {
  const dispatch = useDispatch()
  const { media, loading, upload, total } = useSelector((state:RootState) => state.media_center);

  const [limit] = useState(12);
  const [page, setPage] = useState(0);

  const [open, setOpen] = useState(false);
  const [valueSearch, setValueSearch] = useState("");

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value-1);
  };

  useEffect(() => {
    let param = {
      limit : limit, 
      skip : page,
      search : valueSearch.trim()
    }
    dispatch(getMediaImages(param))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    let param = {
      limit : limit, 
      skip : 0,
      search : valueSearch.trim()
    }
    dispatch(getMediaImages(param))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueSearch]);

  useEffect(() => {
    let param = {
      limit : limit, 
      skip : page,
    }
    if(upload) {
      setTimeout(() => {
        dispatch(getMediaImages(param))
        dispatch(resetMediaCenter())
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upload]);

  return (
    <Stack m={2}>
      <BreadCrumbs
          isPage={false}
          current="Media Center"
      />
      <Stack direction="row" justifyContent="space-between" py={1} alignItems="center" flexWrap="wrap" >
          <Box>
              <HeadlineTitle title='Media Center' variant="h6" bold="bold" />
          </Box>
          {checkValidation('CREMEDCE5341312') &&
          <Box>
            <ButtonCreate 
                name="Upload Images"
                onClick={() => setOpen(true)}
            />
          </Box> }
      </Stack>

      <Box>
        <SearchComponent 
          valueSearch={valueSearch}
          setValueSearch={setValueSearch}
          placeholder="Search image name ..."
        />
      </Box>
      
      <Box my={2}>
        {loading ? 
        <LoaderView /> :
        <Box>
          { media.length === 0 ?
          <EmptyData message="No images is found." caption="You can upload images from button Upload Images." /> :
          <>
          <ImageList images={media} />  
          { total > 12 &&
          <Box sx={{display:'flex', justifyContent: 'center', mt:4, mb:2}}>
              <Pagination count={Math.ceil(total/limit)} page={page+1} onChange={handleChange} />
          </Box> }
          </> }
        </Box>
        }
      </Box>
      <DialogUploadImages open={open} setOpen={setOpen} />
      <SnackBarAlert message="Successfully uploaded image." initialState={upload} />

    </Stack>
  );
};


export default MediaCenter;

const LoaderView = () => {
  return (
    <div>
      <Grid container spacing={1}>
          {[0,1,2,3,4,5,6,7,8,9,10,11].map((img:any) => (
              <Grid item xl={2} lg={3} md={4} sm={6} xs={12} key={img}>
                  <Skeleton variant="rectangular"  height={200} sx={{borderRadius:2}} />
              </Grid>
          ))}

      </Grid>
    </div>
  )
}