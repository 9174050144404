import { Box, Grid, } from '@mui/material';
import React from 'react';
import imagestore from '../../../../assets/img/placeholder.png'
import { ISelectOption, dataWarehouseResponse, objectCoverage, objectOperationTime, objectSchedule, objectUser } from './warehouseTypes';

interface SummaryProps {
    localData : dataWarehouseResponse
}

const Summary : React.FC<SummaryProps> = ({ localData }) => {
    console.log(localData, 'localData?')
    return (
    <div>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Box className='img-warehouse'>
                    <div>
                        <img 
                            alt="store"
                            src={localData?.imageUrl === "" ? imagestore : localData?.imageUrl}
                        />  
                    </div>
                    <div className='detail-warehouse'>
                        <h6>{localData?.name}</h6>
                        <p>{localData?.phone_number}</p>
                        <p>{localData?.email}</p>
                    </div>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box sx={{mt:2}}>
                    <h4>Address : </h4>
                    <p>{localData?.address.street},</p>
                    <p>{localData?.address.village}, {localData?.address.subdistrict}, {localData?.address.city}</p>
                    <p>{localData?.address.province}, {localData?.address.postcode}, {localData?.address.country}</p>
                </Box>
            </Grid>
            <Grid item xs={12} md={12}>
                <Box sx={{mt:2}}>
                    <h3>User Assigned :</h3>
                    { localData?.users && localData?.users ?
                    <Box>
                        { localData?.users.map((itm:objectUser,idx:number) => (
                            <Box key={idx} sx={{mt:1}}>
                                <Box sx={{textTransform: 'capitalize'}}>
                                    {itm.label} 
                                    <span>
                                    { idx !== itm?.label.length - 1 ? 
                                      <span>{","}</span> : null }
                                    </span>
                                </Box>
                            </Box>
                        ))}
                    </Box> : <p>Not Assigned</p> }
                </Box>
            </Grid>
            <Grid item xs={12} md={12}>
                <Box sx={{mt:2}}>
                    <h3>Coverage :</h3>
                    <Box>
                        { localData?.coverage.map((itm:objectCoverage,idx:number) => (
                            <Box key={idx} sx={{mt:1}}>
                                <h4>{itm.province}</h4>
                                <div className='item-coverage'>
                                { itm.cities.map((itm_city:ISelectOption,idx_city:number) => (
                                    <React.Fragment key={idx_city}>
                                    <p key={idx_city}>{itm_city.label.toLowerCase()}</p>
                                    <span>
                                    { idx_city !== itm.cities.length - 1 ? 
                                        <Box pr={1}>{","}</Box>
                                        : null }
                                    </span>
                                    </React.Fragment>
                                ))} 
                                </div>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Grid>
            { localData?.schedule && localData?.schedule ?
            <Grid item xs={12} md={12}>
                <Box sx={{mt:2}}>
                    <h3>Schedule :</h3>
                    <Box>
                        { localData?.schedule.map((itm:objectSchedule,idx:number) => (
                            <Box key={idx} sx={{mt:1}}>
                                <h4>Name : {itm.name}</h4>
                                <h4>Province : {itm.province}</h4>
                                <h4>Cities : </h4>
                                <div className='item-coverage'>
                                { itm.cities.map((itm_city:ISelectOption,idx_city:number) => (
                                    <React.Fragment key={idx_city}>
                                    <p key={idx_city}>{itm_city.label.toLowerCase()}</p>
                                    <span>
                                    { idx_city !== itm.cities.length - 1 ? 
                                        <Box pr={1}>{","}</Box>
                                        : null }
                                    </span>
                                    </React.Fragment>
                                ))} 
                                </div>
                                <h4>Schedule : </h4>
                                <Box className='contact'>
                                    <Box className='content-contact'>
                                        {itm.operationTime?.map((ele:objectOperationTime, i:number) => (
                                            <Box className='phone' key={i}>
                                                <h4 style={{width : '100px'}}>{ele.day}</h4>
                                                {ele.active ?
                                                <p>: <strong>{ele.open} AM - {ele.close} PM</strong></p> :
                                                <p>: <strong>Closed</strong></p> }
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Grid> : null }
        </Grid> 

    </div>
  )
}

export default Summary;