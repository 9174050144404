import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Stack } from '@mui/material';
import info from '../assets/img/icon/info.png';


const Info = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-start" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[1],
    color: 'rgba(0, 0, 0, 0.87)',
  },
}));

const HoverInfo : React.FC<any> = ({ component }) => {
  return (
    <div>
      <Info
        title={
          <React.Fragment>
            <Stack flexDirection="column" id='hover-info'>
              {component}
            </Stack>
          </React.Fragment>
        }
      >
        <img src={info} alt="plus" width={17} className="hover-img"/>
      </Info>
    </div>
  );
}


export default HoverInfo