import React, { useState } from 'react'
import imagestore from '../assets/img/placeholder.png'
import bannerstore from '../assets/img/banner.jpg'
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { styled } from '@mui/material/styles';
import { Skeleton } from '@mui/material';
import { v4 as uuid } from 'uuid';
import swal from 'sweetalert';
import Box from '@mui/material/Box';

const Input = styled('input')({
    display: 'none',
});

interface IBannerStore {
    logoUrl : string;
    bannerUrl : string;
    onChangeUrlValue : Function;
}

const StoreBanner : React.FC<IBannerStore> = ({
    logoUrl,
    bannerUrl,
    onChangeUrlValue,
}) => {

    const [loadingStore, setLoadingLogo] = useState(false);
    const [loadingBanner, setLoadingBanner] = useState(false);

    const onChangeStore = (e:any, name:any) => {
        e.preventDefault()
        if(name === "logo") {
            setLoadingLogo(true)
        } else {
            setLoadingBanner(true)            
        }
        if(e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/jpg") {
            const random = uuid();
            setTimeout(() => {
                var S3 = require("aws-sdk/clients/s3");
                const s3bucket = new S3({
                    endpoint: process.env.REACT_APP_S3_BUCKET_ENDPOINT,
                    accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
                    secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET
                });
            
                if (e.target.files && e.target.files[0]) {
                const blob = e.target.files[0]
                const file_name = blob.name.replace(/\s/g, "")
                const params = { Body: blob, 
                                Bucket: process.env.REACT_APP_S3_BUCKET_NAME, 
                                Key: process.env.REACT_APP_S3_FOLDER_NAME + random + file_name
                                };
                s3bucket.putObject(params)
                .on('build', (request : any) => {
                    request.httpRequest.headers.Host = process.env.REACT_APP_S3_API_URL
                    request.httpRequest.headers['Content-Length'] = blob.size;
                    request.httpRequest.headers['Content-Type'] = blob.type;
                    request.httpRequest.headers['Access-Control-Allow-Origin']= '*';
                    request.httpRequest.headers['x-amz-acl'] = 'public-read';
                })
                .send((err : any, data : any) => {
                    if (err){  
                        swal(`${err}`, `${err.stack}`, 'error')
                        setLoadingLogo(false)
                        setLoadingBanner(false)
                    } 
                    else {      
                        const imageUrl = `${process.env.REACT_APP_S3_CDN_URL}${process.env.REACT_APP_S3_FOLDER_NAME}${random}${file_name}`
                        onChangeUrlValue(imageUrl, name)
                        setLoadingLogo(false)
                        setLoadingBanner(false)
                    }}
                )} 
            }, 500);
        } else {
            setLoadingLogo(false)
            setLoadingBanner(false)
            swal("Error", "File type must be JPG, JPEG or PNG", "error")
        }

    }


    return (
    <div className='store-banner'>
        <div className='img-banner'>
            { loadingBanner ?  
            <div>
                <Skeleton 
                    sx={{ bgcolor: '#0091d6', borderRadius: 5 }} 
                    animation="wave" 
                    variant="rectangular" height={300}

                />
            </div> :
            <img  
                src={bannerUrl === "" ? bannerstore : bannerUrl}
                alt="banner" 
            /> }
            <div className='upload-btn-banner'>
                <label htmlFor="icon-button-banner" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
                    <Input accept="image/*" id="icon-button-banner" type="file"  onChange={(e) => onChangeStore(e, 'banner')}/>
                    <CameraAltIcon sx={{ height: 35, width: 35, color: '#000' }}/>
                </label>
            </div>
        </div>

        <div className='img-store'>
            { loadingStore ? 
            <div>
                <Skeleton 
                    sx={{ bgcolor: '#0091d6' }} 
                    animation="wave" variant="circular" 
                    width={150} height={150} 
                />
            </div> :
            <img 
                alt="store"
                src={logoUrl === "" ? imagestore : logoUrl}
            />  }

            <div className='upload-btn-store'>
                <label htmlFor="icon-button-store" style={{ cursor: 'pointer' }}>
                    <Input accept="image/*" id="icon-button-store" type="file"  onChange={(e) => onChangeStore(e, "logo")}/>
                    <CameraAltIcon sx={{ height: 35, width: 35, color: '#000' }}/>
                </label>
            </div>
        </div>

        <Box fontWeight="400" fontSize={15} sx={{display: 'flex', justifyContent: 'flex-end'}}>
            Recommend banner ratio is 600 x 160 Pixel.
        </Box>
    </div>
  )
}

export default StoreBanner