import {
    Box,
    TextField,
    CircularProgress,
} from '@mui/material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux'; 
import { RootState } from '../../../app/store';
import logo from '../../../assets/img/logo.png'
import { resetPasswordAction } from '../redux/authReducers';
import { useEffect } from 'react';
import logocolor from '../../../assets/img/logos.png'
import { Link } from 'react-router-dom';

const validationSchema = yup
  .object({
    email: yup.string()
      .required("Email is required")
      .email("Email is invalid")
  })
  .required(); 


const Forgot = () => {

  const dispatch = useDispatch()
  const { forgot, loading_forgot } = useSelector((state : RootState) => state.auth)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema)
  });
  

  const onSubmit = (data: any): void => {
    dispatch(resetPasswordAction(data.email))
  }

  useEffect(() => {
    if (forgot) {
     reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgot]);


    return (
      <div className='container-auth'>
      <div className='auth-box'>
          <div className='left-img'>
              <img alt="logo" src={logo} />
              <h1>Pasar Pemesanan</h1>
              <h2>Platform that provides services to <br/> ease the procurement process for the business.</h2>
          </div>
          <div className='right-content'>
              <div className='form-auth-container'>
                  <img alt="logo" src={logocolor} />
                  <h3>Forgot Password</h3>
                  <h6>Please enter your email to reset password.</h6>
                  <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='form-group'>
                      <Box><h2>Email Address</h2></Box>
                      <TextField
                        error={!!errors.email}
                        helperText={errors.email && errors.email.message}
                        {...register('email', { required: true })}
                        margin="dense"
                        size="small"
                        fullWidth
                        id="email"
                        placeholder='your@email.com'
                        name="email"
                        autoComplete="email"
                      />
                      <Box className='button-auth'>
                            <button type='submit' disabled={loading_forgot}>
                            <p> Submit </p>
                            { loading_forgot && <CircularProgress size={20} color="inherit" style={{ marginLeft: 10}} /> }
                            </button>
                        </Box>
                      </div>
                  </form>
              </div>
              <div className='footer-auth'>
                  <p>Back to  <Link to="/">Sign In</Link></p>
              </div>
          </div>
      </div>
  </div>
    )
}

export default Forgot;
