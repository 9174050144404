import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Stack, Box } from '@mui/material';
import moment from 'moment';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const HistoryStatus : React.FC<any> = ({ open, setOpen }) => {
    
    return (  
    <div>
      <BootstrapDialog
        onClose={() => setOpen({...open, open: false})}
        aria-labelledby="customized-dialog-title"
        open={open.open}
        fullWidth={true}
        maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen({...open, open: false})}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">History Status</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
          <Box className='history-status'>
              { open.data.map((item:any, index:number) => (
              <Box className='content' key={index}>
                <Box className='top'>
                  <h5><strong>{item.name}</strong> by {(item.optional.length && item.optional[0].user) || "System"}</h5>
                  <Box>
                    <p>{moment(item.timestamp).format('lll')}</p>
                  </Box>
                </Box>
                <h6>Note:</h6>
                <h5>{(item.optional.length && item.optional[0].note) || "N/A"}</h5>
              </Box>
              )) }
            </Box>
            
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export default HistoryStatus;