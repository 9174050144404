import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import info from '../assets/img/icon/info.png';


const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-start" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export default function TooltipHTML() {
  return (
    <div>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Stack flexDirection="column" sx={{padding: '5px'}}>
              <Box>1. Percentage discount will reducer your retail price base on percentage value.</Box>
              <Box>2. Amount discount will reduce your retail price.</Box>
              <Box>3. Special discount will replace your retail price. </Box>
            </Stack>
          </React.Fragment>
        }
      >
        <img src={info} alt="plus" width="20px" className='img-info'/>
      </HtmlTooltip>
    </div>
  );
}
