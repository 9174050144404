import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import EmptyData from '../../../../components/EmptyData';
import ButtonCreate from '../../../../components/ButtonCreate';
import DialogDetail from './DialogDetail';
import DialogSettings from './DialogSettings';
import DialogConfirmation from '../../../../components/DialogConfirmation';
import { RootState } from '../../../../app/store';
import ButtonActions from '../../../../components/ButtonActions';
import { useDispatch, useSelector } from 'react-redux';
import { updateSettingExtensionsAction } from '../redux/extensionsReducers';

const InstalledExtensions : React.FC<any> = ({installed_extensions}) => {
    const dispatch = useDispatch()

    const { loading_update, update } = useSelector((state : RootState) => state.extensions)
    const [open, setOpen] = useState({
        open: false,
        tab : ""
    });

    const [openSetting, setOpenSetting] = useState<any>({
        open: false,
        item : {}
    })

    const [openActive, setOpenActive] = useState<any>({
        name : "",
        label : "",
        open : false,
        item: {}
    });


    const onClickActiveInactive = () => {
        let data = {
            body : {
                isActive : !openActive.item.isActive
            },
            id : openActive.item._id
        }
        dispatch(updateSettingExtensionsAction(data))
    }


    return (
    <Box className='extension-int-container' mt={1}>
        { installed_extensions.filter((ele:any) => ele.isDeleted === false).length ? 
        <Grid container spacing={2}>
            { installed_extensions.filter((ele:any) => ele.isDeleted === false).map((item:any, index:number) => (
            <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                <Box className='extension-int-card'  sx={{backgroundColor: item.isActive ? '#fff' : '#ddd'}}>
                    <Box className='extension-int-item' >
                        <Box className='extension-int-item__image'>
                            <img src={item.extension[0].logoUrl} alt="rolesimg" />
                        </Box>
                        <Box className='extension-int-item__name'>
                            <h1>{item.extension[0].name}</h1>
                            <a href={item.extension[0].website} target='_blank' rel="noreferrer">{item.extension[0].website}</a>
                        </Box>
                    </Box>
                    <Box className='extension-int-item__action'>
                        <ButtonCreate 
                            name="Invoice"
                            onClick={() => setOpen({...open, open: true, tab: "invoice"})}
                            disabled={!item.isActive}

                        />
                        {/* <ButtonCreate 
                            name="E-Faktur"
                            onClick={() => setOpen({...open, open: true, tab: "efaktur"})}
                        /> */}
                        <ButtonCreate 
                            name="Setting"
                            onClick={() => setOpenSetting({...openSetting, open: true, item: item})}
                            disabled={!item.isActive}

                        />
                        <ButtonActions 
                            onClick={() => setOpenActive({
                                name : item.extension[0].name,
                                label : item.isActive ? "Disable" : "Enable",
                                open : true,
                                item: item
                            })}
                            name={item.isActive ? "Disable" : "Enable"}
                            color={item.isActive ? "error" : "primary"}
                        />
                    </Box>
                </Box>
            </Grid>
            ))}
        </Grid> : 
        <Box width="100%">
            <EmptyData 
                message="No Installed Extensions Found"
            />
        </Box>
        }
        <DialogDetail 
            open={open}
            setOpen={setOpen}
        />
        <DialogSettings 
            open={openSetting}
            setOpen={setOpenSetting}
            item={(openSetting.item && openSetting.item.extension && openSetting.item.extension[0]) || {}}
        />
        <DialogConfirmation 
            open={openActive}
            setOpen={setOpenActive}
            loading={loading_update}
            success={update}
            message="Success update status extension"
            onClick={onClickActiveInactive}
        />
    </Box> 
  )
}

export default InstalledExtensions