import Button from '@mui/material/Button';
import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack } from '@mui/material';
import SnackBarAlert from './SnackBarAlert';

const DialogConfirmation : React.FC<any> = ({open, setOpen, loading, onClick, success, message}) => {
  
    const handleClose = () => {
        setOpen({...open, open : false, name : "", label: ""})
    };

    useEffect(() => {
        if(success) {
            handleClose()
        }
        // eslint-disable-next-line
    }, [success]);

    return (
        <div>
        <Dialog open={open.open} maxWidth="sm" fullWidth>
            <DialogTitle>Confirmation!</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure want to {open.label} <strong>{open.name}</strong> ?
                </DialogContentText>
            </DialogContent>
            <Stack sx={{ p :2 }} flexDirection="row" justifyContent="space-between">
                <Button 
                    onClick={handleClose} 
                    size="small" variant="outlined" 
                    color={open.label !== "Disable" ? "error" : "primary"}
                    disabled={loading}
                >Cancel</Button>
                <Button 
                    onClick={onClick} 
                    size="small" variant="contained" 
                    color={open.label === "Disable" ? "error" : "primary"} 
                    disabled={loading}
                >{loading ? "Loading..." : open.label}</Button>
            </Stack>
        </Dialog>

        <SnackBarAlert message={message} initialState={success} />
        </div>
    )
}

export default DialogConfirmation
