import { createAsyncThunk } from '@reduxjs/toolkit';
import { userCredentials } from '../../../../utilities/config';
import axios from 'axios';

const headers : any = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }


export const getStatusCount = createAsyncThunk(
    'status-fulfillment/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/fulfillment/count`, {
                params,
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getFulfillmentData = createAsyncThunk(
    'fulfillment/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/fulfillment`, {
                params,
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getFulfillmentDataLoadMore = createAsyncThunk(
    'fulfillment-loadmore/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/fulfillment`, {
                params,
                headers
            })
            if(response.data) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getItemFulfillment = createAsyncThunk(
    'item-fulfillment/get',
    async (id:string, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/fulfillment/${id}/itemOnly`, {
                headers
            })
            if(response.data) { 
                return response.data.items
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const submitPickFulfillment = createAsyncThunk(
    'pick-fulfillment/get',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/fulfillment/inbound/Pick/${params.id}`, params.body, {
                headers
            })
            if(response.data) { 
                return true
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const updateStatusFulfillment = createAsyncThunk(
    'status-fulfillment/put',
    async (params:any, { rejectWithValue }) => {
        try {
            let body = {
                "packages": [params.id],
                "despatch_date": new Date(),
                "shipping": {
                    "awb": null,
                    "method": null,
                    "cost" : params.shipping_cost
                },
                "withInvoice" : params.withInvoice
            }
            const delivery : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/delivery`, body, {
                headers
            })
            if(delivery.data) {
                const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/fulfillment/${params.status}/${params.id}`, params.body, {
                    headers
                })
                if(response.data) { 
                    return {data : true, status : params.status}
                }
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const printDownloadPDF = createAsyncThunk(
    'download-pdf/print',
    async (params:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/pdf/${params.type}`, params.body, {
                headers
            })
            if(response.data) { 
                return response.data
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);



export const submitProofPayment = createAsyncThunk(
    'approve-payment/put',
    async (id:any, { rejectWithValue }) => {
        let body = {
            name : userCredentials.fullname,
            date : new Date()
        }
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/purchase/approvePayment/${id}`,body, {
                headers
            })
            if(response.data) { 
                return true
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);
