import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack, DialogActions, AlertTitle, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone, DropzoneOptions  } from 'react-dropzone';
import classNames from 'classnames';
import styles from '../Dropzone.module.scss';
import ButtonCreate from '../../../../components/ButtonCreate';
import ButtonActions from '../../../../components/ButtonActions';
import LoaderBackdrop from '../../../../components/LoaderBackdrop';
import ButtonAction from '../../../../components/ButtonAction';
import { RootState } from '../../../../app/store';
import { postMediaImages } from '../redux/mediaCenterReducers';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};


const ImageGallery = ({ images, setImages } : any) => {

  const onClickRemove = (index:any) => {
    const newImages = images.filter((image:any, i:any) => i !== index);
    setImages(newImages);
  };

  return (
    <>
    <Box mb={3} ml={-2} sx={{zIndex:100}}>Images Preview</Box>
    <div className="image-gallery">
      {images.map((image:any, index:any) => (
        <div key={index}>
        <div  className="image-gallery__item">
          <img
            className="image-gallery__item__image"
            src={image.url}
            alt={image.fileName}
          />
          <div className="image-gallery__item__name">{image.fileName}</div>
        </div>
        <Box 
          m={0.3} sx={{display:'flex', justifyContent: 'flex-end'}}
          onClick={() => onClickRemove(index)}
        >
          <ButtonAction type="remove" />
        </Box>
        </div>
      ))}
    </div>
    </>
  );
};


const DialogUploadImages : React.FC<any> = ({ 
  open, setOpen,
 }) => {

    const dispatch = useDispatch()
    function handleClose () {
        setOpen(false);
    };

  const [isDragActive, setIsDragActive] = useState(false);
  const onDragEnter = useCallback(() => setIsDragActive(true), []);
  const onDragLeave = useCallback(() => setIsDragActive(false), []);
  
  const { loading_upload } = useSelector((state:RootState) => state.media_center);

  const [imagePreviews, setImagePreviews] = useState<any[]>([]);
  const [arrayFileError, setArrayFileError] = useState<any>([]);
  const [arrayFile, setArrayFile] = useState<any>([]);
  
  const handleUpload = (files: File[]) => {
    dispatch(postMediaImages(files))
    setOpen(false);
    onDragLeave();
    setImagePreviews([]);
    setArrayFileError([]);
    setArrayFile([]);
  };


  const handleDrop = (acceptedFiles: File[]) => {
    setArrayFileError([])
    setArrayFile([])
    const previews: any[] = [];
    let arrayFileError : any = []
    acceptedFiles.forEach((file) => {
      if (file.size <= 1 * 1024 * 1024) { // Check file size
        const reader = new FileReader();
        reader.onload = (event: any) => {
          const previewUrl = event.target.result as string;
          previews.push({ url: previewUrl, file, fileName: file.name });
          if (previews.length === acceptedFiles.length) {
            setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
          }
        };
        reader.readAsDataURL(file);
      } else {
        arrayFileError.push(file.name)
        setArrayFileError(arrayFileError)
      }
    });
  };

  const dropzoneOptions: DropzoneOptions = {
    onDrop: handleDrop,
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg', '.gif', '.svg', '.webp', '.bmp', '.tiff', '.jfif']
    }
  }

  const { getRootProps, getInputProps }  = useDropzone(dropzoneOptions);

     useEffect(() => {
      setArrayFile(arrayFileError)
     }, [arrayFileError]);

    return (  
    <div>
      <LoaderBackdrop loading={loading_upload} />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">Upload Images</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
          <Box mb={2}>
            <div
              {...getRootProps()}
              className={classNames(styles.dropzone, {
                [styles.isDragActive]: isDragActive,
              })}
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p className={styles.dragText}>Drop your files here</p>
              ) : (
                <>
                  <p className={styles.selectText}>Select files or drag and drop</p>
                  <p className={styles.supportedText}>Supported file types: jpg, jpeg, png, gif, svg, webp, tiff</p>
                  <p className={styles.supportedText}>Max file size is 1 MB</p>
                </>
              )}
            </div>
          </Box>
          <Box m={2}>
            
            {imagePreviews.length > 0 && <ImageGallery images={imagePreviews} setImages={setImagePreviews} />}
          </Box> 

          { arrayFile.length > 0 &&
            <Box my={2}>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                <strong>File size is too large! Max file size is 1MB</strong>
                <ul>
                  {arrayFile.map((item:any, index:any) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </Alert>
            </Box>
          }
        </DialogContent>
        <DialogActions>
          <ButtonActions 
              name="Clear Images"
              color="error"
              onClick={() => {
                onDragLeave()
                setImagePreviews([]) 
                setArrayFileError([])
              }}
          />
          <ButtonCreate 
              name="Upload Images"
              onClick={() => handleUpload(imagePreviews)}
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}


export default DialogUploadImages;