import { Box } from '@mui/system'
import { Button, Skeleton, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumbs from '../../../../components/BreadCrumbs';
import HeadlineTitle from '../../../../components/HeadlineTitle';
import { useState, useEffect } from 'react';
import ButtonCreate from '../../../../components/ButtonCreate';
import OrderCard from './components/OrderCard';
import { RootState } from '../../../../app/store';
import { getItemFulfillment } from '../../fulfillment/redux/fulfillmentReducers';
import swal from 'sweetalert';
import LoaderBackdrop from '../../../../components/LoaderBackdrop';
import { createCalculateInvoice, getFulfillmentInvoice, getFulfillmentInvoiceLoadMore, getInvoiceNumber } from './redux/invoicesReducers';
import { userCredentials } from '../../../../utilities/config';
import { closeOpenInvoice, } from './redux/invoicesSlice';
import moment from 'moment';
import EmptyData from '../../../../components/EmptyData';
import { getAddressCompany } from '../../masters/mastersReducers';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoaderProgress from '../../../../components/LoaderProgress';
import { getPreferredAll } from '../../buyer_lists/redux/buyerListsReducer';
import { checkValidation } from '../../../../utilities/userFeaturesValidation';

const PackageDelivered = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { loading_items } = useSelector((state : RootState) => state.fulfillment)
    const { 
        loading_invoice_number, open_invoice, loading_calculate_inv,
        fulfilment_invoice, total_fulfilment_invoice, loading_fulfilment_invoice,
    } = useSelector((state : RootState) => state.invoice)
    const { create } = useSelector((state : RootState) => state.preferred)

    const [dataInfinite, setDataInfinite] = useState<any>([]);
    const [limit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [hasMore,setHasMore] = useState(true);

    const [viewItems, setViewItems] = useState({
        open : false, 
        id : "" 
    });

    const [referenceDocument, setReferenceDocument] = useState<any>([]);

    const getItemsFulfillment =  async(id:string) => {
        const item : any = await dispatch(getItemFulfillment(id))
        if(item.meta.requestStatus === 'fulfilled'){
            return item.payload
        }
    }

    const getVendorDetail =  async(id:string) => {
        let params = {
            id : id, 
            type : 'vendor'
        }
        const vendor : any = await dispatch(getAddressCompany(params))
        if(vendor.meta.requestStatus === 'fulfilled'){
            return {
                address : vendor.payload.data[0],
                logo : vendor.payload.data[0].company.logo,
                legalName : vendor.payload.data[0].company.legalName,
                _id : vendor.payload.data[0].company._id,
                code : vendor.payload.data[0].company.code,
            }
        }
    }

    const getBuyerDetail =  async(id:string) => {
        let params = {
            id : id, 
            type : 'buyer'
        }
        const vendor : any = await dispatch(getAddressCompany(params))
        if(vendor.meta.requestStatus === 'fulfilled'){
            return {
                address : vendor.payload.data[0],
                logo : vendor.payload.data[0].company.logo,
                legalName : vendor.payload.data[0].company.legalName,
                _id : vendor.payload.data[0].company._id,
                code : vendor.payload.data[0].company.code,
                name : vendor.payload.data[0].company.name
            }
        }
    }

    const sumTotalItem = async (data : any) => {
        let items = await getItemsFulfillment(data._id)
        return await items.reduce((total : any, data : any) => {
            return total + (data.total - data.total_tax)
        }, 0)
    } 

    const onClickSelected = async (data : any) => {
        let temp = [...referenceDocument];
        if(temp.length === 0) {
            let value = {
                buyer : await getBuyerDetail(data.buyer._id),
                vendor : await getVendorDetail(userCredentials.company_id),
                packageId : data._id,
                packageCode : data.code,
                date_order : data.date,
                payment_term : data.payment_term,
                shipping_cost : data.shipping_cost,
                sub_total : await sumTotalItem(data),
                sub_tax : data.sub_tax,
                items : await getItemsFulfillment(data._id),
                down_payment : data.down_payment
            }
            temp.push(value);
            setReferenceDocument(temp)
        } else {
            if (temp.find((item : any) => item.payment_term === data.payment_term && item.buyer._id === data.buyer._id)) {
                if (temp.find((item : any) => item.packageId === data._id)) {
                    let filter = temp.filter((item : any) => item.packageId !== data._id)
                    setReferenceDocument(filter)
                } else {
                    let value = {
                        buyer : await getBuyerDetail(data.buyer._id),
                        vendor : await getVendorDetail(userCredentials.company_id),
                        packageId : data._id,
                        packageCode : data.code,
                        date_order : data.date,
                        payment_term : data.payment_term,
                        items : await getItemsFulfillment(data._id),
                        shipping_cost : data.shipping_cost,
                        sub_total : await sumTotalItem(data),
                        sub_tax : data.sub_tax,
                        down_payment : data.down_payment
                    }
                    temp.push(value);
                    setReferenceDocument(temp)
                }
            } else {
                swal('Error', 'You can only merge same buyer and same payment term!', 'error')
            }
        }
    }


    function getDueDate(params: any ) {
        let result = new Date();
        result.setDate(result.getDate() + parseInt(params.payment_term));
        return result;
    }

    function getOnlyReference(arr: any) {
        const newData = arr.map((obj : any) => {
            return {
                packageId : obj.packageId,
                packageCode : obj.packageCode,
                date_order : obj.date_order,
                payment_term : parseInt(obj.payment_term),
            }
        });
        return newData;
    }

    function getArrayItems(arr:any) {
        let result : any = [];
        arr.forEach((item : any) => {
            result.push(...item.items)
        })
        return result;
    }


    const onClickCreateInvoice = async () => {
        let buyer_name = referenceDocument[0].buyer.legalName
        const inv_number : any = await dispatch(getInvoiceNumber(buyer_name))
        if(inv_number.meta.requestStatus === 'fulfilled') {
            const data = {
                invoiceNumber: await inv_number.payload,
                dueDate:  moment(getDueDate(referenceDocument[0])).format(),
                buyer: referenceDocument[0].buyer,
                vendor: referenceDocument[0].vendor,
                reference: getOnlyReference(referenceDocument),
                shippingCosts: 0,
                downPayment: 0,
                items: getArrayItems(referenceDocument),
                subTotal: 0,
                grandTotal: 0,
                note: "",
                creator: userCredentials.fullname,
                createdAt : moment().format(),
                type : "Final Payment"
            }
            dispatch(createCalculateInvoice(data))
            // dispatch(createOpenInvoice(data))
        }
    }

    const fetchMoreData = async () => {
        const params = {
            limit,
            skip : skip + 1,
        }
         
        const newdata : any = await dispatch(getFulfillmentInvoiceLoadMore(params))
        if(newdata.type === "fulfillment-readyToInV-laod-more/get/fulfilled"){
            setSkip(skip + 1)
            let array = [...dataInfinite, ...newdata.payload.data]
            if(newdata.payload.data.length === 0){
                setHasMore(false)
            } else {
                setHasMore(true)
                setDataInfinite(array)
            }
        } else {
            swal("Error", newdata.payload.message, "error")
        }
    };

    useEffect(() => {
        setDataInfinite(fulfilment_invoice)
        if(fulfilment_invoice.length >= total_fulfilment_invoice) {
            setHasMore(false)
        }
        // eslint-disable-next-line
    }, [fulfilment_invoice, total_fulfilment_invoice]);

    const scrollToTop = () => {
        // @ts-ignore
        document.getElementById("scrollableDiv").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        if(open_invoice) {
            history.push({
                pathname: "/dashboard/finance/invoice",
                search: `?page=create`, 
            })
            dispatch(closeOpenInvoice())
        }
         // eslint-disable-next-line
    }, [open_invoice, create]);

    useEffect(() => {
        const params = {
            limit,
            skip,
        }
        dispatch(getFulfillmentInvoice(params))
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(getPreferredAll())
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(create) {
            const params = {
                limit,
                skip,
            }
            dispatch(getFulfillmentInvoice(params))
            dispatch(getPreferredAll())
        }
        // eslint-disable-next-line
    }, [create]);

    useEffect(() => {
        if(viewItems.open) {
            dispatch(getItemFulfillment(viewItems.id))
        }
         // eslint-disable-next-line
    }, [viewItems]);


    return (
        <Stack m={2} >
            <LoaderBackdrop 
                loading={loading_items || loading_invoice_number || loading_calculate_inv} 
            />
            <BreadCrumbs 
                isPage={true}
                current="Package Delivered"
                link='/dashboard/finance/invoice'
                page='Invoices'
            />

            <Stack sx={{pt:2}} flexWrap="wrap" direction="row" justifyContent="space-between" alignItems="center" >
                <Box>
                    <HeadlineTitle 
                        title='Package Delivered'
                        variant="h6"
                        bold="bold"
                    />
                     <HeadlineTitle 
                        title={referenceDocument.length > 0 ? `${referenceDocument.length} Package Delivered selected` : 'Select at least one Package Delivered below to Create Invoice'}
                        variant="body2"
                        bold="400"
                        color="green"

                    />
                </Box>
                <Box>
                { checkValidation('CREINV438542') &&
                    <ButtonCreate 
                        disabled={referenceDocument.length > 0 ? false : true} 
                        name="Create Invoice"
                        onClick={onClickCreateInvoice}
                    /> 
                }
                </Box>
            </Stack>


            { loading_fulfilment_invoice ? 
            <Box className="item-card-invoice">
                {[1,2,3,4,5,6].map((item : any) => (
                    <Box key={item}>
                        <Skeleton sx={{borderRadius:5}}  variant="rectangular" height={150}/>
                        <Box my={1}/>
                    </Box>
                ))}
            </Box> :
            <div id="scrollableDiv"  className="overhiddenflow">
                { dataInfinite.length > 0 ? 
                <div>
                    <InfiniteScroll
                        dataLength={dataInfinite.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<LoaderProgress/>}
                        scrollableTarget="scrollableDiv"
                        endMessage={
                            <Stack flexDirection="column" justifyContent="center" alignItems="center" mt={3} mb={5}>
                                <Box>
                                    <HeadlineTitle title="No more data found." variant="body2" bold='400' />
                                </Box>
                                { dataInfinite.length > 5 && 
                                <Box>
                                    <Button onClick={() => scrollToTop()} size="small" variant='contained'>
                                        <p>Back to Top</p>
                                    </Button>
                                </Box>  }
                            </Stack>
                        }
                    >
                    <Box>
                        {dataInfinite.map((item:any, idx:number) => (
                            <Box key={idx}>
                                <OrderCard 
                                    item={item} 
                                    idx={idx} 
                                    viewItems={viewItems} 
                                    setViewItems={setViewItems} 
                                    onClickSelected={onClickSelected}
                                    referenceDocument={referenceDocument}
                                />
                            </Box>
                        ))}
                    </Box>
                    </InfiniteScroll>
                </div> :
                <Box mt={2} mx={0.2}>
                    <EmptyData 
                        message="There is no package delivered yet." 
                        caption="Unfortunately at the moment you can't create an invoice." 
                    />
                </Box> }
            </div> }
        </Stack>
    )
}

export default PackageDelivered;
