import { useState, useEffect } from 'react';
import { 
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Box,
    Button,
    Stack,
} from '@mui/material';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { remove } from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { RootState } from '../../../../app/store';
import { getAllFeatureDashboard, getAllFeatureNavigations, getAllNavigations } from '../redux/rolesReducers';

const ModuleItems : React.FC<any> = ({
    moduleItems, setModuleItems, type
}) => {
 
    const dispatch = useDispatch()
    const { navigations, features_default } = useSelector((state : RootState) => state.roles)


    useEffect(() => {
        dispatch(getAllNavigations())
        dispatch(getAllFeatureDashboard())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if(type === 'Add') {
            if(features_default.length !== 0) {
                const default_item = [{ 
                    selectedChild : [],
                    selectedParent : {
                        value : "61d6e7ed5e53360715fcdd9c",
                        label : "Dashboard"
                    },
                    selectedFeatures: features_default,
                    optionFeatures : [],
                    optionsChild : [],
                    has_child_module : false,
                    mandatory: true,
                    flag: "BUYER"
                }]
                setModuleItems(default_item)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [features_default, type]);

    const [indexErrorSelected, setIndexErrorSelected] = useState<any>(null);
    const [errorSelected, setErrorSelected] = useState({
        error : false,
        message : ""
    });

    
    const [optionsParent, setOptionsParent] = useState<any[]>([]);
    const [errorParent, setErrorParent] = useState<boolean>(false);

    const proceedOptionsChild = (data : any) => {
        let data_options = []
        for(let element of data) {
            data_options.push({ value: element._id, label: element.name })
        }
        return data_options
    }

    const proceedOptionsParent = (data : any) => {
        setOptionsParent(data.filter((element:any) => element.label !== "Dashboard"))
    }

    const checkSelectedParent = (value : string, items :any) => {
        let error = false

        for(let i of items) {
            if(i.selectedParent.label === value) {
                error = true
            }
        }
        return error
    }

    const handleChangeParent = async (value: any, index : any) => {
        setErrorSelected({...errorSelected, error : false, message : "" })
        setIndexErrorSelected(null)
        if(checkSelectedParent(value.label, moduleItems)) {
            setErrorSelected({...errorSelected, error : true, message : `${value.label} is already selected!` })
            setIndexErrorSelected(index)
        } else {
            setErrorParent(false)
            let copy_array = [...moduleItems]
            const response : any = await dispatch(getAllFeatureNavigations(value.value))
            if(response.meta.requestStatus === 'fulfilled') {
                if(value.childs.length !== 0) {
                    const newData = copy_array.map((obj : any, i : any) => {
                        if(i === index)
                        return {
                            ...obj,
                            selectedParent: value,
                            has_child_module : true,
                            optionFeatures :  response.payload,
                            selectedFeatures :  response.payload,
                            optionsChild : proceedOptionsChild(value.childs),
                            selectedChild: proceedOptionsChild(value.childs)
                        }
                        return obj
                    });
                    setModuleItems(newData)
                } else {
                    const newData = copy_array.map((obj : any, i : any) => {
                        if(i === index)
                        return {
                            ...obj,
                            selectedParent: value,
                            optionFeatures :  response.payload,
                            selectedFeatures :  response.payload
                        }
                        return obj
                    });
                    setModuleItems(newData)
                }
            }
        }

    }

    const handleChangeChild = (value: any, index : any) : void => {
        setErrorParent(false)
        let copy_array = [...moduleItems]
        const newData = copy_array.map((obj : any, i : any) => {
            if(i === index)
            return {
                ...obj,
                selectedChild: value,
            }
            return obj
        });
        setModuleItems(newData)
    }

    const handleChangeFeatures = (value: any, index : any) : void => {
        setErrorParent(false)
        let copy_array = [...moduleItems]
        const newData = copy_array.map((obj : any, i : any) => {
            if(i === index)
            return {
                ...obj,
                selectedFeatures: value,
            }
            return obj
        });
        setModuleItems(newData)
    }

    const moveArray = (from:any, to:any, arr:any) => {
        const newArr = [...arr];
        const item = newArr.splice(from, 1)[0];
        newArr.splice(to, 0, item);
        setModuleItems(newArr)
    }

    const onClickAddNewField = () => {
        let copy_item = [...moduleItems]
        let new_object = {
            selectedChild : [],
            selectedParent : [],
            selectedFeatures: [],
            optionFeatures : [],
            optionsChild : [],
            has_child_module : false,
            flag: "VENDOR"
        }
        copy_item.push(new_object)
        setModuleItems(copy_item)
    }

    const onClickRemoveItem = (value : any, row:any) => {
        let copy_item = [...moduleItems]
        const items_remove = remove(copy_item, function(obj : any, index : any) {
            return index !== value
        });
        setModuleItems(items_remove)
        let array_option = [...optionsParent]
        array_option.push(row)
        setOptionsParent(array_option)
    }

    useEffect(() => {
        if(navigations.length !== 0) {
            proceedOptionsParent(navigations)
        }
        // eslint-disable-next-line
    }, [navigations]);

    return (
    <Box>
        <Stack> 
          <Box mb={2}>
            <Button 
                variant="outlined" color="primary"
                size="small"
                onClick={() => onClickAddNewField()}
            >
              Add More Navigation
            </Button>
          </Box>
        </Stack>
        <Box className='modules-iatems' >
          <Table   > 
              <TableHead>
                <TableRow sx={{display:'flex', flexWrap: 'wrap'}}>
                    <TableCell style={{fontWeight: 700, width: '250px' }}>Navigation</TableCell>
                    <TableCell style={{fontWeight: 700,width: '250px' }}>Child Navigation</TableCell>
                    <TableCell style={{fontWeight: 700,}}>Features</TableCell>
                    <TableCell style={{fontWeight: 700,}}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
              { moduleItems.map((row :any, i : any) => (
                <TableRow key={i} sx={{display:'flex', flexWrap: 'wrap'}}>
                    <TableCell component="th" scope="row">
                        <Box>
                            <Select
                                placeholder="Select Navigation"
                                value={row.selectedParent}
                                isSearchable={true}
                                options={optionsParent && optionsParent}
                                onChange={(e) => handleChangeParent(e, i)}
                                id={`select-style-parent-module-${i}`}
                                isDisabled={row.selectedParent.label === "Dashboard" ? true : false}
                                noOptionsMessage={() => 'There is no other Navigation!'}
                            />
                            { errorParent ? <div className="error-p"><p>Navigation required</p></div> : null }
                        </Box> 
                        <Box pt={1}>
                            { errorSelected.error && indexErrorSelected === i ? <div className="error-p"><p>{errorSelected.message}</p></div> : null }
                        </Box>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        { row.has_child_module ?
                        <Box>
                            <Select
                                placeholder="Select child Navigation"
                                value={row.selectedChild}
                                isSearchable={true}
                                isMulti
                                options={row.optionsChild}
                                onChange={(e) => handleChangeChild(e, i)}
                                id={`select-style-parent-module-${i}`}
                                isDisabled={row.selectedParent.label === "Dashboard" ? true : false}
                                noOptionsMessage={() => 'There is no other child Navigation!'}
                            />
                            { errorParent ? <div className="error-p"><p>Child Navigation is required</p></div> : null }
                        </Box>  : "Navigation doesn't have child " }
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Box>
                            <Select
                                placeholder="Select Features"
                                value={row.selectedFeatures}
                                isSearchable={true}
                                isMulti
                                options={row.optionFeatures}
                                onChange={(e) => handleChangeFeatures(e, i)}
                                isDisabled={row.selectedParent.label === "Dashboard" ? true : false}
                                id={`select-style-parent-module-${i}`}
                                noOptionsMessage={() => 'There is no other Feature!'}
                            />
                            { errorParent ? <div className="error-p"><p>Features is required</p></div> : null }
                        </Box> 
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Stack flexDirection="row" >
                            <Box
                                sx={{cursor: 'pointer', mr: 1}}
                                onClick={() => moveArray(i, i-1, moduleItems)}
                            >
                                <ArrowCircleUpIcon color="success" />
                            </Box> 
                            <Box
                                sx={{cursor: 'pointer'}}
                                onClick={() => moveArray(i, i+1, moduleItems)}
                            >
                                <ArrowCircleDownIcon color="info"/>
                            </Box> 
                        </Stack>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Button 
                            color="error" onClick={() => onClickRemoveItem(i, row.selectedParent)}
                            disabled={row.selectedParent.label === "Dashboard" ? true : false}
                            size="small" 
                        >Remove</Button>
                    </TableCell>
                </TableRow>
              ))}
              </TableBody>
          </Table>
        </Box>
    </Box>
  );
}

export default ModuleItems