import { 
    Paper,
    Box,
    Button,
    Skeleton
} from '@mui/material';
import Select from 'react-select'
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { useEffect, useState } from 'react';
import { changeStepWarehouse } from '../warehouseSlice';
import LoaderBackdrop from '../../../../components/LoaderBackdrop';
import { getMasterUserTeam } from '../../masters/mastersReducers';


const AssignUser : React.FC<any> = () => {

    const { users, loading_users } = useSelector((state : RootState) => state.masters)
    const dispatch = useDispatch()

    const [localData, setLocalData] = useState<any>({});
    const [loading, setLoading] = useState(false);

    const [userSelected, setUserSelected] = useState<any>([]);
    
    const handleNextSubmit = () => {
        if(userSelected.length === 0) {
            swal('Error', 'Please assign at least one user', 'error')
        } else {
            let data_form = {
                ...localData,
                users: userSelected.map((item : any) => ({ userId : item.value, label : item.label })),
            }
            localStorage.setItem('warehouse', JSON.stringify(data_form))
            dispatch(changeStepWarehouse(4))
        }
    }


    useEffect(() => {
        const local_data = localStorage.getItem('warehouse')
        const checkLocalData = async () => {
            setLoading(true)
            const data : any = local_data === null ? null : JSON.parse(local_data)
            if(data !== null) {
                setUserSelected(data.users.length ? data.users.map((item : any) => ({ value : item.userId, label : item.label })) : [])
                setLocalData(data)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }
        checkLocalData()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(getMasterUserTeam())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return ( 
    <Box mt={2} className="modules-itemss">
        <LoaderBackdrop loading={loading} />
        <Paper sx={{p:3, mt:2}}>

        <Box className="modules-items-ssecond" >
          <h3>Assign User</h3>
          <Box py={1}>
          { loading_users ? 
            <Skeleton height={60} /> :
            <Box >
                { users.length === 0 ? 
                <Select
                    placeholder="Getting data user..."
                /> 
                :
                <Select
                    placeholder="You can select multiple User"
                    value={userSelected}
                    isSearchable={true}
                    onChange={(e) => setUserSelected(e)}
                    isMulti
                    options={users}
                    noOptionsMessage={() => "There is no another user."}
                />  }
            </Box> 
            }
          </Box>
        </Box>


            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    sx={{ mr: 1 }}
                    variant="contained"
                    onClick={() => dispatch(changeStepWarehouse(0))}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button 
                    variant="contained"
                    onClick={handleNextSubmit}
                >
                    Next
                </Button>
            </Box>
        </Paper>
    </Box>
  );
}

export default AssignUser