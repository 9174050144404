import { Box } from '@mui/material'
import imgdetail from '../assets/img/icon/view.png'
import imgremove from '../assets/img/icon/remove.png'
import imgedit from '../assets/img/icon/edit.png'

const ButtonAction : React.FC<any> = ({type, onClick}) => {
    return (
        <div>
            <Box 
                component="img"
                className='icon-button'
                src={ type === "detail" ? imgdetail : type === "edit" ? imgedit : imgremove }
                onClick={onClick}
            />
        </div>
    )
}

export default ButtonAction
