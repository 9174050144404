import { Box, Typography } from '@mui/material'
import React from 'react'

interface Props {
    title : string;
    variant : any;
    bold : string;
    color?: string;
}
const HeadlineTitle : React.FC<Props> = ({ title,variant, bold, color }) => {
  return (
    <Box py={0.5}>
        <Typography fontWeight={bold} color={color} variant={variant}>
            {title}
        </Typography>
    </Box>
  )
}

export default HeadlineTitle