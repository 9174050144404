import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }

export const getAllProduct = createAsyncThunk(
    'get/products',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/product/paginate`, {
                params : {
                    skip : "0",
                    limit : "10",
                },
                headers
                
            })
            if(response.data) {
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getAllProductByWarehouses = createAsyncThunk(
    'get/products-by-warehouse',
    async (param:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/product/paginate`, {
                params : {
                    skip : param.page,
                    limit : param.limit,
                    "filterTopic[]" : "warehouses.warehouse",
                    "filterValue[]" : param.warehouse_id,
                    search : param.search
                },
                headers
                
            })
            if(response.data) {
                return response.data
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getAllProductBySearch = createAsyncThunk(
    'get/products-search',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/product/algoliaByVendor`, {
                params : {
                    vendor_id : `${userCredentials.vendor_id}`,
                    [body.topic] : `${body.value}`
                },
                headers
            })
            if(response.data) {
                let message = response.data.message
                return {data : response.data.data.hits, message : message}
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getProductBySlug = createAsyncThunk(
    'product-slug/fetch', 
    async (slug: string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/product/${slug}/details`, { headers })
            if(response.data) { 
                return {data : response.data, exist : response.data._id ? true : false}
            }
        } catch (err : any) {
            return rejectWithValue(err)
        }
})


export const getMasterTax = createAsyncThunk(
    'pajak/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/pajak`, {headers})
            if(response.data) { 
                return {data : response.data.data, message : response.data.message}
            } 
        } catch (err : any) {
            return rejectWithValue(err)
        }
})

export const getMasterCategory = createAsyncThunk(
    'category/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/category-product/tree`, {headers})
            if(response.data) { 
                return {data : response.data.data, message : response.data.message}
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})


export const getMasterCategoryList = createAsyncThunk(
    'category-list/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/category-product/tree`, {headers})
            if(response.data) { 
                return {data : response.data, message : response.data.message}
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})


export const getMasterCategoryListByChild = createAsyncThunk(
    'category-list-bychild/fetch', 
    async (id:string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`https://api.pasarpemesanan.com/v1/category/${id}`, {
                headers
            })
            // const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/category-product/${id}/tree`, {
            //     headers
            // })
            if(response.data) { 
                return {data : response.data, message : response.data.message}
            } 
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const postCreateProducts = createAsyncThunk(
    'product/post',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/product`, body, {headers})
            if(response.data) {
                return {data : true, message : "Success added new product."}
            } 
            } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const updateProducts = createAsyncThunk(
    'product/update',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/product/${value._id}`, value.data, {headers})
            if(response.data) {
                return {data : true, message : "Success updated product."}
            } 
            } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const uploadBulkProduct = createAsyncThunk(
    'product-bulk/post',
    async (value : any, { rejectWithValue }) => { 
        let bodyFormData = new FormData();
            bodyFormData.append('file', value.file[0]);
            bodyFormData.append('warehouse', value.warehouse);
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/product/${value.type}/bulk`, bodyFormData, {headers}) 
            if(response) {
                return {data : true, message : "Bulk upload is running in the background, we will update on notification when its done."}
            } 
            } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const uploadBulkProductUpdateAll = createAsyncThunk(
    'all-bulk/post',
    async (value : any, { rejectWithValue }) => { 
        let bodyFormData = new FormData();
            bodyFormData.append('file', value.file[0]);
            bodyFormData.append('vendor_name', userCredentials.vendor_name);
            bodyFormData.append('company_code', userCredentials.company_code);
            bodyFormData.append('vendor_id', userCredentials.vendor_id);
            bodyFormData.append('warehouse_id', value.warehouse_id);
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/product/uploadExcel`, bodyFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization' : `Bearer ${userCredentials.access_token}`
                }
            }) 
            if(response.data) {
                return {data : true, message : "Bulk upload is running in the background, please check later while we are processing the upload."}
            }
            } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const uploadBulkProductUpdateStock = createAsyncThunk(
    'stock-bulk-update/post',
    async (value : any, { rejectWithValue }) => { 
        let bodyFormData = new FormData();
        bodyFormData.append('file', value.file[0]);
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/product/updateExcel/stock/${userCredentials.vendor_id}`, bodyFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization' : `Bearer ${userCredentials.access_token}`
                }
            })
            if(response.data) {
                return {data : true, message : "Bulk update stock is running in the background, please check later while we are processing the update."}
            } 
            } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const uploadBulkProductUpdatePrice = createAsyncThunk(
    'price-bulk-update/post',
    async (value : any, { rejectWithValue }) => { 
        let bodyFormData = new FormData();
        bodyFormData.append('file', value.file[0]);
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/product/updateExcel/price/${userCredentials.vendor_id}`, bodyFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization' : `Bearer ${userCredentials.access_token}`
                }
            })
            if(response.data) {
                return {data : true, message : "Bulk update price is running in the background, please check later while we are processing the update."}
            } 
            } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const removeProduct = createAsyncThunk(
    'product/remove', 
    async (id: string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.delete(`${process.env.REACT_APP_API_SERVER}/product/${id}`, {headers})
            if(response) { 
                return {data : true }
            } 
        } catch (error) {
            return rejectWithValue(error)
        }
})


export const downloadExcelTemplate = createAsyncThunk(
    'product-template/bulk', 
    async (value: any, { rejectWithValue }) => { 
        let body = {
            "template": value.template,
            "company": "null",
            "warehouse": value.warehouse
        }
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/excels`, body, {headers})
            if(response.data) { 
                return response.data
            } 
        } catch (error) {
            return rejectWithValue(error)
        }
})


export const getTotalAllProducts = createAsyncThunk(
    'total-products/get',
    async (_, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/product/count/list-without-warehouse`, {
                headers
            })
            if(response) { 
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);