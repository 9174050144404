import { useEffect, useState } from 'react';
import {
    Box,
    TextField,
    CircularProgress,
    Alert, 
} from '@mui/material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../app/store';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import logo from '../../../assets/img/logo.png'
import { signUpAction } from '../redux/authReducers';
import { ISignUpInput } from '../types';
import logocolor from '../../../assets/img/logos.png'
import { Link } from 'react-router-dom';

const validationSchema = yup
  .object({ 
    email: yup.string()
      .required("Email is required")
      .email("Email is invalid"),
    password: yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password Must Contain 8 Characters and at least have one Special Case Character, One Lowercase and One Number"
      ),
    fullname: yup.string()
      .required("Full Name is required")
  })
  .required();


const SignUpPage = () => {
  const dispatch = useDispatch()
  const { signup, success_signup, loading_signup, error_signup } = useSelector((state : RootState) => state.auth )

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ISignUpInput>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema)
  });
  
  const onSubmit = (values: ISignUpInput): void => {
    dispatch(signUpAction(values))
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    if(signup) {
      setTimeout(() => {
          reset()
          window.location.href = "/"
        }, 3000);
    }
    // eslint-disable-next-line
  }, [signup, reset]);

  return (
    <div className='container-auth'>
      <div className='auth-box'>
          <div className='left-img'>
              <img alt="logo" src={logo} />
              <h1>Pasar Pemesanan</h1>
              <h2>Platform that provides services to <br/> ease the procurement process for the business.</h2>
          </div>
          <div className='right-content'>
              <div className='form-auth-container'>
                  <img alt="logo" src={logocolor} />
                  <h3>Register Account</h3>
                  <h6>Please enter your detail to sign up to Vendor Platform.</h6>
                  <form onSubmit={handleSubmit(onSubmit)}>
                      <div className='form-group'>
                      <Box><h2>Full Name</h2></Box>
                      <TextField
                        error={!!errors.fullname}
                        helperText={errors.fullname && errors.fullname.message}
                        {...register('fullname', { required: true })}
                        margin="dense"
                        size='small'
                        fullWidth
                        id="fullname"
                        placeholder="John Doe"
                        name="fullname"
                        autoComplete="fullname"
                      />
                      <Box mt={1}><h2>Email Address</h2></Box>
                      <TextField
                        error={!!errors.email}
                        helperText={errors.email && errors.email.message}
                        {...register('email', { required: true })}
                        margin="dense"
                        size='small'
                        fullWidth
                        id="email"
                        placeholder="your@email.com"
                        name="email"
                        autoComplete="email"
                      />
                      <Box mt={1}><h2>Password</h2></Box>
                      <TextField
                        error={!!errors.password}
                        helperText={errors.password && errors.password.message}
                        {...register('password', { required: true })}
                        margin="dense"
                        size='small'
                        fullWidth
                        name="password"
                        placeholder="*******"
                        id="password"
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment : (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />

                      { error_signup === null ? null :
                        <Box pt={1}>
                          <Alert severity="error">
                            {error_signup}
                          </Alert>
                        </Box> 
                        }

                        { success_signup === null ? null :
                        <Box pt={1}>
                          <Alert severity="success">
                            { success_signup }
                          </Alert>
                        </Box>  
                        }

                          <Box className='button-auth'>
                              <button type='submit' disabled={loading_signup}>
                              <p> Sign Up </p>
                              { loading_signup && <CircularProgress size={20} color="inherit" style={{ marginLeft: 10}} /> }
                              </button>
                          </Box>
                      </div>
                  </form>
              </div>
              <div className='footer-auth'>
                  <p>Already have an account?  <Link to="/">Sign In</Link></p>
              </div>
          </div>
      </div>
  </div>
    )
}

export default SignUpPage;
