import { Box } from '@mui/system'
import { Button, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumbs from '../../../components/BreadCrumbs';
import HeadlineTitle from '../../../components/HeadlineTitle';
import { useState, useEffect } from 'react';
import OrderSearch from './components/OrderSearch';
import OrderCard from './components/OrderCard';
import { RootState } from '../../../app/store';
import { getFulfillmentData, getFulfillmentDataLoadMore, getItemFulfillment } from './redux/fulfillmentReducers';
import OrderLoading from './components/OrderLoading';
import EmptyData from '../../../components/EmptyData';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import { addLengthInfiniteOrder, updateValueAlert } from './redux/fulfillmentsSlice';
import SnackBarAlert from '../../../components/SnackBarAlert';
import InfiniteScroll from 'react-infinite-scroll-component';
import swal from 'sweetalert';
import LoaderProgress from '../../../components/LoaderProgress';
import { useLocation } from 'react-router-dom';
import { closeOpenInvoice } from '../finance/invoices/redux/invoicesSlice';
import { getPreferredAll } from '../buyer_lists/redux/buyerListsReducer';

const PageFulfillment = () => {
    const dispatch = useDispatch()
    const location :any = useLocation()

    const { 
        fulfillment, total_fulfillment, loading_fulfillment, loading_pick, pick,
        loading_update_status, update_status, status_name,
        loading_download_pdf, download_pdf, loading_approve_payment, 
        approve_payment, loaded_fulfillment, total_infinite
    } = useSelector((state : RootState) => state.fulfillment)
    const { create  } = useSelector((state : RootState) => state.preferred)

    const [dataInfinite, setDataInfinite] = useState<any>([]);
    const [fetch, setFetch] = useState(false);
    const [limit, ] = useState(10);
    const [skip, setSkip ] = useState(0);
    const [valueSearch, setValueSearch] = useState("");
    const [filterTopic, setFilterTopic] = useState<any>([]);
    const [filterValue, setFilterValue] = useState<any>([]);
    const [rangeTopic, setRangeTopic] = useState<any>([]);
    const [rangeFrom, setRangeFrom] = useState<any>([]);
    const [rangeTo, setRangeTo] = useState([]);
    const [hasMore,setHasMore] = useState(true);
    const [activeStatus, setActiveStatus] = useState("All");

    const [viewItems, setViewItems] = useState({
        open : false, 
        id : ""
    });

    function closeViewItems() {
        setViewItems({
            open : false,
            id : ""
        })
    }
    
    const fetchMoreData = async () => {
        const params = {
            limit,
            skip : skip + 1,
            search : valueSearch,
            'filterTopic[]' : filterTopic,
            'filterValue[]' : filterValue,
            'rangeTopic' : rangeTopic,
            'rangeFrom' : rangeFrom,
            'rangeTo' : rangeTo,
        }
        const newdata : any = await dispatch(getFulfillmentDataLoadMore(params))
        if(newdata.type === "fulfillment-loadmore/get/fulfilled"){
            setSkip(skip + 1)
            let array = [...dataInfinite, ...newdata.payload.data]
            if(newdata.payload.data.length === 0){
                setHasMore(false)
            } else {
                dispatch(addLengthInfiniteOrder(newdata.payload.data.length))
                setHasMore(true)
                setDataInfinite(array)
            }
        } else {
            swal("Error", newdata.payload.message, "error")
        }
    };

    const scrollToTop = () => {
        // @ts-ignore
        document.getElementById("scrollableDivFulfillment").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        if(viewItems.open) {
            dispatch(getItemFulfillment(viewItems.id))
        }
        // eslint-disable-next-line
    }, [viewItems]);

    useEffect(() => {
        const params = {
            limit,
            skip : 0,
            search : valueSearch,
            'filterTopic[]' : filterTopic,
            'filterValue[]' : filterValue,
            'rangeTopic' : rangeTopic,
            'rangeFrom' : rangeFrom,
            'rangeTo' : rangeTo,
        }
        dispatch(getFulfillmentData(params))
        setFetch(true)
        setHasMore(true)
        setSkip(0)
        // eslint-disable-next-line
    }, [valueSearch, filterTopic, filterValue, rangeTopic, rangeFrom, rangeTo, create]);


    useEffect(() => {
        if(pick || update_status || approve_payment ) {
            setTimeout(() => {
                const params = {
                    limit,
                    skip : 0,
                    search : valueSearch,
                    'filterTopic[]' : filterTopic,
                    'filterValue[]' : filterValue,
                    'rangeTopic' : rangeTopic,
                    'rangeFrom' : rangeFrom,
                    'rangeTo' : rangeTo,
                }
                dispatch(getFulfillmentData(params))
                setFetch(true)
                setHasMore(true)
                setSkip(0)
                dispatch(updateValueAlert())
                closeViewItems()
            }, 1000);
        }
        // eslint-disable-next-line
    }, [pick, approve_payment, update_status]);

    useEffect(() => {
        if(download_pdf !== "" ) {
            window.location.assign(download_pdf)
            setTimeout(() => {
                dispatch(updateValueAlert())
            }, 1500);
        }
        // eslint-disable-next-line
    }, [download_pdf]);

    useEffect(() => {
        dispatch(getPreferredAll())
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(create) {
            dispatch(getPreferredAll())
        }
        // eslint-disable-next-line
    }, [create]);

    useEffect(() => {
        if(location.state !== null) {
            if(location.state.dashboard === undefined) {
                setFilterTopic(['lastStatus','codePo'])
                setFilterValue([location.state.status, location.state.payload.codePo])
                setActiveStatus(location.state.status)
            } else {
                if(location.state.status === "All") {
                    setFilterTopic([])
                    setFilterValue([])
                    setActiveStatus("All")
                } else {
                    setFilterTopic(['lastStatus'])
                    setFilterValue([location.state.status])
                    setActiveStatus(location.state.status)
                }
            }
        }
    }, [location]);

    useEffect(() => {
        if(loaded_fulfillment) {
            setDataInfinite(fulfillment)
        }
        // eslint-disable-next-line
    }, [fulfillment, loaded_fulfillment]);

    useEffect(() => {
        if(fetch && loaded_fulfillment) {
            if(total_infinite >= total_fulfillment) {
                setHasMore(false)
            }
        }
    }, [fetch, loaded_fulfillment, total_infinite, total_fulfillment]);

    useEffect(() => {
        if(loaded_fulfillment && dataInfinite.length > 0) {
            if(!fetch && total_infinite >= total_fulfillment) {
                setHasMore(false)
            }
        }
        // eslint-disable-next-line
    }, [dataInfinite, loaded_fulfillment, fetch, total_infinite]);

    useEffect(() => {
        dispatch(closeOpenInvoice())
        // eslint-disable-next-line
    }, []);


    return (
        <Stack m={2} >
            <LoaderBackdrop 
                loading={loading_pick || loading_update_status || loading_download_pdf || loading_approve_payment} 
            />
            <BreadCrumbs 
                isPage={false}
                current="Order Fulfillment"
            />
            <Stack direction="row" justifyContent="space-between" pt={1} >
                <Box>
                    <HeadlineTitle 
                        title='Order Fulfillment'
                        variant="h6"
                        bold="bold"
                    />
                </Box>
            </Stack>

            <OrderSearch 
                valueSearch={valueSearch}
                setValueSearch={setValueSearch}
                activeStatus={activeStatus}
                setActiveStatus={setActiveStatus}
                setFilterTopic={setFilterTopic}
                setFilterValue={setFilterValue}
                setSkip={setSkip}
                setRangeTopic={setRangeTopic}
                setRangeFrom={setRangeFrom}
                setRangeTo={setRangeTo}
                rangeFrom={rangeFrom}
                rangeTo={rangeTo}
            />

            { loading_fulfillment ?
            <Stack mt={1} className="item-card-fulfillment">
                {[1,2].map(idx => (
                    <OrderLoading key={idx} />
                ))}
            </Stack>
            :
            <div id="scrollableDivFulfillment"  className="overhiddenflow">
                { dataInfinite.length > 0 ? 
                <div>
                    <InfiniteScroll
                        dataLength={dataInfinite.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<LoaderProgress/>}
                        scrollableTarget="scrollableDivFulfillment"
                        endMessage={
                            <Stack flexDirection="column" justifyContent="center" alignItems="center" mt={3} mb={5}>
                                <Box>
                                    <HeadlineTitle title="No more data found." variant="body2" bold='400' />
                                </Box>
                                <Box>
                                    <Button onClick={() => scrollToTop()} size="small" variant='contained'>
                                        <p>Back to Top</p>
                                    </Button>
                                </Box> 
                            </Stack>
                        }
                    >
                    <Box>
                        {dataInfinite.map((item:any, idx:number) => (
                            <OrderCard item={item} key={idx} viewItems={viewItems} setViewItems={setViewItems} />
                        ))}
                    </Box>
                    </InfiniteScroll>
                </div> :
                <Box mt={2} mx={0.2}>
                    <EmptyData 
                        message="Order is not found." 
                        caption="Try to change your search or filter."
                    />
                </Box> }
            </div> }
            <SnackBarAlert message="Submit Pick List is success!" initialState={pick} />
            <SnackBarAlert message="Approve Down Payment is success!" initialState={approve_payment} />
            <SnackBarAlert message={`Submit ${status_name} is success!`} initialState={update_status} />
        </Stack>
    )
}

export default PageFulfillment;
