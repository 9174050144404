import { createSlice } from '@reduxjs/toolkit';
import { 
  downloadTemplatePreferred,
  getOnePreferredProducts,
  getPreferredAll,
  getPreferredListsData,
  getSearchCompanyByCode, postPreferredBuyerList, 
  removePreferredBuyerLists, updatePreferredBuyerList, 
  uploadBulkPreferedList 
} from './buyerListsReducer';
import { errorSweetAlert } from '../../../../utilities/errorhandling';


const initialState: any = {
  data: [], 
  preferred_all : [],
  loading_preferred_all : false,
  search_buyer : [],
  loading : true,
  loading_search : false,
  loaded_search : false,
  create : false,
  loading_create : false,
  update : false,
  loading_update : false,
  remove : false,
  loading_remove : false,
  loading_bulk_upload : false,
  bulk_upload : false,
  preferred_products : {},
  loading_preferred_products : false,
  template_excel : "",
  loading_template_excel: false,
};

export const getPreferredBuyer = createSlice({
  name: 'buyer-lists',
  initialState, 
  reducers: {
    resetSearchBuyer : (state) => {
      state.search_buyer = [];
    },
    removeDownloadExcel : (state) => {
      state.template_excel = "";
    },
    resetSnackBarPreferred : (state) => {
      state.create = false;
      state.update = false;
      state.remove = false;
      state.bulk_upload = false;
    }
  },
  extraReducers: (builder) => {
    builder
      // search buyer from db
      .addCase(getSearchCompanyByCode.pending, (state) => {
        state.loading_search = true;
        state.loaded_search = false;
      })
      .addCase(getSearchCompanyByCode.fulfilled, (state, action:any) => {
        state.loading_search = false;
        state.search_buyer = action.payload.data;
        state.loaded_search = true;
      })
      .addCase(getSearchCompanyByCode.rejected, (state, action : any) => {
        state.loading_search = false;
        errorSweetAlert(action.payload)
      })
      // get buyer lists from db
      .addCase(getPreferredListsData.pending, (state) => { 
        state.loading = true;
        state.create = false;
        state.update = false;
        state.loaded_search = false;
        state.remove_buyer = false;
        state.search_buyer = [];
      })
      .addCase(getPreferredListsData.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data.length > 0 ? action.payload.data : [];
        state.total_data = action.payload.data.length > 0 ? action.payload.total.row : 0;
      })
      .addCase(getPreferredListsData.rejected, (state, action : any) => {
        state.loading = false;
        errorSweetAlert(action.payload)
      })
      // get products list preferred
      .addCase(getOnePreferredProducts.pending, (state) => {
        state.loading_preferred_products = true;
        state.preferred_products = {};
      })
      .addCase(getOnePreferredProducts.fulfilled, (state, action:any) => {
        state.loading_preferred_products = false;
        state.preferred_products = action.payload;
      })
      .addCase(getOnePreferredProducts.rejected, (state, action : any) => {
        state.loading_preferred_products = false;
        errorSweetAlert(action.payload)
      })

      // get all list preferred
      .addCase(getPreferredAll.pending, (state) => {
        state.loading_preferred_all = true;
      })
      .addCase(getPreferredAll.fulfilled, (state, action:any) => {
        state.loading_preferred_all = false;
        state.preferred_all = action.payload;
      })
      .addCase(getPreferredAll.rejected, (state, action : any) => {
        state.loading_preferred_all = false;
        errorSweetAlert(action.payload)
      })
       // post buyer list
       .addCase(postPreferredBuyerList.pending, (state) => {
        state.loading_create = true;
      })
      .addCase(postPreferredBuyerList.fulfilled, (state, action:any) => {
        state.loading_create = false;
        state.create = action.payload
      })
      .addCase(postPreferredBuyerList.rejected, (state, action : any) => {
        state.loading_create = false;
        errorSweetAlert(action.payload)
      })
      // update buyer list
      .addCase(updatePreferredBuyerList.pending, (state) => {
        state.loading_update = true;
      })
      .addCase(updatePreferredBuyerList.fulfilled, (state, action:any) => {
        state.loading_update = false;
        state.update = action.payload;
      })
      .addCase(updatePreferredBuyerList.rejected, (state, action : any) => {
        state.loading_update = false;
        errorSweetAlert(action.payload)
      })
      // remove buyer list
      .addCase(removePreferredBuyerLists.pending, (state) => {
        state.loading_remove = true;
      })
      .addCase(removePreferredBuyerLists.fulfilled, (state, action:any) => {
        state.loading_remove = false;
        state.remove = action.payload.data;
      })
      .addCase(removePreferredBuyerLists.rejected, (state, action : any) => {
        state.loading_remove = false;
        errorSweetAlert(action.payload)
      })

       // bulk upload 
       .addCase(uploadBulkPreferedList.pending, (state) => {
        state.loading_bulk_upload = true;
        state.bulk_upload = false;
      })
      .addCase(uploadBulkPreferedList.fulfilled, (state, action:any) => {
        state.loading_bulk_upload = false;
        state.bulk_upload = action.payload
      })
      .addCase(uploadBulkPreferedList.rejected, (state, action : any) => {
        state.loading_bulk_upload = false;
        errorSweetAlert(action.payload)
      })
    
       // print pdf
      .addCase(downloadTemplatePreferred.pending, (state) => {
        state.loading_template_excel= true;
        state.template_excel = "";
      })
      .addCase(downloadTemplatePreferred.fulfilled, (state, action:any) => {
        state.loading_template_excel= false;
        state.template_excel = action.payload;
      })
      .addCase(downloadTemplatePreferred.rejected, (state, action : any) => {
        state.loading_template_excel= false;
        errorSweetAlert(action.payload)
      })
  },
});

export const { resetSearchBuyer, removeDownloadExcel, resetSnackBarPreferred } = getPreferredBuyer.actions;

export default getPreferredBuyer.reducer;
