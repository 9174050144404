import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumbs from '../../../../components/BreadCrumbs'
import { Box, Stack } from '@mui/material';
import Fade from '@mui/material/Fade';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SelectDateDownload from './SelectDateDownload';
import ButtonCreate from '../../../../components/ButtonCreate';
import { downloadReports } from '../charts/reducers/chartsReducers';
import { RootState } from '../../../../app/store';
import { closeDownloadReport } from '../charts/chartsSlice';
import LoaderBackdrop from '../../../../components/LoaderBackdrop';
import { checkValidation } from '../../../../utilities/userFeaturesValidation';

const data = [
    {label : "Fulfillment", value : 'Fulfillment'},
    {label : "Invoice", value : 'Invoice'}
]

const ChartsDownload = () => {

    const dispatch = useDispatch()

    const { loading_download_report, download_report } = useSelector((state : RootState) => state.chart_report)

    const [show, setShow] = useState(false);
    const [selectedData, setSelectedData] = useState<any>("");

    const [rangeFrom, setRangeFrom] = useState([]);
    const [rangeTo, setRangeTo] = useState([]);


    function onCloseHover () {
        setShow(false);
    }

    function onClickItem (value:any) {
        setSelectedData(value)
        onCloseHover()
        
    }

    const onClickDownloadReport = () => {
        const data = {
            category : selectedData.value,
            from : rangeFrom,
            to : rangeTo
        }
        dispatch(downloadReports(data))
    }

    const Container = (
        <Stack className='container-hover'>
            { data.map((item:any, idx:any) => (
            <Box  
                key={idx}
                className={item.label === selectedData ? 'list-menu-active' : 'list-menu'} 
                onClick={() => { 
                    onClickItem(item)
                }}
            >
                <p>{item.label}</p>
            </Box>
            ))}
        </Stack> 
    );

    useEffect(() => {
        if(download_report !== "" ) {
            window.location.assign(download_report)
            dispatch(closeDownloadReport())
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [download_report]);

    return (
        <Box m={2}>
            <LoaderBackdrop loading={loading_download_report} />
            <BreadCrumbs
                isPage={false}
                current="Report Download"
            />
            <Stack direction="row" justifyContent="space-between" py={1} >
                <Box>
                    <h3>Report Download</h3>
                </Box>
            </Stack>

            <Stack flexDirection="row"  alignItems="center" flexWrap="wrap">
                
                <Box onMouseLeave={onCloseHover} >
                    <Box 
                        className='selection-box-title'
                        onMouseEnter={() => setShow(true)}
                    >
                        <p>{selectedData === "" ? "Select Category" : selectedData.label.toLowerCase()}</p>
                        <KeyboardArrowDownIcon />
                    </Box>
                    <Box sx={{ display: !show ? 'none' : 'flex', position: 'absolute'}}>
                        <Fade in={show}>{Container}</Fade>
                    </Box>
                </Box>

                <Box className='filter-date-paper'>
                    <SelectDateDownload 
                        setRangeFrom={setRangeFrom}
                        setRangeTo={setRangeTo}
                        rangeFrom={rangeFrom}
                        rangeTo={rangeTo}
                    />
                </Box>
                {checkValidation('DOREP5423454') ?
                <Box m={2}>
                    <ButtonCreate 
                        name="Download"
                        onClick={onClickDownloadReport}
                        disabled={selectedData === "" || rangeFrom.length === 0 ? true : false}
                    />
                </Box> : <Box m={1}>You dont have permission to download Report</Box>}
            </Stack>  
        </Box>
    )
}

export default ChartsDownload
