import { Stack, Box } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumbs from '../../../../components/BreadCrumbs';
import HeadlineTitle from '../../../../components/HeadlineTitle';
import ButtonCreate from '../../../../components/ButtonCreate';
import { RootState } from '../../../../app/store';
import moment from 'moment';
import { createNewInvoice, getInvoiceDetail, getInvoiceDetailByPackage, printDownloadInvPDF } from './redux/invoicesReducers';
import LoaderBackdrop from '../../../../components/LoaderBackdrop';
import SnackBarAlert from '../../../../components/SnackBarAlert';
import { useEffect, useState } from 'react';
import { clearDownloadPdf, clearMessageInvoice, closeOpenInvoice } from './redux/invoicesSlice';
import DialogConfirm from './components/DialogConfirm';
import DownloadPdf from '../../../../components/DownloadPdf';
import TextField from '@mui/material/TextField';
import LoadingInvoice from './components/LoadingSkeleton';

const InvoiceDetail = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location : any = useLocation()
    function useQuery() {
      return new URLSearchParams(location.search);
    }
    const active_page = useQuery().get('page')

    const { 
      open_invoice_data, loading_create_invoice, create_invoice,
      loading_invoice_detail, download_inv, approve_payment
    } = useSelector((state : RootState) => state.invoice)

    const [notes, setNotes] = useState("");
    const [dialogPayment, setDialogPayment] = useState(false);
    const [fileUrl, setFileUrl] = useState({
        name : '',
        url : ''
    });

    const id = location.state === undefined ? null : location.state.id
    const type = location.state === undefined ? null : location.state.type

    // function check empty object
    const isEmpty = (obj : any) => {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return true;
        }
        return false;
    }

    const onClickSubmitInvoice = () => {
      let data = {
        ...open_invoice_data,
        note : notes
      }
      dispatch(createNewInvoice(data))
    }

    useEffect(() => {
      if(id !== null && type !== null) {
        if(type === "id") {
          dispatch(getInvoiceDetail(id))
        } else {
          let param = {
            id : id,
            type : type
          }
          dispatch(getInvoiceDetailByPackage(param))
        }
      }   
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, type, approve_payment]);

    useEffect(() => {
      if(approve_payment) {
        if(type === "id") {
          dispatch(getInvoiceDetail(id))
        } else {
          let param = {
            id : id,
            type : type
          }
          dispatch(getInvoiceDetailByPackage(param))
        }
      }   
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approve_payment]);


    const finalPrice = (item : any) => {
      switch (item.discount_type) {
        case "PERCENTAGE":
          return item.retail_price - ((item.discount / 100) * item.retail_price);
        case "AMOUNT":
          return item.retail_price - item.discount;
        case "SPECIAL":
          return item.discount;
        default:
          return 0
      }
    }

    useEffect(() => {
      if(create_invoice) {
        setTimeout(() => {
          history.push('/dashboard/finance/invoice')
          dispatch(clearMessageInvoice())
        }, 1500);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create_invoice]);

    useEffect(() => {
      if(download_inv !== "" ) {
          window.location.assign(download_inv)
          dispatch(clearDownloadPdf())
      } 
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [download_inv]);

  useEffect(() => {
    if(isEmpty(open_invoice_data)) {
      setNotes(open_invoice_data.note)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open_invoice_data]);

  useEffect(() => {
    dispatch(closeOpenInvoice())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
    return (
        <Stack m={2} >
            <LoaderBackdrop 
                loading={loading_create_invoice } 
            />
            <BreadCrumbs 
                isPage={true}
                current={active_page === "detail" ? 'Detail Invoice' : 'Create Invoice'}
                link='/dashboard/finance/invoice'
                page='Invoices'
            />

            <Stack sx={{my:1}} direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                <Box>
                    <HeadlineTitle 
                        title={active_page === "detail" ? 'Detail Invoice' : 'Create Invoice'}
                        variant="h6"
                        bold="bold"
                    />
                </Box>
                { active_page === "detail" ? 
                <Box sx={{display:'flex'}}>
                  { isEmpty(open_invoice_data) && open_invoice_data.lastStatus === "Waiting to Confirm" && (
                  <ButtonCreate 
                    onClick={() => setDialogPayment(true)}
                    name="Check Payment"
                  /> )}
                  <Box mx={0.5}/>
                  {isEmpty(open_invoice_data) && (
                 <DownloadPdf 
                  name="Download PDF Invoice"
                  onClick={() => dispatch(printDownloadInvPDF(open_invoice_data._id))}
                /> )}                  
                </Box>:
                <Box>
                  <ButtonCreate 
                    onClick={onClickSubmitInvoice}
                    name="Submit Invoice"
                  />
                </Box> }
            </Stack>

            { loading_invoice_detail ? 
            <LoadingInvoice />
            :
            <>
            {isEmpty(open_invoice_data) && (
            <Box className="invoice-container">
              <Box className="logo-company">
                <Box className="logo">
                 <img src={open_invoice_data.vendor.logo} alt='logo company' />
                </Box>
                <Box className="company">
                  <h2>{open_invoice_data.vendor.legalName} </h2>
                  <p>{open_invoice_data.vendor.address.label} </p>
                  <p>{open_invoice_data.vendor.address.street}, {open_invoice_data.vendor.address.village.toLowerCase()}</p>
                  <p>
                    {open_invoice_data.vendor.address.subdistrict.toLowerCase()}, {open_invoice_data.vendor.address.city.toLowerCase()}, {open_invoice_data.vendor.address.province}, {open_invoice_data.vendor.address.postcode}
                  </p>
                </Box>
              </Box>

              <Box className="invoice-info">
                <Box className="bill-to">
                  <h3>Bill To</h3>
                  <h4>{open_invoice_data.buyer.legalName}</h4>
                  <p>{open_invoice_data.buyer.address.label} </p>
                  <p>{open_invoice_data.buyer.address.street}, {open_invoice_data.buyer.address.village.toLowerCase()}</p>
                  <p>
                    {open_invoice_data.buyer.address.subdistrict.toLowerCase()}, {open_invoice_data.buyer.address.city.toLowerCase()}, {open_invoice_data.buyer.address.province}, {open_invoice_data.buyer.address.postcode}
                  </p>
                </Box>
                <Box className="inv-number">
                  <Box className='inv-status'>
                    <h6>INVOICE {open_invoice_data.type && open_invoice_data.type}</h6>
                    { open_invoice_data.lastStatus === 'PAID' &&  
                    <Box className="status">
                      <h5>{open_invoice_data.lastStatus}</h5>
                    </Box> }
                  </Box>
                  <Box className="list">
                    <h5>Number :</h5>
                    <p>{open_invoice_data.invoiceNumber}</p>
                  </Box>
                  <Box className="list">
                    <h5>Due Date :</h5>
                    <p>{moment(open_invoice_data.dueDate).format('LL')}</p>
                  </Box>
                </Box>
              </Box>
              
              <Box >
                <table>
                  <thead>
                  <tr >
                    <th scope="col" >Product</th>
                    <th scope="col" >Qty</th>
                    <th scope="col" >Price</th>
                    <th scope="col" >Tax</th>
                    <th scope="col" >Tax Price</th>
                    <th scope="col" >Sub Total + Tax</th>
                  </tr>
                  </thead>
                  <tbody>
                  { open_invoice_data.items.map((itm:any, index:number) => (
                    <tr key={index} >
                      <td data-label="Product Name">{itm.name}</td>
                      <td data-label="Qty">{itm.received ? itm.received : itm.quantity} {itm.unit || "N/A"}</td>
                      <td data-label="Price">Rp. {Math.floor(finalPrice(itm)).toLocaleString()}</td>
                      <td data-label="Tax">{itm.tax.value * 100}%</td>
                      <td data-label="Tax Price">Rp. {Math.floor(itm.total_tax).toLocaleString()}</td>
                      <td data-label="Sub Total + Tax">Rp. {Math.floor(itm.total).toLocaleString()}</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </Box>

              <Box className="invoice-sum">
                <Box>
                  <Box className='item-sum'>
                    <h5>Total + Tax</h5>
                    <h2> Rp. {(open_invoice_data.subTotal ).toLocaleString()}</h2>
                  </Box>
                  <Box className='item-sum'>
                    <h5>Shipping Cost (+)</h5>
                    <h2>Rp. {open_invoice_data.shippingCosts.toLocaleString()}</h2>
                  </Box>
                  <Box className='item-sum'>
                    <h5>Total Tax</h5>
                    <h2>Rp. {open_invoice_data.taxTotal.toLocaleString()}</h2>
                  </Box>
                  <Box className='item-sum'>
                    <h5>Down Payment (-)</h5>
                    <h2> Rp. {open_invoice_data.downPayment.toLocaleString()}</h2>
                  </Box>
                  <Box className='item-sum-total'>
                    <h5>Grand Total </h5>
                    <h2>Rp. {open_invoice_data.grandTotal.toLocaleString()}</h2>
                  </Box>
                </Box>
              </Box>

              <Box className="document-referance">
                <h6>Document Reference</h6>
                <p><strong>Code Package :</strong> {open_invoice_data.reference.map((itm:any) => <span key={itm}>{itm.codePackage}, </span> )}</p>
              </Box>

              <Box className="document-referance">
                <h6>Note  { active_page === "detail" ? null : "(optional)" }</h6>
                { active_page === "detail" ? 
                <p>{notes || "N/A"} </p> :
                <TextField 
                  multiline rows={2} 
                  fullWidth 
                  value={notes} 
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder="Type note here..."
                /> }
              </Box>

              { open_invoice_data.lastStatus === 'PAID' || open_invoice_data.lastStatus === 'Waiting to Confirm' ?
              <Box className="payment-container">
                { open_invoice_data.infoPayment.length ?
                <Box className="method">
                  <h6>Detail Payment</h6>
                  <Box className="detail">
                    <p>Payment Method</p>
                    <h6>{open_invoice_data.infoPayment.slice(-1).pop().method || "N/A"}</h6>
                  </Box>
                  <Box className="detail">
                    <p>Payment Proof</p>
                    <a href={open_invoice_data.infoPayment.slice(-1).pop().fileUrl || "N/A"} target="_blank" rel="noreferrer" >Click View</a>
                  </Box>
                </Box> : <Box/> }
              </Box> : null }

              <Box className="line-bottom"/>
              <Box className="invoice-footer">
                <p>Invoice created {moment(open_invoice_data.createdAt).format('ll')}</p>
              </Box>
            </Box> )}
          </> }
          {isEmpty(open_invoice_data) && (
          <DialogConfirm 
            open={dialogPayment} setOpen={setDialogPayment} 
            infoPayment={open_invoice_data.infoPayment || []} 
            fileUrl={fileUrl} setFileUrl={setFileUrl} 
          /> )}
          <SnackBarAlert message="Create invoice is success!" initialState={create_invoice} />         
        </Stack>
    )
}


export default InvoiceDetail;
