import { Badge } from '@mui/material'
import { useSelector } from 'react-redux';
import { userCredentials } from '../../../../utilities/config';
import { useHistory } from 'react-router-dom';
import ChatIcon from '@mui/icons-material/Chat';
import { useEffect, useState } from 'react';
import { checkValidation } from '../../../../utilities/userFeaturesValidation';


const ChatNotif  = () => {
    const { new_message } = useSelector((state:any) => state.chats);

    const history = useHistory();

    const chatUnRead = (row:any) => {
        return row.filter((data:any) => data.from_company_id !== userCredentials.company_id && ( !data.read_by || !data.read_by[userCredentials.company_id] ))
    }

    const [historyState, setHistoryState] = useState(false);
    const [click, setClick] = useState(false);

    function toChatPage() {
        setHistoryState(!historyState)
        setClick(true)
    } 

    useEffect(() => {
        if(click) {
            if(historyState) {
                history.push("/dashboard/message");
            } else {
                history.goBack();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [historyState, click]);

    return (
        <Badge
            sx={{ display: checkValidation('REAMES803775') && userCredentials.company.isVerified ? 'block' : 'none'  }}
            badgeContent={chatUnRead(new_message).length} 
            color={chatUnRead(new_message).length === 0 ? "primary" : "error"}
        >
            <ChatIcon sx={{ cursor: 'pointer' }} onClick={toChatPage} /> 
        </Badge>
    )
}

export default ChatNotif