import { createSlice } from '@reduxjs/toolkit';
import { getAllExtensions, getAllInstalledExtensions, installExtensionsAction, unInstallExtensionsAction, updateSettingExtensionsAction } from './extensionsReducers';
import { errorSweetAlert } from '../../../../utilities/errorhandling';

const initialState: any = {
  data: [], 
  loading : false,
  loading_use : false,
  use : false,
  installed_extensions : [],
  loading_installed_extensions : false,
  uninstall : false,
  loading_uninstall : false,
  update : false,
  loading_update : false,

};

export const extensionsSlice = createSlice({
  name: 'extensions',
  initialState, 
  reducers: {
    clearMessageExtensions : (state) => {
      state.create = false;
      state.update = false;
      state.use = false;
      state.uninstall = false;
    }
  },
  extraReducers: (builder) => {
    builder
      // get extensions
      .addCase(getAllExtensions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllExtensions.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload
      })
      .addCase(getAllExtensions.rejected, (state, action : any) => {
        state.loading = false;
        errorSweetAlert(action.payload)
      })

       // get intsalled extensions
       .addCase(getAllInstalledExtensions.pending, (state) => {
        state.loading_installed_extensions = true;
      })
      .addCase(getAllInstalledExtensions.fulfilled, (state, action:any) => {
        state.loading_installed_extensions = false;
        state.installed_extensions = action.payload.data
      })
      .addCase(getAllInstalledExtensions.rejected, (state, action : any) => {
        state.loading_installed_extensions = false;
        errorSweetAlert(action.payload)
      })

       // use extensions
       .addCase(installExtensionsAction.pending, (state) => {
        state.loading_use = true;
        state.use = false;
      })
      .addCase(installExtensionsAction.fulfilled, (state, action:any) => {
        state.loading_use = false;
        state.use = action.payload
      })
      .addCase(installExtensionsAction.rejected, (state, action : any) => {
        state.loading_use = false;
        errorSweetAlert(action.payload)
      })

       // update setting extensions
       .addCase(updateSettingExtensionsAction.pending, (state) => {
        state.loading_update = true;
        state.update = false;
      })
      .addCase(updateSettingExtensionsAction.fulfilled, (state, action:any) => {
        state.loading_update = false;
        state.update = action.payload
      })
      .addCase(updateSettingExtensionsAction.rejected, (state, action : any) => {
        state.loading_update = false;
        errorSweetAlert(action.payload)
      })


       // unistall extensions
       .addCase(unInstallExtensionsAction.pending, (state) => {
        state.loading_uninstall = true;
      })
      .addCase(unInstallExtensionsAction.fulfilled, (state, action:any) => {
        state.loading_uninstall = false;
        state.uninstall = action.payload
      })
      .addCase(unInstallExtensionsAction.rejected, (state, action : any) => {
        state.loading_uninstall = false;
        errorSweetAlert(action.payload)
      })



      
  },
});

export const { clearMessageExtensions } = extensionsSlice.actions;
export default extensionsSlice.reducer;
