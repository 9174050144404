import { Box } from '@mui/material'
import React from 'react'

const ButtonCreate :React.FC<any> = ({ name, onClick, disabled, outlined, color, type }) => {

  return (
    <Box className={`button-actions ${outlined && 'btn_outlined'} ${color && `btn_${color}` } `}>
        <button onClick={onClick} disabled={disabled} type={type}>
            <p>{name}</p>
        </button>
    </Box>
  )
}

export default ButtonCreate