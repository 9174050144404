import { Box, Grid, Skeleton, Stack } from '@mui/material'
import React from 'react'

const OrderLoading : React.FC<any> = () =>  {
    return (
    <div className='container-item-purchase' > 
        <Box className="top-div">
            <Box className='left'>
                <Box pr={1}>
                    <Skeleton width={150} height={25} />
                </Box>
            </Box>
            <Box className='right'>
                <Box pr={1}>
                    <Skeleton width={150} height={25} />
                </Box>
            </Box>
        </Box>
        <Box className="center-div">
            <Grid container spacing={1}>
                <Grid item xs={12} md={10} sm={12} lg={10} xl={10}>
                    <Stack flexDirection="row" justifyContent="space-between">
                    <Box className='left-box'>
                        <h2><Skeleton width={180} height={40} /></h2>
                        <Skeleton width={150} height={20} />
                        <Skeleton width={150} height={10} />
                        <Skeleton width={150} height={10} />
                    </Box>
                    <Box className='right' pt={2}>
                        <Skeleton width={150} height={20} />
                        <Skeleton width={150} height={20} />
                        <Skeleton width={150} height={20} />
                    </Box>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
        <Box className='bottom-div'>
            <Box className='view-btn'>
                <p><Skeleton width={150} height={30} /></p>
            </Box>
            <Box className="btn-pr-submit">
                <Skeleton width={80} height={60}/>
            </Box>
        </Box>
    </div>
  )
}

export default OrderLoading