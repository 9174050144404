import Button from '@mui/material/Button';
import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack } from '@mui/material';
import SnackBarAlert from './SnackBarAlert';

const RemoveConfirmation : React.FC<any> = ({openRemove, setOpenRemove, loading, label, onClick, success, message}) => {
  
    const handleClose = () => {
        setOpenRemove({...openRemove, open : false, name : "", id: ""})
    };

    useEffect(() => {
        if(success) {
            handleClose()
        }
        // eslint-disable-next-line
    }, [success]);

    return (
        <div>
        <Dialog open={openRemove.open} maxWidth="sm" fullWidth>
            <DialogTitle>Delete Confirmation!</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure want to remove {label} <strong>{openRemove.name}</strong> ?
                </DialogContentText>
            </DialogContent>
            <Stack sx={{ p :2 }} flexDirection="row" justifyContent="space-between">
                <Button 
                    onClick={handleClose} 
                    size="small" variant="outlined" 
                    color='primary' 
                    disabled={loading}
                >Cancel</Button>
                <Button 
                    onClick={onClick} 
                    size="small" variant="contained" 
                    color='error' 
                    disabled={loading}
                >{loading ? "Loading..." : "Remove " + label}</Button>
            </Stack>
        </Dialog>

        <SnackBarAlert message={message} initialState={success} />
        </div>
    )
}

export default RemoveConfirmation
