import { useState, useEffect } from 'react';
import { 
    Stack, 
    Paper, 
    Box, 
    Grid,
    Typography,
    TextField,
    Button,
    CircularProgress,
} from '@mui/material';
import BreadCrumbs from '../../../components/BreadCrumbs';
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { getMasterCategoryList, getProductBySlug, postCreateProducts, updateProducts } from './reducers/productsReducers';
import TextAreaEditor from './components/TextAreaEditor';
import NewCategoryTree from '../../../components/CategoryTree';
import ImagesItems from './components/ImagesItems';
import { getAddressCountry, getMasterTax, getMasterUnit, getMasterWarehouse } from '../masters/mastersReducers';
import WarehouseItems from './components/WarehouseItems';
import required from '../../../assets/img/icon/required.png';
import SnackBarAlert from '../../../components/SnackBarAlert';
import { useHistory, useLocation } from 'react-router-dom';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import DataNotFound from '../../../components/DataNotFound';
import moment from 'moment';
import swal from 'sweetalert';
import { defaultMessageSuccess } from './productsSlice';
import HintsInfo from '../../../components/HintsInfo';

const images_items = [
    {
        image : ""
    },
    {
        image : ""
    },
    {
        image : ""
    },
    {
        image : ""
    }
]

const CreateNewProducts = () => {

    const dispatch = useDispatch()
    const history = useHistory() 
    const location : any = useLocation()
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    const page_query = useQuery().get('page');
    const slug_query :any = location.state

    const { category, loading_category }= useSelector((state : RootState) => state.products)
    const { 
        loading_create, success_create, message_create, 
        loading_product_detail, product_detail, product_detail_exist, 
        loading_update, success_update, message_update
    } = useSelector((state : RootState) => state.products)


    const { tax, loading_tax, unit, loading_unit, warehouse, loading_warehouse, country, loading_country } = useSelector((state : RootState) => state.masters)

    const [warehouseItems, setWarehouseItems] = useState<any>([]);
    const [warehouseItemsOthers, setWarehouseItemsOthers] = useState<any>([]);
    const [imagesItems, setImagesItems] = useState<any>(images_items);

    const [valueProduct, setValueProduct] = useState<any>({
        name: "",
        sku: "",
        brand : "", 
        made_in : null,
        expired_in : null,
        retail_price: "",
        dimension_w : "",
        dimension_l : "",
        dimension_h : "",
        weight : "",
        minimum_order_quantity: "",
        payment_term: "",
        down_payment : "", 
        volume : "",
        size : "",
        special_handling : "",
        status : "OPEN",
    });

    const [loaded] = useState(true);
    const [valueDescription, setValueDescription] = useState("");

    const [dataCategory, setDataCategory] = useState<any>([]);
    const [selectedData, setSelectedData] = useState<any>([]);
    const [defaultCategory, setDefaultCategory] = useState<any>([]);

    const [errorDescription, setErrorDescription] = useState(false);
    const [errorImages, setErrorImages] = useState(false);

    const [selectedTax, setSelectedTax] = useState<any>([]);
    const [errorTax, setErrorTax] = useState<boolean>(false);

    const [selectedUnit, setSelectedUnit] = useState<any>([]);
    const [errorUnit, setErrorUnit] = useState<boolean>(false);

    
    const [selectedCountry, setSelectedCountry] = useState<any>([]);


    const [errorWarehouse, setErrorWarehouse] = useState<boolean>(false);
    const [errorCategory, setErrorCategory] = useState<boolean>(false);

    const [dataNotFound, setDataNotFound] = useState(false);
 
    const handleChangeTax = (value: any) : void => {
        setErrorTax(false)
        setSelectedTax(value)
    }

    const handleChangeUnit = (value: any) : void => {
        setErrorUnit(false)
        setSelectedUnit(value)
    }

    const handleChangeCountry = (value: any) : void => {
        setSelectedCountry(value)
    }
    const onchangeValue = (e : any) => {
        const { name, value } = e.target
        setValueProduct({...valueProduct, [name] : value })
    }

    const onchangeValueNumeric = (e : any) => {
        const { name, value } = e.target
        if(value === isNaN || value === "") {
            setValueProduct({...valueProduct, [name] : 0 })
        } else {
            setValueProduct({...valueProduct, [name] : parseFloat(value) })
        }
    }

    const onchangeValueDecimals = (e : any) => {
        const { name, value } = e.target
        setValueProduct({...valueProduct, [name] : parseFloat(value) })
    }

    const onChangeValuePercentage = (e : any) => {
        const { name, value } = e.target 
        if(value === isNaN || value === "" ) {
            setValueProduct({...valueProduct, [name] : 0 })
        } else {
            if(value > 100) {
                swal('Error', "Percentage can't be more than 100!", 'error')
            } else {
                setValueProduct({...valueProduct, [name] : parseFloat(value) })
            }
        }
    }

    const handleChangeVisibility = (value: any) : void => {
        setValueProduct({...valueProduct, status : value.value })
    }

    function checkWarehouseItems() {
        let error = true
        warehouseItems.forEach((item : any) => {
            if(item.error_warehouse || item.error_retail_price || item.error_stock) {
                error = true
            } else {
                error = false
            }
        })
        return error
    }

    const checkError = () => {
        let error = true
        if (selectedData.length === 0) {
            setErrorCategory(true)
            error = true
        } else if(selectedTax.length === 0) {
            setErrorTax(true)
            error = true
        } else if(selectedUnit.length === 0) {
            setErrorUnit(true)
            error = true
        } else if (valueDescription === "") {
            setErrorDescription(true)
            error = true
        } else if (imagesItems[0].image === "") {
            setErrorImages(true)
            error = true
        } else if (warehouseItems.length === 0 || checkWarehouseItems()) {
            setErrorWarehouse(true)
            error = true
        } else {
            error = false
        }
        return error
    }

    const getImagesArray = (data: any) => {
        let images = []
        for(let i of data) {
            images.push(i.image)
        }
        let filter = images.filter(ele => ele !== "")
        return filter
    }

    const getWarehouseArray = (data: any) => {
        let warehouse = []
        let mixArray = [...data, ...warehouseItemsOthers]
        for(let i of mixArray) {
            warehouse.push({
                warehouse : i.warehouse_select.value,
                retail_price : parseInt(i.retail_price),
                stock : parseInt(i.stock),
                discount : {
                    label : i.discount_type.value,
                    value : parseInt(i.discount_value)
                },
                storage : {
                    rack : i.storage_rack,
                    bin : i.storage_bin,
                    level : i.storage_level
                }
            })
        }
        return warehouse
    }

    const onClickSubmit = (e : any) => {
        e.preventDefault()
        
        if(!checkError()) {
            let data_submit = {
                "company": "null",
                "name": valueProduct.name,
                "sku": valueProduct.sku,
                "description": valueDescription,
                "short_description" : "",
                "brand": valueProduct.brand,
                "images": getImagesArray(imagesItems),
                "dimension": {
                    "L": valueProduct.dimension_l,
                    "W": valueProduct.dimension_w,
                    "H": valueProduct.dimension_h,
                },
                "status": valueProduct.status,
                "tax" : selectedTax.value,
                "category" : selectedData.slice(-1)[0]._id,
                "minimum_order_quantity": parseInt(valueProduct.minimum_order_quantity) === 0 ? 1 : parseInt(valueProduct.minimum_order_quantity) ,
                "payment_term" : parseInt(valueProduct.payment_term),
                "down_payment" : valueProduct.down_payment,
                "country_of_origin" : selectedCountry.value || "",
                "volume" : valueProduct.volume,
                "weight" : valueProduct.weight,
                "size" : valueProduct.size,
                "special_handling" : valueProduct.special_handling,
                "made_in": valueProduct.made_in,
                "expired_in": valueProduct.expired_in,
                "warehouses" : getWarehouseArray(warehouseItems),
                "unit" : selectedUnit.value
            }
            if(page_query === "create") {
                dispatch(postCreateProducts(data_submit))
            } else {
                let body = {
                    data : data_submit,
                    _id : product_detail._id
                }
                dispatch(updateProducts(body))
            }
        } 
    } 

    useEffect(() => {
        if(success_create || success_update) {
            setTimeout(() => {
                history.push("/dashboard/products") 
                dispatch(defaultMessageSuccess())
            }, 1500);
        } 
        // eslint-disable-next-line
    }, [success_create, success_update]);

    const isEmpty = (obj : any) => {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return true;
        }
        return false;
    }

    const proceedImages = (data: any) => {
        let images = []
        for(let i of data) {
            images.push({ image : i })
        }
        if(images.length < 4) {
            let count = 4 - images.length
            for(let i = 0; i < count; i++) {
                images.push({ image : "" })
            }
        }
        return images
    }

    const proceedWarehouses = (data: any) => {
        let warehouse_list = []
        for(let i of data) {
            warehouse_list.push({ 
                warehouse_select : { value : i.warehouse._id, label : i.warehouse.name },
                retail_price : i.retail_price,
                stock : i.stock,
                discount_type : { value : i.discount.label, label : i.discount.label },
                discount_value : i.discount.value,
                storage_rack : i.storage.rack,
                storage_bin : i.storage.bin,
                storage_level : i.storage.level  
            })
        }
        const filteredData = warehouse_list.filter((wr: any) => {
            return warehouse.some((filteredWr: any) => filteredWr.value === wr.warehouse_select.value);
          });
          
        const unfilteredData = warehouse_list.filter((wr: any) => {
            return !warehouse.some((filteredWr: any) => filteredWr.value === wr.warehouse_select.value);
        });
        setWarehouseItemsOthers(unfilteredData)
        return filteredData
    }

    useEffect(() => {
        if(page_query === "edit" && !loading_product_detail && !loading_warehouse) {
            if(product_detail_exist && isEmpty(product_detail)) {
                setValueProduct({...valueProduct, 
                    name : product_detail.name,
                    sku : product_detail.sku,
                    brand : product_detail.brand,
                    payment_term : product_detail.payment_term,
                    down_payment : product_detail.down_payment,
                    minimum_order_quantity : product_detail.minimum_order_quantity,
                    dimension_h : product_detail.dimension.H,
                    dimension_l : product_detail.dimension.L,
                    dimension_w : product_detail.dimension.W,
                    weight : parseFloat(product_detail.weight) || "",
                    size : product_detail.size || "",
                    volume : product_detail.volume || "",
                    special_handling : product_detail.special_handling || "",
                    made_in : product_detail.made_in ? moment(product_detail.made_in).format("YYYY-MM-DD") : null,
                    expired_in : product_detail.expired_in ? moment(product_detail.expired_in).format("YYYY-MM-DD") : null,
                    status : product_detail.status
                })
                setSelectedCountry((product_detail.country_of_origin && { value : product_detail.country_of_origin, label : product_detail.country_of_origin }) || [])
                setSelectedTax({value : product_detail.tax._id, label : product_detail.tax.label})
                setSelectedUnit((product_detail.unit && { value : (product_detail.unit && product_detail.unit._id) || "", label : (product_detail.unit && product_detail.unit.name) || ""}) || [])
                setValueDescription(product_detail.description || "")
                setImagesItems(proceedImages(product_detail.images))
                setDefaultCategory(product_detail.categories.tree !== undefined ? product_detail.categories.tree : [])
                setWarehouseItems(proceedWarehouses(product_detail.warehouses))
            } else {
                setDataNotFound(true)
            }
        }
        // eslint-disable-next-line
    }, [product_detail, page_query, product_detail_exist, loading_product_detail, loading_warehouse]);

    useEffect(() => {
        if(page_query === "edit") {
            if(slug_query !== undefined) {
                setValueProduct({})
                setSelectedTax([])
                setValueDescription("")
                setImagesItems([])
                setDefaultCategory([])
                setWarehouseItems([])
                dispatch(getProductBySlug(slug_query.slug))
            } else {
                setDataNotFound(true)
            }
        }
        // eslint-disable-next-line
    }, [slug_query, page_query]);

    useEffect(() => {
        dispatch(getMasterCategoryList())
        dispatch(getMasterTax())
        dispatch(getMasterUnit())
        dispatch(getMasterWarehouse())
        dispatch(getAddressCountry())
        // eslint-disable-next-line
    }, []);

    const resetAllField = () => {
        window.location.reload()
    }

    return (
        <Box m={2}>
            { page_query === "edit" && 
                <LoaderBackdrop loading={loading_product_detail} />
            }
            <BreadCrumbs
                isPage={true}
                link="/dashboard/products"
                page="Product"
                current={page_query === "create" ? "Create New Product" : "Edit Product"}
            />
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>{page_query === "create" ? "Create New Product" : "Edit Product"} </h3>
                </Box>
            </Stack>
            { !dataNotFound ? 
            <Box mt={2}>
            <form onSubmit={onClickSubmit}>
                <Grid container spacing={3}>
                    <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
                        <Paper elevation={2} sx={{p:3, borderRadius: 3}}>
                            <Box py={1}>
                                <Box pb={0.6} fontWeight="bold" fontSize={15}> 
                                    Product Name <span><img src={required} width="10px" alt='icon'/></span>
                                </Box>
                                <TextField 
                                    placeholder="Chicken Frozen"
                                    size="small"
                                    name="name"
                                    onChange={(e) => onchangeValue(e)}
                                    value={valueProduct.name}
                                    fullWidth
                                    required
                                />
                            </Box>
                            <Box py={1}>
                                <Box pb={0.6} fontWeight="bold" fontSize={15}>Stock Keeping Unit</Box>
                                <TextField 
                                    placeholder="SKU01273413"
                                    size="small"
                                    name="sku"
                                    onChange={(e) => onchangeValue(e)}
                                    value={valueProduct.sku}
                                    fullWidth
                                />
                            </Box>
                            <Box py={1}>
                                <Box pb={0.6} fontWeight="bold" fontSize={15}>
                                    Brand <span><img src={required} width="10px" alt='icon'/></span>
                                </Box>
                                <TextField 
                                    placeholder="Freexo"
                                    type="text"
                                    size="small"
                                    name="brand"
                                    onChange={(e) => onchangeValue(e)}
                                    value={valueProduct.brand}
                                    fullWidth
                                    required
                                />
                            </Box>
                            <Box py={1}>
                                <Box pb={0.6} fontWeight="bold" fontSize={15}>
                                    Category <span><img src={required} width="10px" alt='icon'/></span>
                                </Box>
                                <NewCategoryTree 
                                    data={category} 
                                    loading={loading_category}
                                    dataCategory={dataCategory}
                                    setDataCategory={setDataCategory}
                                    selectedData={selectedData}
                                    setSelectedData={setSelectedData}
                                    setErrorCategory={setErrorCategory}
                                    defaultCategory={defaultCategory}
                                />
                                { errorCategory ? <div className="error-p"><p>Category is required</p></div> : null } 
                            </Box>
                            <Box py={1}>
                                <Grid container spacing={3}>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Box pb={0.6} fontWeight="bold" fontSize={15}>
                                            Tax <span><img src={required} width="10px" alt='icon'/></span>
                                        </Box>
                                        { loading_tax ? 
                                        <Select
                                            placeholder="Loading.."
                                        /> :
                                        <Select
                                            placeholder="Select Tax"
                                            value={selectedTax}
                                            isSearchable={true}
                                            options={tax && tax}
                                            onChange={handleChangeTax}
                                            id="select-style-mes-product"
                                        /> }
                                        { errorTax ? <div className="error-p"><p>Tax is required</p></div> : null }
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Box pb={0.6} fontWeight="bold" fontSize={15}>
                                            Min Order Quantity <span><img src={required} width="10px" alt='icon'/></span>
                                        </Box>
                                        <TextField 
                                            placeholder='100'
                                            size="small"
                                            name="minimum_order_quantity"
                                            type="text"
                                            onChange={(e) => onchangeValueNumeric(e)}
                                            value={valueProduct.minimum_order_quantity}
                                            inputProps={{ min: 0 }}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                </Grid> 
                            </Box>
                            <Box py={1}>
                                <Grid container spacing={3}>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Box pb={0.6} fontWeight="bold" fontSize={15} className="title-hints-info">
                                            Down Payment  <HintsInfo message="Percentage"/> <span><img src={required} width="10px" alt='icon'/></span>
                                        </Box>
                                        <TextField 
                                            placeholder="10"
                                            size="small"
                                            type="text"
                                            name="down_payment"
                                            onChange={(e) => onChangeValuePercentage(e)}
                                            value={valueProduct.down_payment}
                                            inputProps={{ min: 0 }}
                                            fullWidth
                                            required
                                            helperText="Default value Down Payment applied from setting platform."
                                        />
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Box pb={0.6} fontWeight="bold" fontSize={15} className="title-hints-info">
                                            Payment Term <HintsInfo message="Days"/> <span><img src={required} width="10px" alt='icon'/></span>
                                        </Box>
                                        <TextField 
                                            placeholder='30 (Days)'
                                            size="small"
                                            name="payment_term"
                                            onChange={(e) => onchangeValueNumeric(e)}
                                            value={valueProduct.payment_term}
                                            inputProps={{ min: 0 }}
                                            type="text"
                                            fullWidth
                                            required
                                            helperText="Default value Payment Term applied from setting platform."
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Grid container spacing={3}>
                                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <Box pb={0.6} fontWeight="bold" fontSize={15} className="title-hints-info">
                                            Dimension <HintsInfo message="Width"/> <span><img src={required} width="10px" alt='icon'/></span>
                                        </Box>
                                        <TextField 
                                            placeholder="2 cm"
                                            size="small"
                                            type="text"
                                            name="dimension_w"
                                            onChange={(e) => onchangeValueNumeric(e)}
                                            value={valueProduct.dimension_w}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <Box pb={0.6} fontWeight="bold" fontSize={15} className="title-hints-info">
                                            Dimension <HintsInfo message="Length"/> <span><img src={required} width="10px" alt='icon'/></span>
                                        </Box>
                                        <TextField 
                                            placeholder="3 cm"
                                            size="small"
                                            type="text"
                                            name="dimension_l"
                                            onChange={(e) => onchangeValueNumeric(e)}
                                            value={valueProduct.dimension_l}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12} >
                                        <Box pb={0.6} fontWeight="bold" fontSize={15} className="title-hints-info">
                                            Dimension <HintsInfo message="Height"/> <span><img src={required} width="10px" alt='icon'/></span>
                                        </Box>
                                        <TextField 
                                            placeholder="5 cm"
                                            size="small"
                                            type="text"
                                            name="dimension_h"
                                            onChange={(e) => onchangeValueNumeric(e)}
                                            value={valueProduct.dimension_h}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Grid container spacing={3}>
                                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12} >
                                        <Box pb={0.6} fontWeight="bold" fontSize={15} className="title-hints-info">
                                            Weight <HintsInfo message="Kilograms"/> <span><img src={required} width="10px" alt='icon'/></span>
                                        </Box>
                                        <TextField 
                                            placeholder="1 kg"
                                            size="small"
                                            type="number"
                                            name="weight"
                                            onChange={(e) => onchangeValueDecimals(e)}
                                            value={parseFloat(valueProduct.weight)}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <Box pb={0.6} fontWeight="bold" fontSize={15}>Size</Box>
                                        <TextField 
                                            placeholder='Small / Large'
                                            size="small"
                                            name="size"
                                            onChange={(e) => onchangeValue(e)}
                                            value={valueProduct.size}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <Box pb={0.6} fontWeight="bold" fontSize={15} className="title-hints-info">
                                            Volume  <HintsInfo message="Millilitres"/>
                                        </Box>
                                        <TextField 
                                            placeholder='1000 ml'
                                            size="small"
                                            name="volume"
                                            onChange={(e) => onchangeValue(e)}
                                            value={valueProduct.volume}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Grid container spacing={3}>
                                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <Box pb={0.6} fontWeight="bold" fontSize={15}>
                                            Original Country 
                                        </Box>
                                        { loading_country ? 
                                        <Select
                                            placeholder="Loading.."
                                        /> :
                                        <Select
                                            placeholder="Select Country"
                                            value={selectedCountry}
                                            isSearchable={true}
                                            options={country && country}
                                            onChange={handleChangeCountry}
                                            id="select-style-brand-product"
                                        /> }
                                    </Grid>
                                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <Box pb={0.6} fontWeight="bold" fontSize={15}>MFG Date</Box>
                                        <TextField
                                            value={valueProduct.made_in}
                                            name="made_in"
                                            onChange={(e) => onchangeValue(e)}
                                            type="date"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <Box pb={0.6} fontWeight="bold" fontSize={15}>Expired Date</Box>
                                        <TextField
                                            value={valueProduct.expired_in}
                                            name="expired_in"
                                            onChange={(e) => onchangeValue(e)}
                                            type="date"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Grid container spacing={3}>
                                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <Box pb={0.6} fontWeight="bold" fontSize={15}>
                                            Unit <span><img src={required} width="10px" alt='icon'/></span>
                                        </Box>
                                        { loading_unit ? 
                                        <Select
                                            placeholder="Loading.."
                                        /> :
                                        <Select
                                            placeholder="Select Unit"
                                            value={selectedUnit}
                                            isSearchable={true}
                                            options={unit && unit}
                                            onChange={handleChangeUnit}
                                            id="select-style-mes-product"
                                        /> }
                                        { errorUnit ? <div className="error-p"><p>Unit is required</p></div> : null }
                                    </Grid>
                                    <Grid item xl={8} lg={8} md={8} xs={12}>
                                        <Box ><h4>Product Visibility</h4></Box>
                                        <Box pt={1}>
                                            <Select
                                                placeholder="Select Product Visibility"
                                                value={{value: valueProduct.status, label: valueProduct.status}}
                                                isSearchable={true} 
                                                options={optionsVisibility}
                                                onChange={handleChangeVisibility}
                                                id="select-style-cat"
                                            /> 
                                        </Box> 
                                    </Grid>
                                    
                                </Grid>
                            </Box>
                            <Box py={1}>
                                <Box pb={0.6} fontWeight="bold" fontSize={15}>Special Handling</Box>
                                <TextField 
                                    placeholder="Stored in -4 celcius in Freezer"
                                    type="text" 
                                    size="small"
                                    name="special_handling"
                                    onChange={(e) => onchangeValue(e)}
                                    value={valueProduct.special_handling}
                                    fullWidth
                                />
                            </Box>
                            <Box py={1}>
                                <Box pb={0.6} fontWeight="bold" fontSize={15}>
                                    Product Description <span><img src={required} width="10px" alt='icon'/></span>
                                </Box>
                                <TextAreaEditor 
                                    value={loaded && valueDescription}
                                    setValue={setValueDescription}
                                    error={errorDescription}
                                    setError={setErrorDescription}
                                    placeholder="You can type long description and even product ingredients in here.."
                                    loaded={loaded}
                                />
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
                        <Paper elevation={2} sx={{p:3, borderRadius: 3}}>
                            <Box py={1}>
                                <Box pb={0.6} fontWeight="bold" fontSize={15}>
                                    Product Images <span><img src={required} width="10px" alt='icon'/></span>
                                </Box>
                                <Box>
                                    <ImagesItems 
                                        imagesItems={imagesItems}
                                        setImagesItems={setImagesItems}
                                        setErrorImages={setErrorImages}
                                    />
                                </Box>
                                <Box pt={1}>
                                    <Typography variant="body2" fontWeight="300">Image file type you can upload is only .JPG .JPEG and .PNG</Typography>
                                </Box>
                            </Box>
                            { errorImages ? <div className="error-p"><p>Please at least Upload an image.</p></div> : null }
                        </Paper>
                        <Box my={2} />
                        <WarehouseItems 
                            warehouseItems={warehouseItems}
                            setWarehouseItems={setWarehouseItems}
                            errorWarehouse={errorWarehouse}
                            setErrorWarehouse={setErrorWarehouse}
                        />
                        <Box my={2} />
                        <Paper elevation={2} sx={{p:3, borderRadius: 3}}>
                            { page_query === "create" ?
                            <Stack flexDirection="row" justifyContent="space-between">
                                <Button 
                                    variant='outlined' color="error" 
                                    onClick={resetAllField}
                                >
                                    Reset Field
                                </Button>
                                <Button 
                                    variant='contained' color="primary" type="submit" 
                                    disabled={loading_create}
                                >
                                    { 
                                        loading_create ? 
                                        <span>Loading.. <CircularProgress color='inherit' size={20}/></span> : 
                                        "Submit Product" 
                                    } 
                                </Button>
                            </Stack>
                            :
                            <Stack flexDirection="row" justifyContent="space-between">
                                <Button 
                                    variant='outlined' color="error" 
                                    disabled={loading_update}
                                    onClick={() => history.push('/dashboard/products')}
                                >
                                    Cancel
                                </Button>
                                <Button 
                                    variant='contained' color="primary" type="submit" 
                                    disabled={loading_update}
                                >
                                    { 
                                        loading_update ? 
                                        <span>Loading.. <CircularProgress color='inherit' size={20}/></span> : 
                                        "Save Product" 
                                    } 
                                </Button> 
                            </Stack> }
                        </Paper>
                    </Grid>
                </Grid>
                </form>
            </Box>
            :
            <DataNotFound title="Product not found. It is because product slug is not exist or there is a problem with the server."/> }
            <SnackBarAlert message={message_create} initialState={success_create} />
            <SnackBarAlert message={message_update} initialState={success_update} />

        </Box>
    )
}


const optionsVisibility  = [
    { value : "OPEN", label : "OPEN"},
    { value : "CLOSED", label : "CLOSED"},
    { value : "EXCLUSIVE", label : "EXCLUSIVE"}
]


export default CreateNewProducts;
