import {  useEffect } from 'react';
import { Box, Skeleton, Stack, } from '@mui/material';
import BreadCrumbs from '../../../../components/BreadCrumbs'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { getAllNotifications, getStatusUpdateFullfilment } from './reducers/notificationReducers';
// import moment from 'moment';
import HeadlineTitle from '../../../../components/HeadlineTitle';
import shopping from '../../../../assets/img/icon/shopping-bag.png'
import bill from '../../../../assets/img/icon/bill.png'
import general from '../../../../assets/img/icon/bell-ring.png'
import EmptyData from '../../../../components/EmptyData';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import LoaderBackdrop from '../../../../components/LoaderBackdrop';
import { userCredentials } from '../../../../utilities/config';
import { socket } from '../../../../utilities/socket';


const NotificationPage = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { data, read, loading, loading_status } = useSelector((state : RootState) => state.notification)

    useEffect(() => {
        dispatch(getAllNotifications(0))
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(read){
            dispatch(getAllNotifications(1))
        }
        // eslint-disable-next-line
    }, [read]);

    function renderImageNotif(params:string) {
        switch (params) {
          case "Orders":
            return shopping;
          case "BORADCAST":
            return general;
          case "Invoice":
            return bill; 
          default:
            return general;;
        }
    }

    function readNoficationsMessage(row:any) {
        if(row.read_by && row.read_by[userCredentials.auth_id]) { 
          return 'read'
        } else {
          socket.emit('read_notif', [`${row._id}`])
        }
    }


    const getLatestStatusOrder = async (id:string) => {
        const response : any = await dispatch(getStatusUpdateFullfilment(id))
        if(response.meta.requestStatus === 'fulfilled'){
            return response.payload
        }
        return null
    }
    
    const onClickReadNotif = async (row: any) => {
        readNoficationsMessage(row)
        switch (row.category) {
            case "Orders":
                let status = await getLatestStatusOrder(row.payload.idPo)  
                history.push({
                pathname: "/dashboard/orders",
                state : {
                    payload : row.payload,
                    status : status[0].lastStatus,
                }
                })
                break
            case "BORADCAST":
                return general;
            case "Invoice":
                history.push({
                pathname: '/dashboard/finance/invoice',
                search: `?page=detail`, 
                state : {
                    id : row.payload.invoiceId,
                    type : "id",
                    open : true
                }
                })
                break
            case "Products":
                if(row.link_url) {
                    return window.open(row.link_url, '_blank')
                } else {
                    history.push({
                    pathname: "/dashboard/products",
                    state : {}
                    })
                }
                break
            default:
            return general;;
        }
    }

    return (
        <Box m={2}>
            <LoaderBackdrop loading={loading_status} />
            <BreadCrumbs
                isPage={false}
                current="Notifications"
            />
            <Stack direction="row" justifyContent="space-between" py={1}>
                <HeadlineTitle 
                    title="Notifications"
                    variant="h6"
                    bold='600'
                />
            </Stack>
            { loading ? 
            <LoaderNotif /> :
            <Box className='notification-page'>
                { data.length === 0 ? 
                <EmptyData 
                    message="We dont have any notification for you yet!"
                    caption="Please check back later"
                /> :
                <Box className='content'>
                    {data.map((row:any, idx:number) => (
                        <Box 
                            className={`list-item ${!row.read && 'unread'}`} 
                            key={idx}
                            onClick={() => onClickReadNotif(row)}
                        >
                            <Box>
                                <img alt="shopping" src={renderImageNotif(row.category)}/>
                            </Box>
                            <Box className='right-notif'>
                                <h5 className='title'>{row.message}</h5>
                                <h6 className='time'>{moment(row.createdAt).fromNow()}</h6>
                            </Box>
                        </Box>
                    ))}
                </Box> }
            </Box> }
        </Box>
    )
}

const LoaderNotif = () => {
    return (
        <Box className='notification-page'>
            <Box className='content'>
                {[0,1,2,3,4,5,6,7,8].map((_, idx:number) => (
                    <Box className='list-item' key={idx}>
                        <Box>
                            <Skeleton variant="circular" width={40} height={40} />
                        </Box>
                        <Box className='right-notif' ml={1}>
                            <Skeleton variant="text" width={250} height={35} />
                            <Skeleton variant="text" width={200} height={20} />
                        </Box>
                    </Box>
                ))}
            </Box> 
        </Box>
    )
}

export default NotificationPage
