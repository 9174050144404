import { useEffect, useState } from 'react';
import { Box, Stack, Grid, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import BreadCrumbs from '../../../../components/BreadCrumbs'
import SalesValues from './components/SalesValue';
import TotalOrders from './components/TotalOrders';
import { getChartsReports } from './reducers/chartsReducers';
import moment from 'moment';
import HeadlineTitle from '../../../../components/HeadlineTitle';
import CardTiles from './components/CardTiles';
import payableimg from '../../../../assets/img/dashboard/sales.png'
import CardPurchase from './components/CardPurchase';
import SelectDate from '../../../../components/SelectDate';


const ChartsPage = () => {
    const dispatch = useDispatch()

    const { data, loading, charts_sales, charts_orders } = useSelector((state : RootState) => state.chart_report)
    
    const [rangeTopic, setRangeTopic] = useState([]);
    const [rangeFrom, setRangeFrom] = useState([]);
    const [rangeTo, setRangeTo] = useState([]);

    useEffect(() => {
        let range = {
            from : moment(rangeFrom).format('YYYY-MM-DD'),
            to : moment(rangeTo).format('YYYY-MM-DD'),
            topic : rangeTopic
        }
        dispatch(getChartsReports(range))
        // eslint-disable-next-line
    }, [rangeTo, rangeFrom]);

    return (
        <Box m={2}>
            <BreadCrumbs
                isPage={false}
                current="Charts Report"
            />
            <Stack direction="row" justifyContent="space-between" py={1} alignItems="center">
                <Box>
                    <HeadlineTitle title='Charts Report' variant="h6" bold="bold" />
                </Box>
                <Box className='filter-date-paper'>
                    <SelectDate 
                        setRangeTopic={setRangeTopic}
                        setRangeFrom={setRangeFrom}
                        setRangeTo={setRangeTo}
                        name="Filter Date"
                        type="date"
                    />
                </Box>
            </Stack>

            <Grid pt={0.5} container spacing={2}>
                <Grid item xs={12} md={6} lg={3} sm={6}>
                    <Box className='card-three-tiles'>
                        <CardPurchase 
                            label="Sales Today"
                            value={(data.salesDaily && data.salesDaily.total) || 0}
                            loading={loading}
                            currency={true}
                            background="#fff"
                            src={payableimg}
                        />
                        <CardPurchase 
                            label="Sales This Month"
                            value={(data.salesMonthly &&  data.salesMonthly.total) || 0}
                            loading={loading}
                            currency={true}
                            background="#fff"
                            src={payableimg}
                        />
                        <CardPurchase 
                            label="Sales This Year"
                            value={(data.salesYearly &&  data.salesYearly.total) || 0}
                            loading={loading}
                            currency={true}
                            background="#fff"
                            src={payableimg}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={9} sm={6}>
                    <Box className='card-six-tiles'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={4} sm={12}>
                            <CardTiles 
                                label="PO's in RTP"
                                value={(data.packageRTS && data.packageRTS.total) || 0}
                                loading={loading}
                                count={(data.packageRTS && data.packageRTS.count) || 0}
                                currency={true}
                                background="#fff"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={4} sm={12}>
                            <CardTiles 
                                label="PO's Canceled"
                                value={(data.packageCancel && data.packageCancel.totalAmount) || 0}
                                loading={loading}
                                count={(data.packageCancel && data.packageCancel.count) || 0}
                                currency={true}
                                background="#fff"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={4} sm={12}>
                            <CardTiles 
                                label="DN in Transit"
                                value={(data.packagePending && data.packagePending.totalAmount) || 0}
                                loading={loading}
                                count={(data.packagePending && data.packagePending.count) || 0}
                                currency={true}
                                background="#fff"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={4} sm={12}>
                            <CardTiles 
                                label="PO's Late Payment"
                                value={(data.latePayment && data.latePayment.total) || 0}
                                loading={loading}
                                count={(data.latePayment && data.latePayment.count) || 0}
                                currency={true}
                                background="#fff"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={4} sm={12}>
                            <CardTiles 
                                label="Account Receivable"
                                value={(data.accountReceive && data.accountReceive.totalAmount) || 0}
                                loading={loading}
                                count={(data.accountReceive && data.accountReceive.count) || 0}
                                currency={true}
                                background="#fff"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={4} sm={12}>
                            <CardTiles 
                                label="Net Sales"
                                value={(data.netSales && data.netSales.total) || 0}
                                loading={loading}
                                count={(data.netSales && data.netSales.count) || 0}
                                currency={true}
                                background="#fff"
                            />
                        </Grid>
                    </Grid>
                    </Box>
                </Grid>
            </Grid>

            <Box mt={4}>
                <Box className='card-lines-paper'>
                    <h2>Sales Value</h2>
                    <SalesValues 
                        loading={loading}
                        charts_sales={charts_sales}
                    />
                </Box>
            </Box>
            <Box mt={4}>
                <Box className='card-lines-paper'>
                    <h2>Total Orders</h2>
                    <TotalOrders 
                        loading={loading}
                        charts_orders={charts_orders}
                    />
                </Box>
            </Box>

            <Grid pt={4} container spacing={2}>
                <Grid mb={2} item xs={12} md={12} lg={12} sm={12}>
                    <Box className='card-brand-sales'>
                        <h2>Top 10 Most Sold Product</h2>
                        <Box className='table-card' mt={2}>
                            <TableContainer >
                                <Table className='table' aria-label="simple table">
                                    <TableHead sx={{backgroundColor: '#0091d6'}}>
                                        <TableRow>
                                            <TableCell align="center" sx={{color:'#fff'}}>PRODUCT NAME</TableCell>
                                            <TableCell align="center" sx={{color:'#fff'}}>QUANTITY</TableCell>
                                            <TableCell align="center" sx={{color:'#fff'}}>TOTAL IDR</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    { loading ? 
                                    <LoadingTable />
                                    :
                                    <TableBody>
                                        {( data.productList && data.productList.length && data.productList.map((item:any, index:any) => (
                                            <TableRow key={index}>
                                                <TableCell align="center">{item.name}</TableCell>
                                                <TableCell align="center">{item.qty}</TableCell>
                                                <TableCell align="center">Rp. {item.price.toLocaleString() || 0}</TableCell>
                                            </TableRow>
                                        ))) || 
                                            <TableRow>
                                                <TableCell align="center" colSpan={3}>No Data</TableCell>
                                            </TableRow>
                                         }
                                    </TableBody> }
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box> 
                </Grid>
                <Grid item xs={12} md={6} lg={6} sm={6}>
                    <Box className='card-seller-sales'>
                        <h2>Buyer by Order Value</h2>
                        <Box className='table-card' mt={2}>
                            <TableContainer >
                                <Table className='table' aria-label="simple table">
                                    <TableHead sx={{backgroundColor: '#0091d6'}}>
                                        <TableRow>
                                            <TableCell align="center" sx={{color:'#fff'}}>SELLER</TableCell>
                                            <TableCell align="center" sx={{color:'#fff'}}>TOTAL IDR</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    { loading ? 
                                    <LoadingTable />
                                    :
                                    <TableBody>
                                        {( data.totalCompanies && data.totalCompanies.length && data.totalCompanies.map((item:any, index:any) => (
                                            <TableRow key={index}>
                                                <TableCell align="center">{item.name}</TableCell>
                                                <TableCell align="center">Rp. {item.total.toLocaleString() || 0}</TableCell>
                                            </TableRow>
                                        ))) || 
                                            <TableRow>
                                                <TableCell align="center" colSpan={3}>No Data</TableCell>
                                            </TableRow>
                                         }
                                    </TableBody> }
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6} sm={6}>
                    <Box className='card-brand-sales'>
                        <h2>Sales By Category</h2>
                        <Box className='table-card' mt={2}>
                            <TableContainer >
                                <Table className='table' aria-label="simple table">
                                    <TableHead sx={{backgroundColor: '#0091d6'}}>
                                        <TableRow>
                                            <TableCell align="center" sx={{color:'#fff'}}>CATEGORY</TableCell>
                                            <TableCell align="center" sx={{color:'#fff'}}>TOTAL IDR</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    { loading ? 
                                    <LoadingTable />
                                    :
                                    <TableBody>
                                        {( data.productCategoryList && data.productCategoryList.length && data.productCategoryList.map((item:any, index:any) => (
                                            <TableRow key={index}>
                                                <TableCell align="center">{item._id || "N/A"}</TableCell>
                                                <TableCell align="center">Rp. {item.grandTotal.toLocaleString() || 0}</TableCell>
                                            </TableRow>
                                        ))) || 
                                            <TableRow>
                                                <TableCell align="center" colSpan={3}>No Data</TableCell>
                                            </TableRow>
                                         }
                                    </TableBody> }
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

const LoadingTable = () => {
    return (
        <TableBody>
            <TableRow>
                <TableCell align="center"><Skeleton variant='text' height={40}/></TableCell>
                <TableCell align="center"><Skeleton variant='text' height={40}/></TableCell>
                <TableCell align="center"><Skeleton variant='text' height={40}/></TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center"><Skeleton variant='text' height={40}/></TableCell>
                <TableCell align="center"><Skeleton variant='text' height={40}/></TableCell>
                <TableCell align="center"><Skeleton variant='text' height={40}/></TableCell>
            </TableRow>
        </TableBody>
    )
}

export default ChartsPage
