import { 
    Table,
    TableBody,
    TableHead,
    TableRow,
    Paper,
    TableCell,
    Box,
    Button,
    Skeleton
} from '@mui/material';
import { remove } from 'lodash'
import Select from 'react-select'
import axios from 'axios'
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { useEffect, useState } from 'react';
import { changeStepWarehouse } from '../warehouseSlice';
import LoaderBackdrop from '../../../../components/LoaderBackdrop';
import { fetchMasterSchedule, getAddressProvince } from '../../masters/mastersReducers';
import { 
    citiesResponse, 
    ISelectOption,
    localDataTypes,
    objectCoverage,
    addressApiResponse,
    objectSchedule,
    objectOperationTime
} from './warehouseTypes';

const CoverageInformation = () => {

    const { province, loading_province, schedule, loading_schedule } = useSelector((state : RootState) => state.masters)
    const dispatch = useDispatch()

    const [localData, setLocalData] = useState<localDataTypes>();
    const [coverageItems, setCoverageItems] = useState<objectCoverage[]>([]);

    const [loading, setLoading] = useState(false);
    
    const handleChangeProvince = (value: ISelectOption, index : number) : void => {
        let copy_array = [...coverageItems]
        if(copy_array.some(item => item.province === value.label)) {
            swal('Duplicate', `Province ${value.label} already selected!`, 'error')
        } else {
            const newData = copy_array.map((obj : objectCoverage, i : number) => {
                if(i === index)
                return {
                    ...obj,
                    loading_city : true,
                }
                return obj
            });
            setCoverageItems(newData)
            getDataCity(value, index)
        }

    }

    const proceedCity = (params : citiesResponse[], index :number, value:ISelectOption) => {
        let copy_array = [...coverageItems]
        let city : ISelectOption[] = []
        for(let prov of params) {
            city.push({
                value : prov.name,
                label : prov.name,
            })
        }
        const newData = copy_array.map((obj : objectCoverage, i : number) => {
            if(i === index)
            return {
                ...obj,
                loading_city : false,
                cities : city,
                city_options : city,
                province : value.label
            }
            return obj
        }); 
        setCoverageItems(newData)
    }


    const getDataCity = async (value : ISelectOption, index: number) => {
        try {
            const response : addressApiResponse = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/city?province=${value.label}`)
            if(response.data) {
                await proceedCity(response?.data, index, value)
            } else {
                swal('Error', `${response?.error}`, 'error')
            }
            
        } catch (error) {
            swal('Error', `${error}`, 'error')
        }
    }

    const getDataCityExisting = async (value : string) => {
        try {
            const response : addressApiResponse = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/city?province=${value}`)
            if(response.data) {
                let cities : ISelectOption[] = []
                for(let prov of response.data) {
                    cities.push({
                        value : prov.name,
                        label : prov.name,
                    })
                }

                return await cities
            } else {
                swal('Error', `${response.error}`, 'error')
            }
            
        } catch (error) {
            swal('Error', `${error}`, 'error')
        }
    }


    const handleChangeCity = (value: ISelectOption, index : number) : void => {
        let copy_array = [...coverageItems]
        const newData : any = copy_array.map((obj : objectCoverage, i : number) => {
            if(i === index)
            return {
                ...obj,
                cities: value,
            }
            return obj
        });
        setCoverageItems(newData)
    }

    const onClickAddNewField = () => {
        let copy_item = [...coverageItems]
        let new_object = {
            province : "Select Province",
            cities : [],
            city_options : [],
            loading_city : false
        }
        copy_item.unshift(new_object)
        setCoverageItems(copy_item)
    }

    const onClickRemoveItem = (idx : number) => {
        let copy_item = [...coverageItems]
        const items_remove = remove(copy_item, function(obj : objectCoverage, index : number) {
            return index !== idx
        });
        setCoverageItems(items_remove)
    }


    const processDeliverySchedule = (params : objectSchedule[]) => {
        let delivery : objectSchedule[] = []
        for(let i = 0; i < params.length; i++) {
            let new_object = {
                name : params[i].province,
                province : params[i].province,
                cities : params[i].cities,
                city_options : params[i].cities,
                loading_city : false,
                operationTime : schedule && schedule.map((day:objectOperationTime) =>  ({ ...day, active: true  }))
            }
            delivery.push(new_object)
        }
        return delivery
    }

    const handleNextSubmit = () => {
        if(coverageItems.length === 0) {
            swal('Error', 'Please add at least one coverage', 'error')
        } else if(coverageItems[0].province === "Select Province") {
            swal('Error', 'Please add at least one coverage', 'error')
        } else {
            let coverage : objectSchedule[] = coverageItems.filter((item : objectSchedule) => item.province !== "Select Province")
            let data_form = {
                ...localData,
                coverage: coverage,
                schedule : processDeliverySchedule(coverage)
            }
            console.log(data_form, 'data_form')
            localStorage.setItem('warehouse', JSON.stringify(data_form))
            dispatch(changeStepWarehouse(2))
        }
    }

    // function to add city_options from existing data array 
    const addCityOptions =  async (params : objectCoverage[]) => { 
        let array_datas = []
        for(let i = 0; i < params.length; i++) {
            array_datas.push({
                ...params[i],
                city_options : await getDataCityExisting(params[i].province)
            })
        }
        return array_datas
        // setCoverageItems(newData)
    }

    useEffect(() => {
        const local_data = localStorage.getItem('warehouse')
        const checkLocalData = async () => {
            setLoading(true)
            const data = local_data === null ? null : JSON.parse(local_data)
            if(data !== null) {
                let datas : any[] = await addCityOptions(data.coverage)
                setCoverageItems(datas)
                setLocalData(data)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }
        checkLocalData()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(getAddressProvince())    
        dispatch(fetchMasterSchedule())
        // eslint-disable-next-line
    }, []);

    return ( 
    <Box mt={2} className="modules-itemss">
        <LoaderBackdrop loading={loading || loading_schedule} />
        <Paper sx={{p:3, mt:2}}>

        <Box className="modules-items-ssecond" >
          <Table aria-label="simple table"  > 
              <TableHead>
                <TableRow sx={{display:'flex', flexWrap: 'wrap'}}>
                    <TableCell style={{fontWeight: 700, minWidth: '270px' }}>Province</TableCell>
                    <TableCell style={{fontWeight: 700, minWidth: '350px' }}>Cities</TableCell>
                    <TableCell style={{fontWeight: 700, minWidth: '270px'}}>
                        <Button 
                            variant="outlined" color="primary"
                            size="small"
                            onClick={() => onClickAddNewField()}
                        >
                        Add More Coverage
                        </Button>
                    </TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
              { coverageItems.map((row :objectCoverage, i : number) => (
                <TableRow key={i} sx={{display:'flex', flexWrap: 'wrap'}}>
                    <TableCell component="th" scope="row" width={300}>
                        { loading_province ? 
                        <Skeleton height={60} /> :
                        <Box>
                            <Select
                                placeholder="Select Province"
                                value={{ value: row.province, label: row.province }}
                                options={province && province}
                                onChange={(e:any) => handleChangeProvince(e,i)}
                            />
                        </Box>  }
                    </TableCell>
                    <TableCell component="th" scope="row">
                        { row.loading_city ? 
                        <Skeleton height={60} /> :
                        <Box >
                            { row.city_options.length === 0 ? 
                            <Select
                                placeholder="Please select province first"
                            /> 
                            :
                            <Select
                                placeholder="You can select multiple city"
                                value={row.cities}
                                isSearchable={true}
                                onChange={(e:any) => handleChangeCity(e, i)}
                                isMulti
                                options={row.city_options}
                                noOptionsMessage={() => "There is no another City."}
                                isClearable={true}
                            /> }
                        </Box> 
                        }
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Button 
                            color="error" 
                            onClick={() => onClickRemoveItem(i)}
                        >Remove</Button>
                    </TableCell>
                </TableRow>
              ))}
              </TableBody>
          </Table>
        </Box>


            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    sx={{ mr: 1 }}
                    variant="contained"
                    onClick={() => dispatch(changeStepWarehouse(0))}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button 
                    variant="contained"
                    onClick={handleNextSubmit}
                >
                    Next
                </Button>
            </Box>
        </Paper>
    </Box>
  );
}

export default CoverageInformation


