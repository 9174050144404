import React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import InvoiceList from './Pajak/InvoiceList';
import EfakturList from './Pajak/EfakturList';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};


const DialogDetail : React.FC<any> = ({ 
  open, setOpen,
 }) => {

    function handleClose () {
        setOpen({...open, open : false, tab : ""});
    };

    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open.open}
        fullWidth={true}
        maxWidth="lg"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Box className='tabs-extensions'>
              {tabs.map((item:any, index:number) => (
                  <Box 
                      key={index} className={item.value === open.tab ? 'tabs-active' : 'tabs'}
                      onClick={() => setOpen({...open, tab : item.value})}
                  >
                      <h2>{item.label}</h2>
                  </Box>
              ))}
          </Box>
        </BootstrapDialogTitle>
        <DialogContent >
          <Box>
            { open.tab === "invoice" ?
            <InvoiceList /> :
            <EfakturList/> }
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

const tabs = [
  {
      label : "Invoice Lists",
      value : "invoice",
  },
  // {
  //     label : "E-Faktur Lists",
  //     value : "efaktur",
  // },
]

export default DialogDetail;