
import { useHistory } from 'react-router-dom';
import {
    Container,
    CssBaseline,
    Stack,
    Box
  } from '@mui/material';
  import maintenance from '../../assets/img/maintenance.png'
  import logo from '../../assets/img/logo-color-p.png'
  import ButtonCreate from '../../components/ButtonCreate';
import { useEffect, useState } from 'react';

function ErrorRouting() {

    const history = useHistory()

    const [dataState, setDataState] = useState<any>();

    useEffect(() => {
        const data : any = localStorage.getItem('error_state') || null
        if(data !== null) {
            setDataState(JSON.parse(data))
        }
    }, []);

    const isNotEmpty = (obj:any) => {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
            return true;
        }
        return false;
    }
    
    return (
        <Box m={2}> 
            <Container component="main" maxWidth="lg">
                <CssBaseline />
                <Stack sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt : 10
                }}>
                    <Box>
                        <a href='/'>
                            <img src={logo} alt="logo" width="180px" />
                        </a>
                    </Box>
                    {isNotEmpty(dataState) ?
                    <div className="img-error-status"> 
                        <img alt="notfound" src={maintenance} />
                        <h1>Oops, Something went wrong!</h1>
                        <h5> {(dataState && dataState.error.message) || "Oops, something went wrong. Please try again later."}</h5>
                        <h6>Status Code : <strong style={{color: 'red'}}>{(dataState && (dataState.error.status || dataState.error.statusCode)) || ""}</strong></h6>
                    </div>  : null }
                    <div className="text-notfound-status">
                        <ButtonCreate 
                        name='Back to Previous Page'
                        onClick={() => history.goBack()}
                        />
                    </div>
                    
                </Stack>
            </Container>
        </Box>
    )
}

export default ErrorRouting
