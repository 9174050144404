import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }

export const getAllWarehouse = createAsyncThunk(
    'warehouse/get',
    async (params:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/warehouse/paginate`, { 
                params,
                headers 
            })
            if(response.data) {
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const postWarehouse = createAsyncThunk(
    'warehouse/post',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/warehouse`, body , {headers})
            if(response.data) {
                let message = response.data.message
                return {data : true, message : message}
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const updateWarehouse = createAsyncThunk(
    'warehouse/update',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/warehouse/${body.id}`, body.data, {headers})
            if(response.data) {
                return {data : true}
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const updateDefaultWarehouse = createAsyncThunk(
    'warehouse/update-default',
    async (id : string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/warehouse/${id}/default`, null, {headers})
            if(response.data) {
                return {data : true}
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const updateIsActiveWarehouse = createAsyncThunk(
    'warehouse/update-isActive',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/warehouse/${body.id}/isActive`, body.body, {headers})
            if(response.data) {
                return {data : true}
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


