import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack, Box, Switch } from '@mui/material';
import { useDispatch } from 'react-redux';
import { userCredentials } from '../../../../utilities/config';
import { updateStatusFulfillment } from '../redux/fulfillmentReducers';
import {  } from 'react-router-dom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogShipping : React.FC<any> = ({ open, setOpen, data }) => {

    const dispatch = useDispatch()

    const [price, setPrice] = React.useState("0");
    const [focusedPrice, setFocusedPrice] = React.useState(false)
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
    };


    const onFocusPrice = () => setFocusedPrice(true)
    const onBlurPrice = () => setFocusedPrice(false)


    const onChangePrice = (value : any) => {
      if(value === isNaN || value === "") {
        return setPrice("0")
      } else {
        return setPrice(value.replace(/,/g, ""));
      }
    }

    function handleClose () {
        setOpen(false);
    };

    const onClickUpdateStatus = (id:string, name:string) => {
      const params ={
          id : id,
          status : name,
          shipping_cost : parseFloat(price),
          body : {
              "user": userCredentials.fullname,
              "note": `Update Status to ${name}`,
              "lastStatus": "Ready to Ship"
          }, 
          withInvoice : checked ? "yes" : "no"
      }
      console.log(focusedPrice)
      dispatch(updateStatusFulfillment(params))
    }

    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">Submit Delivery Note</Box>
           </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers >
          <Box>
            <p>Please input shipping cost if any :</p>
            <Box className="input-shipping-cost">
                <p>Rp.</p>
                <input 
                  // type={!focusedPrice ? "text" : 'number'} 
                  type="text"
                  onFocus={onFocusPrice} 
                  onBlur={onBlurPrice}  
                  value={parseFloat(price).toLocaleString()}
                  onChange={(e) => onChangePrice(e.target.value)} 
                />
            </Box>
            <Box py={1}>
              <p>Choose option Invoice creation : </p>
              <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <span>{checked ? "With" : "Without"} Invoice</span>
            </Box>


          </Box> 
        </DialogContent>
        <DialogActions>
          <Button 
            color="primary"
            size="small" autoFocus variant='contained' 
            onClick={() => onClickUpdateStatus(data._id, "Shipped")}
          >
            Submit
          </Button> 
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default DialogShipping;