import { Box, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TableColumn } from 'react-data-table-component';
import { RootState } from '../../../app/store'
import DataTableBase from '../../../components/TableData'
import {userCredentials} from '../../../utilities/config'
import {  getAllVendorCompany } from './reducers/selectCompanyReducers'
import ButtonCreate from '../../../components/ButtonCreate';
import ListUserRoles from './ListUserRoles';
import SearchComponent from '../../../components/SearchComponent';

function SelectCompany() {

    const dispatch = useDispatch()
    const { data, loading, total_data } = useSelector((state : RootState) => state.all_vendor)

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [open, setOpen] = useState(false);
    const [valueSearch, setValueSearch] = useState<string>("");
    const [detail, setDetail] = useState<any>({});


    useEffect(() => {
        let params = {
            limit : limit,
            skip : page,
            search : valueSearch.trim(),
            'filterTopic[]' : 'platform',
            'filterValue[]' : 'VENDOR'
        }
        dispatch(getAllVendorCompany(params))
        // eslint-disable-next-line
    }, [limit, page, valueSearch]);

    const onChangePerPage = (page:any) => {
        setPage(page-1)
    }

    const columns: TableColumn<any>[] = [
        {
            name: 'No',
            width: '70px',
            cell: (row, index) => (
                <p>
                { page > 0 ? 
                    <>
                    {index + 1 + (limit * (page))}.
                    </>
                    :
                    <> 
                    {index + 1}.
                    </>
                }
                </p>
            )
        },
        {
            name: 'Company',
            selector: row => row.legalName,
        },
        {
            name: 'Type',
            selector: row =>  (row.type && row.type.name) || "-",
        },
        {
            name: 'Platform',
            selector: row => row.platform,
        },
        {
            name: 'Action',
            cell: (row, i) => (
                <Stack direction="row" spacing={3}>
                    <Box >
                        <ButtonCreate 
                            name="Select Role"
                            onClick={() => {
                                setOpen(true)
                                setDetail(row)
                            }}
                        />
                    </Box> 
                </Stack>
            ),
        },
    ];
    return (
        <Box m={2}>
           <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Box>
                    <h3>Welcome back, {userCredentials.fullname}</h3>
                    <Box pt={1} fontWeight="400">Please select a company you want to take over.</Box>
                </Box>
            </Stack>
            <SearchComponent 
                valueSearch={valueSearch}
                setValueSearch={setValueSearch}
                placeholder="Search Company"
            />

            <Stack pt={2}>
                <DataTableBase 
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    paginationServer
                    onChangePage={(value) => onChangePerPage(value)}
                    onChangeRowsPerPage={(value) => setLimit(value)}
                    paginationPerPage={limit}
                    noDataComponent="Company is not found, please search with another keyword"
                    paginationTotalRows={total_data}
                    persistTableHead
                />
            </Stack>

            <ListUserRoles open={open} setOpen={setOpen} detail={detail}/>
        </Box>
    )
}

export default SelectCompany
