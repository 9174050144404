import { Box, Grid } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { removeTokenCredential } from '../redux/tokenCredentialReducers'

const TokenCard : React.FC<any> = ({ item }) =>  {
    const dispatch = useDispatch()

    function getStyle (name:boolean) {
        switch (name) {
            case true:
                return {  backgroundColor : '#28c76f' }
            case false:
                return {  backgroundColor : '#e8505b' }
            default:
                return {  backgroundColor : '#43a047' }
        }
    }

    return (
    <div className='container-item-purchase'>
        <Box className="top-div">
            <Box className='left'>
                <h6>Name : {item.name}</h6>
            </Box>
            <Box className='right'>
                <Box className='status' sx={getStyle(item.isActive)}>
                    <h6>{item.isActive ? "Active" : "Inactive"}</h6>
                </Box>
            </Box>
        </Box>
        <Box className="center-div">
            <Grid container spacing={1}>
                <Grid item xs={12} md={10} sm={12} lg={10} xl={10}>
                    <Box className='left-box'>
                        <h2>Event Permissions :</h2>
                        <Box className='vendor-list'>
                            { item.event.map((ele:any, i:any) => (
                                <Box className='box-vendor' key={i}>
                                    <h3>{ele}</h3>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        <Box className='bottom-div'>
            <Box className="btn-pr-reject">
                <button 
                    onClick={() => dispatch(removeTokenCredential(item._id))}
                >
                    <p>Delete</p>
                </button>
            </Box>
        </Box>
    </div>
  )
}

export default TokenCard