import { useState, useEffect } from 'react';
import { Stack, Box, Skeleton } from '@mui/material';
import BreadCrumbs from '../../../components/BreadCrumbs'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { getAllWarehouse, updateDefaultWarehouse } from './reducers/warehouseReducers';
import { useHistory } from 'react-router-dom';
import { changeStepWarehouse, resetDefaultSnackbar } from './warehouseSlice';
import HeadlineTitle from '../../../components/HeadlineTitle';
import { checkValidation } from '../../../utilities/userFeaturesValidation';
import ButtonCreate from '../../../components/ButtonCreate';
import EmptyData from '../../../components/EmptyData';
import CardWarehouse from './components/CardWarehouse';
import SearchComponent from '../../../components/SearchComponent';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import SnackBarAlert from '../../../components/SnackBarAlert';
import { 
    objectUser, 
    ISelectOption,
    localDataTypes,
    objectSchedule,
    objectCoverage,
    dataWarehouseResponse,
} from './components/warehouseTypes';

const ListWarehouse = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const { data, loading, loading_default,  isdefault , update_isactive, loading_update_isactive} = useSelector((state : RootState) => state.warehouse)
    
    const [valueSearch, setValueSearch] = useState<string>("");

    function processCoverage (coverage: objectCoverage[]) {
        let coverages : objectCoverage[] = []
        coverage.map((item: objectCoverage) => {
            let city: ISelectOption[] = [];
            item.cities.forEach((value: any) => {
                city.push({ value: value, label: value });
            });
            coverages.push({
                ...item,
                province: item.province,
                cities : city,
            })
            return coverages
        })
        return coverages 
    }

    const processSchedule = (schedule: objectSchedule[]) => {
        let schedules : objectSchedule[] = []
        schedule.map(async(item: objectSchedule) => {
            let city: ISelectOption[] = [];
            item.cities.forEach((value: any) => {
                city.push({ value: value, label: value });
            });
            schedules.push({
                ...item,
                cities : city,
                city_options : city,
            })
            return schedules
        })
        return schedules 
    }

    const renderUsers = (users:objectUser[]) => {
        return users.map((item:objectUser) => ({ userId : item.userId, label : item.label }))
    }

    const onClickUpdate = (data: localDataTypes) => {
        let localData  = {
            name : data.name,
            phone_number : data.phone_number,
            imageUrl : data.imageUrl || "",
            email : data.email,
            address : data.address,
            users : data.users ? renderUsers(data.users) : [],
            schedule : data.schedule ? processSchedule(data.schedule) : [],
            coverage : processCoverage(data.coverage), 
            id : data._id
        }
        history.push({
            pathname : `/dashboard/warehouse`,    
            search: `?page=edit`,
        })
        localStorage.setItem('warehouse', JSON.stringify(localData))
        dispatch(changeStepWarehouse(0))
    }

    const onClickUpdateDefault = (id: string) => {
        dispatch(updateDefaultWarehouse(id))
    }

    const onClickDetail = (data: localDataTypes) => {
        history.push({
            pathname : `/dashboard/warehouse`,
            search: `?page=detail`, 
            state : {
                data : {
                    name : data.name,
                    phone_number : data.phone_number,
                    imageUrl : data.imageUrl || "",
                    email : data.email,
                    address : data.address,
                    users : data.users ? renderUsers(data.users) : [],
                    coverage : processCoverage(data.coverage),
                    schedule : data.schedule ? processSchedule(data.schedule) : [],

                }
            }
        })
    }

    const onClickCreate = () => {
        history.push({
            pathname : `/dashboard/warehouse`,    
            search: `?page=create`,
        }) 
        localStorage.removeItem('warehouse')
        dispatch(changeStepWarehouse(0))
    }

    useEffect(() => {
        const params = {
            limit : 1000,
            skip : 0,
            sortTopic : 'createdAt',
            sortValue : 'ASC',
            search : valueSearch
        }
        dispatch(getAllWarehouse(params))
        // eslint-disable-next-line
    }, [valueSearch]);

    useEffect(() => {
        const params = {
            limit : 1000,
            skip : 0,
            sortTopic : 'createdAt',
            sortValue : 'ASC',
            search : valueSearch
        }
        if(isdefault || update_isactive) {
            dispatch(getAllWarehouse(params))
            setTimeout(() => {
                dispatch(resetDefaultSnackbar())
            }, 2500);
        }
        // eslint-disable-next-line
    }, [isdefault, update_isactive]);

    return (
        <Box m={2}>
            <BreadCrumbs
                isPage={false}
                current="Warehouse Page"
            />
            <Stack direction="row" justifyContent="space-between" py={1} alignItems="center" flexWrap="wrap">
                <Box>
                    <HeadlineTitle title="Warehouse Lists" variant="h6" bold="bold" />
                </Box>
                <Box> 
                    { checkValidation('W-C-001') &&
                    <ButtonCreate 
                        name="Add New Warehouse"
                        onClick={onClickCreate}
                    /> }
                </Box>
            </Stack>

            <SearchComponent 
                valueSearch={valueSearch}
                setValueSearch={setValueSearch}
                placeholder="Search warehouse by name .."
            />
 
            { loading ?
            <Stack mt={1} className="item-card-preferred">
                {[1,2,3,4].map(idx => (
                    <Box key={idx} my={0.5}>
                        <Skeleton variant="rectangular" width={"100%"} height={250} sx={{borderRadius:2}} />
                    </Box>
                ))}
            </Stack>
            :
            <div id="scrollableDivPreferred"  className="overhiddenflow">
                { data.length > 0 ? 
                <div>
                    <Box>
                        {data.map((item:dataWarehouseResponse, idx:number) => (
                            <Box key={idx}>
                                <CardWarehouse 
                                    item={item} 
                                    onClickUpdate={onClickUpdate}
                                    onClickDetail={onClickDetail}
                                    onClickUpdateDefault={onClickUpdateDefault}
                                />
                            </Box>
                        ))}
                    </Box>
                </div> :
                <Box mt={2} mx={0.2}>
                    <EmptyData 
                        message="Warehouse is not found." 
                        caption="Try to change your search or filter."
                    />
                </Box> }
            </div> }
            <LoaderBackdrop  loading={loading_default || loading_update_isactive}/>
            <SnackBarAlert message="Update warehouse to default is success" initialState={isdefault} />
        </Box>
    )
}

export default ListWarehouse
