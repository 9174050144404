import { Stack, Grid, Box, Skeleton } from '@mui/material'
import React, { useState, useEffect } from 'react'
import searchimg from '../../../../assets/img/icon/search1.png'
import removeimg from '../../../../assets/img/icon/close.png'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import { getStatusCount } from '../redux/fulfillmentReducers'
import SelectDate from '../../../../components/SelectDate'
import moment from 'moment'

const OrderSearch : React.FC<any> = ({
    setValueSearch, valueSearch,
    activeStatus, setActiveStatus,
    setFilterTopic, setFilterValue,
    setRangeTopic, rangeFrom, rangeTo,
    setRangeFrom, setRangeTo
}) => {
    const dispatch = useDispatch()
    const { status, loading_status, update_status, approve_payment, pick } = useSelector((state : RootState) => state.fulfillment)
    const [inputSearch, setInputSearch] = useState("");

    function onSearch(e:any) {
        e.preventDefault()
        setValueSearch(inputSearch)
    }

    function onChangeStatus (name:string) {
        setActiveStatus(name)
        if(name === "All") { 
            setFilterTopic([])
            setFilterValue([])
        } else {
            setFilterTopic(["lastStatus"])
            setFilterValue([name])
        }
    }

    const getDataCount = (name:string) => {
        if(name === "All") {
            return status.length ? <span className="notify-badge">{status.length ? status.reduce((a:any, b:any) => a + b.count, 0) : 0}</span> : null
        } else {
            const data = status.filter((item:any) => item._id === name)
            return data.length ? <span className="notify-badge">{data.length ? data[0].count : 0}</span> : null
        }
    } 


    const getFetchStatusCount = (rangeFrom:any, rangeTo:any) => {
        let params = {
            from : rangeFrom.length ? moment(rangeFrom).format("YYYY-MM-DD") : '',
            to : rangeTo.length ? moment(rangeTo).format("YYYY-MM-DD") : '',
        }
        dispatch(getStatusCount(params))
    }

    useEffect(() => {
        getFetchStatusCount(rangeFrom, rangeTo)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangeFrom, rangeTo]);

    useEffect(() => {
        if(pick || update_status || approve_payment) {
            setTimeout(() => {
                getFetchStatusCount(rangeFrom, rangeTo)
            }, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pick, update_status, approve_payment]);

    return (
    <Stack className="container-search">
        <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={9}>
                <form onSubmit={onSearch}>
                <Box className='search-box'> 
                        <input 
                            placeholder={`Search buyer, package id, ...`}
                            onChange={(e) => setInputSearch(e.target.value)}
                            value={inputSearch}
                        />
                        <Box>
                            { valueSearch === "" ? 
                            <Box className='notyping'>
                                <img alt='searchimg' className='searchimg' src={searchimg} onClick={onSearch} />
                            </Box>
                            :
                            <Box className='typing'>
                                <img 
                                    alt='removeimg' className='removeimg' src={removeimg} 
                                    onClick={() => {
                                        setInputSearch("")
                                        setValueSearch("")
                                    }}
                                />
                                <Box className='divider-line'/>
                                <img alt='searchimg' className='searchimg' src={searchimg} onClick={onSearch} />
                            </Box> }
                    </Box>
                </Box>
                </form>
            </Grid>
            <Grid item xs={12} md={12} lg={3}  sx={{ display: {xs: 'none', sm: 'none', lg: 'block' }}}>
                <SelectDate 
                    setRangeTopic={setRangeTopic}
                    setRangeFrom={setRangeFrom}
                    setRangeTo={setRangeTo}
                    type="date"
                />
            </Grid>
        </Grid>
       
        <Stack className='status'>
            <Box><h5>Status</h5></Box>
            { loading_status ? 
            <Box className='list-status'>
                { array_tabs.map((_, index:number) => (
                    <Box className='container-status' key={index}>
                        <Box   className='status-box'>
                            <Skeleton height={30} width={80} sx={{m:0}} />
                        </Box>
                    </Box>
                )) }
            </Box>:
            <Box className='list-status'>
                { array_tabs.map((item:any, index:number) => (
                    <Box className='container-status' key={index}>
                        {getDataCount(item.value)}
                        <Box 
                            className={activeStatus === item.value ? 'status-box active' : 'status-box'}
                            onClick={() => onChangeStatus(item.value)}
                        >
                            <h6>{item.label}</h6>
                        </Box>
                    </Box>
                )) }
            </Box> }
        </Stack> 
    </Stack>
  )
}

const array_tabs = [
    { label: "All", value: "All" },
    { label: "New", value: "New" },
    { label: "Pick & Pack", value: "Pick" },
    { label: "Ready to Ship", value: "Ready to Ship" },
    { label: "Shipped", value: "Shipped" },
    { label: "Delivered", value: "Delivered" },
    { label: "Completed", value: "Completed" },
    { label: "Canceled", value: "Cancel" },
  ]

export default OrderSearch