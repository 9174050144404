import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }

export const getAddressCountry = createAsyncThunk(
    'get/address-Country',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/country`, { headers })
            if(response.data) {
                let data = []
                for(let item of response.data) {
                    data.push({
                        value : item.name,
                        label : item.name,
                    })
                }
                let sortAccending = data.sort((a, b) => a.label.localeCompare(b.label))
                return sortAccending
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getAddressProvince = createAsyncThunk(
    'get/address-province',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/province?country=Indonesia`, { headers })
            if(response.data) {
                let data = []
                for(let item of response.data) {
                    data.push({
                        value : item.name,
                        label : item.name,
                    })
                }
                let sortAccending = data.sort((a, b) => a.label.localeCompare(b.label))
                return sortAccending
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getAddressCity = createAsyncThunk(
    'get/address-city',
    async (name : string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/city?province=${name}`, { headers })
            if(response.data) {
                let data = []
                for(let item of response.data) {
                    data.push({
                        value : item.name,
                        label : item.name,
                    })
                }
                let sortAccending = data.sort((a, b) => a.label.localeCompare(b.label))
                return sortAccending
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getAddressDistrict = createAsyncThunk(
    'get/address-district',
    async (name : string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/district?city=${name}`, { headers })
            if(response.data) {
                let data = []
                for(let item of response.data) {
                    data.push({
                        value : item.name,
                        label : item.name,
                    })
                }
                let sortAccending = data.sort((a, b) => a.label.localeCompare(b.label))
                return sortAccending
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getAddressVillage = createAsyncThunk(
    'get/address-village',
    async (name : string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/village?district=${name}`, { headers })
            if(response.data) {
                let data = []
                for(let item of response.data) {
                    data.push({
                        value : item.name,
                        label : item.name,
                    })
                }
                let sortAccending = data.sort((a, b) => a.label.localeCompare(b.label))
                return sortAccending
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getMasterWarehouse = createAsyncThunk(
    'get/warehouse-fetch',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/warehouse/fetch/specification`, { headers })
            if(response.data) {
                let data = []
                for(let item of response.data) {
                    data.push({
                        value : item._id,
                        label : item.name,
                    })
                }
                return data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getMasterTax = createAsyncThunk(
    'get/tax-fetch',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/tax`, { headers })
            if(response.data) {
                let data = []
                for(let item of response.data) {
                    data.push({
                        value : item._id,
                        label : item.label,
                    })
                }
                let sortAccending = data.sort((a, b) => a.label.localeCompare(b.label))
                return sortAccending
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getMasterBank = createAsyncThunk(
    'get/bank-fetch',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/bank/fetch`, { headers })
            if(response.data) {
                let data = []
                for(let element of response.data) {
                    data.push({
                        value: element.code, 
                        name : element.name_code, 
                        label: element.name, 
                        id: element._id, 
                        iconUrl : element.imageUrl 
                    })
                }
                let sortAccending = data.sort((a, b) => a.label.localeCompare(b.label))
                return sortAccending
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);



export const getAddressCompany = createAsyncThunk(
    'get/address-company',
    async (params:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/address/${params.id}/by-company`, { headers })
            if(response.data) {
                return {data: response.data, type : params.type}
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getProductForDiscount = createAsyncThunk(
    'get/product-for-discount',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/product/list-without-warehouse`, { headers })
            if(response.data) {
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getSettingPlatform = createAsyncThunk(
    'get/setting-platform',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/setting-platform`, { headers })
            if(response.data) {
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getMasterUnit = createAsyncThunk(
    'get/master-unit',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/unit`, { headers })
            if(response.data) {
                let data = []
                for(let item of response.data) {
                    data.push({
                        value : item._id,
                        label : item.name,
                    })
                }
                let sortAccending = data.sort((a, b) => a.label.localeCompare(b.label))
                return sortAccending
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getMasterUserTeam = createAsyncThunk(
    'get/master-team',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/user/team`, { 
                params : {
                    limit : 1000,
                    skip : 0
                },
                headers 
            })
            if(response.data) {
                let data = []
                for(let item of response.data.data) {
                    data.push({
                        value : item.profile._id,
                        label : item.profile.name,
                    })
                }
                let sortAccending = data.sort((a, b) => a.label.localeCompare(b.label))
                return sortAccending
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const fetchMasterSchedule = createAsyncThunk(
    'MasterSchedule/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/time-operational`, {
                headers
            })
            if(response.data) { 
                return response.data
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})