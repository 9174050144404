import React, { useState } from "react";
import { 
    Grid,
} from '@mui/material';
import SnackBarAlert from "../../../../components/SnackBarAlert";

interface Image {
  src: string;
  fileName: string;
}

interface Props {
  images: Image[];
}

const ImageList: React.FC<Props> = ({ images }) => {
  
  const [copy, setCopy] = useState(false);
  const [fileCopy, setFileCopy] = useState("");

  const handleCopyButtonClick = (name:string) => {
    
    setFileCopy(name);
    setCopy(true);
    navigator.clipboard.writeText(name);
    setTimeout(() => {
        setCopy(false);
    }, 500);
  };
  
  return (
    <div className="media-center">
        <Grid container spacing={1}>
            {images.map((image:any) => (
                <Grid item xl={2} lg={3} md={4} sm={6} xs={12} key={image.fileName}>
                    <div
                        className="image-container"
                    >
                        <div className="file-name">
                            <p>{image.fileName}</p>
                        </div>
                        <img src={image.image_url} alt={image.image_url} />
                        <div className="btn-download ">
                            <div 
                              className="download"
                            >
                              <a
                                href={image.image_url}
                                download
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa fa-download" />
                                Download
                              </a>
                            </div>
                            <div 
                              className="copy"
                              onClick={() => handleCopyButtonClick(image.fileName)}   
                            >
                              <p>Copy Filename</p>
                            </div>
                        </div>
                    </div>
                </Grid>
            ))}
        </Grid>
         
      <SnackBarAlert message={`${fileCopy} copied`} initialState={copy} />

    </div>
  );
};

export default ImageList;
