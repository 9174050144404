import swal from "sweetalert";

export function errorHandler (error:any) {
    if(error.response === undefined) {
        const state = { error : {
            message : "Sorry, we are unable to display the requested information at this time. Our system has encountered an error, which means that we cannot retrieve the data you are requesting. Please try again later or contact our support team if the issue persists.",
            status : 403
        }};
        localStorage.setItem('error_state', JSON.stringify(state))
        if (process.env.NODE_ENV === 'development') {
            console.log('Running in development mode');
        } else if (process.env.NODE_ENV === 'production') {
            window.location.href = "/dashboard?page=error"
        } else {
            window.location.href = "/dashboard?page=error"
        }
        return { message : null}
    } else {
        if(error.response.status === 401 || error.response.status === 403 || error.response.status === 404 || error.response.status === 405 ||  error.response.status === 409 || error.response.status === 410 || error.response.status === 500 || error.response.status === 503 ) {
            const state = { error : error.response.data };
            localStorage.setItem('error_state', JSON.stringify(state))
            if (process.env.NODE_ENV === 'development') {
                console.log('Running in development mode');
            } else if (process.env.NODE_ENV === 'production') {
                window.location.href = "/dashboard?page=error"
            } else {
                window.location.href = "/dashboard?page=error"
            }
            return { message : null}
        } else if (error.response.status === 400 || error.response.status === 406) {
            return error.response.data || { message : "Something went wrong"}
        } else {
            return error.response.data
        }
    }
}
    
export function errorSweetAlert (err:any) {
    let error = errorHandler(err)
    if(error.message !== null) {
        swal("Error", `${error.message}`, 'error')
    }
}

