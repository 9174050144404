import { createSlice } from '@reduxjs/toolkit';
import {  getAllWarehouse, postWarehouse, updateDefaultWarehouse, updateIsActiveWarehouse, updateWarehouse } from './reducers/warehouseReducers';
import { errorSweetAlert } from '../../../utilities/errorhandling';

const initialState: any = {
  data: [], 
  total_data : 0,
  loading : false,
  create : false,
  isdefault : false,
  loading_default : false,
  message_create : "",
  loading_create : false,
  warehouse_update : false,
  loading_warehouse_update : false,
  step : 0,
  update_isactive : false,
  loading_update_isactive : false,
};

export const warehouseReducers = createSlice({
  name: 'warehouse',
  initialState, 
  reducers: {
    changeStepWarehouse: (state, action: any) => {
      state.step = action.payload
    },
    resetDefaultSnackbar: (state) => {
      state.create = false;
      state.warehouse_update = false;
      state.isdefault = false;
      state.update_isactive = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // get all warehouse
      .addCase(getAllWarehouse.pending, (state) => {
        state.loading = true;
        state.create = false;
        state.warehouse_update = false;
        state.message_create = "";
      })
      .addCase(getAllWarehouse.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data.length > 0 ? action.payload.data : [];
        state.total_data = action.payload.data.length > 0 ? action.payload.total.row : 0;
      })
      .addCase(getAllWarehouse.rejected, (state, action : any) => {
        state.loading = false;
        errorSweetAlert(action.payload)
      })
      // post warehouse
      .addCase(postWarehouse.pending, (state) => {
        state.loading_create = true;
      })
      .addCase(postWarehouse.fulfilled, (state, action:any) => {
        state.loading_create = false;
        state.create = action.payload.data;
        state.message_create = "Success Created new Warehouse"
      })
      .addCase(postWarehouse.rejected, (state, action : any) => {
        state.loading_create = false;
        errorSweetAlert(action.payload)
      })
      // update warehouse
      .addCase(updateWarehouse.pending, (state) => {
        state.loading_warehouse_update = true;
      })
      .addCase(updateWarehouse.fulfilled, (state, action:any) => {
        state.loading_warehouse_update = false;
        state.warehouse_update = action.payload.data;
      })
      .addCase(updateWarehouse.rejected, (state, action : any) => {
        state.loading_warehouse_update = false;
        errorSweetAlert(action.payload)
      })

      // default warehouse
      .addCase(updateDefaultWarehouse.pending, (state) => {
        state.loading_default = true;
      })
      .addCase(updateDefaultWarehouse.fulfilled, (state, action:any) => {
        state.loading_default = false;
        state.isdefault = action.payload.data;
      })
      .addCase(updateDefaultWarehouse.rejected, (state, action : any) => {
        state.loading_default = false;
        errorSweetAlert(action.payload)
      })

      // isActive warehouse
      .addCase(updateIsActiveWarehouse.pending, (state) => {
        state.loading_update_isactive = true;
      })
      .addCase(updateIsActiveWarehouse.fulfilled, (state, action:any) => {
        state.loading_update_isactive = false;
        state.update_isactive = action.payload.data;
      })
      .addCase(updateIsActiveWarehouse.rejected, (state, action : any) => {
        state.loading_update_isactive = false;
        errorSweetAlert(action.payload)
      })
      
  },
});


export const { changeStepWarehouse, resetDefaultSnackbar } = warehouseReducers.actions;

export default warehouseReducers.reducer;
