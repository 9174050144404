import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';
import Fade from '@mui/material/Fade';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ButtonCreate from '../../../../components/ButtonCreate';

const SelectDateDownload : React.FC<any> = ({ 
  setRangeFrom, 
  setRangeTo,
  rangeFrom,
  rangeTo 
}) => {
  const [open, setOpen] = useState(false);

  const [change, setChange] = useState(false);
  const [state, setState] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const onClickFilter = () => {
    setRangeFrom(`${moment(state[0].startDate).format('YYYY-MM-DD')}T00:00:00`)
    setRangeTo(`${moment(state[0].endDate).format('YYYY-MM-DD')}T23:59:59`)
    setOpen(false)
    setChange(true)
  }

  const onClickResetState = () => {
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }
    ])
  }

  const onClickReset = () => {
    setRangeFrom([])
    setRangeTo([])
    setOpen(false)
    onClickResetState()
    setChange(false)
  }

  const Container = (
    <Stack className='date-select'>
      <Box>
        <DateRangePicker
          onChange={(item:any) => {
            setState([item.selection])
          }}
          ranges={state}
          rangeColors={['#0091d6', '#0091d6', '#0091d6']}
        />
      </Box>
      <Box className='divider-btn'>
        <ButtonCreate 
          name='Reset Filter Date'
          onClick={() => onClickReset()}
          outlined={true}
        />
        <Box mx={0.5} />
        <ButtonCreate 
          name='Apply Filter'
          onClick={() => onClickFilter()}
        />
      </Box>
    </Stack>
  );

  return (
    <Box
      onMouseLeave={() => {
        setOpen(false)
        onClickResetState()
      }}
    >
        <Box 
          className='recently-view'
          onMouseEnter={() => {
            setOpen(true);
          }}
        >
          { change ? 
          <div id='flex-date'>
            <DateRangeIcon sx={{ mr:0.5, fontSize: 20 }} />
            <p>{moment(rangeFrom).format('DD MMM YYYY')} - {moment(rangeTo).format('DD MMM YYYY')}</p>
          </div> 
          :
          <div id='flex-date'>
            <DateRangeIcon sx={{ mr:0.5, fontSize: 20 }} />
            <p>Select Range Date</p>
          </div>
           }
        </Box>
        <Box sx={{ display: !open ? 'none' : 'flex', position: 'absolute', zIndex: 2000, pt:2 }}>
            <Fade in={open}>{Container}</Fade>
        </Box>
    </Box>
  );
}

export default SelectDateDownload
