import { useState, useEffect } from 'react'
import { Box, Button, Paper, Stack } from '@mui/material';
// import imagestore from '../../../../assets/img/placeholder.png'
import { changeStepWarehouse } from '../warehouseSlice';
import { useDispatch, useSelector } from 'react-redux';
import { postWarehouse, updateWarehouse } from '../reducers/warehouseReducers';
import { RootState } from '../../../../app/store';
import { useHistory, useLocation } from 'react-router-dom';
import SnackBarAlert from '../../../../components/SnackBarAlert';
import Summary from './Summary';

function SummarySection() {

    const dispatch = useDispatch()
    const history = useHistory()
    const location : any = useLocation()
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    let page = useQuery().get('page')

    const { create, loading_create, message_create, loading_warehouse_update, warehouse_update } = useSelector((state : RootState) => state.warehouse)

    const [localData, setLocalData] = useState<any>({});

    // function to check empty object
    const isEmpty = (obj : any) => {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return true;
        }
        return false;
    }

    useEffect(() => {
        const local_data = localStorage.getItem('warehouse')
        const checkLocalData = () => {
            const data : any = local_data === null ? null : JSON.parse(local_data)
            if(data !== null) {
                setLocalData(data)
            }
        }
        checkLocalData()
        // eslint-disable-next-line
    }, []);
    
    function changeFormatArray (coverage:any) {
        let array :any = []
        for(let itm of coverage) {
            array.push({
                province: itm.province,
                cities: itm.cities.map((item:any) => item.label)
            })
        }
        return array
    }

    function changeFormatSchedules (schedules:any) {
        let array :any = []
        for(let itm of schedules) {
            array.push({
                ...itm,
                cities: itm.cities.map((item:any) => item.label)
            })
        }
        return array
    }

    const onClickSubmit = () => {
        let body = {
            ...localData,
            coverage : changeFormatArray(localData.coverage),
            schedule : changeFormatSchedules(localData.schedule)
        }
        console.log(body, 'bod')
        dispatch(postWarehouse(body))
    }

    const onClickSave = () => {
        let body = {
            data : {
                ...localData,
                coverage : changeFormatArray(localData.coverage),
                schedule : changeFormatSchedules(localData.schedule)
            },
            id : localData.id
        }
        console.log(body, 'bod')
        dispatch(updateWarehouse(body))
    }

    useEffect(() => {
        if(create || warehouse_update) {
            setTimeout(() => {
                history.push('/dashboard/warehouse')
                localStorage.removeItem('warehouse')
                dispatch(changeStepWarehouse(0))
            }, 1500);
        }
        // eslint-disable-next-line
    }, [create, warehouse_update]);

    return (
    <div>
        <Paper sx={{p:3, mt:2}}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Box pb={2}>
                    <h3>Warehouse Information</h3>
                </Box>
            </Stack>
            { isEmpty(localData) && 
            <Summary localData={localData} />
            }
        </Paper>


        <Paper sx={{p:3, mt:2}}>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    sx={{ mr: 1 }}
                    variant="contained"
                    onClick={() => dispatch(changeStepWarehouse(2))}
                    disabled={loading_create || loading_warehouse_update}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                { page === "create" ?
                <Button 
                    variant="contained"
                    onClick={onClickSubmit}
                    disabled={loading_create}
                >
                    { loading_create ? "Loading.." : "Submit" }
                </Button> :
                <Button 
                    variant="contained"
                    onClick={onClickSave}
                    disabled={loading_warehouse_update}
                >
                    { loading_warehouse_update ? "Loading.." : "Save" }
                </Button> }
            </Box>
        </Paper>
        <SnackBarAlert message={message_create} initialState={create} />
        <SnackBarAlert message="Success update Warehouse." initialState={warehouse_update} />
    </div>
  )
}

export default SummarySection;