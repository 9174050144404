import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import BreadCrumbs from '../../../components/BreadCrumbs'
import { getCompanyDetail } from './reducers/profilesReducers'
import Box from '@mui/material/Box';
import CompanyBanner from './components/CompanyBanner'
import { Stack } from '@mui/material'
import Profile from './components/Profile'
import Address from './components/Address'
import Legal from './components/Legal'
import Bank from './components/Bank'
import LoaderBackdrop from '../../../components/LoaderBackdrop'
import Contact from './components/Contact'
import Account from './components/Account'
import { getActionCategoryCompany } from '../step_register/components/stepFormSlice'
import SettingCompany from './components/SettingCompany'

function ProfilePage() {

    const dispatch = useDispatch()
    const { profile, loading_profile, update_image } = useSelector((state : RootState) => state.profile)

    const [refetch, setRefetch] = React.useState(false);

    const [activeTabIndex, setActiveTabIndex] = React.useState<any>(0);

    const handleTabClick = (index:number) => {
        setActiveTabIndex(index);
    };

    const isEmpty = (obj : any) => {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    useEffect(() => {
        dispatch(getCompanyDetail())
        // eslint-disable-next-line
    }, [refetch, update_image]);

    useEffect(() => {
        dispatch(getActionCategoryCompany())
        // eslint-disable-next-line
    }, []);

    const tabsComponent = [
        {
            label: 'Profile',
            component : <Profile profile={profile}/>
        },
        {
            label: 'Settings',
            component : <SettingCompany profile={profile}  setRefetch={setRefetch} refetch={refetch}/>
        },
        {
            label: 'Contact',
            component : <Contact profile={profile}/>
        },
        {
            label: 'Address',
            component : <Address profile={profile}/>
        },
        {
            label: 'Legal Documents',
            component : <Legal profile={profile}/>
        },
        {
            label: 'Bank Account',
            component : <Bank profile={profile}/>
        },
       
        {
            label: 'Account',
            component : <Account/>
        },
    ]

    return (
        <Box m={2}>
            <LoaderBackdrop loading={loading_profile} />
            <BreadCrumbs 
                isPage={false}
                current="Profile Company"
            />

            { isEmpty(profile) ? null :
            <>
            <Stack sx={{mt:3}}>
                <CompanyBanner 
                    profile={profile}
                />
            </Stack>

            <Stack sx={{mt:3}} className="tab-profile"> 
                <Box className='container-tab'>
                    {tabsComponent.map((tab:any, index:any) => (
                        <button
                            key={index}
                            onClick={() => handleTabClick(index)}
                            className={index === activeTabIndex ? "active-tabs-profile" : "tabs-profile"}
                        >
                            <p>{tab.label}</p>
                        </button>
                    ))}
                </Box>

                <Box px={4}>
                    {tabsComponent[activeTabIndex].component}
                </Box>
            </Stack>
            </> }
        </Box>
    )
}

export default ProfilePage
