import { createSlice } from '@reduxjs/toolkit';
import { fetchMasterSchedule, getAddressCity, getAddressCompany, getAddressCountry, getAddressDistrict, getAddressProvince, getAddressVillage, 
  getMasterBank, getMasterTax, getMasterUnit, getMasterUserTeam, getMasterWarehouse, getProductForDiscount, getSettingPlatform 
} from './mastersReducers';
import { errorSweetAlert } from '../../../utilities/errorhandling';

const initialState: any = {
  country : [],
  loading_country : false,
  province : [],
  loading_province : false,
  city : [],
  loading_city : false,
  district : [],
  loading_district : false,
  village : [],
  loading_village : false,
  warehouse : [],
  loading_warehouse : false,
  tax : [],
  loading_tax : false,
  setting_platform : {},
  loading_setting_platform : true,
  bank : [],
  loading_bank : false,
  loading_address_company : false,
  buyer_address : {},
  vendor_address : {},
  products : [],
  loading_products : false,
  unit : [],
  loading_unit : false,
  users : [],
  loading_users : false,
  schedule : [],
  loading_schedule : false,
};

export const getMasterReducers = createSlice({
  name: 'masters',
  initialState, 
  reducers: {
    resetProducts : (state) => {
      state.products = []
    }
  },
  extraReducers: (builder) => {
    builder
      // get all country
      .addCase(getAddressCountry.pending, (state) => {
        state.loading_country = true;
      })
      .addCase(getAddressCountry.fulfilled, (state, action:any) => {
        state.loading_country = false;
        state.country = action.payload;
      })
      .addCase(getAddressCountry.rejected, (state, action : any) => {
        state.loading_country = false;
        errorSweetAlert(action.payload)
      })

      // get all province
      .addCase(getAddressProvince.pending, (state) => {
        state.loading_province = true;
      })
      .addCase(getAddressProvince.fulfilled, (state, action:any) => {
        state.loading_province = false;
        state.province = action.payload;
      })
      .addCase(getAddressProvince.rejected, (state, action : any) => {
        state.loading_province = false;
        errorSweetAlert(action.payload)
      })

      // get all city
      .addCase(getAddressCity.pending, (state) => {
        state.loading_city = true;
      })
      .addCase(getAddressCity.fulfilled, (state, action:any) => {
        state.loading_city = false;
        state.city = action.payload;
      })
      .addCase(getAddressCity.rejected, (state, action : any) => {
        state.loading_city = false;
        errorSweetAlert(action.payload)
      })

      // get all district
      .addCase(getAddressDistrict.pending, (state) => {
        state.loading_district = true;
      })
      .addCase(getAddressDistrict.fulfilled, (state, action:any) => {
        state.loading_district = false;
        state.district = action.payload;
      })
      .addCase(getAddressDistrict.rejected, (state, action : any) => {
        state.loading_district = false;
        errorSweetAlert(action.payload)
      })

      // get all village
      .addCase(getAddressVillage.pending, (state) => {
        state.loading_village = true;
      })
      .addCase(getAddressVillage.fulfilled, (state, action:any) => {
        state.loading_village = false;
        state.village = action.payload;
      })
      .addCase(getAddressVillage.rejected, (state, action : any) => {
        state.loading_village = false;
        errorSweetAlert(action.payload)
      })

      // get all master warehouse
      .addCase(getMasterWarehouse.pending, (state) => {
        state.loading_warehouse = true;
      })
      .addCase(getMasterWarehouse.fulfilled, (state, action:any) => {
        state.loading_warehouse = false;
        state.warehouse = action.payload;
      })
      .addCase(getMasterWarehouse.rejected, (state, action : any) => {
        state.loading_warehouse = false;
        errorSweetAlert(action.payload)
      })

      // get all master tax
      .addCase(getMasterTax.pending, (state) => {
        state.loading_tax = true;
      })
      .addCase(getMasterTax.fulfilled, (state, action:any) => {
        state.loading_tax = false;
        state.tax = action.payload;
      })
      .addCase(getMasterTax.rejected, (state, action : any) => {
        state.loading_tax = false;
        errorSweetAlert(action.payload)
      })

      // get all bank tax
      .addCase(getMasterBank.pending, (state) => {
        state.loading_bank = true;
      })
      .addCase(getMasterBank.fulfilled, (state, action:any) => {
        state.loading_bank = false;
        state.bank = action.payload;
      })
      .addCase(getMasterBank.rejected, (state, action : any) => {
        state.loading_bank = false;
        errorSweetAlert(action.payload)
      })
      // get company address buyer
      .addCase(getAddressCompany.pending, (state) => {
        state.loading_address_company = true;
      })
      .addCase(getAddressCompany.fulfilled, (state, action:any) => {
        state.loading_address_company = false;
        if(action.payload.type === 'buyer')
          state.buyer_address = action.payload.data;
        else {
          state.vendor_address = action.payload.data;
        }
      })
      .addCase(getAddressCompany.rejected, (state, action : any) => {
        state.loading_address_company = false;
        errorSweetAlert(action.payload)
      })

      // get all product for discount
      .addCase(getProductForDiscount.pending, (state) => {
        state.loading_products = true;
      })
      .addCase(getProductForDiscount.fulfilled, (state, action:any) => {
        state.loading_products = false;
        state.products = action.payload;
      })
      .addCase(getProductForDiscount.rejected, (state, action : any) => {
        state.loading_products = false;
        errorSweetAlert(action.payload)
      })

      // get all product for discount
      .addCase(getMasterUnit.pending, (state) => {
        state.loading_unit = true;
      })
      .addCase(getMasterUnit.fulfilled, (state, action:any) => {
        state.loading_unit = false;
        state.unit = action.payload;
      })
      .addCase(getMasterUnit.rejected, (state, action : any) => {
        state.loading_unit = false;
        errorSweetAlert(action.payload)
      })
      // get setting platform
      .addCase(getSettingPlatform.pending, (state) => {
        state.loading_setting_platform = true;
      })
      .addCase(getSettingPlatform.fulfilled, (state, action:any) => {
        state.loading_setting_platform = false;
        state.setting_platform = action.payload;
      })
      .addCase(getSettingPlatform.rejected, (state, action : any) => {
        state.loading_setting_platform = false;
        errorSweetAlert(action.payload)
      })

       // get user teams
       .addCase(getMasterUserTeam.pending, (state) => {
        state.loading_users = true;
      })
      .addCase(getMasterUserTeam.fulfilled, (state, action:any) => {
        state.loading_users = false;
        state.users = action.payload;
      })
      .addCase(getMasterUserTeam.rejected, (state, action : any) => {
        state.loading_users = false;
        errorSweetAlert(action.payload)
      })

      // get master schedule
      .addCase(fetchMasterSchedule.pending, (state) => {
        state.loading_schedule = true;
      })
      .addCase(fetchMasterSchedule.fulfilled, (state, action:any) => {
        state.loading_schedule = false;
        state.schedule = action.payload;
      })
      .addCase(fetchMasterSchedule.rejected, (state, action : any) => {
        state.loading_schedule = false;
        errorSweetAlert(action.payload)
      })
  },
});


export const { resetProducts } = getMasterReducers.actions;

export default getMasterReducers.reducer;
