import React, { useEffect, useState } from 'react'
import { Grid, Box, Skeleton, TextField, Paper, Button } from '@mui/material'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { getAddressCity, getAddressDistrict, getAddressProvince, getAddressVillage } from '../../masters/mastersReducers';
import PhoneInput from 'react-phone-number-input'
import imagestore from '../../../../assets/img/placeholder.png'
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { styled } from '@mui/material/styles';
import { v4 as uuid } from 'uuid';
import swal from 'sweetalert';
import { changeStepWarehouse } from '../warehouseSlice';
import StarRequired from '../../../../components/StarRequired';

const default_img = "https://mpindo.sgp1.digitaloceanspaces.com/vendor/faea01a7-b9c7-4db6-8c60-f922bcb55f64warehouse(1).png"

const Input = styled('input')({
    display: 'none',
});

const BasicInformation : React.FC<any> = () => {
    const dispatch = useDispatch()

    const { 
        province, loading_province,
        city, loading_city,
        district, loading_district,
        village, loading_village, 
    } = useSelector((state : RootState) => state.masters)

    const [coverageDefault, setCoverageDefault] = useState({
        province : "",
        cities : [],
        loading_city : false
    });

    const [localData, setLocalData] = useState<any>({});
    const [errorPhone, setErrorPhone] = useState(false);

    const [valueWarehouse, setValueWarehouse] = useState<any>({
        name : "",
        email : "",
        phone_number: "",
        imageUrl : default_img
    });

    const [valueAddress, setValueAddress] = useState({
        country : "Indonesia",
        province : "",
        error_province : false,
        city : "",
        error_city : false,
        subdistrict : "",
        error_subdistrict : false,
        village : "",
        error_village : false,
        postcode : "",
        street : "",
    });

    // state for Country
    const optionsCountry : any = [{ value : "Indonesia", label: "Indonesia" }];
    const [selectedCountry, setSelectedCountry] = useState<any>([{ value : "Indonesia", label: "Indonesia" }]);

    const handleChangeCountry = (value: any) : void => {
        setSelectedCountry(value)
    }

    const handleChangeProvince = (value: any) : void => {
        dispatch(getAddressCity(value.label))
        setCoverageDefault({
            ...coverageDefault,
            province : value.label,
        })
        setValueAddress({...valueAddress, 
            province : value.label,
            error_province : false,
            city : "",
            subdistrict: "",
            village: "",
        })
    }

    const handleChangeCity = (value: any) : void => {
        dispatch(getAddressDistrict(value.label))
        setValueAddress({...valueAddress, 
            city : value.label,
            subdistrict: "",
            village: "",
            error_city : false,
        })
    }

    const handleChangesubdistrict = (value: any) : void => {
        dispatch(getAddressVillage(value.label))
        setValueAddress({...valueAddress, 
            subdistrict : value.label,
            village: "",
            error_subdistrict : false,
        })
    }

    const handleChangeVillage = (value: any) : void => {
        setValueAddress({...valueAddress, 
            village : value.label,
            error_village : false,
        })
    }

    const onChangeValue = (e : any) => {
        const { name, value } = e.target 
        setValueWarehouse({...valueWarehouse, [name] : value })
    }

    const [loadingStore, setLoadingLogo] = useState(false);

    const onChangeStore = (e:any) => {
        e.preventDefault()
        setLoadingLogo(true)
        if(e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/jpg") {
            const random = uuid();
            setTimeout(() => {
                var S3 = require("aws-sdk/clients/s3");
                const s3bucket = new S3({
                    endpoint: process.env.REACT_APP_S3_BUCKET_ENDPOINT,
                    accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
                    secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET
                });
            
                if (e.target.files && e.target.files[0]) {
                const blob = e.target.files[0]
                const file_name = blob.name.replace(/\s/g, "")
                const params = { Body: blob, 
                                Bucket: process.env.REACT_APP_S3_BUCKET_NAME, 
                                Key: process.env.REACT_APP_S3_FOLDER_NAME + random + file_name
                                };
                s3bucket.putObject(params)
                .on('build', (request : any) => {
                    request.httpRequest.headers.Host = process.env.REACT_APP_S3_API_URL
                    request.httpRequest.headers['Content-Length'] = blob.size;
                    request.httpRequest.headers['Content-Type'] = blob.type;
                    request.httpRequest.headers['Access-Control-Allow-Origin']= '*';
                    request.httpRequest.headers['x-amz-acl'] = 'public-read';
                })
                .send((err : any, data : any) => {
                    if (err){  
                        swal(`${err}`, `${err.stack}`, 'error')
                        setLoadingLogo(false)
                    } 
                    else {      
                        const imageUrl = `${process.env.REACT_APP_S3_CDN_URL}${process.env.REACT_APP_S3_FOLDER_NAME}${random}${file_name}`
                        setValueWarehouse({...valueWarehouse, imageUrl : imageUrl})
                        setLoadingLogo(false)
                    }}
                )} 
            }, 500);
        } else {
            setLoadingLogo(false)
            swal("Error", "File type must be JPG, JPEG or PNG", "error")
        }
    }
    

    function checkValidation() {
        let validate = true
        if (valueWarehouse.phone_number === "") {
            setErrorPhone(true)
            validate = false
        } else if (valueAddress.province === "") {
            setValueAddress({...valueAddress, error_province : true})
            validate = false
        } else if (valueAddress.city === "") {
            setValueAddress({...valueAddress, error_city : true})
            validate = false
        } else if (valueAddress.subdistrict === "") {
            setValueAddress({...valueAddress, error_subdistrict : true})
            validate = false
        } else if (valueAddress.village === "") {
            setValueAddress({...valueAddress, error_village : true})
            validate = false 
        } else {
            validate = true
        }
        return validate
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if(checkValidation()) {
            let data_form = {
                "id" : localData.id ? localData.id : "",
                "company": "null",
                "name": valueWarehouse.name,
                "imageUrl": valueWarehouse.imageUrl === "" ? default_img : valueWarehouse.imageUrl,
                "phone_number": valueWarehouse.phone_number,
                "email": valueWarehouse.email,
                "address": {
                    "country": valueAddress.country,
                    "province": valueAddress.province,
                    "city": valueAddress.city,
                    "subdistrict": valueAddress.subdistrict,
                    "village": valueAddress.village,
                    "postcode": valueAddress.postcode,
                    "street": valueAddress.street
                },
                coverage: localData.coverage ? localData.coverage : [{...coverageDefault}],
                users : localData.users ? localData.users : [],
                schedule : localData.schedule ? localData.schedule : [],
                isActive : true,
            }
            localStorage.setItem('warehouse', JSON.stringify(data_form))
            dispatch(changeStepWarehouse(1))
        }
    }


    useEffect(() => {
        const local_data = localStorage.getItem('warehouse')
        const checkLocalData = () => {
            const data : any = local_data === null ? null : JSON.parse(local_data)
            if(data !== null) {
                setValueAddress({...valueAddress, 
                    country : data.address.country,
                    province : data.address.province,
                    city : data.address.city,
                    subdistrict : data.address.subdistrict,
                    village : data.address.village,
                    postcode : data.address.postcode,
                    street : data.address.street,
                })
                setValueWarehouse({...valueWarehouse, 
                    name : data.name,
                    imageUrl : data.imageUrl,
                    email : data.email,
                    phone_number : data.phone_number,
                })
                setLocalData(data)
            }
        }
        checkLocalData()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(getAddressProvince())    
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(city.length !== 0) {
            setCoverageDefault({...coverageDefault, cities : city})
        }
        // eslint-disable-next-line
    }, [city]);

    return (
        <div>
        <form onSubmit={handleSubmit}>
        <Paper sx={{p:3, mt:2}}>
            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xl={5} lg={5} xs={12}>
                            <Box className='img-warehouse'>
                                <div>
                                    { loadingStore ? 
                                    <div>
                                        <Skeleton 
                                            variant="circular" 
                                            height={100}
                                            width={100}
                                        />
                                    </div> :
                                    <img 
                                        alt="store"
                                        src={valueWarehouse.imageUrl === "" ? imagestore : valueWarehouse.imageUrl}
                                    />  }
                                </div>
                                <div className='upload-btn-warehouse'>
                                    <label htmlFor="icon-button-store" className='btn-label-warehouse' >
                                        <Input accept="image/*" id="icon-button-store" type="file"  onChange={(e) => onChangeStore(e)}/>
                                        { valueWarehouse.imageUrl === "" ? "Upload" : "Change" }
                                        <CameraAltIcon sx={{ height: 35, width: 35, color: '#000', ml:1 }}/>
                                    </label>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xl={7} lg={7} xs={12}>
                            <Box>
                                <Box pt={1}><h4>Name <StarRequired/> </h4></Box>
                                <TextField
                                    onChange={onChangeValue}
                                    value={valueWarehouse.name}
                                    margin="dense"
                                    fullWidth
                                    id="name"
                                    placeholder="Main Warehouse"
                                    type="text"
                                    name="name"
                                    size="small"
                                    required
                                />
                            </Box>
                        </Grid>
                    </Grid>
               </Grid>
               <Grid item xl={6} lg={6} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xl={6} lg={6} xs={12}>
                            <Box pt={1}><h4>Email <StarRequired/></h4></Box>
                            <TextField
                                onChange={onChangeValue}
                                value={valueWarehouse.email}
                                margin="dense"
                                fullWidth
                                type="email"
                                placeholder="your@email.com"
                                name="email"
                                size="small"
                                required
                            />
                        </Grid>
                        <Grid item xl={6} lg={6} xs={12}>
                            <Box pt={1}><h4>Phone <StarRequired/></h4></Box>
                            <Box>
                                <PhoneInput
                                    placeholder="Enter phone number"
                                    value={valueWarehouse.phone_number}
                                    onChange={(e) => {
                                        setErrorPhone(false)
                                        setValueWarehouse({...valueWarehouse, phone_number : e})
                                    }}
                                    defaultCountry="ID"
                                />
                                { errorPhone ? <div className="error-p"><p>Phone Number is required</p></div> : null }
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box mt={4} />

            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} xs={12}>
                    <Box pt={1}><h4>Country (Negara) <StarRequired/></h4></Box>
                    <Box pt={1} pb={1}>
                        <Select
                            placeholder="Select Country"
                            value={selectedCountry}
                            isSearchable={true}
                            options={optionsCountry && optionsCountry}
                            onChange={handleChangeCountry}
                            id="select-style-countryy"
                        />
                    </Box>
                </Grid>
                <Grid item xl={6} lg={6} xs={12}>
                    <Box pt={1}><h4>Province (Provinsi) <StarRequired/></h4></Box>
                    { loading_province ? 
                    <Skeleton height={60} />
                    :
                    <Box pt={1} pb={1}>
                        <Select
                            placeholder="Select Province"
                            defaultValue={{value : valueAddress.province, label: valueAddress.province || "Select Province"}}
                            isSearchable={true}
                            options={province && province}
                            onChange={handleChangeProvince}
                            id="select-style-provincee"
                        /> 
                    </Box> }
                    { valueAddress.error_province ? <div className="error-p"><p>Province is required</p></div> : null }
                </Grid>
            </Grid>
            
            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} xs={12}>
                    <Box  pt={1}><h4>City (Kota) <StarRequired/></h4></Box>
                    { loading_city ? 
                    <Skeleton height={60} />
                    :
                    <Box pt={1} pb={1}>
                    <Select
                        placeholder="Select City"
                        value={{value : valueAddress.city, label: valueAddress.city || "Select City"}}
                        isSearchable={true}
                        options={city && city}
                        onChange={handleChangeCity}
                        id="select-style-cityy"
                    /> 
                    </Box> }
                    { valueAddress.error_city ? <div className="error-p"><p>City is required</p></div> : null }
                </Grid>
                <Grid item xl={6} lg={6} xs={12}>
                    <Box pt={1}><h4>Sub District (Kecamatan) <StarRequired/></h4></Box>
                    { loading_district ? 
                    <Skeleton height={60} />
                    :
                    <Box pt={1} pb={1}>
                    <Select
                        placeholder="Select Sub subdistrict"
                        value={{value : valueAddress.subdistrict, label: valueAddress.subdistrict || "Select Sub District"}}
                        isSearchable={true}
                        options={district && district}
                        onChange={handleChangesubdistrict}
                        id="select-style-subdistrict"
                    /> 
                    </Box> }
                    { valueAddress.error_subdistrict ? <div className="error-p"><p>Sub subdistrict is required</p></div> : null }
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} xs={12}>
                    <Box pt={1}><h4>Village (Kelurahan) <StarRequired/></h4></Box>
                    { loading_village ? 
                    <Skeleton height={60} />
                    :
                    <Box pt={1} pb={1}>
                    <Select
                        placeholder="Select Village"
                        value={{value : valueAddress.village, label: valueAddress.village || "Select Village"}}
                        isSearchable={true}
                        options={village && village}
                        onChange={handleChangeVillage}
                        id="select-style-village"
                    /> 
                    </Box> }
                    { valueAddress.error_village ? <div className="error-p"><p>Village is required</p></div> : null }
                </Grid>
                <Grid item xl={6} lg={6} xs={12}>
                    <Box pt={1}><h4>Zip Code (Kode Pos) <StarRequired/></h4></Box>
                    <TextField
                        onChange={(e) => setValueAddress({...valueAddress, postcode : e.target.value})}
                        value={parseInt(valueAddress.postcode)}
                        placeholder="Enter Zip Code"
                        margin="dense"
                        fullWidth
                        type="number"
                        size="small"
                        required
                        InputProps={{
                            inputProps: { 
                                min : 0
                            }
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xl={12} lg={12} xs={12}>
                    <Box ><h4>Street (Jalan) <StarRequired/></h4></Box>
                    <TextField
                        margin="dense"
                        fullWidth
                        name="street"
                        placeholder="Jalan Merdeka 1"
                        onChange={(e) => setValueAddress({...valueAddress, street : e.target.value })}
                        value={valueAddress.street}
                        type="text"
                        id="street"
                        size="small"
                        multiline
                        rows={3}
                        required
                    />
                </Grid>
            </Grid>
        </Paper>

        <Paper sx={{p:3, mt:2}}>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    sx={{ mr: 1 }}
                    variant="contained"
                    disabled
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button 
                    variant="contained"
                    type='submit'
                >
                    Next
                </Button>
            </Box>
        </Paper>
        </form>
        </div>
    )
}

export default BasicInformation
