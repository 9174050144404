import React, { useEffect, useState } from 'react'
import imagestore from '../../../../assets/img/placeholder.png'
import bannerstore from '../../../../assets/img/banner.jpg'
import verified from '../../../../assets/img/icon/verified.png'
import unverified from '../../../../assets/img/icon/unverified.png'
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { styled } from '@mui/material/styles';
import { Box, Stack, Chip, CircularProgress } from '@mui/material';
import { v4 as uuid } from 'uuid';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import { updateImageCompany } from '../reducers/profilesReducers'
import { updateImageState } from '../profileSlice'
import SnackBarAlert from '../../../../components/SnackBarAlert'
import { checkValidation } from '../../../../utilities/userFeaturesValidation'

const Input = styled('input')({
    display: 'none',
});

interface IBannerStore {
    profile: any;
}

const CompanyBanner : React.FC<IBannerStore> = ({
    profile,
}) => {

    const dispatch = useDispatch()
    const { loading_update_image, update_image } = useSelector((state : RootState) => state.profile)

    const [typeUpload, setTypeUpload] = useState("");
    const [companyForm, setCompanyForm] = useState<any>({
        logo : profile.logo,
        banner : profile.banner,
    });

    const onChangeUrlValue = (url : string, name:string) => {
        setCompanyForm({...companyForm, [name] : url })
    }
    
    const onChangeStore = (e:any, name:any) => {
        e.preventDefault()
        setTypeUpload(name)
        if(e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/jpg") {
            const random = uuid();
            setTimeout(() => {
                var S3 = require("aws-sdk/clients/s3");
                const s3bucket = new S3({
                    endpoint: process.env.REACT_APP_S3_BUCKET_ENDPOINT,
                    accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
                    secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET
                });
            
                if (e.target.files && e.target.files[0]) {
                const blob = e.target.files[0]
                const file_name = blob.name.replace(/\s/g, "")
                const params = { Body: blob, 
                                Bucket: process.env.REACT_APP_S3_BUCKET_NAME, 
                                Key: process.env.REACT_APP_S3_FOLDER_NAME + random + file_name
                                };
                s3bucket.putObject(params)
                .on('build', (request : any) => {
                    request.httpRequest.headers.Host = process.env.REACT_APP_S3_API_URL
                    request.httpRequest.headers['Content-Length'] = blob.size;
                    request.httpRequest.headers['Content-Type'] = blob.type;
                    request.httpRequest.headers['Access-Control-Allow-Origin']= '*';
                    request.httpRequest.headers['x-amz-acl'] = 'public-read';
                })
                .send((err : any, data : any) => {
                    if (err){  
                        swal(`${err}`, `${err.stack}`, 'error')
                    } 
                    else {      
                        const imageUrl = `${process.env.REACT_APP_S3_CDN_URL}${process.env.REACT_APP_S3_FOLDER_NAME}${random}${file_name}`
                        const data = {
                            body : {
                                [name] : imageUrl
                            },
                            id : profile._id
                        }
                        dispatch(updateImageCompany(data))
                        onChangeUrlValue(imageUrl, name)
                    }}
                )} 
            }, 500);
        } else {
            swal("Error", "File type must be JPG, JPEG or PNG", "error")
        }
    }

    useEffect(() => {
        if(update_image) {
            setTimeout(() => {
                dispatch(updateImageState())
            }, 3000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update_image]);


    return (
    <div className='company-banner'>
        <div className='img-banner'>
            { loading_update_image && typeUpload === "banner" ?  
            <Box className='img-banner-skeleton'>
                <CircularProgress  color="inherit"/> 
                <Box pt={1} fontSize={16} >Loading ...</Box>
            </Box> :
            <img  
                src={companyForm.banner === "" ? bannerstore : companyForm.banner}
                alt="banner" 
            /> }
            <div className='upload-btn-banner'>
                <label htmlFor="icon-button-banner" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
                    <Input accept="image/*" id="icon-button-banner" type="file"  onChange={(e) => onChangeStore(e, 'banner')}/>
                    <Box 
                        sx={{
                            bgcolor: '#fff',
                            padding: '1px',
                            borderRadius: 3,
                            zIndex: '100px'
                        }}
                    >
                        { checkValidation('UPBANLOG1235123') ?
                        <CameraAltIcon sx={{ height: 40, width: 40, paddingTop: 1, color: '#0091d6' }}/>
                        : <Box sx={{width : 40, height: 40}} />
                        }
                    </Box> 
                </label>
            </div>
        </div>
        <Stack flexDirection="row" justifyContent="space-between" flexWrap="wrap"> 
            <Stack flexDirection="row" >
                <div className='img-store'>
                    { loading_update_image && typeUpload === "logo"  ? 
                    <Box className='img-store-skeleton'>
                    <CircularProgress  color="inherit"/> 
                </Box> :
                    <img 
                        alt="store"
                        src={companyForm.logo === "" ? imagestore : companyForm.logo}
                    />  }
                    { checkValidation('UPBANLOG1235123') &&
                    <div className='upload-btn-store'>
                        <label htmlFor="icon-button-store" style={{ cursor: 'pointer' }}>
                            <Input accept="image/*" id="icon-button-store" type="file"  onChange={(e) => onChangeStore(e, "logo")}/>
                            <CameraAltIcon sx={{ height: 40, width: 40, paddingTop: 1, color: '#000' }}/>
                        </label>
                    </div> }
                </div>
                <Box className='side-name'>
                    <h2>{profile.legalName}</h2>
                    <h4>{profile.name}</h4>
                    <Stack flexDirection="row" alignItems="center">
                        <Box>
                            <img src={profile.isVerified ? verified : unverified} alt='status' />
                        </Box>
                        <Box>
                            <p>{profile.isVerified ? "Verified" : "Unverified"}</p>
                        </Box>
                    </Stack>
                    <Stack direction="row" gap={1} sx={{pt:1}} justifyContent="flex-start" flexWrap="wrap">
                        <Chip label={`Code : ${profile.code}`} size='small' />
                        <Chip label={`Category : ${profile.category.name}`} size='small'/>
                        <Chip label={`PKP : ${profile.isPKP ? "Yes" : 'No'}`} size='small'/>
                    </Stack>
                </Box> 
            </Stack>
            <Box p={2} fontWeight="400" fontSize={15} className='hide-info-mobile' >
                Recommend banner ratio is 600 x 160 Pixel.
            </Box>
        </Stack>
        <SnackBarAlert message={`Success update ${typeUpload} Company.`} initialState={update_image} />
    </div>
  )
}

export default CompanyBanner