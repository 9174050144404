import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack, DialogActions, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ButtonActions from '../../../../components/ButtonActions';
import { updateSettingExtensionsAction } from '../redux/extensionsReducers';
import { RootState } from '../../../../app/store';
import LoaderBackdrop from '../../../../components/LoaderBackdrop';
import { clearMessageExtensions } from '../redux/extensionsSlice';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};


const DialogInstall : React.FC<any> = ({ 
  open, setOpen, item
 }) => {

  const { 
      use, loading_use, uninstall, loading_uninstall, loading_update, update
  } = useSelector((state : RootState) => state.extensions)

    const dispatch = useDispatch()

    function handleClose () {
        setOpen({open: false, item: {}})
    };

    const [formSetting, setFormSetting] = useState<any>({});


    useEffect(() => {
        if(item.settings){
          const newObj = { ...open.item, extension: item._id}
          const obj = item.settings.map((item:any) => {
            if (item.field_name === 'company') {
              return { [item.field_name]: 'null' };
            } else {
              return { [item.field_name]: '' };
            }
          }).reduce((prev:any, current:any) => ({ ...prev, ...current }), {});
          Object.keys(newObj).forEach(key => {
            if (obj.hasOwnProperty(key)) {
              obj[key] = newObj[key];
            }
          });
          setFormSetting(obj)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item, open])


    const onClickSubmit = (e:any) => {
        e.preventDefault()
        let data = {
          body : formSetting,
          id : open.item._id
        }
        dispatch(updateSettingExtensionsAction(data)) 
    }


    useEffect(() => {
      if(use || uninstall || update){
        setOpen({open: false, item: {}})
        setFormSetting({})
        setTimeout(() => {
          clearMessageExtensions()
        }, 1500);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [use, uninstall, update]);

    return (  
    <div>
      <LoaderBackdrop loading={loading_use || loading_uninstall || loading_update}/>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open.open}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">Setting Extension</Box>
           </Stack>
        </BootstrapDialogTitle>
        <form onSubmit={onClickSubmit}>
        <DialogContent dividers >
          <Box className='extension-dialog'>
            <Box className='extension-card' >
                <Box className='extension-item' >
                    <Box className='extension-item__image'>
                        <img src={item.logoUrl} alt="rolesimg" />
                    </Box>
                    <Box className='extension-item__name'>
                        <h1>{item.name}</h1>
                        <a href={item.website} target='_blank' rel="noreferrer">{item.website}</a>
                        <h6>{item.description}</h6>
                    </Box>
                </Box>
            </Box> 
            <Box my={2}>
              <h2>Settings</h2>

                <Box mt={2}>
                  {item.settings && item.settings.filter((itm:any) => itm.field_type !== 'nonform').map((item: any, idx:any) => (
                  <Box key={idx}>
                    <Box pb={0.6} fontWeight="bold" fontSize={15} sx={{ textTransform: 'capitalize' }}>
                        {item.field_name}
                    </Box>
                    <TextField 
                        placeholder={item.field_name}
                        name={item.field_name}
                        size="small"
                        type={item.field_type}
                        inputProps={{ min: 0 }}
                        fullWidth
                        required
                        value={formSetting[item.field_name]}
                        onChange={(e) => {
                          setFormSetting({
                            ...formSetting,
                            [item.field_name]: e.target.value,
                          });
                        }}
                        multiline={item.field_type === 'textarea'}
                        rows={item.field_type === 'textarea' ? 4 : 1}
                    />
                    <Box py={1} />
                  </Box>
                  ))}
                </Box>
            </Box> 
          </Box>
        </DialogContent>
        <DialogActions>
          <ButtonActions 
            name="Save Changes"
            type="submit"
          />
        </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}



export default DialogInstall;


