import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }

export const getCompanyDetail = createAsyncThunk(
    'profile/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/company/full/${userCredentials.company_id}/details`, {headers})
            if(response.data) {
                return {data : response.data}
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);
 
export const updateImageCompany = createAsyncThunk(
    'image/patch',
    async (data:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/company/image/${data.id}`, data.body, {headers})
            if(response.data) {
                return {data : true}
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const updateAutomationOrder = createAsyncThunk(
    'automation/patch',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/setting-platform/${body.id_company}/auto-fulfillment`, body.body, {headers})
            if(response.data) {
                return true
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);



export const updateProfileCompany = createAsyncThunk(
    'profile/patch',
    async (body:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/company/${body.id}`, body.data, {headers})
            if(response.data) {
                return {data : true}
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const updateContactCompany = createAsyncThunk(
    'contact/patch',
    async (body:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/contact-us/${body.id}`, body.data, {headers})
            if(response.data) {
                return {data : true}
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const updateAddressCompany = createAsyncThunk(
    'address/patch',
    async (body:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/address/update/${body.id}`, body.data, {headers})
            if(response.data) {
                return {data : true}
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const updateLegalDocumentCompany = createAsyncThunk(
    'legaldoc/patch',
    async (body:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/legal-document/bulkUpdate/${body.id}`, body.data, {headers})
            if(response.data) {
                return {data : true}
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const createBankAccount = createAsyncThunk(
    'bank-account/post',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/account-number`, body, {headers})
            if(response.data) {
                return {data : true}
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const updateBankAccount = createAsyncThunk(
    'bank-account/patch',
    async (data : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/account-number/${data.id}`, data.body, {headers})
            if(response.data) {
                return {data : true}
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const removeBankAccount = createAsyncThunk(
    'bank-account/remove',
    async (id : string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.delete(`${process.env.REACT_APP_API_SERVER}/account-number/${id}`, {headers})
            if(response.data) {
                return true
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const updateDefaultBankAccount = createAsyncThunk(
    'bank-account/default',
    async (id : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/account-number/default/${id}`, null, {headers})
            if(response.data) {
                return {data : true}
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const updateSettingPlatform = createAsyncThunk(
    'settings/patch',
    async (body : any, { rejectWithValue }) => { 
        try {
            let body_visibility = {
                isVisibility : body.visibiltySetting.value,
                platform : "VENDOR"
            }
            const visibility : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/visibility`, body_visibility, {headers})
            if(visibility.data) {
                const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/setting-platform/${body.body.company}`, body.body, {headers})
                if(response.data) {
                    return {data : true}
                } else {
                    return rejectWithValue(response.data.message[0])
                }
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const updateSettingInvoice = createAsyncThunk(
    'settings-inv/patch',
    async (params : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.patch(`${process.env.REACT_APP_API_SERVER}/setting-platform/${params.company}/auto-invoice`, params.body, {headers})
            if(response.data) {
                return {data : true}
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getSettingVisibility = createAsyncThunk(
    'visibility/get',
    async (id:string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/visibility/${id}`, {headers})
            if(response.data) {
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getSettingCustomNumberDN = createAsyncThunk(
    'customNumber-dn/get',
    async (id:string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/setting-orders/delivery-number/${id}/by-company`, {headers})
            if(response) {
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const updateSettingCustomNumberDN = createAsyncThunk(
    'customNumber-dn/post',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/setting-orders/delivery-number`, body, {headers})
            if(response.data) {
                return true
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);




export const getSettingCustomNumberInvoice = createAsyncThunk(
    'customNumber-Invoice/get',
    async (id:string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/setting-invoice/${id}/by-company`, {headers})
            if(response) {
                return response.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const updateSettingCustomNumberInvoice = createAsyncThunk(
    'customNumber-Invoice/post',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/setting-invoice`, body, {headers})
            if(response.data) {
                return true
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);