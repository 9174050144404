import React, {useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {  Box, Stack, TextField } from '@mui/material';
import Select from 'react-select'
import { useDispatch } from 'react-redux';
import ButtonCreate from '../../../../components/ButtonCreate';
import StarRequired from '../../../../components/StarRequired';
import { createNewTokenCredential } from '../redux/tokenCredentialReducers';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};


const DialogAddToken : React.FC<any> = ({ 
  open, setOpen, 
 }) => {

    const dispatch = useDispatch()
    // const { data, loading } = useSelector((state : RootState) => state.roles)
    const loading = false
    
    const [name, setName] = useState("");
    const [optionsEvents] = useState<any[]>([
      { value: 'Orders', label: 'Orders' },
      // { value: 'Invoice', label: 'Invoice' },
    ]);
    const [selectedEvents, setSelectedEvents] = useState<any>([]);
    const [errorSelectedEvents, setErrorSelectedEvents] = useState<boolean>(false);
    

    const onSubmit = (e: any): void => {
      e.preventDefault()
      if(selectedEvents.length === 0) {
        setErrorSelectedEvents(true)
      } else {
        let body = {
          company : "null",
          name : name,
          event : selectedEvents.map((item : any) => item.value),
        }
        dispatch(createNewTokenCredential(body))
      }

    } 
  
    const handleChangeRoles = (value: any) : void => {
        setSelectedEvents(value)
        setErrorSelectedEvents(false)
    }

    function handleClose () {
      setOpen(false);
    };

    
    return (  
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="sm"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
           <Stack flexDirection="row" justifyContent="center">
                <Box pt={1} fontWeight="bold">Generate New Token</Box>
           </Stack>
        </BootstrapDialogTitle>
        <form onSubmit={onSubmit}>
        <DialogContent dividers >
          <Box className="create-team">
            <Box className='form-auth' >
                <Box pt={1}>Name <StarRequired/></Box>
                <TextField
                  margin="dense"
                  fullWidth
                  id="name"
                  placeholder="Token API"
                  name="name"
                  autoComplete="name"
                  size='small'
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required
                />
                <Box pt={2} pb={1}>Events <StarRequired/></Box>
                { loading ?
                <Select
                    placeholder="Loading.."
                    id="select-roles"
                />
                :
                <Select
                    placeholder="Select Event"
                    value={selectedEvents}
                    isSearchable={false}
                    options={optionsEvents && optionsEvents}
                    onChange={handleChangeRoles}
                    isMulti
                    id="select-roles"
                /> }
                { errorSelectedEvents && <Box pt={1} color="red" fontSize={14}>Event is required</Box> }
            </Box> 
          </Box> 
        </DialogContent>
        <DialogActions>
          <ButtonCreate 
            name="Submit"
            type="submit"
            />
        </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}

export default DialogAddToken;