import React, { useState, useRef, useEffect } from 'react'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { 
    ChatContainer, 
    MessageList, 
    Message, 
    MessageInput,
    Avatar,
    ConversationHeader,
 } from '@chatscope/chat-ui-kit-react';
 import profile from '../../../../assets/img/icon/profile.png'
 import start_conversation from '../../../../assets/img/icon/start-conversation.jpg'
import HeadlineTitle from '../../../../components/HeadlineTitle';
import { socket } from '../../../../utilities/socket';
import swal from 'sweetalert';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { userCredentials } from '../../../../utilities/config';

const ChatAction : React.FC<any> = ({width}) => {
    const { conversation_lists, conversation_room, conversation_active, loading_conversation_room } = useSelector((state:any) => state.chats);

    let messageEnd = useRef<null | HTMLDivElement>()

    const [messageInputValue, setMessageInputValue] = useState("");

    const autoScrollBottom = () =>{
        messageEnd.current?.scrollIntoView({ behavior : 'smooth'})
    }

    const typing = (e:any) => {
        if(e.key === 'Enter'){
            e.preventDefault();
            sendMsg();
        }        
    }

    const sendMsg = () => {
        const data = {
            to_company_id: conversation_active?.company_id,
            to_company_name: conversation_active?.company_name,
            to_company_logo: conversation_active?.company_logo,
            text: messageInputValue,
        };
        if(messageInputValue.length) {
            socket.emit('msg_to', data);
            setMessageInputValue('')
        }
    }
    
    // function isnotempty
    const isNotEmpty = (obj:any) => {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
            return true;
        }
        return false;
    }

    useEffect(() =>{
        autoScrollBottom()
    },[])

    const inputFile :any = useRef(null) 
    const handleAttach = () =>{
        inputFile.current.click();
    }

    const onChangeFileAttachment = (e:any) => {
        e.preventDefault()
        if(e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/jpg") {
            const random = uuid();
            setTimeout(() => {
                var S3 = require("aws-sdk/clients/s3");
                const s3bucket = new S3({
                    endpoint: process.env.REACT_APP_S3_BUCKET_ENDPOINT,
                    accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
                    secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET
                });
            
                if (e.target.files && e.target.files[0]) {
                const blob = e.target.files[0]
                const file_name = blob.name.replace(/\s/g, "")
                const params = { Body: blob, 
                                Bucket: process.env.REACT_APP_S3_BUCKET_NAME, 
                                Key: process.env.REACT_APP_S3_FOLDER_NAME + random + file_name
                                };
                s3bucket.putObject(params)
                .on('build', (request : any) => {
                    request.httpRequest.headers.Host = process.env.REACT_APP_S3_API_URL
                    request.httpRequest.headers['Content-Length'] = blob.size;
                    request.httpRequest.headers['Content-Type'] = blob.type;
                    request.httpRequest.headers['Access-Control-Allow-Origin']= '*';
                    request.httpRequest.headers['x-amz-acl'] = 'public-read';
                })
                .send((err : any, data : any) => {
                    if (err){  
                        swal(`${err}`, `${err.stack}`, 'error')
                    } 
                    else {      
                        const imageUrl = `${process.env.REACT_APP_S3_CDN_URL}${process.env.REACT_APP_S3_FOLDER_NAME}${random}${file_name}`
                        const data = {
                            to_company_id: conversation_active?.company_id,
                            to_company_name: conversation_active?.company_name,
                            to_company_logo: conversation_active?.company_logo,
                            text: "",
                            attachment : true, 
                            attachment_url : imageUrl
                        };
                        socket.emit('msg_to', data);
                    }}
                )} 
            }, 500);
        } else {
            swal("Error", "File type must be JPG, JPEG or PNG", "error")
        }
    }

    useEffect(() => {
        if(conversation_room.length > 0) {
            let last_msg = conversation_room.slice(-1)[0]
            if(last_msg?.from_company_id !== userCredentials.company_id) {
                socket.emit('read_msg', [last_msg?._id]);
            }
        }
    }, [conversation_room]);


    return (isNotEmpty(conversation_active) &&
    <>
        <ChatContainer>
            <ConversationHeader>
                <ConversationHeader.Back />
                <Avatar src={conversation_active.company_logo || profile} />
                <ConversationHeader.Content 
                    userName={conversation_active?.company_name} 
                    // info={conversation_active?.last_activity} 
                />
            </ConversationHeader>     
            <MessageList 
                loading={loading_conversation_room}
                autoScrollToBottomOnMount={true} 
                scrollBehavior='auto' 
                // typingIndicator={active_chat.typing?.length > 0 && <TypingIndicator content={active_chat.typing.join(', ')}/>}
            >
                {conversation_room.map((itm:any, idx:number) =>
                <Message 
                    style={{ maxWidth:'400px' }} 
                    key={idx}
                    model={{
                        message: itm.text,
                        sentTime: moment(itm.createdAt).fromNow(),
                        sender: itm.from_company_name,
                        direction:  itm.to_company_id === conversation_active.company_id? 'outgoing' : 'incoming',
                        position: "single"
                    }}                    
                >
                    { itm.attachment && 
                            <Message.ImageContent 
                                src={itm.attachment_url} 
                                alt="file attachment" 
                                width={200}
                                style={{cursor: 'pointer'}}
                                onClick={() => window.open(itm.attachment_url, '_blank')}
                            /> 
                        }
                    <Message.Header sender={itm.from_user_name} />
                    <Message.Footer sentTime={moment(itm.createdAt).fromNow()} />
                </Message>)
                }
            </MessageList>
            <MessageInput 
                onAttachClick={handleAttach}  
                placeholder="Type message here" 
                value={messageInputValue} 
                onChange={val => setMessageInputValue(val)} 
                onKeyUp={typing}
                onSend={sendMsg}
            />
        </ChatContainer> 
        <input 
            accept="image/*" 
            id="icon-button-banner" 
            type="file"  
            onChange={(e) => onChangeFileAttachment(e)} 
            ref={inputFile} 
            style={{display: 'none'}}
        />
        </>
    ) || (
        <Box sx={{ width: `${width}%`, margin:'auto'}}>
            <Box sx={{ margin:'auto', width:`${width}%`, display:'flex', justifyContent:'center', textAlign:'center'}} component='img' src={start_conversation} alt='chatting'/>
            <Box sx={{ display:'flex', flexDirection:'column', justifyContent:'center', textAlign:'center'}} >
                <HeadlineTitle 
                    title={conversation_lists.length < 0 ? "There is no message yet" :  "Start a conversation!" }
                    variant="h6"
                    bold="500"
                />
                <HeadlineTitle 
                    title={conversation_lists.length < 1 ? 
                        "You can start message with Buyer by clicking Chat Buyer button from page Preferred Buyer, Order and others."
                        : "Choose a Buyer from left side list to start a conversation."
                    }
                    variant="body2"
                    bold="400"
                />
            </Box>
        </Box>
    )
}

export default ChatAction
