import { Stack, Box, Button } from '@mui/material'
import {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { updateAddressCompany } from '../reducers/profilesReducers';
import AddressFormList from '../../../../components/AddressFormList';
import SnackBarAlert from '../../../../components/SnackBarAlert';
import { updateContactSnackBar } from '../profileSlice';
import { checkValidation } from '../../../../utilities/userFeaturesValidation';

const Address : React.FC<any> = ({ profile }) => {

    const { update_address, loading_update_address } = useSelector((state : RootState) => state.profile)

    const dispatch = useDispatch()
    
    const [valueAddress, setValueAddress] = useState({
        label : "",
        country : "Indonesia",
        error_country : false,
        province : "",
        error_province : false,
        city : "",
        error_city : false,
        subdistrict : "",
        error_subdistrict : false,
        village : "",
        error_village : false,
        postcode : "",
        error_postcode : false,
        street : ""
    });

    useEffect(() => {
        if(profile) {
            setValueAddress({...valueAddress, 
                country : profile.addresses[0].country,
                province : profile.addresses[0].province,
                city : profile.addresses[0].city,
                subdistrict : profile.addresses[0].subdistrict,
                village : profile.addresses[0].village,
                postcode : profile.addresses[0].postcode,
                street : profile.addresses[0].street,
                label : profile.addresses[0].label,
            })
        }
        // eslint-disable-next-line
    }, [profile]);
     
    const onClickSave = () => {
        const body = {
            id : profile.addresses[0]._id,
            data : {
                "company": profile.addresses[0].company,
                "label": valueAddress.label,
                "country": valueAddress.country,
                "province": valueAddress.province,
                "city": valueAddress.city,
                "subdistrict": valueAddress.subdistrict,
                "village": valueAddress.village,
                "postcode": valueAddress.postcode,
                "street": valueAddress.street,
                "phone": "null",
                "details": "null",
                "isdefault": "true"
            }
        }
        dispatch(updateAddressCompany(body))
    }

    useEffect(() => {
        if(update_address) {
            setTimeout(() => {
                dispatch(updateContactSnackBar())
            }, 3000);
        }
        // eslint-disable-next-line
    }, [update_address]);

    return (
        <Stack mt={1}>
            <Box>
                <AddressFormList 
                    setValueAddress={setValueAddress}
                    valueAddres={valueAddress}
                />
                { checkValidation('UPDCOMADD074657') &&
                <Stack sx={{my:3}} flexDirection="row" justifyContent="flex-end">
                    <Button 
                        variant='contained'
                        onClick={onClickSave}
                        disabled={loading_update_address}
                    >{loading_update_address ? "Loading.." : "Save"}</Button>
                </Stack> }
                <SnackBarAlert message={`Success update Address Company.`} initialState={update_address} />
            </Box>
        </Stack>
    )
}

export default Address
